import {
  FileExcelTwoTone,
  FileImageTwoTone,
  FilePdfTwoTone,
  FilePptTwoTone,
  FileUnknownTwoTone,
  FileWordTwoTone,
  FileZipTwoTone,
} from "@ant-design/icons";
import React from "react";

interface IFileImageProps {
  type: string;
}

const FileImage = (props: IFileImageProps): JSX.Element => {
  const { type } = props;
  switch (type) {
    case "image/jpeg":
      return (
        <FileImageTwoTone twoToneColor="orange" style={{ fontSize: 24 }} />
      );
      break;
    case "image/png":
      return (
        <FileImageTwoTone twoToneColor="orange" style={{ fontSize: 24 }} />
      );
      break;
    case "image/webp":
      return (
        <FileImageTwoTone twoToneColor="orange" style={{ fontSize: 24 }} />
      );
      break;
    case "image/tiff":
      return (
        <FileImageTwoTone twoToneColor="orange" style={{ fontSize: 24 }} />
      );
      break;
    case "image/svg+xml":
      return (
        <FileImageTwoTone twoToneColor="orange" style={{ fontSize: 24 }} />
      );
      break;
    case "application/vnd.ms-excel":
      return <FileExcelTwoTone twoToneColor="green" style={{ fontSize: 24 }} />;
      break;
    case "application/vnd.oasis.opendocument.spreadsheet":
      return <FileExcelTwoTone twoToneColor="green" style={{ fontSize: 24 }} />;
      break;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return <FileExcelTwoTone twoToneColor="green" style={{ fontSize: 24 }} />;
      break;
    case "application/msword":
      return <FileWordTwoTone twoToneColor="blue" style={{ fontSize: 24 }} />;
      break;
    case "application/rtf":
      return <FileWordTwoTone twoToneColor="blue" style={{ fontSize: 24 }} />;
      break;
    case "application/x-empty":
      return <FileWordTwoTone twoToneColor="blue" style={{ fontSize: 24 }} />;
      break;
    case "text/plain":
      return <FileWordTwoTone twoToneColor="blue" style={{ fontSize: 24 }} />;
      break;
    case "application/vnd.oasis.opendocument.text":
      return <FileWordTwoTone twoToneColor="blue" style={{ fontSize: 24 }} />;
      break;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return <FileWordTwoTone twoToneColor="blue" style={{ fontSize: 24 }} />;
      break;
    case "application/vnd.ms-powerpoint":
      return <FilePptTwoTone twoToneColor="crimson" style={{ fontSize: 24 }} />;
      break;
    case "application/vnd.oasis.opendocument.presentation":
      return <FilePptTwoTone twoToneColor="crimson" style={{ fontSize: 24 }} />;
      break;
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return <FilePptTwoTone twoToneColor="crimson" style={{ fontSize: 24 }} />;
      break;
    case "application/pdf":
      return <FilePdfTwoTone twoToneColor="red" style={{ fontSize: 24 }} />;
      break;
    case "application/vnd.rar":
      return <FileZipTwoTone twoToneColor="purple" style={{ fontSize: 24 }} />;
      break;
    case "application/x-tar":
      return <FileZipTwoTone twoToneColor="purple" style={{ fontSize: 24 }} />;
      break;
    case "application/gzip":
      return <FileZipTwoTone twoToneColor="purple" style={{ fontSize: 24 }} />;
      break;
    case "application/zip":
      return <FileZipTwoTone twoToneColor="purple" style={{ fontSize: 24 }} />;
      break;
    case "application/x-7z-compressed":
      return <FileZipTwoTone twoToneColor="purple" style={{ fontSize: 24 }} />;
      break;
    default:
      return (
        <FileUnknownTwoTone twoToneColor="darkgray" style={{ fontSize: 24 }} />
      );
      break;
  }
};

export default FileImage;
