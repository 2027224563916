import { AnyAction } from "redux";

import {
  FETCH_ROLES_FAILED,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
  SET_ROLES,
} from "../../actions/action-types";

export interface RoleGroup {
  id: number;
  name: string;
  sort: number;
  icon: string;
  roles: RoleModel[];
}
export interface RoleModel {
  id: number;
  name: string;
  description: string;
  defaultFor: number[];
  possibleFor: number[];
  sort: number;
}

export interface RolesState {
  roles: RoleGroup[];
  isRequest: boolean;
}

const initialState: RolesState = {
  roles: [],
  isRequest: false,
};

export default (state = initialState, action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_ROLES_REQUEST: {
      return {
        ...state,
        isRequest: true,
      };
    }
    case SET_ROLES: {
      return {
        ...state,
        roles: payload,
        isRequest: false,
      };
    }
    case FETCH_ROLES_FAILED: {
      return {
        ...state,
        isRequest: false,
      };
    }
    case FETCH_ROLES_SUCCESS: {
      return {
        ...state,
        roles: payload,
        isRequest: false,
      };
    }

    default:
      return state;
  }
};
