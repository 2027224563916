/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from "react";
import { Col, Divider, Empty, Input, Row, Space, Spin, Typography } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import classnames from "classnames";
import { Link } from "react-router-dom";
import SearchInput from "./SearchInput";
import SearchTicket from "./SearchTicket";
import TrimmedTitle from "../TrimmedTitle";
import SearchGroup from "./SearchGroup";

interface ILastVisitedProps {
  visible: boolean;
  onRowClick: () => void;
  isLoading: boolean;
  lastVisited: {
    tickets: any[];
    groups: any[];
  };
}
const LastVisited = (props: ILastVisitedProps) => {
  const { visible, lastVisited, onRowClick, isLoading } = props;

  const recentTickets = !!lastVisited.tickets.length;
  const recentGroups = !!lastVisited.groups.length;

  if (!visible) return null;

  if (!recentGroups && !recentTickets) {
    return (
      <Spin spinning={isLoading}>
        <Empty
          description={
            isLoading ? "Wczytywanie" : "Brak odwiedzonych działów i wątków"
          }
        />
      </Spin>
    );
  }

  return (
    <Row style={{ width: "100%" }} gutter={[16, 16]}>
      {recentTickets && (
        <Col xs={24} sm={24} md={recentGroups ? 16 : 20}>
          <p style={{ marginBottom: 16 }}>
            <strong>Ostatnio przeglądane wątki</strong>
          </p>
          {lastVisited.tickets.map((ticket) => {
            return <SearchTicket ticket={ticket} onClick={onRowClick} />;
          })}
        </Col>
      )}
      {recentGroups && (
        <Col xs={24} sm={24} md={recentTickets ? 8 : 24}>
          <p style={{ marginBottom: 16 }}>
            <strong>Ostatnio przeglądane działy</strong>
          </p>
          {lastVisited.groups.map((group) => {
            return <SearchGroup group={group} onClick={onRowClick} />;
          })}
        </Col>
      )}
    </Row>
  );
};

export default LastVisited;
