import React, { PropsWithChildren, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "moment/locale/pl";

import { Card, Col, Row, Space } from "antd";
import moment, { Moment } from "moment";
import { useParams } from "react-router-dom";
import { AppState } from "../../../reducers";
import CalendarPicker, {
  CalendarFilterOptions,
} from "../../Shared/CustomFilters/CalendarPicker";
import { getTicketGroupWorktimes } from "../../../actions/worktimes";
import { getTicketGroup } from "../../../actions/ticketgroups";
import {
  setCurrentEntityForbidden,
  setCurrentGroupTitle,
} from "../../../actions/global";
import WorktimesTable from "./WorktimesTable";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";

interface IRouteParams {
  groupId: string;
}

const TicketGroupWorktimesPage = (
  props: PropsWithChildren<any>
): JSX.Element => {
  const [filterVisible, setFilterVisible] = React.useState(false);
  const {
    worktimes,
    worktimesRequest,
    group,
    groupRequest,
    getTicketGroupWorktimesAction,
    setCurrentGroupTitleAction,
    getTicketGroupAction,
    setCurrentEntityForbiddenAction,
    displayNotification,
  } = props;
  const [filter, setFilter] = React.useState<{
    begin: number;
    end: number;
    selectedType: CalendarFilterOptions;
    group: number[];
  }>({
    begin: moment().startOf("month").unix(),
    end: moment().endOf("month").unix(),
    selectedType: CalendarFilterOptions.THIS_MONTH,
    group: [],
  });
  const { groupId } = useParams<IRouteParams>();

  const filterRef = useRef();

  useEffect(() => {
    setCurrentGroupTitleAction(group?.name);
  }, [group, setCurrentGroupTitleAction]);

  useEffect(() => {
    return () => {
      setCurrentEntityForbiddenAction(false);
    };
  }, [setCurrentEntityForbiddenAction]);

  useEffect(() => {
    if (groupId) getTicketGroupAction(groupId);
  }, [getTicketGroupAction, groupId]);

  useEffect(() => {
    getTicketGroupWorktimesAction(groupId, filter.begin, filter.end);
  }, [filter.begin, filter.end, getTicketGroupWorktimesAction, groupId]);

  return (
    <Can renderError type="ticket_group_worktimes_view">
      <div className="ticket-group-page">
        <MetaTitle
          title="Czas pracy w dziale"
          displayBadge={displayNotification}
        />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
            <Space
              direction="vertical"
              style={{ marginLeft: "auto", width: "100%" }}
            >
              <Card bordered={false}>
                <Row justify="space-between" gutter={[16, 16]}>
                  <Space>
                    <CalendarPicker
                      ref={filterRef}
                      begin={filter.begin}
                      selectedType={filter.selectedType}
                      end={filter.end}
                      onChange={(
                        begin: Moment,
                        end: Moment,
                        selectedType: CalendarFilterOptions
                      ) => {
                        setFilter({
                          begin: begin.unix(),
                          end: end.unix(),
                          group: [...filter.group],
                          selectedType,
                        });
                      }}
                    />
                  </Space>
                </Row>
              </Card>
              <Card bordered={false}>
                <WorktimesTable
                  ticketGroup={group}
                  data={worktimes?.data || []}
                  summary={worktimes?.summary || []}
                  isRequest={worktimesRequest}
                />
              </Card>
            </Space>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

const mapDispatchToProps = {
  getTicketGroupWorktimesAction: getTicketGroupWorktimes,
  getTicketGroupAction: getTicketGroup,
  setCurrentGroupTitleAction: setCurrentGroupTitle,
  setCurrentEntityForbiddenAction: setCurrentEntityForbidden,
};

const mapStateToProps = (state: AppState) => {
  return {
    group: state.ticketgroups.ticketgroup,
    groupRequest: state.ticketgroups.isFetchTicketGroupRequest,
    worktimes: state.worktime.ticketGroup,
    worktimesRequest: state.worktime.ticketGroupRequest,
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketGroupWorktimesPage);
