import React, {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { Row } from "antd";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import { Form, Input, SubmitButton } from "formik-antd";
import { IQuickEstimationFormValues } from "../../../../types/quick-forms";
import "moment/locale/pl";
import { openNotificationWithIcon } from "../../../../utils/common";

const EstimationForm: React.FC<any> = (
  props: PropsWithChildren<any>
): JSX.Element => {
  const formikRef = useRef<FormikValues>();

  const { value, onSubmit, setPopoverVisible } = props;

  const initialFormValues: IQuickEstimationFormValues = {
    estimation: value,
  };

  const [formData, setFormData] = useState<IQuickEstimationFormValues>(
    initialFormValues
  );

  useEffect(() => {
    setFormData({ estimation: 1 });
  }, [value]);

  const Schema = Yup.object().shape({
    estimation: Yup.number()
      .min(0, "Estymacja nie może być mniejsza niż 0h")
      .nullable()
      .required("Pole wymagane"),
  });

  return (
    <Formik
      innerRef={formikRef as MutableRefObject<any>}
      initialValues={formData}
      validationSchema={Schema}
      onSubmit={(
        values: IQuickEstimationFormValues,
        { setErrors, setSubmitting }
      ) => {
        onSubmit(
          values,
          () => {
            setPopoverVisible(false);
            setSubmitting(false);
            openNotificationWithIcon("success", "Estymacja zaktualizowana");
          },
          setErrors
        );
        // console.log(values, resetForm, setErrors);
      }}
      validateOnChange
      render={() => (
        <Form>
          <Form.Item
            label="Estymacja"
            required
            name="estimation"
            labelCol={{ span: 24 }}
            style={{ width: "200px" }}
            rules={[{ required: true, message: "Wprowadź estymację!" }]}
          >
            <Input
              type="number"
              name="estimation"
              style={{ width: "100%" }}
              suffix="godz."
            />
          </Form.Item>
          <Row justify="center">
            <SubmitButton>Zapisz</SubmitButton>
          </Row>
        </Form>
      )}
    />
  );
};

export default EstimationForm;
