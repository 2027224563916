import { AxiosResponse } from "axios";
import api from "../axiosInstance";

class ChatService {
  postMessage = async (
    room: string | number,
    comment: string,
    mentions: number[],
    tags: number[],
    notifyAll: boolean,
    attachment: []
  ): Promise<AxiosResponse> => {
    const response = await api.post(`chat/rooms/${room}`, {
      comment,
      mentions,
      tags,
      notifyAll,
      attachment,
    });
    return response;
  };

  getMessages = async (room: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`chat/rooms/${room}`);
    return response;
  };

  getLinkMessages = async (
    room: string | number,
    id: string | number
  ): Promise<AxiosResponse> => {
    const response = await api.get(`chat/rooms/${room}/link/${id}`);
    return response;
  };

  getNewMessages = async (room: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`chat/rooms/${room}/longpool`);
    return response;
  };

  getMessagesArchive = async (
    room: string | number,
    timestamp: number
  ): Promise<AxiosResponse> => {
    const response = await api.get(`chat/rooms/${room}/${timestamp}`);
    return response;
  };

  getNewMessagesCount = async (): Promise<AxiosResponse> => {
    const response = await api.get("chat/new-count");
    return response;
  };

  getChatUsers = async (room: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`chat/rooms/${room}/users`);
    return response;
  };

  getChatTickets = async (): Promise<AxiosResponse> => {
    const response = await api.get("chat/tickets");
    return response;
  };

  getChatUserTags = async (room: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`chat/rooms/${room}/tags`);
    return response;
  };

  getAttachments = async (
    room: string | number,
    id: number
  ): Promise<AxiosResponse> => {
    const response = await api.get(`chat/rooms/${room}/attachments/${id}`);
    return response;
  };

  getRooms = async (): Promise<AxiosResponse> => {
    const response = await api.get(`chat/rooms`);
    return response;
  };

  removeRoomMessages = async (
    groupId: string | number
  ): Promise<AxiosResponse> => {
    const response = await api.delete(`chat/ticketgroup/${groupId}`);
    return response;
  };

  postReaction = async (
    messageId: string | number,
    reaction: string
  ): Promise<AxiosResponse> => {
    const response = await api.post(`chat/${messageId}/reaction`, {
      reaction,
    });
    return response;
  };
}
export default new ChatService();
