import { AxiosResponse } from "axios";
import api from "../axiosInstance";

class NotificationService {
  getNotifications = async (): Promise<AxiosResponse> => {
    const response = await api.get(`notifications`);
    return response;
  };

  getAllNotifications = async (): Promise<AxiosResponse> => {
    const response = await api.get(`notifications/all`);
    return response;
  };

  readNotifications = async (id: number): Promise<AxiosResponse> => {
    const response = await api.get(`notifications/read/${id}`);
    return response;
  };

  clearNotifications = async (): Promise<AxiosResponse> => {
    const response = await api.get(`notifications/clear`);
    return response;
  };
}
export default new NotificationService();
