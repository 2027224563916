import { useEffect, useState } from "react";
import _ from "lodash";
import api, { removeToken, setToken } from "../services/axiosInstance";

// Rejects the promise after ms
const timeoutAfterMs = (ms: number) =>
  new Promise((__, reject) => setTimeout(reject, ms));

// Returns a stringified version of the arguments meants as key for the memoize function
const keyResolver = (
  pollingUrl: string,
  interval: number,
  timeout: number,
  method: string,
  isIdle: boolean
) => [pollingUrl, interval, timeout, method, isIdle].join("_");

// This function actually polls the endpoint
const pollFunction = (
  pollingUrl: string,
  timeout: number,
  method: string,
  isIdle: boolean
) => {
  return Promise.race([
    api.post(pollingUrl, { idle: isIdle }),
    timeoutAfterMs(timeout),
  ]);
};

// This function returns a throttled version of the polling function
const getPollFunc = (
  pollingUrl: string,
  interval: number,
  timeout: number,
  method: string,
  isIdle: boolean
) =>
  _.throttle(
    () => pollFunction(pollingUrl, timeout, method, isIdle),
    interval,
    {
      leading: true,
      trailing: true,
    }
  );

// This function returns a memoized throttled version from
// If this is called with a different method, timeout, interval or url argument, a new polling function is returned

export const getPoller = _.memoize(getPollFunc, keyResolver);

const useIsOnline = (
  pollingUrl: false | string = false,
  isIdle: boolean,
  {
    timeout = 10000,
    interval = 10000,
    method = "POST",
    onPollingError = (error: Error) => {},
  } = {}
) => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const poller = getPoller(
      pollingUrl as string,
      interval,
      timeout,
      method,
      isIdle
    );

    const poll = async () => {
      try {
        await poller();
        setIsOnline(true);
      } catch (err) {
        if (onPollingError) onPollingError(err);
        setIsOnline(false);
      }
    };
    poll();
    const handle = pollingUrl && setInterval(poll, interval);

    return () => {
      if (handle) clearInterval(handle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline, isIdle]);

  return isOnline;
};

export default useIsOnline;
