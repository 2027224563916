import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import "moment/locale/pl";

import { Badge, Button, Card, Col, Drawer, Row, Space, Tooltip } from "antd";
import { AxiosResponse } from "axios";
import List from "./list";

import NotificationsService from "../../../services/notification-service";
import { Notification } from "../../UserNotifications/UserNotifications";
import { MetaTitle } from "../../Shared/MetaTitle";
import { createTicket } from "../../../actions/tickets";
import { AppState } from "../../../reducers";

const NotificationsPage = (props: PropsWithChildren<any>): JSX.Element => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(false);
  const timer = useRef<any>(null);

  const { displayNotification } = props;
  const readNotifications = (id: number) => {
    NotificationsService.readNotifications(id);
  };

  const poolNotifications = () => {
    NotificationsService.getAllNotifications()
      .then((response) => {
        setNotifications(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    NotificationsService.getAllNotifications()
      .then((response) => {
        setNotifications(response.data);
      })
      .finally(() => {
        setLoading(false);
        timer.current = setInterval(() => poolNotifications(), 5000);
      });

    return () => {
      clearInterval(timer.current);
    };
  }, []);

  return (
    <>
      <div className="notifications-page">
        <MetaTitle title="Powiadomienia" displayBadge={displayNotification} />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
            <Space
              direction="vertical"
              style={{ marginLeft: "auto", width: "100%" }}
            >
              <Card bordered={false}>
                <List
                  data={notifications}
                  isRequest={loading}
                  readNotification={readNotifications}
                />
              </Card>
            </Space>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(mapStateToProps, null)(NotificationsPage);
