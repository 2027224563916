import React, { MutableRefObject, PropsWithChildren, useRef } from "react";
import { Form, Input, SubmitButton } from "formik-antd";
import { Button, Col, Row, Space } from "antd";
import { Formik, FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import { openNotificationWithIcon } from "../../../../utils/common";
import { IQuickEndWorktimeFormValues } from "../../../../types/quick-forms";
import "moment/locale/pl";

const EndWorkTimeForm: React.FC<any> = (
  props: PropsWithChildren<any>
): JSX.Element => {
  const formikRef = useRef<FormikValues>();

  const { onSubmit, setPopoverVisible, worktimeId, onCancel } = props;

  const initialFormValues: IQuickEndWorktimeFormValues = {
    id: worktimeId,
    comment: "",
  };

  const Schema = Yup.object().shape({
    comment: Yup.string()
      .required("Pole wymagane")
      .min(1, "Pole wymagane")
      .typeError("Wprowadź komentarz"),
  });

  return (
    <Formik
      innerRef={formikRef as MutableRefObject<any>}
      initialValues={initialFormValues}
      validationSchema={Schema}
      enableReinitialize
      onSubmit={(
        values: IQuickEndWorktimeFormValues,
        { resetForm, setErrors, setSubmitting }
      ) => {
        onSubmit(
          {
            id: values.id,
            comment: values.comment,
          },
          () => {
            setPopoverVisible(false);
            setSubmitting(false);
            resetForm();
            openNotificationWithIcon("success", "Czas dodany.");
          },
          setErrors
        );
      }}
      validateOnChange
      render={(formProps: FormikProps<any>) => (
        <Form>
          <Row justify="center" align="middle">
            <Col span={24}>
              <Form.Item
                label="Komentarz do pracy"
                name="comment"
                required
                labelCol={{ span: 24 }}
                style={{ marginBottom: "4px", marginTop: "4px" }}
              >
                <Input.TextArea name="comment" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify="end" style={{ width: "100%" }}>
            <Col span={24}>
              <Space
                align="end"
                style={{
                  width: "100%",
                  justifyContent: "flex-end",
                  paddingTop: "8px",
                  marginTop: "8px",
                  borderTop: "1px solid #eee",
                }}
              >
                <Button onClick={() => setPopoverVisible(false)}>Anuluj</Button>
                <SubmitButton>Zapisz</SubmitButton>
              </Space>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

export default EndWorkTimeForm;
