import { Dispatch } from "redux";
import { RcFile } from "antd/es/upload";
import { AxiosError } from "axios";
import TaskGroupsService from "../../services/taskgroups-service";
import {
  BULK_ASSIGN_TICKET_GROUPS_LEADERS_FAILED,
  BULK_ASSIGN_TICKET_GROUPS_LEADERS_REQUEST,
  BULK_ASSIGN_TICKET_GROUPS_LEADERS_SUCCESS,
  BULK_ASSIGN_TICKET_GROUPS_MEMBERS,
  BULK_ASSIGN_TICKET_GROUPS_MEMBERS_FAILED,
  BULK_ASSIGN_TICKET_GROUPS_MEMBERS_SUCCESS,
  CLEAR_TICKET_GROUP_STATE,
  CLEAR_TICKET_GROUPS_STATE,
  CREATE_TICKET_GROUP_REQUEST,
  CREATE_TICKET_GROUP_REQUEST_FAILED,
  CREATE_TICKET_GROUP_REQUEST_SUCCESS,
  CREATE_TICKET_GROUPS_TICKET_TYPE,
  CREATE_TICKET_GROUPS_TICKET_TYPE_FAILED,
  CREATE_TICKET_GROUPS_TICKET_TYPE_SUCCESS,
  DELETE_TICKET_GROUPS_LEADER_FAILED,
  DELETE_TICKET_GROUPS_LEADER_REQUEST,
  DELETE_TICKET_GROUPS_LEADER_SUCCESS,
  DELETE_TICKET_GROUPS_MEMBER,
  DELETE_TICKET_GROUPS_MEMBER_FAILED,
  DELETE_TICKET_GROUPS_MEMBER_SUCCESS,
  DELETE_TICKET_GROUPS_TICKET_TYPE,
  DELETE_TICKET_GROUPS_TICKET_TYPE_FAILED,
  DELETE_TICKET_GROUPS_TICKET_TYPE_SUCCESS,
  DELETE_USER_GROUP_MEMBERSHIP,
  EDIT_TICKET_GROUP_FAILED,
  EDIT_TICKET_GROUP_REQUEST,
  EDIT_TICKET_GROUP_SUCCESS,
  FETCH_NOTES,
  FETCH_TICKET_GROUP_FAILED,
  FETCH_TICKET_GROUP_REQUEST,
  FETCH_TICKET_GROUP_SUCCESS,
  FETCH_TICKET_GROUPS_FAILED,
  FETCH_TICKET_GROUPS_LEADERS_FAILED,
  FETCH_TICKET_GROUPS_LEADERS_REQUEST,
  FETCH_TICKET_GROUPS_LEADERS_SUCCESS,
  FETCH_TICKET_GROUPS_MEMBERS_FAILED,
  FETCH_TICKET_GROUPS_MEMBERS_REQUEST,
  FETCH_TICKET_GROUPS_MEMBERS_SUCCESS,
  FETCH_TICKET_GROUPS_REQUEST,
  FETCH_TICKET_GROUPS_SUCCESS,
  FETCH_TICKET_GROUPS_TICKET_TYPES_FAILED,
  FETCH_TICKET_GROUPS_TICKET_TYPES_REQUEST,
  FETCH_TICKET_GROUPS_TICKET_TYPES_SUCCESS,
  SET_CURRENT_ENTITY_FORBIDDEN,
  SET_TICKET_GROUP,
  SOFTDELETE_TICKET_GROUPS_FAILED,
  SOFTDELETE_TICKET_GROUPS_REQUEST,
  SOFTDELETE_TICKET_GROUPS_SUCCESS,
  UPDATE_TICKET_GROUPS_LEADER_FAILED,
  UPDATE_TICKET_GROUPS_LEADER_REQUEST,
  UPDATE_TICKET_GROUPS_LEADER_SUCCESS,
  UPDATE_TICKET_GROUPS_POSITIONS_FAILED,
  UPDATE_TICKET_GROUPS_POSITIONS_REQUEST,
  UPDATE_TICKET_GROUPS_POSITIONS_SUCCESS,
  UPDATE_TICKET_GROUPS_TICKET_TYPE,
  UPDATE_TICKET_GROUPS_TICKET_TYPE_FAILED,
  UPDATE_TICKET_GROUPS_TICKET_TYPE_SUCCESS,
} from "../action-types";

export const getTicketGroups = (noLoader: boolean) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_TICKET_GROUPS_REQUEST,
    payload: !noLoader,
  });
  return TaskGroupsService.getGroups().then(
    (response) => {
      dispatch({
        type: FETCH_TICKET_GROUPS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_TICKET_GROUPS_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const getTicketGroupsAdmin = (noLoader: boolean) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_TICKET_GROUPS_REQUEST,
    payload: !noLoader,
  });
  return TaskGroupsService.getGroupsAdmin().then(
    (response) => {
      dispatch({
        type: FETCH_TICKET_GROUPS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_TICKET_GROUPS_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const clearTicketGroupState = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: CLEAR_TICKET_GROUP_STATE,
  });

  return Promise.resolve();
};

export const clearTicketGroupsState = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: CLEAR_TICKET_GROUPS_STATE,
  });

  return Promise.resolve();
};

export const getTicketGroup = (id: string | number) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_TICKET_GROUP_REQUEST,
    payload: true,
  });
  return TaskGroupsService.getGroup(id).then(
    (response) => {
      dispatch({
        type: FETCH_TICKET_GROUP_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error: AxiosError) => {
      dispatch({
        type: FETCH_TICKET_GROUP_FAILED,
      });

      if (error.response?.status === 403) {
        dispatch({
          type: SET_CURRENT_ENTITY_FORBIDDEN,
          payload: true,
        });
      }
      return Promise.reject();
    }
  );
};

export const uploadLogo = (
  id: string | number,
  file: RcFile | Blob | string
) => (dispatch: Dispatch<any>) => {
  return TaskGroupsService.uploadLogo(id, file).then(
    (response) => {
      dispatch({
        type: SET_TICKET_GROUP,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const removeAvatar = (id: string | number) => (
  dispatch: Dispatch<any>
) => {
  return TaskGroupsService.editTicketGroup(id, { img: "" }).then(
    (response) => {
      dispatch({
        type: SET_TICKET_GROUP,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const getTicketGroupMembers = (id: string | number) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_TICKET_GROUPS_MEMBERS_REQUEST,
    payload: true,
  });
  return TaskGroupsService.getGroupMembers(id).then(
    (response) => {
      dispatch({
        type: FETCH_TICKET_GROUPS_MEMBERS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_TICKET_GROUPS_MEMBERS_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const getTicketGroupLeaders = (id: string | number) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_TICKET_GROUPS_LEADERS_REQUEST,
    payload: true,
  });
  return TaskGroupsService.getGroupLeaders(id).then(
    (response) => {
      dispatch({
        type: FETCH_TICKET_GROUPS_LEADERS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_TICKET_GROUPS_LEADERS_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const createTicketGroup = (ticketGroup: any) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: CREATE_TICKET_GROUP_REQUEST,
    payload: true,
  });
  return TaskGroupsService.createGroup(ticketGroup).then(
    (response) => {
      dispatch({
        type: CREATE_TICKET_GROUP_REQUEST_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: CREATE_TICKET_GROUP_REQUEST_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};

export const updateTicketGroupPositions = (
  type: string,
  groupsIds: string[],
  parent?: number
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: UPDATE_TICKET_GROUPS_POSITIONS_REQUEST,
    payload: true,
  });
  return TaskGroupsService.updatePositions(type, groupsIds, parent).then(
    (response) => {
      dispatch({
        type: UPDATE_TICKET_GROUPS_POSITIONS_SUCCESS,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: UPDATE_TICKET_GROUPS_POSITIONS_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const editTicketGroup = (id: string | number, data: any) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: EDIT_TICKET_GROUP_REQUEST,
    payload: true,
  });
  return TaskGroupsService.editTicketGroup(id, data).then(
    (response) => {
      dispatch({
        type: EDIT_TICKET_GROUP_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: EDIT_TICKET_GROUP_FAILED,
      });
      return Promise.reject(error);
    }
  );
};

export const softDeleteTicketGroup = (id: string) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: SOFTDELETE_TICKET_GROUPS_REQUEST,
    payload: true,
  });

  return TaskGroupsService.softDeleteTicketGroup(id).then(
    (response) => {
      dispatch({
        type: SOFTDELETE_TICKET_GROUPS_SUCCESS,
        payload: id,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: SOFTDELETE_TICKET_GROUPS_FAILED,
        payload: id,
      });

      return Promise.reject(error);
    }
  );
};

export const bulkAddTicketGroupLeaders = (
  id: string | number,
  users: number[]
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: BULK_ASSIGN_TICKET_GROUPS_LEADERS_REQUEST,
    payload: true,
  });

  const requests: any[] = [];
  users.forEach((userId) => {
    requests.push(TaskGroupsService.assignLeader(id, userId));
  });

  return Promise.all(requests).then(
    (response) => {
      const responseData = response
        .filter((res) => res.status === 201)
        .map((res) => res.data);
      dispatch({
        type: BULK_ASSIGN_TICKET_GROUPS_LEADERS_SUCCESS,
        payload: responseData,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: BULK_ASSIGN_TICKET_GROUPS_LEADERS_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};

export const updateTicketGroupLeader = (
  id: string | number,
  userId: string | number,
  data: any
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: UPDATE_TICKET_GROUPS_LEADER_REQUEST,
    payload: true,
  });

  return TaskGroupsService.updateLeader(id, userId, data).then(
    (response) => {
      dispatch({
        type: UPDATE_TICKET_GROUPS_LEADER_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: UPDATE_TICKET_GROUPS_LEADER_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};

export const removeTicketGroupLeader = (
  id: string | number,
  userId: string | number
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: DELETE_TICKET_GROUPS_LEADER_REQUEST,
    payload: true,
  });

  return TaskGroupsService.removeLeader(id, userId).then(
    (response) => {
      dispatch({
        type: DELETE_TICKET_GROUPS_LEADER_SUCCESS,
        payload: userId,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: DELETE_TICKET_GROUPS_LEADER_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};

export const bulkAddTicketGroupMembers = (
  id: string | number,
  users: number[]
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: BULK_ASSIGN_TICKET_GROUPS_MEMBERS,
    payload: true,
  });

  const requests: any[] = [];
  users.forEach((userId) => {
    requests.push(TaskGroupsService.assignMember(id, userId));
  });

  return Promise.all(requests).then(
    (response) => {
      const responseData = response
        .filter((res) => res.status === 201)
        .map((res) => res.data);
      dispatch({
        type: BULK_ASSIGN_TICKET_GROUPS_MEMBERS_SUCCESS,
        payload: responseData,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: BULK_ASSIGN_TICKET_GROUPS_MEMBERS_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};

export const removeTicketGroupMember = (
  id: string | number,
  userId: string | number
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: DELETE_TICKET_GROUPS_MEMBER,
    payload: true,
  });

  return TaskGroupsService.removeMember(id, userId).then(
    (response) => {
      dispatch({
        type: DELETE_TICKET_GROUPS_MEMBER_SUCCESS,
        payload: userId,
      });
      dispatch({
        type: DELETE_USER_GROUP_MEMBERSHIP,
        payload: id,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: DELETE_TICKET_GROUPS_MEMBER_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};

export const getTicketGroupTicketTypes = (id: string | number) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_TICKET_GROUPS_TICKET_TYPES_REQUEST,
    payload: true,
  });
  return TaskGroupsService.getGroupTicketTypes(id).then(
    (response) => {
      dispatch({
        type: FETCH_TICKET_GROUPS_TICKET_TYPES_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_TICKET_GROUPS_TICKET_TYPES_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const createTicketGroupTicketType = (id: string | number, data: any) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: CREATE_TICKET_GROUPS_TICKET_TYPE,
    payload: true,
  });

  return TaskGroupsService.createTicketType(id, data).then(
    (response) => {
      dispatch({
        type: CREATE_TICKET_GROUPS_TICKET_TYPE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: CREATE_TICKET_GROUPS_TICKET_TYPE_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};

export const updateTicketGroupTicketType = (
  id: string | number,
  typeId: string | number,
  data: any
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: UPDATE_TICKET_GROUPS_TICKET_TYPE,
    payload: true,
  });

  return TaskGroupsService.updateTicketType(id, typeId, data).then(
    (response) => {
      dispatch({
        type: UPDATE_TICKET_GROUPS_TICKET_TYPE_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: UPDATE_TICKET_GROUPS_TICKET_TYPE_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};

export const removeTicketGroupTicketType = (
  id: string | number,
  typeId: string | number
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: DELETE_TICKET_GROUPS_TICKET_TYPE,
    payload: true,
  });

  return TaskGroupsService.removeTicketType(id, typeId).then(
    (response) => {
      dispatch({
        type: DELETE_TICKET_GROUPS_TICKET_TYPE_SUCCESS,
        payload: typeId,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: DELETE_TICKET_GROUPS_TICKET_TYPE_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};
