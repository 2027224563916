/* eslint-disable react-hooks/exhaustive-deps */
import React, { ForwardedRef, forwardRef, useEffect, useMemo } from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

import { Delta } from "quill";
import {
  defaultEditorFormats,
  defaultEditorModules,
  defaultEditorModulesWithMedia,
} from "../../../utils/common";

const QuillEditor = forwardRef((props: any, ref: ForwardedRef<any>) => {
  const {
    className,
    value,
    onChange,
    initValue,
    withMedia,
    reinitialize,
  } = props;

  const { quill, quillRef, Quill } = useQuill({
    modules: withMedia ? defaultEditorModulesWithMedia : defaultEditorModules,
    formats: defaultEditorFormats,
    theme: "snow",
  });

  // console.log("rendered");
  if (Quill && !quill) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
    const MagicUrl = require("quill-magic-url").default;
    Quill.register("modules/magicUrl", MagicUrl);
  }

  useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldDelta, source) => {
        onChange(quill.root.innerHTML);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quill]);

  useEffect(() => {}, [reinitialize]);

  useEffect(() => {
    if (quill) {
      const delta = quill.clipboard.convert(initValue || "");
      quill.setContents(delta);
    }
  }, [initValue, reinitialize, quill]);

  useEffect(() => {
    if (initValue === value) {
      if (quill) {
        const delta = quill.clipboard.convert(initValue || "");
        quill.setContents(delta);
      }
    }
  }, [initValue, reinitialize, value]);

  // useEffect(() => {
  //   const clearValue = () => {
  //     if (quill) {
  //       const delta = quill.clipboard.convert("");
  //       quill.setContents(delta);
  //     }
  //   };
  //
  //   if (clear > 0) clearValue();
  // }, [clear]);

  return (
    <div className={`quill ${className}`} ref={ref}>
      <div ref={quillRef} />
    </div>
  );
});

export default QuillEditor;
