import React, {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { DatePicker, Form, SubmitButton, Switch, Input } from "formik-antd";
import { Button, Col, Divider, Row, Space } from "antd";
import { Formik, FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import moment from "moment";
import locale from "antd/es/date-picker/locale/pl_PL";
import { openNotificationWithIcon } from "../../../../utils/common";
import {
  IQuickAddWorktimeFormValues,
  IQuickDueDateFormValues,
} from "../../../../types/quick-forms";
import "moment/locale/pl";

const AddWorkTimeForm: React.FC<any> = (
  props: PropsWithChildren<any>
): JSX.Element => {
  const formikRef = useRef<FormikValues>();

  const { onSubmit, setPopoverVisible } = props;

  const initialFormValues: IQuickAddWorktimeFormValues = {
    start: null,
    seconds: null,
    comment: "",
  };

  const Schema = Yup.object().shape({
    start: Yup.date().required("Pole wymagane").typeError("Wprowadź datę"),
    seconds: Yup.number()
      .required("Pole wymagane")
      .min(1, "Wartość nie może być mniejsza niż 1")
      .typeError("Wprowadź czas w minutach"),
  });

  return (
    <Formik
      innerRef={formikRef as MutableRefObject<any>}
      initialValues={initialFormValues}
      validationSchema={Schema}
      enableReinitialize
      onSubmit={(
        values: IQuickAddWorktimeFormValues,
        { resetForm, setErrors, setSubmitting }
      ) => {
        onSubmit(
          {
            start: values.start ? moment(values.start).unix() : null,
            seconds: values.seconds ? values.seconds * 60 : 0,
            comment: values.comment,
          },
          () => {
            setPopoverVisible(false);
            setSubmitting(false);
            resetForm();
            openNotificationWithIcon("success", "Czas dodany.");
          },
          setErrors
        );
      }}
      validateOnChange
      render={(formProps: FormikProps<any>) => (
        <Form>
          <Row justify="center" align="middle">
            <Col span={24}>
              <Form.Item
                label="Data początkowa"
                required
                name="start"
                labelCol={{ span: 24 }}
                rules={[
                  { required: true, message: "Wprowadź datę początkową!" },
                ]}
              >
                <DatePicker
                  getPopupContainer={(trigger) =>
                    trigger.parentElement?.parentElement as HTMLElement
                  }
                  style={{ minWidth: "100%" }}
                  locale={locale}
                  onChange={(change) => {
                    formProps.setFieldValue("start", change?.toDate());
                  }}
                  format="DD.MM.Y HH:mm"
                  showTime={{ showSecond: false }}
                  name="start"
                />
              </Form.Item>
              <Form.Item
                label="Czas w minutach"
                required
                name="seconds"
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: "Wprowadź czas!" }]}
              >
                <Input type="number" name="seconds" />
              </Form.Item>

              <Form.Item
                label="Komentarz do pracy"
                name="comment"
                labelCol={{ span: 24 }}
                style={{ marginBottom: "4px", marginTop: "4px" }}
              >
                <Input.TextArea name="comment" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify="end" style={{ width: "100%" }}>
            <Col span={24}>
              <Space
                align="end"
                style={{
                  width: "100%",
                  justifyContent: "flex-end",
                  paddingTop: "8px",
                  marginTop: "8px",
                  borderTop: "1px solid #eee",
                }}
              >
                <Button
                  onClick={() => {
                    formProps.resetForm();
                    setPopoverVisible(false);
                  }}
                >
                  Anuluj
                </Button>
                <SubmitButton>Zapisz</SubmitButton>
              </Space>
            </Col>
          </Row>
        </Form>
      )}
    />
  );
};

export default AddWorkTimeForm;
