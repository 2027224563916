import { Dispatch } from "redux";

import { AxiosError, AxiosResponse } from "axios";
import {
  FETCH_LAST_VISITED,
  FETCH_LAST_VISITED_FAILED,
  FETCH_LAST_VISITED_SUCCESS,
  FETCH_LINK_MESSAGES,
  FETCH_LINK_MESSAGES_FAILED,
  FETCH_LINK_MESSAGES_SUCCESS,
  SET_ARCHIVED_COUNT,
  SET_CURRENT_ENTITY_FORBIDDEN,
  SET_CURRENT_GROUP_TITLE,
  SET_CURRENT_TICKET_TITLE,
  SET_EDITED_USER,
  SET_LOGGED_USER,
} from "../action-types";

import SearchService from "../../services/search-service";

export const setCurrentTicketTitle = (title: string) => (
  dispatch: Dispatch<any>
) => {
  return dispatch({
    type: SET_CURRENT_TICKET_TITLE,
    payload: title,
  });
};

export const setCurrentGroupTitle = (title: string | null) => (
  dispatch: Dispatch<any>
) => {
  return dispatch({
    type: SET_CURRENT_GROUP_TITLE,
    payload: title,
  });
};

export const setCurrentEditedUser = (name: string | null) => (
  dispatch: Dispatch<any>
) => {
  return dispatch({
    type: SET_EDITED_USER,
    payload: name,
  });
};

export const setCurrentEntityForbidden = (value: boolean) => (
  dispatch: Dispatch<any>
) => {
  return dispatch({
    type: SET_CURRENT_ENTITY_FORBIDDEN,
    payload: value,
  });
};

export const setArchivedCount = (value: number) => (
  dispatch: Dispatch<any>
) => {
  return dispatch({
    type: SET_ARCHIVED_COUNT,
    payload: value,
  });
};

export const getLastVisited = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_LAST_VISITED,
    payload: true,
  });
  return SearchService.getLastVisited().then(
    (response) => {
      dispatch({
        type: FETCH_LAST_VISITED_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FETCH_LAST_VISITED_FAILED,
      });
      return Promise.reject();
    }
  );
};
