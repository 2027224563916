import React, { PropsWithChildren } from "react";
import { Empty, Spin } from "antd";
import SingleMonth from "./SingleMonth";
import { Disposable } from "../../../types/ticket-group";

const WorktimesTable = (props: PropsWithChildren<any>): JSX.Element => {
  const { data, summary, isRequest, handleRemove } = props;
  return (
    <Spin spinning={isRequest}>
      {Object.keys(data).map((key: any, index) => {
        const month = data[key];
        return (
          <SingleMonth
            month={key}
            summary={summary.months[key]}
            data={month}
            totals={Object.keys(data).length - 1 === index ? summary : null}
            handleRemove={handleRemove}
          />
        );
      })}
      {!isRequest && !Object.values(data).length && (
        <Empty description="Brak raportów pracy dla tego wątku" />
      )}
    </Spin>
  );
};

export default WorktimesTable;
