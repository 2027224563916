import React, {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { Form, Input, SubmitButton, Switch } from "formik-antd";
import { Col, Row } from "antd";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import { IQuickCostFormValues } from "../../../../types/quick-forms";
import "moment/locale/pl";
import { openNotificationWithIcon } from "../../../../utils/common";

const CostForm: React.FC<any> = (
  props: PropsWithChildren<any>
): JSX.Element => {
  const formikRef = useRef<FormikValues>();

  const { cost, pay, onSubmit, setPopoverVisible } = props;

  const initialFormValues: IQuickCostFormValues = {
    cost,
    pay,
  };

  const [formData, setFormData] = useState<IQuickCostFormValues>(
    initialFormValues
  );

  useEffect(() => {
    setFormData({
      cost,
      pay,
    });
  }, [cost, pay]);

  const Schema = Yup.object().shape({
    cost: Yup.number().min(0, "Cena nie może być mniejsza niż 0 zł").nullable(),
  });

  return (
    <Formik
      innerRef={formikRef as MutableRefObject<any>}
      initialValues={formData}
      validationSchema={Schema}
      onSubmit={(
        values: IQuickCostFormValues,
        { resetForm, setErrors, setSubmitting }
      ) => {
        onSubmit(
          {
            cost: values.cost,
            pay: values.pay,
          },
          () => {
            setPopoverVisible(false);
            setSubmitting(false);
            openNotificationWithIcon("success", "Wycena zaktualizowana.");
          },
          setErrors
        );
      }}
      validateOnChange
      render={() => (
        <Form>
          <Row justify="start" align="middle">
            <Col span={24}>
              <Form.Item
                label="Koszt"
                required
                name="cost"
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: "Wprowadź koszt!" }]}
              >
                <Input
                  type="number"
                  name="cost"
                  style={{ width: "100%" }}
                  suffix="zł."
                />
              </Form.Item>
            </Col>
            {/* <Col span={6} style={{ marginLeft: "8px" }}> */}
            {/*  <Form.Item */}
            {/*    name="pay" */}
            {/*    valuePropName="checked" */}
            {/*    style={{ */}
            {/*      marginBottom: "14px", */}
            {/*      marginTop: "26px", */}
            {/*    }} */}
            {/*  > */}
            {/*    <Switch name="pay" checkedChildren="$" unCheckedChildren="$" /> */}
            {/*  </Form.Item> */}
            {/* </Col> */}
          </Row>
          <Row justify="center">
            <SubmitButton type="primary">Zapisz</SubmitButton>
          </Row>
        </Form>
      )}
    />
  );
};

export default CostForm;
