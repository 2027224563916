import { Tag, Tooltip } from "antd";
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

interface ITicketTypeProps {
  ticketType: ITicketType | null;
  // eslint-disable-next-line react/require-default-props
  submitting?: boolean | null;
}

interface ITicketType {
  name: string;
  color: string;
}

const TicketType = (props: ITicketTypeProps) => {
  const { ticketType, submitting } = props;

  if (!ticketType) {
    return (
      <Tooltip title="Brak">
        <Tag
          color="#aaa"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
          }}
        >
          {submitting ? (
            <LoadingOutlined spin style={{ fontSize: 10 }} />
          ) : (
            "Brak"
          )}
        </Tag>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      mouseEnterDelay={1}
      title={
        submitting ? (
          <LoadingOutlined spin style={{ fontSize: 10 }} />
        ) : (
          ticketType.name
        )
      }
    >
      <Tag
        color={ticketType.color}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
        }}
      >
        {submitting ? (
          <LoadingOutlined spin style={{ fontSize: 10 }} />
        ) : (
          ticketType.name
        )}
      </Tag>
    </Tooltip>
  );
};

export default TicketType;
