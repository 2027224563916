import { AnyAction } from "redux";

import { stat } from "fs";
import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_FAILED,
  FETCH_NOTIFICATIONS_SUCCESS,
  READ_TICKET_NOTIFICATIONS,
  SET_NEW_NOTIFICATION,
} from "../../actions/action-types";

export interface NotificationsState {
  notifications: any;
  newNotificationIndicator: boolean;
  isFetchRequest: boolean;
}

const initialState: NotificationsState = {
  notifications: null,
  isFetchRequest: false,
  newNotificationIndicator: false,
};

export default (state = initialState, action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_NOTIFICATIONS: {
      return {
        ...state,
        isFetchRequest: true,
      };
    }
    case FETCH_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        notifications: payload,
        isFetchRequest: false,
      };
    }
    case FETCH_NOTIFICATIONS_FAILED: {
      return {
        ...state,
        isFetchRequest: false,
      };
    }
    case SET_NEW_NOTIFICATION: {
      return {
        ...state,
        newNotificationIndicator: payload,
      };
    }
    case READ_TICKET_NOTIFICATIONS: {
      if (!state.notifications?.data) {
        return state;
      }
      return {
        ...state,
        notifications: {
          ...state.notifications,
          data: [
            ...state.notifications?.data?.map((notification: any) => {
              return {
                ...notification,
                isRead:
                  notification.ticket?.id === payload
                    ? true
                    : notification.isRead,
              };
            }),
          ],
        },
      };
    }

    default:
      return state;
  }
};
