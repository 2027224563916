import { AxiosResponse } from "axios";
import { RcFile } from "antd/lib/upload/interface";
import api from "../axiosInstance";

class TaskGroupsService {
  getGroupsHome = async (): Promise<AxiosResponse> => {
    const response = await api.get("ticketgroups/home");
    return response;
  };

  getGroupHome = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`ticketgroups/home/${id}`);
    return response;
  };

  getGroups = async (): Promise<AxiosResponse> => {
    const response = await api.get("ticketgroups");
    return response;
  };

  getGroupsAdmin = async (): Promise<AxiosResponse> => {
    const response = await api.get("ticketgroups/admin");
    return response;
  };

  getGroup = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`ticketgroups/${id}`);
    return response;
  };

  getContactPerson = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`ticketgroups/${id}/contact`);
    return response;
  };

  getNotes = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`ticketgroups/${id}/notes`);
    return response;
  };

  postNotes = async (
    id: string | number,
    notes: string
  ): Promise<AxiosResponse> => {
    const response = await api.post(`ticketgroups/${id}/notes`, { notes });
    return response;
  };

  postAnnouncement = async (
    id: string | number,
    announcement: string
  ): Promise<AxiosResponse> => {
    const response = await api.post(`ticketgroups/${id}/announcement`, {
      announcement,
    });
    return response;
  };

  createGroup = async (ticketGroup: any): Promise<AxiosResponse> => {
    const response = await api.post(`ticketgroups`, ticketGroup);
    return response;
  };

  editTicketGroup = async (
    id: number | string,
    ticketGroup: any
  ): Promise<AxiosResponse> => {
    const response = await api.patch(`ticketgroups/${id}`, ticketGroup);
    return response;
  };

  softDeleteTicketGroup = async (
    id: string | number
  ): Promise<AxiosResponse> => {
    const response = await api.patch(`ticketgroups/${id}/softdelete`);
    return response;
  };

  updatePositions = async (
    type: string,
    groupIds: string[],
    parent?: number
  ): Promise<AxiosResponse> => {
    const body = {
      type,
      groupIds,
      parent,
    };
    const response = await api.put("ticketgroups/positions", body);
    return response;
  };

  getGroupMembers = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`ticketgroups/${id}/members`);
    return response;
  };

  getGroupLeaders = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`ticketgroups/${id}/leaders`);
    return response;
  };

  getGroupTicketTypes = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`ticketgroups/${id}/ticket-types`);
    return response;
  };

  getGroupTicketTypesSummary = async (
    id: string | number
  ): Promise<AxiosResponse> => {
    const response = await api.get(`ticketgroups/${id}/ticket-types/summary`);
    return response;
  };

  uploadLogo = async (
    id: string | number,
    file: string | RcFile | Blob
  ): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.set("logo", file);
    const response = await api.post(`ticketgroups/${id}/uploadLogo`, formData);
    return response;
  };

  assignLeader = async (
    id: string | number,
    userId: string | number
  ): Promise<AxiosResponse> => {
    const response = await api.post(`ticketgroups/${id}/leaders/${userId}`);
    return response;
  };

  updateLeader = async (
    id: string | number,
    userId: string | number,
    leader: any
  ): Promise<AxiosResponse> => {
    const response = await api.put(
      `ticketgroups/${id}/leaders/${userId}`,
      leader
    );
    return response;
  };

  removeLeader = async (
    id: string | number,
    userId: string | number
  ): Promise<AxiosResponse> => {
    const response = await api.delete(`ticketgroups/${id}/leaders/${userId}`);
    return response;
  };

  createTicketType = async (
    id: string | number,
    data: any
  ): Promise<AxiosResponse> => {
    const response = await api.post(`ticketgroups/${id}/ticket-types`, data);
    return response;
  };

  updateTicketType = async (
    id: string | number,
    typeId: string | number,
    data: any
  ): Promise<AxiosResponse> => {
    const response = await api.put(
      `ticketgroups/${id}/ticket-types/${typeId}`,
      data
    );
    return response;
  };

  removeTicketType = async (
    id: string | number,
    userId: string | number
  ): Promise<AxiosResponse> => {
    const response = await api.delete(
      `ticketgroups/${id}/ticket-types/${userId}`
    );
    return response;
  };

  assignMember = async (
    id: string | number,
    userId: string | number
  ): Promise<AxiosResponse> => {
    const response = await api.post(
      `ticketgroups/${id}/assign-member/${userId}`
    );
    return response;
  };

  removeMember = async (
    id: string | number,
    userId: string | number
  ): Promise<AxiosResponse> => {
    const response = await api.delete(
      `ticketgroups/${id}/remove-member/${userId}`
    );
    return response;
  };

  setAsRead = async (id: number | string): Promise<AxiosResponse> => {
    const response = await api.get(`ticketgroups/${id}/markasread`);
    return response;
  };
}

export default new TaskGroupsService();
