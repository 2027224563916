import React from "react";
import { Tooltip } from "antd";

const TrimmedTitle = (props: any) => {
  const { title, maxChars } = props;

  const MAX_CHARS = maxChars || 100;
  if (title.length > MAX_CHARS)
    return (
      <Tooltip
        title={title}
        mouseEnterDelay={1}
        zIndex={1337}
        getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      >
        {title.substring(0, MAX_CHARS)} ...
      </Tooltip>
    );

  return title;
};

export default TrimmedTitle;
