import React, { PropsWithChildren } from "react";
import { Checkbox, Form, Input } from "formik-antd";
import { Alert, Button, Row, Space } from "antd";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

interface ILoginFormProps {
  isRequest: boolean;
  isSent: boolean;
  handleSubmit: (username: string, setStatus: (status: string) => void) => void;
}
interface IFormValues {
  username: string;
}

const ForgotPasswordForm: React.FC<ILoginFormProps> = (
  props: PropsWithChildren<ILoginFormProps>
): JSX.Element => {
  const onSubmit = (
    values: IFormValues,
    actions: FormikHelpers<IFormValues>
  ) => {
    const { username } = values;
    actions.setStatus(null);
    props.handleSubmit(username, actions.setStatus);
  };

  const initialFormValues: IFormValues = {
    username: "",
  };
  const UserCredentialSchema = Yup.object().shape({
    username: Yup.string()
      .email("Niepoprawny adres email")
      .required("Pole wymagane"),
  });

  const { isSent } = props;
  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={(values, actions) => {
        onSubmit(values, actions);
      }}
      validateOnChange
      validationSchema={UserCredentialSchema}
      render={({ status }) => (
        <Form>
          {status && (
            <Alert
              message={status}
              type="error"
              style={{ marginBottom: 8, maxWidth: 300 }}
            />
          )}
          {!isSent && (
            <>
              <Form.Item
                label="E-mail"
                name="username"
                labelCol={{ span: 24 }}
                style={{ maxWidth: 277 }}
                rules={[{ required: true, message: "Wprowadź hasło!" }]}
              >
                <Input name="username" />
              </Form.Item>
              <Row justify="space-around" wrap>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={props.isRequest}
                  className="auth-login__submit"
                >
                  Zatwierdź
                </Button>
              </Row>
            </>
          )}
        </Form>
      )}
    />
  );
};

export default ForgotPasswordForm;
