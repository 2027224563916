import { Button, Modal, Popover } from "antd";
import React, { useState } from "react";
import moment from "moment";
import {
  CaretRightOutlined,
  LoadingOutlined,
  PauseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import DueDateForm from "./DueDateForm";
import Can from "../../../Shared/Can";
import AddWorkTimeForm from "./AddWorkTimeForm";
import { timeElapsedFromTimestamp } from "../../../../utils/common";
import EndWorkTimeForm from "./EndWorkTimeForm";

const EndWorkTimeHeaderModal = (props: any): JSX.Element => {
  const { ticket, onSubmit, loading, activeWorktime, children } = props;
  const [visible, setVisible] = useState<boolean>(false);

  const commentRequired = Can({
    type: "worktime_comment_required",
    children: <></>,
  });
  return (
    <>
      <Button
        onClick={() =>
          commentRequired
            ? setVisible(true)
            : onSubmit({ id: activeWorktime?.id })
        }
        icon={
          // eslint-disable-next-line no-nested-ternary
          loading ? <LoadingOutlined spin /> : <PauseOutlined />
        }
        type="primary"
        shape="round"
      />
      <Modal
        onCancel={() => setVisible(false)}
        visible={visible}
        title="Dodaj komentarz do pracy"
        footer={null}
      >
        <EndWorkTimeForm
          onSubmit={onSubmit}
          setPopoverVisible={setVisible}
          worktimeId={activeWorktime?.id}
        />
      </Modal>
    </>
  );
};

export default EndWorkTimeHeaderModal;
