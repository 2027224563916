import { useCallback, useEffect, useLayoutEffect, useRef } from "react";

const useKeyPress = (
  type: string,
  keys: string[],
  callback: any,
  node: any = null
) => {
  // implement the callback ref pattern
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event) => {
      // check if one of the key is part of the ones we want
      if (
        type === "shift+ctrl" &&
        event.ctrlKey === true &&
        event.shiftKey === true
      ) {
        if (keys.some((key) => event.key.toLowerCase() === key.toLowerCase())) {
          callbackRef.current(event);
        }
      }

      if (
        type === "ctrl" &&
        (event.ctrlKey === true || event.metaKey === true)
      ) {
        if (keys.some((key) => event.key.toLowerCase() === key.toLowerCase())) {
          callbackRef.current(event);
        }
      }
    },
    [keys, type]
  );

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode = node ?? document;
    // attach the event listener
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    targetNode && targetNode.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () =>
      targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress, node]);
};

export default useKeyPress;
