import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import "moment/locale/pl";
import locale from "antd/es/locale/pl_PL";
import { ConfigProvider } from "antd";
import App from "./App";

import reportWebVitals from "./reportWebVitals";

moment.locale("pl");

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={locale}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
