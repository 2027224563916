import { Avatar, Card, Col, Empty, Row, Skeleton, Space } from "antd";
import React from "react";
import { makeAvatarName, stringToColour } from "../../../utils/common";
import Person from "./Person";

type PropsType = {
  person: any;
  isLoading: boolean;
};

const ContactPerson = (props: PropsType) => {
  const { person, isLoading } = props;

  return (
    <Card bordered={false} title="Osoba kontaktowa">
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Row gutter={[16, 16]}>
          {!person ? (
            <Col span={24}>
              <Empty description="Brak osoby kontaktowej" />
            </Col>
          ) : (
            <Person person={person} divider={false} />
          )}
        </Row>
      )}
    </Card>
  );
};

export default ContactPerson;
