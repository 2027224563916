import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Form, Input, Radio, Select, SubmitButton } from "formik-antd";
import { Card, Spin } from "antd";
import { Formik, FormikErrors, FormikValues } from "formik";
import * as Yup from "yup";
import { RcFile } from "antd/es/upload";
import { generateRandomString } from "../../../../../utils/common";
import { IUserBasicFormValues } from "../../../../../types/user";
import UploadAvatar from "./UploadAvatar";

interface IUserBasicFormProps {
  img: string | null;
  email: string | null;
  name: string | null;
  surname: string | null;
  tag: any[];
  tags: any[];
  phone: string | null;
  typeAccount: string | number;
  isLoading: boolean;
  onUpload: (
    file: RcFile | Blob | string,
    onRequestComplete: (url?: string) => void
  ) => void;
  onAvatarRemove: (callback: () => void) => void;
  onSave: (
    values: IUserBasicFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<IUserBasicFormValues>) => void
  ) => void;
}

const UserBasicForm = (props: IUserBasicFormProps) => {
  const {
    img,
    email,
    name,
    surname,
    tag,
    tags,
    phone,
    typeAccount,
    isLoading,
    onUpload,
    onAvatarRemove,
    onSave,
  } = props;
  const initialFormValues: IUserBasicFormValues = {
    email: "",
    name: "",
    surname: "",
    tag: [],
    phone: "",
    typeAccount: 2,
  };
  const [formData, setFormData] = useState<IUserBasicFormValues>(
    initialFormValues
  );
  const [editRequest, setEditRequest] = useState<boolean>(false);

  useEffect(() => {
    setFormData({
      email,
      name,
      surname,
      tag: tag?.map((t) => {
        return { label: t.name, value: t.id };
      }),
      phone,
      typeAccount,
    });
  }, [email, name, surname, tag, phone, img, typeAccount]);
  const formikRef = useRef<FormikValues>();
  const generatePassword = () => {
    const password = generateRandomString();
    if (formikRef.current)
      formikRef.current.setFieldValue("password", password);
  };

  const filterOption = useCallback((search, option) => {
    return option.label.toLowerCase().includes(search.toLowerCase());
  }, []);

  const NewUserSchema = Yup.object().shape({
    email: Yup.string()
      .email("Niepoprawny adres email")
      .required("Pole wymagane"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Telefon powinien składać się z samych cyfr")
      .min(9, "Telefon powinien składać się z 9 cyfr")
      .max(9, "Telefon powinien składać się z 9 cyfr")
      .nullable(),
  });

  return (
    <Spin spinning={isLoading}>
      <Card title="Dane użytkownika">
        <Formik
          innerRef={formikRef as MutableRefObject<any>}
          initialValues={formData}
          enableReinitialize
          validationSchema={NewUserSchema}
          onSubmit={(
            values: IUserBasicFormValues,
            { resetForm, setErrors }
          ) => {
            setEditRequest(true);
            onSave(values, () => setEditRequest(false), setErrors);
          }}
          validateOnChange
          render={() => (
            <Form>
              <Form.Item name="upload" label="Avatar" labelCol={{ span: 24 }}>
                <UploadAvatar
                  imageUrl={img}
                  onUpload={onUpload}
                  onRemove={onAvatarRemove}
                />
              </Form.Item>
              <Form.Item
                label="E-mail"
                required
                name="email"
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: "Wprowadź email!" }]}
              >
                <Input name="email" required />
              </Form.Item>
              <Form.Item
                name="typeAccount"
                label="Typ użytkownika"
                labelCol={{ span: 24 }}
              >
                <Radio.Group name="typeAccount" buttonStyle="solid">
                  <Radio.Button value={2}>Administrator</Radio.Button>
                  <Radio.Button value={1}>Użytkownik</Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Imię"
                name="name"
                labelCol={{ span: 24 }}
                rules={[{ required: false }]}
              >
                <Input name="name" />
              </Form.Item>

              <Form.Item
                label="Nazwisko"
                name="surname"
                labelCol={{ span: 24 }}
                rules={[{ required: false }]}
              >
                <Input name="surname" />
              </Form.Item>

              <Form.Item
                label="Etykiety"
                name="tag"
                labelCol={{ span: 24 }}
                rules={[{ required: false }]}
              >
                <Select
                  mode="tags"
                  name="tag"
                  filterOption={filterOption}
                  labelInValue
                  options={tags.map((availableTag: any) => {
                    return { value: availableTag.id, label: availableTag.name };
                  })}
                />
              </Form.Item>

              <Form.Item
                label="Telefon"
                name="phone"
                labelCol={{ span: 24 }}
                rules={[{ required: false }]}
              >
                <Input name="phone" />
              </Form.Item>
              <SubmitButton loading={editRequest}>Zapisz</SubmitButton>
            </Form>
          )}
        />
      </Card>
    </Spin>
  );
};

export default UserBasicForm;
