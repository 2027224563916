import { AnyAction } from "redux";
import {
  END_WORKTIME,
  END_WORKTIME_FAILED,
  END_WORKTIME_SUCCESS,
  FETCH_TICKET_GROUP_WORKTIMES,
  FETCH_TICKET_GROUP_WORKTIMES_FAILED,
  FETCH_TICKET_GROUP_WORKTIMES_SUCCESS,
  FETCH_TICKET_GROUP_WORKTIMES_SUMMARY,
  FETCH_TICKET_GROUP_WORKTIMES_SUMMARY_FAILED,
  FETCH_TICKET_GROUP_WORKTIMES_SUMMARY_SUCCESS,
  FETCH_TICKET_WORKTIMES,
  FETCH_TICKET_WORKTIMES_FAILED,
  FETCH_TICKET_WORKTIMES_SHORT,
  FETCH_TICKET_WORKTIMES_SHORT_FAILED,
  FETCH_TICKET_WORKTIMES_SHORT_SUCCESS,
  FETCH_TICKET_WORKTIMES_SUCCESS,
  FETCH_USER_ACTIVE_WORKTIME,
  FETCH_USER_ACTIVE_WORKTIME_FAILED,
  FETCH_USER_ACTIVE_WORKTIME_SUCCESS,
  FETCH_USER_LAST_WORKTIME,
  FETCH_USER_LAST_WORKTIME_FAILED,
  FETCH_USER_LAST_WORKTIME_SUCCESS,
  FETCH_USERS_WORKTIMES,
  FETCH_USERS_WORKTIMES_FAILED,
  FETCH_USERS_WORKTIMES_SUCCESS,
  START_WORKTIME,
  START_WORKTIME_FAILED,
  START_WORKTIME_SUCCESS,
} from "../../actions/action-types";

export interface WorktimeState {
  active: any;
  last: any[];
  ticketShort: any;
  ticketShortRequest: boolean;
  ticket: any;
  ticketRequest: boolean;
  users: any[];
  usersRequest: boolean;
  ticketGroup: any;
  ticketGroupRequest: boolean;
  ticketGroupSummary: any;
  ticketGroupSummaryRequest: boolean;
  activeRequest: boolean;
  lastRequest: boolean;
  toggleWorktimeRequest: boolean;
}
const initialState: WorktimeState = {
  active: null,
  last: [],
  ticketShort: null,
  ticket: [],
  ticketGroup: [],
  ticketGroupSummary: [],
  ticketRequest: false,
  ticketShortRequest: false,
  ticketGroupRequest: false,
  ticketGroupSummaryRequest: false,
  usersRequest: false,
  users: [],
  lastRequest: false,
  activeRequest: false,
  toggleWorktimeRequest: false,
};

export default (state = initialState, action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TICKET_WORKTIMES: {
      return {
        ...state,
        ticketRequest: true,
      };
    }
    case FETCH_TICKET_WORKTIMES_SUCCESS: {
      return {
        ...state,
        ticket: payload,
        ticketRequest: false,
      };
    }
    case FETCH_TICKET_WORKTIMES_FAILED: {
      return {
        ...state,
        ticketRequest: false,
      };
    }

    case FETCH_TICKET_WORKTIMES_SHORT: {
      return {
        ...state,
        ticketShortRequest: true,
      };
    }
    case FETCH_TICKET_WORKTIMES_SHORT_SUCCESS: {
      return {
        ...state,
        ticketShort: payload,
        ticketShortRequest: false,
      };
    }
    case FETCH_TICKET_WORKTIMES_SHORT_FAILED: {
      return {
        ...state,
        ticketShortRequest: false,
      };
    }

    case FETCH_TICKET_GROUP_WORKTIMES_SUMMARY: {
      return {
        ...state,
        ticketGroupSummaryRequest: true,
      };
    }
    case FETCH_TICKET_GROUP_WORKTIMES_SUMMARY_SUCCESS: {
      return {
        ...state,
        ticketGroupSummary: payload,
        ticketGroupSummaryRequest: false,
      };
    }
    case FETCH_TICKET_GROUP_WORKTIMES_SUMMARY_FAILED: {
      return {
        ...state,
        ticketGroupSummaryRequest: false,
      };
    }

    case FETCH_TICKET_GROUP_WORKTIMES: {
      return {
        ...state,
        ticketGroupRequest: true,
      };
    }
    case FETCH_TICKET_GROUP_WORKTIMES_SUCCESS: {
      return {
        ...state,
        ticketGroup: payload,
        ticketGroupRequest: false,
      };
    }
    case FETCH_TICKET_GROUP_WORKTIMES_FAILED: {
      return {
        ...state,
        ticketGroupRequest: false,
      };
    }

    case FETCH_USER_ACTIVE_WORKTIME: {
      return {
        ...state,
        activeRequest: true,
      };
    }
    case FETCH_USER_ACTIVE_WORKTIME_SUCCESS: {
      return {
        ...state,
        active: payload,
        activeRequest: false,
      };
    }
    case FETCH_USER_ACTIVE_WORKTIME_FAILED: {
      return {
        ...state,
        activeRequest: false,
      };
    }

    case FETCH_USER_LAST_WORKTIME: {
      return {
        ...state,
        lastRequest: true,
      };
    }
    case FETCH_USER_LAST_WORKTIME_SUCCESS: {
      return {
        ...state,
        last: payload,
        lastRequest: false,
      };
    }
    case FETCH_USER_LAST_WORKTIME_FAILED: {
      return {
        ...state,
        lastRequest: false,
      };
    }

    case START_WORKTIME: {
      return {
        ...state,
        toggleWorktimeRequest: true,
      };
    }
    case START_WORKTIME_FAILED: {
      return {
        ...state,
        toggleWorktimeRequest: false,
      };
    }
    case START_WORKTIME_SUCCESS: {
      return {
        ...state,
        active: payload,
        toggleWorktimeRequest: false,
      };
    }

    case END_WORKTIME: {
      return {
        ...state,
        toggleWorktimeRequest: true,
      };
    }
    case END_WORKTIME_FAILED: {
      return {
        ...state,
        toggleWorktimeRequest: false,
      };
    }
    case END_WORKTIME_SUCCESS: {
      return {
        ...state,
        active: null,
        toggleWorktimeRequest: false,
      };
    }

    case FETCH_USERS_WORKTIMES: {
      return {
        ...state,
        usersRequest: true,
      };
    }
    case FETCH_USERS_WORKTIMES_SUCCESS: {
      return {
        ...state,
        users: payload,
        usersRequest: false,
      };
    }
    case FETCH_USERS_WORKTIMES_FAILED: {
      return {
        ...state,
        usersRequest: false,
      };
    }

    default:
      return state;
  }
};
