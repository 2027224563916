import React, { PropsWithChildren, useEffect } from "react";
import { Checkbox, Form, Input } from "formik-antd";
import { Alert, Button, Row, Space } from "antd";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

interface ILoginFormProps {
  isRequest: boolean;
  isTokenValid: boolean;
  isSent: boolean;
  handleSubmit: (
    password: string,
    repeat_password: string,
    setStatus: (status: string) => void
  ) => void;
}
interface IFormValues {
  password: string;
  repeat_password: string;
}

const ResetPasswordForm: React.FC<ILoginFormProps> = (
  props: PropsWithChildren<ILoginFormProps>
): JSX.Element => {
  const { isTokenValid } = props;
  //
  // useEffect(() => {
  //   if(!isTokenValid) {
  //     actions.setStatus('')
  //   }
  // }, [isTokenValid])
  const onSubmit = (
    values: IFormValues,
    actions: FormikHelpers<IFormValues>
  ) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { password, repeat_password } = values;
    actions.setStatus(null);
    props.handleSubmit(password, repeat_password, actions.setStatus);
  };

  const initialFormValues: IFormValues = {
    password: "",
    repeat_password: "",
  };
  const UserCredentialSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Hasło jest za krótkie. Minimum 8 znaków")
      .required("Pole wymagane"),
    repeat_password: Yup.string()
      .min(8, "Hasło jest za krótkie. Minimum 8 znaków")
      .required("Pole wymagane")
      .oneOf([Yup.ref("password"), null], "Hasła muszą być takie same."),
  });

  const { isSent } = props;
  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={(values, actions) => {
        onSubmit(values, actions);
      }}
      validateOnChange
      validationSchema={UserCredentialSchema}
      render={({ status }) => (
        <Form>
          {status && (
            <Alert
              message={status}
              type="error"
              style={{ marginBottom: 8, maxWidth: 300 }}
            />
          )}
          {isSent && (
            <Space
              align="center"
              style={{ width: "100%", marginTop: 36 }}
              direction="vertical"
            >
              <Link to="/">
                <Button type="primary">Przejdź do logowania</Button>
              </Link>
            </Space>
          )}
          {!isSent && (
            <>
              <Form.Item
                label="Hasło"
                name="password"
                style={{ maxWidth: 277 }}
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: "Wprowadź hasło!" }]}
              >
                <Input.Password name="password" />
              </Form.Item>
              <Form.Item
                label="Powtórz hasło"
                name="repeat_password"
                labelCol={{ span: 24 }}
                style={{ maxWidth: 277 }}
                rules={[{ required: true, message: "Wprowadź hasło!" }]}
              >
                <Input.Password name="repeat_password" />
              </Form.Item>
              <Row justify="space-around" wrap>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={props.isRequest}
                  className="auth-login__submit"
                >
                  Zatwierdź
                </Button>
              </Row>
            </>
          )}
        </Form>
      )}
    />
  );
};

export default ResetPasswordForm;
