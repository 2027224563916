import React, { PropsWithChildren } from "react";

import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { AppState } from "../../reducers";

interface IPrivateRouteProps {
  isLogged: boolean;
}
function PrivateRoute({
  children,
  isLogged,
  ...rest
}: PropsWithChildren<IPrivateRouteProps>) {
  return (
    <Route
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...rest}
      render={({ location }) =>
        isLogged ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    isLogged: state.auth.isLogged,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
