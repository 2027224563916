import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Checkbox, Form, Input, Select, SubmitButton } from "formik-antd";
import { Card, Spin } from "antd";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import UploadAvatar from "./UploadAvatar";
import {
  ISplitterBasicFormProps,
  ISplitterBasicFormValues,
} from "../../../../../types/ticket-group";

const { Option, OptGroup } = Select;
const SplitterBasicForm = (props: ISplitterBasicFormProps) => {
  const {
    name,
    description,
    img,
    isLoading,
    onUpload,
    onAvatarRemove,
    onSave,
  } = props;
  // TODO: managerID should be relation instead of integer
  const initialFormValues: ISplitterBasicFormValues = {
    name: "",
    description: "",
  };
  const [formData, setFormData] = useState<ISplitterBasicFormValues>(
    initialFormValues
  );

  // TODO: zmodyfikować requesty zapisu
  const [editRequest, setEditRequest] = useState<boolean>(false);

  useEffect(() => {
    setFormData({
      name,
      description,
    });
  }, [name, description]);

  const formikRef = useRef<FormikValues>();

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Pole wymagane"),
    description: Yup.string()
      .max(255, "Opis powinien składać się z maksymalnie 255 znaków")
      .nullable(),
  });

  return (
    <Spin spinning={isLoading}>
      <Card title="Podstawowe informacje">
        <Formik
          innerRef={formikRef as MutableRefObject<any>}
          initialValues={formData}
          enableReinitialize
          validationSchema={FormSchema}
          onSubmit={(
            values: ISplitterBasicFormValues,
            { resetForm, setErrors }
          ) => {
            setEditRequest(true);
            onSave(values, () => setEditRequest(false), setErrors);
          }}
          validateOnChange
          render={() => (
            <Form>
              <Form.Item name="upload" label="Avatar" labelCol={{ span: 24 }}>
                <UploadAvatar
                  imageUrl={img}
                  onUpload={onUpload}
                  onRemove={onAvatarRemove}
                />
              </Form.Item>

              <Form.Item
                label="Tytuł"
                name="name"
                labelCol={{ span: 24 }}
                rules={[{ required: true }]}
              >
                <Input name="name" />
              </Form.Item>
              <Form.Item
                label="Opis"
                name="description"
                labelCol={{ span: 24 }}
                rules={[{ required: true, max: 255 }]}
              >
                <Input.TextArea name="description" />
              </Form.Item>
              <SubmitButton loading={editRequest}>Zapisz</SubmitButton>
            </Form>
          )}
        />
      </Card>
    </Spin>
  );
};

export default SplitterBasicForm;
