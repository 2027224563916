import { Badge, Button, Divider, Dropdown, Menu, Space } from "antd";
import Text from "antd/es/typography/Text";
import {
  BookOutlined,
  CheckOutlined,
  DownOutlined,
  LoadingOutlined,
  PhoneOutlined,
  StarOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Priority, PriorityColor } from "../../../../types/ticket-group";
import Can from "../../../Shared/Can";
import { openNotificationWithIcon } from "../../../../utils/common";

interface IProps {
  ticket: any;
  onSubmit: (values: any, onRequestComplete: () => void) => void;
  type: string;
}
const PriorityDropdown = (props: IProps) => {
  const { ticket, onSubmit, type } = props;
  const [submitting, setSubmitting] = useState(false);
  const [priority, setPriority] = useState(ticket?.priority_id?.id);
  const [favorite, setFavorite] = useState(ticket?.favorite);
  const [highlight, setHighlight] = useState(ticket?.highlight);
  const [callclient, setCallClient] = useState(ticket?.callclient);

  useEffect(() => {
    setPriority(ticket?.priority_id?.id);
    setFavorite(ticket?.favorite);
    setHighlight(ticket?.highlight);
    setCallClient(ticket?.callclient);
  }, [ticket]);

  const changePriority = (id: number) => {
    setSubmitting(true);
    setPriority(id);
    onSubmit({ priorityId: id, entityId: ticket?.id }, () => {
      setSubmitting(false);
      openNotificationWithIcon("success", "Priorytet zmieniony");
    });
  };

  const CanChangeHighlight = Can({
    entity: ticket,
    type: "ticket_edit_highlight",
    children: (
      <>
        <Menu.Item
          key="0"
          className="ant-dropdown-menu-item"
          onClick={() => {
            setSubmitting(true);
            setFavorite((prev: number) => (prev === 1 ? 0 : 1));
            onSubmit(
              { favorite: !ticket?.favorite, entityId: ticket?.id },
              () => {
                setSubmitting(false);
                openNotificationWithIcon("success", "Zaktualizowano");
              }
            );
          }}
        >
          <Space>
            <StarOutlined />
            <Text>Wyróżnij</Text>
            {favorite === 1 && <CheckOutlined />}
          </Space>
        </Menu.Item>
        <Menu.Item
          key="1"
          className="ant-dropdown-menu-item"
          onClick={() => {
            setCallClient((prev: number) => (prev === 1 ? 0 : 1));
            setSubmitting(true);
            onSubmit(
              { callclient: !ticket?.callclient, entityId: ticket?.id },
              () => {
                setSubmitting(false);
                openNotificationWithIcon("success", "Zaktualizowano");
              }
            );
          }}
        >
          <Space>
            <PhoneOutlined />
            <Text>Zadzwoń</Text>
            {callclient === 1 && <CheckOutlined />}
          </Space>
        </Menu.Item>
        <Menu.Item
          key="2"
          className="ant-dropdown-menu-item"
          onClick={() => {
            setHighlight((prev: number) => (prev === 1 ? 0 : 1));
            setSubmitting(true);
            onSubmit(
              { highlight: !ticket?.highlight, entityId: ticket?.id },
              () => {
                setSubmitting(false);
                openNotificationWithIcon("success", "Zaktualizowano");
              }
            );
          }}
        >
          <Space>
            <BookOutlined />
            <Text>Przypnij do góry</Text>
            {highlight === 1 && <CheckOutlined />}
          </Space>
        </Menu.Item>
      </>
    ),
  });

  const CanChangePriority = Can({
    entity: ticket,
    type: "ticket_edit_priority",
    children: (
      <>
        <Menu.Item
          key="3"
          className="ant-dropdown-menu-item"
          onClick={() => changePriority(5)}
        >
          <Badge
            status="processing"
            text={
              <Space size="small">
                <Text>{Priority[5]}</Text>
                {priority === 5 && <CheckOutlined />}
              </Space>
            }
            color={PriorityColor[5]}
          />
        </Menu.Item>
        <Menu.Item
          key="4"
          className="ant-dropdown-menu-item"
          onClick={() => changePriority(4)}
        >
          <Badge
            status="processing"
            text={
              <Space size="small">
                <Text>{Priority[4]}</Text>
                {priority === 4 && <CheckOutlined />}
              </Space>
            }
            color={PriorityColor[4]}
          />
        </Menu.Item>

        <Menu.Item
          key="5"
          className="ant-dropdown-menu-item"
          onClick={() => changePriority(3)}
        >
          <Badge
            status="processing"
            text={
              <Space size="small">
                <Text>{Priority[3]}</Text>
                {priority === 3 && <CheckOutlined />}
              </Space>
            }
            color={PriorityColor[3]}
          />
        </Menu.Item>

        <Menu.Item
          key="6"
          className="ant-dropdown-menu-item"
          onClick={() => changePriority(2)}
        >
          <Badge
            status="processing"
            text={
              <Space size="small">
                <Text>{Priority[2]}</Text>
                {priority === 2 && <CheckOutlined />}
              </Space>
            }
            color={PriorityColor[2]}
          />
        </Menu.Item>

        <Menu.Item
          key="7"
          className="ant-dropdown-menu-item"
          onClick={() => changePriority(1)}
        >
          <Badge
            status="processing"
            text={
              <Space size="small">
                <Text>{Priority[1]}</Text>
                {priority === 1 && <CheckOutlined />}
              </Space>
            }
            color={PriorityColor[1]}
          />
        </Menu.Item>
      </>
    ),
  });

  const menu = (
    <Menu>
      {CanChangeHighlight}
      <Divider style={{ margin: "8px 0" }} />
      {CanChangePriority}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={
        (CanChangePriority || CanChangeHighlight) && !submitting
          ? ["click"]
          : []
      }
      getPopupContainer={
        type !== "text"
          ? (trigger) => trigger.parentElement as HTMLElement
          : undefined
      }
    >
      <Button
        type={type === "text" ? "text" : "default"}
        style={{
          pointerEvents:
            !CanChangePriority && !CanChangeHighlight ? "none" : "unset",
        }}
      >
        <Badge
          status="processing"
          text={
            <Space size="small">
              <Text>{Priority[priority]}</Text>
              {(CanChangePriority || CanChangeHighlight) && !submitting && (
                <DownOutlined style={{ fontSize: 10 }} />
              )}

              {(CanChangePriority || CanChangeHighlight) && submitting && (
                <LoadingOutlined spin style={{ fontSize: 10 }} />
              )}
            </Space>
          }
          color={PriorityColor[priority]}
        />
      </Button>
    </Dropdown>
  );
};

export default PriorityDropdown;
