import React, {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { DatePicker, Form, SubmitButton, Switch } from "formik-antd";
import { Col, Row } from "antd";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import moment, { Moment } from "moment";
import { openNotificationWithIcon } from "../../../../utils/common";
import { IQuickDueDateFormValues } from "../../../../types/quick-forms";

const DueDateForm: React.FC<any> = (
  props: PropsWithChildren<any>
): JSX.Element => {
  console.log(moment.locale(), "locale due date");
  const formikRef = useRef<FormikValues>();

  const { value, showOnFrontPage, onSubmit, setPopoverVisible } = props;

  const initialFormValues: IQuickDueDateFormValues = {
    dueDate: value ? moment(value * 1000).toDate() : null,
    dueDateShow: showOnFrontPage,
  };

  const [formData, setFormData] = useState<IQuickDueDateFormValues>(
    initialFormValues
  );

  const disabledDate = (current: Moment) => {
    return current && current < moment().startOf("day");
  };

  useEffect(() => {
    setFormData({
      dueDate: value ? moment(value * 1000).toDate() : null,
      dueDateShow: showOnFrontPage,
    });
  }, [value, showOnFrontPage]);

  const Schema = Yup.object().shape({
    dueDate: Yup.date().nullable(),
  });

  return (
    <Formik
      innerRef={formikRef as MutableRefObject<any>}
      initialValues={formData}
      validationSchema={Schema}
      onSubmit={(
        values: IQuickDueDateFormValues,
        { resetForm, setErrors, setSubmitting }
      ) => {
        onSubmit(
          {
            dueDate: values.dueDate ? moment(values.dueDate).unix() : null,
            dueDateShow: values.dueDateShow,
          },
          () => {
            setPopoverVisible(false);
            setSubmitting(false);
            openNotificationWithIcon("success", "Termin zaktualizowany.");
          },
          setErrors
        );
      }}
      validateOnChange
      render={() => (
        <Form>
          <Row justify="center" align="middle" style={{ width: "280px" }}>
            <Col span={24}>
              <Form.Item
                label="Termin"
                required
                name="dueDate"
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: "Wprowadź termin!" }]}
              >
                <DatePicker
                  style={{ minWidth: "180px" }}
                  format="DD.MM.Y HH:mm"
                  showTime={{ showSecond: false }}
                  onChange={() => console.log("data")}
                  name="dueDate"
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
            <Col span={24} style={{ marginLeft: "8px" }}>
              <Form.Item
                label="Pokazuj na stronie głównej"
                labelCol={{ span: 24 }}
                name="dueDateShow"
                valuePropName="checked"
              >
                <Switch
                  name="dueDateShow"
                  checkedChildren="Tak"
                  unCheckedChildren="Nie"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <SubmitButton type="primary">Zapisz</SubmitButton>
          </Row>
        </Form>
      )}
    />
  );
};

export default DueDateForm;
