import React, { PropsWithChildren, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Badge, Card, Col, Menu, Row, Space, Tabs } from "antd";
import { AxiosError, AxiosResponse } from "axios";
import { FormikErrors } from "formik";
import { Helmet } from "react-helmet";
import { AppState } from "../../../reducers";
import {
  mergeByProperty,
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";

import {
  getTicketGroup,
  getTicketGroupLeaders,
  getTicketGroupMembers,
  getTicketGroupTicketTypes,
} from "../../../actions/ticketgroups";

import { getUsers } from "../../../actions/users";
import TicketBasicForm, {
  ITicketBasicFormValues,
} from "./NewTicketForm/BasicForm";
import { createTicket } from "../../../actions/tickets";
import { setCurrentGroupTitle } from "../../../actions/global";
import Can from "../../Shared/Can";
import { Priority, PriorityColor } from "../../../types/ticket-group";
import { MetaTitle } from "../../Shared/MetaTitle";

const { TabPane } = Tabs;

interface IRouteParams {
  id: string;
  groupId: string;
}
interface INewTicketPageProps {
  loggedUser: any;
  ticket: any;
  users: any[];
  leaders: any[];
  members: any[];
  ticketGroup: any;
  ticketTypes: any[];
  displayNotification: boolean;
  isGroupLoading: boolean;
  isTicketGroupLoading: boolean;
  isUsersLoading: boolean;
  isMembersLoading: boolean;
  isLeadersLoading: boolean;
  isTicketTypesLoading: boolean;
  getUsersAction: () => void;
  getLeadersAction: (id: string | number) => void;
  getMembersAction: (id: string | number) => void;
  getTicketGroupAction: (id: string | number) => void;
  getTicketTypesAction: (id: string | number) => void;
  createTicketAction: (ticket: any) => Promise<any>;
  setCurrentGroupTitleAction: (name: string | null) => void;
}

const NewTicket = (
  props: PropsWithChildren<INewTicketPageProps>
): JSX.Element => {
  const { groupId } = useParams<IRouteParams>();
  const history = useHistory();
  const [initAssigned, setInitAssigned] = React.useState<any[]>([]);
  const [availableUsers, setAvailableUsers] = React.useState<any[]>([]);
  const {
    ticket,
    displayNotification,
    loggedUser,
    users,
    leaders,
    members,
    ticketTypes,
    getUsersAction,
    getLeadersAction,
    getMembersAction,
    getTicketGroupAction,
    getTicketTypesAction,
    setCurrentGroupTitleAction,
    createTicketAction,
    isGroupLoading,
    isTicketTypesLoading,
    isLeadersLoading,
    isMembersLoading,
    isUsersLoading,
    isTicketGroupLoading,
    ticketGroup,
  } = props;

  const onSave = (
    values: ITicketBasicFormValues,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    createTicketAction(values)
      .then((response: AxiosResponse) => {
        openNotificationWithIcon("success", "Wątek dodany");
        history.replace(`/group/${groupId}/${response.data.id}`);
        callbackRequestCompleted();
      })
      .catch((err: AxiosError<{ detail?: string }>) => {
        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }

        if (err.response?.status === 403) {
          openNotificationWithIcon("error", err.response?.data?.detail || "");
        }
      });
  };

  useEffect(() => {
    setCurrentGroupTitleAction(ticketGroup?.name);
  }, [setCurrentGroupTitleAction, ticketGroup.name]);

  useEffect(() => {
    return () => {
      setCurrentGroupTitleAction(null);
    };
  }, [setCurrentGroupTitleAction]);

  useEffect(() => {
    if (groupId) {
      getLeadersAction(groupId);
      getMembersAction(groupId);
      getTicketTypesAction(groupId);
      getTicketGroupAction(groupId);
    }
    getUsersAction();
  }, [
    getLeadersAction,
    getMembersAction,
    getTicketGroupAction,
    getTicketTypesAction,
    getUsersAction,
    groupId,
  ]);

  useEffect(() => {
    const init = leaders.map((leader) => {
      return {
        key: leader.user.id,
        label: `${leader.user.name} ${leader.user.surname}`,
        tag: leader.user.tag,
      };
    });
    if (!init.find((assigned) => assigned.key === loggedUser.id)) {
      init.unshift({
        key: loggedUser.id,
        label: `${loggedUser.name} ${loggedUser.surname}`,
        tag: loggedUser.tag,
      });
    }

    const availableUsersInit = leaders.map((leader) => {
      return leader.user;
    });
    if (
      !availableUsersInit.find((available) => available.id === loggedUser.id)
    ) {
      availableUsersInit.unshift(loggedUser);
    }

    mergeByProperty(availableUsersInit, users, "id");

    setInitAssigned(init);
    setAvailableUsers(availableUsersInit);
  }, [
    leaders,
    loggedUser,
    loggedUser.id,
    loggedUser.name,
    loggedUser.surname,
    users,
  ]);

  const canAssignUsers = Can({
    type: "ticket_can_assign_users",
    children: <></>,
  });

  const canAssignLeaders = Can({
    entity: ticketGroup,
    type: "ticket_can_assign_leaders",
    children: <></>,
  });

  return (
    <Can
      entity={ticketGroup}
      type={isTicketGroupLoading ? "loading" : "ticket_create"}
      renderError
    >
      <div className="ticket_group-edit-page">
        <MetaTitle title="Nowy wątek" displayBadge={displayNotification} />
        <Row gutter={16}>
          <Col span={24}>
            <Card bordered={false} style={{ width: "100%" }}>
              <Row gutter={16} align="middle" justify="center">
                <Col lg={24} xl={20} xxl={12}>
                  <TicketBasicForm
                    group={groupId}
                    ticketTypes={ticketTypes}
                    assignedUsers={initAssigned}
                    leaders={leaders}
                    members={members}
                    canEdit={!!canAssignUsers}
                    canEditLeaders={!!canAssignLeaders}
                    users={availableUsers}
                    isLoading={
                      isGroupLoading ||
                      isMembersLoading ||
                      isLeadersLoading ||
                      isUsersLoading ||
                      isTicketTypesLoading ||
                      isTicketGroupLoading
                    }
                    onSave={onSave}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

const mapDispatchToProps = {
  getTicketGroupAction: getTicketGroup,
  getLeadersAction: getTicketGroupLeaders,
  getMembersAction: getTicketGroupMembers,
  getTicketTypesAction: getTicketGroupTicketTypes,
  getUsersAction: getUsers,
  createTicketAction: createTicket,
  setCurrentGroupTitleAction: setCurrentGroupTitle,
};

const mapStateToProps = (state: AppState) => {
  return {
    loggedUser: state.auth.logged,
    displayNotification: state.notifications.newNotificationIndicator,
    ticket: state.ticketgroups.ticketgroup,
    users: state.users.users,
    leaders: state.ticketgroups.ticketgroupLeaders,
    members: state.ticketgroups.ticketgroupMembers,
    ticketTypes: state.ticketgroups.ticketgroupTicketTypes,
    ticketGroup: state.ticketgroups.ticketgroup,
    isTicketGroupLoading: state.ticketgroups.isFetchTicketGroupRequest,
    isGroupLoading: state.ticketgroups.isFetchTicketGroupRequest,
    isUsersLoading: state.users.isFetchUserRequest,
    isLeadersLoading: state.ticketgroups.isFetchTicketGroupLeadersRequest,
    isMembersLoading: state.ticketgroups.isFetchTicketGroupMembersRequest,
    isTicketTypesLoading:
      state.ticketgroups.isFetchTicketGroupTicketTypesRequest,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTicket);
