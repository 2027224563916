import React, { cloneElement, PropsWithChildren } from "react";
import { Button, Empty, Row } from "antd";
import { WarningTwoTone } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

type PropsType = {
  noContent: boolean;
  children: JSX.Element;
};
const NoContentError = (props: PropsWithChildren<PropsType>): JSX.Element => {
  const history = useHistory();

  const { noContent, children, ...rest } = props;

  if (!noContent) return cloneElement(children, { ...rest });

  return (
    <Row
      style={{ width: "100%", height: "100%", padding: 30 }}
      justify="center"
      align="top"
    >
      <Empty
        description="Strona nie mogła być załadowana"
        image={<WarningTwoTone twoToneColor="#aaa" style={{ fontSize: 120 }} />}
        imageStyle={{ height: 140 }}
      >
        <Button type="primary" danger onClick={() => history.replace("/")}>
          Wróć na stronę główną
        </Button>
      </Empty>
    </Row>
  );
};

export default NoContentError;
