import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Checkbox,
  Form,
  Input,
  Radio,
  Select,
  SubmitButton,
} from "formik-antd";
import { Card, Spin } from "antd";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import {
  ITicketGroupBasicFormValues,
  ITicketGroupFormProps,
} from "../../../../../types/ticket-group";

import { AccountType } from "../../../../../types/user";
import UploadAvatar from "../SplitterBasicForm/UploadAvatar";

const { Option, OptGroup } = Select;
const TicketGroupBasicForm = (props: ITicketGroupFormProps) => {
  const {
    img,
    name,
    users,
    description,
    disposable,
    managerId,
    isLoading,
    onUpload,
    onAvatarRemove,
    onSave,
  } = props;
  // TODO: managerID should be relation instead of integer
  const initialFormValues: ITicketGroupBasicFormValues = {
    name: "",
    description: "",
    managerId: null,
    disposable: 0,
  };
  const [formData, setFormData] = useState<ITicketGroupBasicFormValues>(
    initialFormValues
  );

  // TODO: zmodyfikować requesty zapisu
  const [editRequest, setEditRequest] = useState<boolean>(false);

  useEffect(() => {
    setFormData({
      name,
      description,
      managerId,
      disposable,
    });
  }, [name, description, managerId, disposable]);

  const formikRef = useRef<FormikValues>();

  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Pole wymagane"),
  });

  const groupedUsers = users.reduce((groups: any, user: any) => {
    const group = groups[user.typeAccount] || [];
    group.push(user);
    // eslint-disable-next-line no-param-reassign
    groups[user.typeAccount] = group;
    return groups;
  }, []);

  const filterOption = useCallback((search, optionOrGroup) => {
    const isGroup = Array.isArray(optionOrGroup.options);
    if (isGroup) {
      return false;
    }
    return optionOrGroup.children.toLowerCase().includes(search.toLowerCase());
  }, []);

  return (
    <Spin spinning={isLoading}>
      <Card title="Podstawowe informacje">
        <Formik
          innerRef={formikRef as MutableRefObject<any>}
          initialValues={formData}
          enableReinitialize
          validationSchema={FormSchema}
          onSubmit={(
            values: ITicketGroupBasicFormValues,
            { resetForm, setErrors }
          ) => {
            setEditRequest(true);
            onSave(values, () => setEditRequest(false), setErrors);
          }}
          validateOnChange
          render={() => (
            <Form>
              <Form.Item name="upload" label="Avatar" labelCol={{ span: 24 }}>
                <UploadAvatar
                  imageUrl={img}
                  onUpload={onUpload}
                  onRemove={onAvatarRemove}
                />
              </Form.Item>
              <Form.Item
                label="Tytuł"
                name="name"
                labelCol={{ span: 24 }}
                rules={[{ required: true }]}
              >
                <Input name="name" />
              </Form.Item>
              <Form.Item
                label="Opis"
                name="description"
                labelCol={{ span: 24 }}
                rules={[{ required: true, max: 255 }]}
              >
                <Input.TextArea name="description" />
              </Form.Item>
              <Form.Item
                name="disposable"
                label="Forma rozliczenia"
                labelCol={{ span: 24 }}
              >
                <Radio.Group name="disposable" buttonStyle="solid">
                  <Radio.Button value={0}>Miesięczna</Radio.Button>
                  <Radio.Button value={1}>Jednorazowa</Radio.Button>
                  <Radio.Button value={2}>Niedotyczy</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="managerId"
                label="Osoba kontaktowa"
                labelCol={{ span: 24 }}
              >
                <Select
                  name="managerId"
                  defaultValue={managerId}
                  showSearch
                  placeholder="Wybierz lub wyszukaj"
                  optionFilterProp="children"
                  filterOption={filterOption}
                >
                  {groupedUsers.map((group: any, index: number) => (
                    <OptGroup label={AccountType[index]}>
                      {group.map((member: any) => {
                        const optionLabel = `${member?.name || ""} ${
                          member.surname || ""
                        } (${member.email})`;
                        return <Option value={member.id}>{optionLabel}</Option>;
                      })}
                    </OptGroup>
                  ))}
                </Select>
              </Form.Item>
              <SubmitButton loading={editRequest}>Zapisz</SubmitButton>
            </Form>
          )}
        />
      </Card>
    </Spin>
  );
};

export default TicketGroupBasicForm;
