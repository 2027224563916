import { Button, Popover } from "antd";
import React, { useState } from "react";
import moment from "moment";
import DueDateForm from "./DueDateForm";
import Can from "../../../Shared/Can";

const DueDatePopover = (props: any): JSX.Element => {
  const { ticket, onSubmit, editable } = props;
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Popover
      visible={visible}
      onVisibleChange={(change) => setVisible(change)}
      style={{ width: "400px" }}
      placement="bottom"
      content={
        <DueDateForm
          onSubmit={onSubmit}
          value={ticket?.dueDate}
          showOnFrontPage={ticket?.dueDateShow}
          setPopoverVisible={setVisible}
        />
      }
      title="Zmień termin"
      trigger={editable ? "click" : "disabled"}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
    >
      <Button
        type="text"
        style={{ padding: 0, pointerEvents: editable ? "initial" : "none" }}
      >
        {ticket?.dueDate
          ? moment(ticket?.dueDate * 1000).format("DD.MM.Y HH:mm")
          : "Brak"}
      </Button>
    </Popover>
  );
};

export default DueDatePopover;
