import { AxiosResponse } from "axios";
import { RcFile } from "antd/lib/upload/interface";
import api from "../axiosInstance";

class TicketService {
  getTicket = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`tickets/${id}`);
    return response;
  };

  getTicketAttachments = async (
    id: string | number
  ): Promise<AxiosResponse> => {
    const response = await api.get(`tickets/${id}/attachments`);
    return response;
  };

  getCalendarTickets = async (): Promise<AxiosResponse> => {
    const response = await api.get(`tickets/calendar`);
    return response;
  };

  getArchiveTickets = async (): Promise<AxiosResponse> => {
    const response = await api.get(`tickets/archive`);
    return response;
  };

  getUserAssignedTickets = async (): Promise<AxiosResponse> => {
    const response = await api.get(`tickets/assigned`);
    return response;
  };

  getTicketTypes = async (): Promise<AxiosResponse> => {
    const response = await api.get(`tickets/types`);
    return response;
  };

  create = async (ticket: any): Promise<AxiosResponse> => {
    const response = await api.post(`tickets`, ticket);
    return response;
  };

  createQuick = async (ticket: any): Promise<AxiosResponse> => {
    const response = await api.post(`tickets/quick`, ticket);
    return response;
  };

  update = async (id: number | string, ticket: any): Promise<AxiosResponse> => {
    const response = await api.patch(`tickets/${id}`, ticket);
    return response;
  };

  createComment = async (
    id: number | string,
    comment: any
  ): Promise<AxiosResponse> => {
    const response = await api.post(`tickets/${id}/comment`, comment);
    return response;
  };

  createScratchpad = async (
    id: number | string,
    comment: any
  ): Promise<AxiosResponse> => {
    const response = await api.post(`tickets/${id}/scratchpad`, comment);
    return response;
  };

  getScratchpad = async (id: number | string): Promise<AxiosResponse> => {
    const response = await api.get(`tickets/${id}/scratchpad`);
    return response;
  };

  getComments = async (id: number | string): Promise<AxiosResponse> => {
    const response = await api.get(`tickets/${id}/comments`);
    return response;
  };

  getActivity = async (id: number | string): Promise<AxiosResponse> => {
    const response = await api.get(`tickets/${id}/activity`);
    return response;
  };

  setAsUnread = async (id: number | string): Promise<AxiosResponse> => {
    const response = await api.get(`tickets/${id}/unread`);
    return response;
  };

  leaveTicket = async (id: number | string): Promise<AxiosResponse> => {
    const response = await api.get(`tickets/${id}/leave`);
    return response;
  };

  uploadFile = async (
    id: string | number,
    bulkId: string | number,
    file: string | RcFile | Blob,
    onProgressChange: (progress: number) => void
  ): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.set("file", file);

    const response = await api.post(
      `tickets/${id}/attachment/upload/${bulkId}`,
      formData,
      {
        onUploadProgress(progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          onProgressChange(percentCompleted);
        },
      }
    );
    return response;
  };

  uploadAttachment = async (
    file: string | RcFile | Blob,
    onProgressChange: (progress: number) => void
  ): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.set("file", file);
    const response = await api.post(`/attachments/upload`, formData, {
      onUploadProgress(progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        onProgressChange(percentCompleted);
      },
    });
    return response;
  };

  downloadFile = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`attachments/download/${id}`, {
      responseType: "blob",
    });

    return response;
  };

  previewFile = async (id: string | number) => {
    const response = await api.get(`attachments/download/${id}`, {
      responseType: "blob",
    });
    const objectUrl = URL.createObjectURL(response.data);
    return objectUrl;
  };
}

export default new TicketService();
