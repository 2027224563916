import { Avatar, Col, Divider, Space, Table } from "antd";
import React from "react";
import { makeAvatarName, stringToColour } from "../../../utils/common";

type PropsType = {
  person: any;
  divider: boolean;
  // eslint-disable-next-line react/require-default-props
  info?: string;
};

const Person = (props: PropsType) => {
  const { person, divider, info } = props;
  return (
    <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={24} className="person-box">
      <Space size="large">
        <Avatar
          size="large"
          src={person.img}
          className="user-app__avatar"
          style={{
            color: "white",
            backgroundColor: stringToColour(person.email),
            borderColor: "#fefefe",
            borderWidth: 1,
            borderStyle: "solid",
          }}
        >
          {makeAvatarName(person.deleted, person.name, person.surname)}
        </Avatar>

        <div>
          <h3 style={{ margin: 0 }}>
            {person.name} {person.surname}
          </h3>
          <a href={`mailto:${person.email}`} className="jb-link jb-link--block">
            {person.email}
          </a>
          {person.phone && (
            <a
              href={`tel:${person.phone}`}
              className="jb-link jb-link--block person-box__phone"
            >
              {person.phone}
            </a>
          )}
          {info && <p>{info}</p>}
        </div>
      </Space>
      {divider && <Divider style={{ margin: "10 0" }} />}
    </Col>
  );
};

export default Person;
