import React from "react";
import { Tooltip } from "antd";
import classnames from "classnames";
import { Emoji, EmojiStyle } from "emoji-picker-react";

interface IProps {
  reactions: {
    key: string;
    user: string;
  }[];
  onClick: (key: string) => void;
}

const ChatReactions = (props: IProps): JSX.Element | null => {
  const { reactions, onClick } = props;
  if (!reactions.length) return null;

  const grouped = Object.values(
    reactions.reduce((acc: any, obj) => {
      const { key } = obj;
      if (!acc[key]) {
        acc[key] = { key, count: 0, users: [] };
      }
      // eslint-disable-next-line no-plusplus
      acc[key].count++;
      acc[key].users.push(obj.user);
      return acc;
    }, {})
  );
  return (
    <div className="chat__reactions">
      {grouped.map((reaction: any) => (
        <Tooltip
          title={reaction.users.join(", ")}
          mouseEnterDelay={0.2}
          mouseLeaveDelay={0.2}
        >
          <button
            type="button"
            onClick={() => onClick(reaction.key)}
            className={classnames({
              "chat__reactions-item": true,
              "chat__reactions-item--multiple": reaction.count > 1,
            })}
          >
            <div>
              {reaction.count > 1 && (
                <small className="chat__reactions-count">
                  {reaction.count}
                </small>
              )}
              <Emoji
                unified={reaction.key}
                size={14}
                emojiStyle={EmojiStyle.NATIVE}
              />
            </div>
          </button>
        </Tooltip>
      ))}
    </div>
  );
};

export default ChatReactions;
