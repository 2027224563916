import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Checkbox, Form, Input, Select, SubmitButton } from "formik-antd";
import { Button, Card, Space, Spin } from "antd";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import {
  IChatFormProps,
  IChatFormValues,
  ISplitterBasicFormProps,
  ISplitterBasicFormValues,
} from "../../../../../types/ticket-group";
import RemoveMessagesButton from "../../../../Shared/RemoveMessagesButton";

const { Option, OptGroup } = Select;
const TicketGroupChatForm = (props: IChatFormProps) => {
  const { chatEnabled, onSave, isLoading, onRemoveMessages } = props;
  // TODO: managerID should be relation instead of integer
  const initialFormValues: IChatFormValues = {
    chatEnabled: false,
  };
  const [formData, setFormData] = useState<IChatFormValues>(initialFormValues);

  // TODO: zmodyfikować requesty zapisu
  const [editRequest, setEditRequest] = useState<boolean>(false);

  useEffect(() => {
    setFormData({
      chatEnabled,
    });
  }, [chatEnabled]);

  const formikRef = useRef<FormikValues>();

  const FormSchema = Yup.object().shape({});

  return (
    <Spin spinning={isLoading}>
      <Card title="Czat">
        <Formik
          innerRef={formikRef as MutableRefObject<any>}
          initialValues={formData}
          enableReinitialize
          validationSchema={FormSchema}
          onSubmit={(values: IChatFormValues, { resetForm, setErrors }) => {
            setEditRequest(true);
            onSave(values, () => setEditRequest(false), setErrors);
          }}
          validateOnChange
          render={() => (
            <Form>
              <Form.Item name="chatEnabled" valuePropName="checked">
                <Checkbox name="chatEnabled">Czat aktywny</Checkbox>
              </Form.Item>
              <Space>
                <SubmitButton loading={editRequest}>Zapisz</SubmitButton>
                <RemoveMessagesButton
                  title="Czy usunąć wszczystkie wiadomości z tego kanału?"
                  onConfirm={(callbackHidePopover: () => void) =>
                    onRemoveMessages(callbackHidePopover)
                  }
                  okText="Usuń"
                  cancelText="Anuluj"
                />
              </Space>
            </Form>
          )}
        />
      </Card>
    </Spin>
  );
};

export default TicketGroupChatForm;
