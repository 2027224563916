import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Checkbox, Form, Select, SubmitButton } from "formik-antd";
import { Button, Card, Col, Row, Space, Spin, Typography, Switch } from "antd";
import { Formik, FormikErrors, FormikProps, FormikValues } from "formik";
import {
  DoubleRightOutlined,
  EditOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import Collapsible from "react-collapsible";
import {
  AccountType,
  IUserPermissionForm,
  IUserPermissionFormValues,
} from "../../../../../types/user";
import { RoleGroup, RoleModel } from "../../../../../reducers/roles";
import PermissionGroupIcon from "./PermissionGroupIcon";

interface IUserPermissionFormProps {
  isLoading: boolean;
  permissions: RoleGroup[];
  tags: any[];
  userPermissions: RoleModel[];
  userAssignionTag: any[];
  typeAccount: number;
  onSave: (
    values: IUserPermissionFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<IUserPermissionFormValues>) => void
  ) => void;
}

const UserPermissionsForm = (props: IUserPermissionFormProps) => {
  const {
    isLoading,
    onSave,
    permissions,
    userPermissions,
    userAssignionTag,
    typeAccount,
    tags,
  } = props;
  const initialFormValues: IUserPermissionFormValues = {
    role: [],
    allowedAssignionTag: [],
  };
  const [formData, setFormData] = useState<IUserPermissionForm>(
    initialFormValues
  );

  const [extendedAll, setExtendedAll] = useState<boolean>(false);
  const [openPanels, setOpenPanels] = useState<number[]>([]);
  const filterOption = useCallback((search, option) => {
    return option.label.toLowerCase().includes(search.toLowerCase());
  }, []);

  const [editRequest, setEditRequest] = useState<boolean>(false);

  useEffect(() => {
    setFormData({
      role: userPermissions?.map((role: RoleModel) => role.id),
      allowedAssignionTag: userAssignionTag?.map((tag) => {
        return { value: tag.id, label: tag.name };
      }),
    });
  }, [userAssignionTag, userPermissions]);

  const formikRef = useRef<FormikValues>();

  const toggleAllPanels = () => {
    if (openPanels.length !== permissions.length) {
      setOpenPanels(permissions.map((roleGroup) => roleGroup.id));
    } else {
      setOpenPanels([]);
    }
  };

  const togglePanel = (id: number) => {
    const indexOf = openPanels.findIndex((item) => item === id);
    if (indexOf >= 0) {
      setOpenPanels((prevValues) => [
        ...prevValues.slice(0, indexOf),
        ...prevValues.slice(indexOf + 1),
      ]);
    } else {
      setOpenPanels((prevValues) => [...prevValues, id]);
    }
  };

  const toggleRole = (id: number, shouldToggle: boolean) => {
    const roles = formikRef?.current?.values.role;

    if (roles) {
      const indexOf = roles.findIndex((item: number) => item === id);

      if (shouldToggle && indexOf < 0) {
        roles.push(id);
        formikRef?.current?.setFieldValue("role", roles);
      }

      if (!shouldToggle && indexOf >= 0) {
        formikRef?.current?.setFieldValue(
          "role",
          [...roles.slice(0, indexOf), ...roles.slice(indexOf + 1)],
          false
        );
      }
    }
  };

  const renderPermissionsCheckboxes = (
    group: RoleGroup,
    formProps: FormikProps<any>,
    toggledPanels: number[]
  ): JSX.Element => {
    const fields = group.roles
      .sort((a, b) => a.sort - b.sort)
      .map((role) => {
        return {
          label: role.description,
          value: role.id,
          possibleFor: role.possibleFor,
          defaultFor: role.defaultFor,
        };
      });

    let checked = 0;
    group.roles.forEach((role) => {
      if (
        role.defaultFor.includes(typeAccount) ||
        formProps.values.role?.includes(role.id)
      ) {
        // eslint-disable-next-line no-plusplus
        checked++;
      }
    });

    return (
      <Collapsible
        transitionTime={100}
        open={toggledPanels.includes(group.id)}
        onTriggerClosing={() => togglePanel(group.id)}
        onTriggerOpening={() => togglePanel(group.id)}
        trigger={
          <Row
            justify="space-between"
            align="middle"
            className="permissions-accordion__button"
          >
            <Col>
              <Space>
                <PermissionGroupIcon icon={group.icon} />
                <span>{group.name}</span>
              </Space>
            </Col>
            <Col>
              <Space>
                <strong>
                  {checked}/{group.roles.length}
                </strong>
                <Button
                  type="default"
                  size="middle"
                  style={{ marginLeft: 16 }}
                  icon={<EditOutlined />}
                />
              </Space>
            </Col>
          </Row>
        }
      >
        <Row className="permissions-accordion__panel">
          {fields.map((field: any) => {
            return (
              <>
                <Col span={24}>
                  <Row
                    style={{ marginTop: 4, marginBottom: 4 }}
                    gutter={[16, 16]}
                    wrap={false}
                    align="middle"
                  >
                    <Col flex="auto" md={{ order: 2 }}>
                      <Typography.Text
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          if (
                            !(
                              !field.possibleFor.includes(typeAccount) ||
                              field.defaultFor.includes(typeAccount)
                            )
                          ) {
                            toggleRole(
                              field.value,
                              !(
                                field.defaultFor.includes(typeAccount) ||
                                formProps.values.role?.includes(field.value)
                              )
                            );
                          }
                        }}
                        disabled={
                          !field.possibleFor.includes(typeAccount) ||
                          field.defaultFor.includes(typeAccount)
                        }
                      >
                        {field.label}
                      </Typography.Text>
                    </Col>
                    <Col
                      flex="44px"
                      md={{ order: 1 }}
                      style={{ minWidth: "auto" }}
                    >
                      <Switch
                        style={{ paddingTop: 4, paddingBottom: 4 }}
                        checked={
                          field.defaultFor.includes(typeAccount) ||
                          formProps.values.role?.includes(field.value)
                        }
                        onChange={(value) => toggleRole(field.value, value)}
                        disabled={
                          !field.possibleFor.includes(typeAccount) ||
                          field.defaultFor.includes(typeAccount)
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                {field.value === 21 &&
                typeAccount !== AccountType.Administrator &&
                formProps.values.role?.includes(field.value) ? (
                  <Form.Item
                    name="allowedAssignionTag"
                    label="Etykiety"
                    labelCol={{ span: 24 }}
                    style={{ width: "100%" }}
                  >
                    <Select
                      mode="multiple"
                      name="allowedAssignionTag"
                      placeholder="Wybierz etykiety użytkowników, których będzie można przydzielać"
                      labelInValue
                      filterOption={filterOption}
                      options={tags.map((availableTag: any) => {
                        return {
                          value: availableTag.id,
                          label: availableTag.name,
                        };
                      })}
                    />
                  </Form.Item>
                ) : null}
              </>
            );
          })}
        </Row>
      </Collapsible>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <Card
        className="permissions-accordion__card"
        title="Ustawienia konta"
        bodyStyle={{ padding: 0 }}
        extra={
          <Space>
            <DoubleRightOutlined
              rotate={permissions.length === openPanels.length ? -90 : 90}
              onClick={toggleAllPanels}
            />
            <SettingOutlined className="permissions-accordion__iconBorder" />
          </Space>
        }
      >
        <Formik
          innerRef={formikRef as MutableRefObject<any>}
          initialValues={formData}
          enableReinitialize
          onSubmit={(
            values: IUserPermissionFormValues,
            { resetForm, setErrors }
          ) => {
            setEditRequest(true);
            onSave(values, () => setEditRequest(false), setErrors);
          }}
          validateOnChange
          render={(formProps) => (
            <Form>
              <Row>
                <Checkbox.Group name="role" style={{ width: "100%" }}>
                  <Form.Item name="role">
                    {permissions
                      .sort((a, b) => a.sort - b.sort)
                      .map((roleGroup) =>
                        renderPermissionsCheckboxes(
                          roleGroup,
                          formProps,
                          openPanels
                        )
                      )}
                  </Form.Item>
                </Checkbox.Group>
              </Row>
              <Row className="permissions-accordion__submit">
                <SubmitButton loading={editRequest}>Zapisz</SubmitButton>
              </Row>
            </Form>
          )}
        />
      </Card>
    </Spin>
  );
};

export default UserPermissionsForm;
