/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { DragOutlined } from "@ant-design/icons";

const DragHandleButton = (props: any) => {
  const { additionalClass } = props;
  return (
    <div
      className={`ant-btn ant-btn-icon-only dnd-handle-button ${additionalClass}`}
      {...props}
    >
      <DragOutlined />
    </div>
  );
};

export default DragHandleButton;
