import React, {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Form, Input, SubmitButton } from "formik-antd";
import { Col, Divider, Row, Switch } from "antd";
import { Formik, FormikErrors, FormikValues } from "formik";
import * as Yup from "yup";
import RemoveRecord from "../../../../Shared/RemoveRecord";
import ColorPicker from "../../../../Shared/ColorPicker";
import { Disposable } from "../../../../../types/ticket-group";

export interface ITicketTypeFormProps {
  disposable: number;
  typeId: number;
  group: string | number;
  name: string;
  color: string;
  costHour: number;
  limitHour: number;
  autoclosetickets: number;
  defaultType: number | null;
  isLoading: boolean;
  isUpdating: boolean;
  onSave: (
    values: ITicketTypeFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<ITicketTypeFormValues>) => void
  ) => void;
  onRemove: (userId: string | number, onRequestComplete: () => void) => void;
  onDefaultTypeChange: (ticketTypeId: number, change: boolean) => void;
}

export interface ITicketTypeFormValues {
  typeId: number | string;
  name: string;
  color: string;
  costHour: number;
  limitHour: number;
  autoclosetickets: number;
}

const TicketTypeForm = forwardRef((props: ITicketTypeFormProps, ref) => {
  const {
    disposable,
    name,
    autoclosetickets,
    typeId,
    color,
    costHour,
    limitHour,
    onDefaultTypeChange,
    defaultType,
    isUpdating,
    onSave,
    onRemove,
  } = props;

  const initialFormValues: ITicketTypeFormValues = {
    typeId,
    name,
    autoclosetickets,
    color,
    costHour,
    limitHour,
  };
  const [formData, setFormData] = useState<ITicketTypeFormValues>(
    initialFormValues
  );

  const [editRequest, setEditRequest] = useState<boolean>(false);

  useEffect(() => {
    setFormData({
      typeId,
      name,
      autoclosetickets,
      color,
      costHour,
      limitHour,
    });
  }, [autoclosetickets, color, costHour, limitHour, name, typeId]);

  const formikRef = useRef<FormikValues>();

  useImperativeHandle(ref, () => ({
    bulkSubmit() {
      formikRef.current?.submitForm();
    },
  }));

  const FormSchema = Yup.object().shape({
    name: Yup.string()
      .max(255, "Nazwa powinna się składać z maksymalnie 255 znaków")
      .required("Pole wymagane"),
    autoclosetickets: Yup.number()
      .min(0, "Nazwa powinna się składać z maksymalnie 255 znaków")
      .integer("pełne dni")
      .required("Pole wymagane"),
  });

  return (
    <>
      <Formik
        innerRef={formikRef as MutableRefObject<any>}
        initialValues={formData}
        enableReinitialize
        validationSchema={FormSchema}
        onSubmit={(values: ITicketTypeFormValues, { resetForm, setErrors }) => {
          setEditRequest(true);
          onSave(values, () => setEditRequest(false), setErrors);
        }}
        validateOnChange
        render={(formProps) => (
          <Form>
            <Row gutter={0} align="top" justify="space-around">
              <Col
                xs={{ span: 24, order: 2 }}
                sm={{ span: 24, order: 2 }}
                md={{ span: 2, order: 1 }}
                lg={2}
                xl={2}
                xxl={2}
              >
                <Form.Item
                  name="default"
                  labelCol={{ span: 24 }}
                  label="Domyślny"
                >
                  <Switch
                    disabled={isUpdating}
                    style={{ marginTop: 0 }}
                    size="default"
                    checked={defaultType === typeId}
                    onChange={(change) => onDefaultTypeChange(typeId, change)}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                md={{ span: 4, order: 2 }}
                lg={8}
                xl={8}
                xxl={8}
              >
                <Form.Item
                  name="name"
                  label="Nazwa"
                  labelCol={{ span: 24 }}
                  rules={[{ required: false, max: 255 }]}
                >
                  <Input name="name" />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, order: 3 }}
                sm={{ span: 24, order: 3 }}
                md={2}
                lg={2}
                xl={2}
                xxl={2}
              >
                <Form.Item
                  name="color"
                  labelCol={{ span: 24 }}
                  label="Kolor"
                  rules={[{ required: false }]}
                >
                  <ColorPicker
                    color={formProps.values.color}
                    onChange={(change: string) =>
                      formProps.setFieldValue("color", change)
                    }
                  />
                </Form.Item>
              </Col>

              {disposable !== Disposable.Niedotyczy && (
                <>
                  <Col
                    xs={{ span: 24, order: 4 }}
                    sm={{ span: 24, order: 4 }}
                    md={3}
                    lg={3}
                    xl={2}
                    xxl={3}
                  >
                    <Form.Item
                      name="costHour"
                      label="Koszt(h)"
                      labelCol={{ span: 24 }}
                      rules={[{ required: false }]}
                    >
                      <Input type="number" name="costHour" />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={{ span: 24, order: 5 }}
                    sm={{ span: 24, order: 5 }}
                    md={3}
                    lg={3}
                    xl={2}
                    xxl={3}
                  >
                    <Form.Item
                      name="limitHour"
                      label="Limit(h)"
                      labelCol={{ span: 24 }}
                      rules={[{ required: false }]}
                    >
                      <Input type="number" name="limitHour" />
                    </Form.Item>
                  </Col>
                </>
              )}
              <Col
                xs={{ span: 24, order: 6 }}
                sm={{ span: 24, order: 6 }}
                md={3}
                lg={3}
                xl={2}
                xxl={3}
              >
                <Form.Item
                  name="autoclosetickets"
                  label="Zamykaj nieaktywne wątki (dni)"
                  labelCol={{ span: 24 }}
                  rules={[{ required: false }]}
                >
                  <Input
                    type="number"
                    name="autoclosetickets"
                    step={1}
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24, order: 7 }}
                sm={{ span: 24, order: 7 }}
                md={1}
                lg={1}
                xl={1}
                xxl={1}
              >
                <RemoveRecord
                  title="Czy napewno chcesz usunąć typ wątku?"
                  onConfirm={(callbackHidePopover: () => void) =>
                    onRemove(typeId, callbackHidePopover)
                  }
                  okText="Usuń"
                  cancelText="Anuluj"
                  tooltipText="Usuń"
                />
              </Col>
            </Row>
          </Form>
        )}
      />
      <Divider style={{ marginBottom: 8, marginTop: 8 }} />
    </>
  );
});

export default TicketTypeForm;
