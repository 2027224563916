import React, {
  createRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Checkbox,
  Form,
  Input,
  Radio,
  Select,
  SubmitButton,
} from "formik-antd";
import { Alert, Card, DatePicker, Divider, Spin, Typography } from "antd";
import { Formik, FormikErrors, FormikProps, FormikValues } from "formik";

import HTMLReactParser from "html-react-parser";

import "moment/locale/pl";
import locale from "antd/es/date-picker/locale/pl_PL";
import moment, { Moment } from "moment";

import { CalendarOutlined } from "@ant-design/icons";
import { generateRandomString } from "../../../../../utils/common";
import {
  IUserBasicFormValues,
  IUserHolidayFormValues,
} from "../../../../../types/user";
import { CalendarFilterOptions } from "../../../../Shared/CustomFilters/CalendarPicker";

moment.locale("pl");
interface IUserHolidayFormProps {
  holidaysFrom: number | null;
  holidaysUntil: number | null;
  holidaysReason: string | null;
  onHolidays: boolean;

  isLoading: boolean;

  onSave: (
    values: any,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<IUserHolidayFormValues>) => void
  ) => void;
}

const UserHolidaysForm = (props: IUserHolidayFormProps) => {
  const {
    onHolidays,
    holidaysFrom,
    holidaysUntil,
    holidaysReason,
    isLoading,

    onSave,
  } = props;
  const initialFormValues: IUserHolidayFormValues = {
    onHolidays: false,
    holidaysFrom: moment().toDate(),
    holidaysUntil: moment().toDate(),
    holidaysReason: "",
  };
  const [formData, setFormData] = useState<IUserHolidayFormValues>(
    initialFormValues
  );
  const [editRequest, setEditRequest] = useState<boolean>(false);
  const formikRef = useRef<FormikValues>();

  const onChangeDateRange = (
    type: CalendarFilterOptions,
    dates: [Moment, Moment]
  ) => {
    if (!formikRef.current) return;
    formikRef.current.setFieldValue(
      "holidaysFrom",
      dates ? dates[0] : moment()
    );
    formikRef.current.setFieldValue(
      "holidaysUntil",
      dates ? dates[1] : moment()
    );
    console.log(dates);
  };
  // const onResetClick = () => {
  //   setFromDate(moment());
  //   setToDate(moment());
  // };
  const calendarRef = createRef<any>();

  useEffect(() => {
    setFormData({
      onHolidays,
      holidaysFrom: holidaysFrom ? moment(holidaysFrom * 1000).toDate() : null,
      holidaysUntil: holidaysUntil
        ? moment(holidaysUntil * 1000).toDate()
        : null,
      holidaysReason,
    });
  }, [holidaysFrom, holidaysReason, holidaysUntil, onHolidays]);

  const formValues = formikRef.current?.values;

  return (
    <Spin spinning={isLoading}>
      <Card title="Ustawienia urlopu">
        <Formik
          innerRef={formikRef as MutableRefObject<any>}
          initialValues={formData}
          enableReinitialize
          onSubmit={(
            values: IUserHolidayFormValues,
            { resetForm, setErrors }
          ) => {
            setEditRequest(true);
            onSave(
              {
                ...values,
                holidaysUntil: values.holidaysUntil
                  ? moment(values.holidaysUntil).endOf("day").unix()
                  : null,
                holidaysFrom: values.holidaysFrom
                  ? moment(values.holidaysFrom).startOf("day").unix()
                  : null,
              },
              () => setEditRequest(false),
              setErrors
            );
          }}
          validateOnChange
          render={(formikProps: FormikProps<any>) => {
            const { values } = formikProps;
            return (
              <Form>
                <Form.Item name="onHolidays" valuePropName="checked">
                  <Checkbox name="onHolidays">Urlop aktywny</Checkbox>
                </Form.Item>
                <Divider style={{ marginTop: 16, marginBottom: 8 }} />
                <Typography.Paragraph strong style={{ fontSize: 15 }}>
                  Opcje
                </Typography.Paragraph>
                <Form.Item
                  label="Data urlopu"
                  name="holidaysDate"
                  labelCol={{ span: 24 }}
                >
                  <DatePicker.RangePicker
                    ref={calendarRef}
                    allowClear={false}
                    onChange={(dates: any) => {
                      onChangeDateRange(CalendarFilterOptions.CUSTOM, dates);
                    }}
                    value={[
                      values && values.holidaysFrom
                        ? moment(values.holidaysFrom, "YYYY-MM-DD")
                        : moment(moment(), "YYYY-MM-DD").startOf("day"),
                      values && values.holidaysUntil
                        ? moment(values.holidaysUntil, "YYYY-MM-DD").endOf(
                            "day"
                          )
                        : moment(moment(), "YYYY-MM-DD"),
                    ]}
                    disabled={!values.onHolidays}
                    suffixIcon={<CalendarOutlined />}
                  />
                </Form.Item>
                <Form.Item
                  label="Powód urlopu"
                  name="holidaysReason"
                  labelCol={{ span: 24 }}
                >
                  <Input
                    name="holidaysReason"
                    disabled={!values.onHolidays}
                    placeholder="Pole niewymagane"
                  />
                </Form.Item>
                <Alert
                  style={{
                    marginBottom: 16,
                    fontWeight: "normal",
                  }}
                  message={HTMLReactParser(
                    "<p>Podanie powodu urlopu jest dobrowolne. Wprowadź informacje, jeśli chcesz poinformować pozostałych użytkowników, " +
                      "kiedy ktoś jest chory lub odpoczywa na wakacjach.</p><p style='margin-bottom: 0'>Informacja ta jest widoczna po najechaniu na ikonkę użytkownika.</p>"
                  )}
                  type="warning"
                />
                <SubmitButton loading={editRequest}>Zapisz</SubmitButton>
              </Form>
            );
          }}
        />
      </Card>
    </Spin>
  );
};

export default UserHolidaysForm;
