import { Button, Space } from "antd";
import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { openNotificationWithIcon } from "../../../../utils/common";

const ValuationAnswer = (props: any) => {
  const { handleValuationAnswer } = props;

  const [isLoading, setIsLoading] = useState(0);

  const handleAnswer = (answer: boolean) => {
    setIsLoading(answer ? 2 : 1);
    handleValuationAnswer(
      { acceptValuation: answer },
      () => {
        setIsLoading(0);
        openNotificationWithIcon(
          "success",
          answer ? "Wycena zaakceptowana" : "Wycena odrzucona"
        );
      },
      () => setIsLoading(0)
    );
  };

  return (
    <Space style={{ width: "100%" }} wrap>
      <Button
        danger
        onClick={(event) => {
          if (isLoading) {
            event.preventDefault();
          } else {
            handleAnswer(false);
          }
        }}
      >
        {isLoading === 1 ? <LoadingOutlined spin /> : "Odrzuć"}
      </Button>
      <Button
        className="jb-button--ghost jb-button--success"
        onClick={(event) => {
          if (isLoading) {
            event.preventDefault();
          } else {
            handleAnswer(true);
          }
        }}
      >
        {isLoading === 2 ? <LoadingOutlined spin /> : "Zaakceptuj"}
      </Button>
    </Space>
  );
};

export default ValuationAnswer;
