import { Grid } from "antd";
import { useEffect, useState } from "react";

const useIsMobile = (res = "md") => {
  const { useBreakpoint } = Grid;
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [listening, setListening] = useState<boolean>(false);
  const screens = useBreakpoint();

  useEffect(() => {
    // eslint-disable-next-line eqeqeq

    const isMdBreakpoint = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .filter((screen) => screen[0] === res).length;

    setIsMobile(!isMdBreakpoint);
  }, [res, screens, setIsMobile]);

  return isMobile;
};
export default useIsMobile;
