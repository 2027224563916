import { Dispatch } from "redux";
import { RcFile } from "antd/es/upload";
import UsersService from "../../services/users-service";
import {
  BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP,
  BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP_FAILED,
  BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP_SUCCESS,
  CREATE_REQUEST,
  CREATE_REQUEST_FAILED,
  CREATE_REQUEST_SUCCESS,
  EDIT_USER_REQUEST,
  EDIT_USER_REQUEST_FAILED,
  EDIT_USER_REQUEST_SUCCESS,
  FETCH_FAILED,
  FETCH_REQUEST,
  FETCH_TAGS,
  FETCH_TAGS_FAILED,
  FETCH_TAGS_SUCCESS,
  FETCH_TICKET_GROUPS_MEMBERS_FAILED,
  FETCH_TICKET_GROUPS_MEMBERS_REQUEST,
  FETCH_TICKET_GROUPS_MEMBERS_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_REQUEST_SUCCESS,
  FETCH_USER_TICKET_GROUP_MEMBERSHIP,
  FETCH_USER_TICKET_GROUP_MEMBERSHIP_FAILED,
  FETCH_USER_TICKET_GROUP_MEMBERSHIP_SUCCESS,
  REMOVE_REQUEST,
  REMOVE_REQUEST_FAILED,
  REMOVE_REQUEST_SUCCESS,
  SET_LOGGED_USER,
  SET_USER,
  SET_USERS,
} from "../action-types";
import TaskGroupsService from "../../services/taskgroups-service";

export const getUsers = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_REQUEST,
    payload: true,
  });
  return UsersService.getUsers().then(
    (response) => {
      dispatch({
        type: SET_USERS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const getUsersAdmin = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_REQUEST,
    payload: true,
  });
  return UsersService.getUsersAdmin().then(
    (response) => {
      dispatch({
        type: SET_USERS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const getUser = (id: string | number) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_USER_REQUEST,
    payload: true,
  });
  return UsersService.getUser(id).then(
    (response) => {
      dispatch({
        type: FETCH_USER_REQUEST_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const createUser = (user: any) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: CREATE_REQUEST,
    payload: true,
  });
  return UsersService.createUser(user).then(
    (response) => {
      dispatch({
        type: CREATE_REQUEST_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: CREATE_REQUEST_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};

export const editUser = (id: string | number, data: any) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: EDIT_USER_REQUEST,
    payload: true,
  });
  return UsersService.editUser(id, data).then(
    (response) => {
      dispatch({
        type: EDIT_USER_REQUEST_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: EDIT_USER_REQUEST_FAILED,
      });
      return Promise.reject(error);
    }
  );
};

export const editOwnUser = (data: any) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: EDIT_USER_REQUEST,
    payload: true,
  });
  return UsersService.editOwnUser(data).then(
    (response) => {
      dispatch({
        type: EDIT_USER_REQUEST_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: EDIT_USER_REQUEST_FAILED,
      });
      return Promise.reject(error);
    }
  );
};

export const uploadAvatar = (
  id: string | number,
  file: RcFile | Blob | string
) => (dispatch: Dispatch<any>) => {
  return UsersService.uploadAvatar(id, file).then(
    (response) => {
      dispatch({
        type: SET_USER,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const uploadOwnAvatar = (file: RcFile | Blob | string) => (
  dispatch: Dispatch<any>
) => {
  return UsersService.uploadOwnAvatar(file).then(
    (response) => {
      // dispatch({
      //   type: SET_USER,
      //   payload: response.data,
      // });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const setUser = (user: any) => (dispatch: Dispatch<any>) => {
  return dispatch({
    type: SET_USER,
    payload: true,
  });
};

export const removeAvatar = (id: string | number) => (
  dispatch: Dispatch<any>
) => {
  return UsersService.editUser(id, { img: "" }).then(
    (response) => {
      dispatch({
        type: SET_USER,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const removeOwnAvatar = () => (dispatch: Dispatch<any>) => {
  return UsersService.editOwnUser({ img: "" }).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export const softDeleteUser = (id: string) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: REMOVE_REQUEST,
    payload: true,
  });

  return UsersService.softDelete(id).then(
    (response) => {
      dispatch({
        type: REMOVE_REQUEST_SUCCESS,
        payload: id,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: REMOVE_REQUEST_FAILED,
        payload: id,
      });

      return Promise.reject(error);
    }
  );
};

export const getTags = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_TAGS,
    payload: true,
  });
  return UsersService.getTags().then(
    (response) => {
      dispatch({
        type: FETCH_TAGS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_TAGS_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const getTicketGroupMembership = (id: string | number) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_USER_TICKET_GROUP_MEMBERSHIP,
    payload: true,
  });
  return UsersService.getUserGroupsMembership(id).then(
    (response) => {
      dispatch({
        type: FETCH_USER_TICKET_GROUP_MEMBERSHIP_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_USER_TICKET_GROUP_MEMBERSHIP_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const bulkAddTicketGroupMembership = (
  id: string | number,
  groups: number[]
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP,
    payload: true,
  });

  const requests: any[] = [];
  groups.forEach((groupId) => {
    requests.push(TaskGroupsService.assignMember(groupId, id));
  });

  return Promise.all(requests).then(
    (response) => {
      const responseData = response
        .filter((res) => res.status === 201)
        .map((res) => res.data);
      dispatch({
        type: BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP_SUCCESS,
        payload: responseData,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: BULK_ASSIGN_USER_TICKET_GROUP_MEMBERSHIP_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};
