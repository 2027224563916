/* eslint-disable react-hooks/exhaustive-deps */
import React, { PropsWithChildren, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import "moment/locale/pl";
import HTMLReactParser from "html-react-parser";

import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Empty,
  Grid,
  Input,
  Progress,
  Row,
  Skeleton,
  Space,
  Tabs,
  Tooltip,
} from "antd";

import {
  AreaChartOutlined,
  CalendarOutlined,
  FieldTimeOutlined,
  FilterOutlined,
  LockOutlined,
  NotificationOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { FormikErrors } from "formik";
import moment, { Moment } from "moment";
import TaskGroupsService from "../../../services/taskgroups-service";
import { AppState } from "../../../reducers";
import List from "./list";
import {
  formatMoney,
  formatSeconds,
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";

import {
  clearTicketGroupState,
  getTicketGroup,
  getTicketGroupTicketTypes,
} from "../../../actions/ticketgroups";
import {
  setCurrentEntityForbidden,
  setCurrentGroupTitle,
} from "../../../actions/global";
import { Disposable } from "../../../types/ticket-group";
import CalendarPicker, {
  CalendarFilterOptions,
} from "../../Shared/CustomFilters/CalendarPicker";
import GroupDeadlines from "./deadlines";
import Can from "../../Shared/Can";
import Notes from "./notes";
import ContactPerson from "./ContactPerson";
import Person from "./Person";
import { updateTicket } from "../../../actions/tickets";
import UsersService from "../../../services/users-service";
import TicketGroupUpdateFilter from "../../Shared/CustomFilters/TicketGroupUpdate";
import { MetaTitle } from "../../Shared/MetaTitle";
import TicketType from "../../Shared/TicketType";

const { TabPane } = Tabs;

interface IRouteParams {
  groupId: string;
}

const TaskGroupPage = (props: PropsWithChildren<any>): JSX.Element => {
  const { groupId } = useParams<IRouteParams>();
  const [deadlinesVisible, setDeadlinesVisible] = React.useState(false);
  const [filterVisible, setFilterVisible] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [deadlines, setDeadlines] = React.useState([]);
  const [notes, setNotes] = React.useState<string | null>(null);
  const [typeSummary, setTypeSummary] = React.useState<[]>([]);
  const [isTypeSummaryLoading, setTypeSummaryLoading] = React.useState(false);
  const [isNotesLoading, setNotesLoading] = React.useState<boolean>(false);

  const [contact, setContact] = React.useState<any>(null);
  const [isContactLoading, setContactLoading] = React.useState<boolean>(false);
  const location = useLocation();
  const [supervisorScrolled, setSupervisorScrolled] = React.useState<boolean>(
    false
  );
  const scrollRef = useRef<any>();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isXxlBreakpoint = Object.entries(screens)
    .filter((screen) => !!screen[1])
    .filter((screen) => screen[0] === "xxl").length;

  const isLgBreakpoint = Object.entries(screens)
    .filter((screen) => !!screen[1])
    .filter((screen) => screen[0] === "lg").length;

  useEffect(() => {
    // eslint-disable-next-line eqeqeq

    const isMdBreakpoint = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .filter((screen) => screen[0] === "md").length;

    setIsMobile(!isMdBreakpoint);
  }, [screens, setIsMobile]);

  const getNotes = (id: number | string) => {
    setNotesLoading(true);
    TaskGroupsService.getNotes(id)
      .then((res) => {
        setNotes(res.data.notes);
      })
      .finally(() => {
        setNotesLoading(false);
      });
  };

  const onMetaSave = (meta: any) => {
    return UsersService.updateMeta(meta);
  };

  const getTicketTypeSummary = (id: number | string) => {
    setTypeSummaryLoading(true);
    TaskGroupsService.getGroupTicketTypesSummary(id)
      .then((res) => {
        setTypeSummary(res.data);
      })
      .finally(() => {
        setTypeSummaryLoading(false);
      });
  };

  const toggleDeadlinesVisible = () => {
    UsersService.updateMeta({ deadlinesVisible: !deadlinesVisible });
    setDeadlinesVisible(!deadlinesVisible);
  };

  const handleSubmitNote = (
    id: number | string,
    note: string,
    override: boolean
  ): Promise<any> => {
    if (override) {
      setNotes(note);
    }
    return TaskGroupsService.postNotes(id, note)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((err) => {
        return Promise.reject(err);
      })
      .finally(() => {
        setNotesLoading(false);
      });
  };

  const getContactPerson = (id: number | string) => {
    setContactLoading(true);
    TaskGroupsService.getContactPerson(id)
      .then((res) => {
        if (res.data) {
          setContact(res.data);
        }
      })
      .finally(() => {
        setContactLoading(false);
      });
  };

  const {
    ticketGroup,
    types,
    isRequest,
    loggedUser,
    getTicketGroupAction,
    getTicketTypesAction,
    setCurrentGroupTitleAction,
    clearTicketGroupStateAction,
    setCurrentEntityForbiddenAction,
    updateTicketAction,
    displayNotification,
  } = props;

  const handleSubmitMiniForm = (
    values: any,
    onRequestComplete: () => void,
    setError?: (formikResponse: FormikErrors<any>) => void
  ) => {
    const { entityId, ...vals } = values;
    updateTicketAction(entityId, vals)
      .then((res: any) => {
        getTicketGroupAction(groupId);
        onRequestComplete();
      })
      .catch((err: any) => {
        const formikResponse = transformToFormikError(err);
        if (err.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił błąd w trakcie wykonywania akcji"
          );
          if (setError) setError(formikResponse);
        }

        if (err.response?.status === 403) {
          openNotificationWithIcon(
            "error",
            "Nie posiadasz wystarczających uprawnień do wykonania tej akcji."
          );
          if (setError) setError(formikResponse);
        }
      });
  };

  useEffect(() => {
    if (groupId) {
      getTicketGroupAction(groupId);
      getTicketTypesAction(groupId);
      getNotes(groupId);
      getContactPerson(groupId);
      getTicketTypeSummary(groupId);
    }
  }, [getTicketGroupAction, getTicketTypesAction, groupId]);

  useEffect(() => {
    if (deadlines && deadlines.length) {
      const toggled =
        typeof loggedUser?.meta?.deadlinesVisible === "boolean"
          ? loggedUser?.meta?.deadlinesVisible
          : true;
      setDeadlinesVisible(toggled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deadlines, loggedUser?.meta?.deadlinesVisible]);

  useEffect(() => {
    if (location.hash === "#supervisor" && ticketGroup) {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView();
        // window.scrollTo(0, scrollRef.current.offsetTop);
        setSupervisorScrolled(true);
      }
    }
    setCurrentGroupTitleAction(ticketGroup?.name);
    const groupDeadlines = ticketGroup?.tasks
      ?.filter(
        (task: any) =>
          task.status_id?.id !== 6 &&
          task.status_id?.id !== 7 &&
          task.status_id?.id !== 8 &&
          task.status_id?.id !== 9 &&
          task.dueDate
      )
      .sort((a: any, b: any) => a.dueDate - b.dueDate);
    setDeadlines(groupDeadlines);
  }, [
    location.hash,
    setCurrentGroupTitleAction,
    supervisorScrolled,
    ticketGroup,
  ]);

  useEffect(() => {
    return () => {
      clearTicketGroupStateAction();
      setCurrentGroupTitleAction(null);
      setCurrentEntityForbiddenAction(false);
    };
  }, [
    clearTicketGroupStateAction,
    setCurrentEntityForbiddenAction,
    setCurrentGroupTitleAction,
  ]);

  const userMeta = loggedUser?.meta;
  const groupFilters = userMeta?.groupFilters?.[groupId] || {};
  const [filter, setFilter] = React.useState<{
    begin: number;
    end: number;
    selectedType: CalendarFilterOptions;
    type: number;
    display: number;
    answerCount: number;
    noAnswerCount: number;
  }>({
    begin: groupFilters?.begin || moment("2022-01-01").unix(),
    end: groupFilters?.end || moment().endOf("month").unix(),
    selectedType: groupFilters?.selectedType || CalendarFilterOptions.ALL,
    type: groupFilters?.type || 5,
    noAnswerCount: groupFilters?.noAnswerCount || 7,
    display: groupFilters?.display || 6,
    answerCount: groupFilters?.answerCount || 5,
  });

  useEffect(() => {
    const meta = loggedUser?.meta;
    const groupMeta = meta?.groupFilters?.[groupId] || {};
    setFilter({
      begin: groupMeta?.begin || moment("2022-01-01").unix(),
      end: groupMeta?.end || moment().endOf("month").unix(),
      selectedType: groupMeta?.selectedType || CalendarFilterOptions.ALL,
      type: groupFilters?.type || 5,
      noAnswerCount: groupFilters?.noAnswerCount || 7,
      display: groupFilters?.display || 6,
      answerCount: groupFilters?.answerCount || 5,
    });
  }, [groupId, loggedUser]);

  const filterRef = useRef();

  const shouldRenderDivider = (isLast: boolean) => {
    if (isXxlBreakpoint) return isLast;
    if (!isXxlBreakpoint && isLgBreakpoint) return true;
    if (!isXxlBreakpoint && !isLgBreakpoint) return isLast;

    return false;
  };

  return (
    <Can renderError type="ticket_group_view">
      <div className="ticket-group-page">
        <MetaTitle
          title={ticketGroup?.name ? `${ticketGroup?.name}` : "Listing wątków"}
          displayBadge={displayNotification}
        />

        {deadlinesVisible && (
          <Row gutter={[16, 16]} className="deadline-table">
            <Col span={24}>
              <Card bordered={false} size="small">
                <GroupDeadlines
                  types={types}
                  tasks={deadlines}
                  groupId={ticketGroup.id}
                  isRequest={isRequest}
                  handleSubmitMiniForm={handleSubmitMiniForm}
                />
              </Card>
            </Col>
          </Row>
        )}
        <Row gutter={[16, 16]}>
          <Col sm={24} md={24} lg={24} xl={24} xxl={18}>
            {ticketGroup.deleted && (
              <Alert
                className="announcement"
                message="Dział i wszystkie wątki zostały zarchiwizowane"
                type="error"
                showIcon
                style={{ marginBottom: "8px" }}
                icon={<LockOutlined />}
              />
            )}
            {ticketGroup.announcement && (
              <Alert
                className="announcement"
                message={HTMLReactParser(ticketGroup.announcement || "")}
                type="info"
                showIcon
                style={{ marginBottom: "8px" }}
                icon={<NotificationOutlined />}
              />
            )}
            <Space
              direction="vertical"
              style={{ marginLeft: "auto", width: "100%" }}
            >
              <Card bordered={false}>
                <Row justify="space-between" gutter={[16, 16]}>
                  <Space>
                    <Input
                      prefix={<SearchOutlined />}
                      placeholder="Szukaj w dziale"
                      onChange={(text) => console.log(text)}
                      // style={{ width: 200 }}
                    />
                    <CalendarPicker
                      ref={filterRef}
                      begin={filter.begin}
                      end={filter.end}
                      selectedType={filter.selectedType}
                      onChange={(
                        begin: Moment,
                        end: Moment,
                        selectedType: CalendarFilterOptions
                      ) => {
                        setFilter((prevState) => {
                          return { ...prevState, ...filter };
                        });
                        onMetaSave({
                          groupFilters: {
                            [groupId]: {
                              begin: begin.unix(),
                              end: end.unix(),
                              selectedType,
                            },
                          },
                        }).then((res: any) => {
                          getTicketGroupAction(groupId);
                        });
                      }}
                    />
                  </Space>
                  <Space align="end">
                    {isMobile && (
                      <Tooltip title="Filtry">
                        <Button
                          type={filter.type !== 5 ? "primary" : "default"}
                          icon={<FilterOutlined />}
                          onClick={() => setFilterVisible(true)}
                        />
                      </Tooltip>
                    )}
                    <Can type="ticket_group_worktimes_view_button">
                      <Tooltip title="Czas pracy">
                        <Link to={`/group/${groupId}/worktimes`}>
                          <Button icon={<FieldTimeOutlined />} />
                        </Link>
                      </Tooltip>
                    </Can>
                    <Tooltip title="Terminy">
                      <Badge
                        count={deadlines?.length}
                        size="small"
                        className="ant-badge-round"
                      >
                        <Button
                          type={deadlinesVisible ? "primary" : "default"}
                          icon={<CalendarOutlined />}
                          onClick={() => toggleDeadlinesVisible()}
                        />
                      </Badge>
                    </Tooltip>
                    <Can entity={ticketGroup} type="ticket_create">
                      <Link to={`/group/${groupId}/new-ticket`} type="ghost">
                        <Button type="primary" icon={<PlusOutlined />}>
                          Dodaj wątek
                        </Button>
                      </Link>
                    </Can>
                  </Space>
                </Row>
              </Card>
              <Card bordered={false} size="small">
                <List
                  types={types}
                  tasks={ticketGroup.tasks}
                  groupId={ticketGroup.id}
                  isRequest={isRequest}
                  leaders={ticketGroup?.leaders}
                  onMetaSave={onMetaSave}
                  userMeta={loggedUser?.meta}
                  filters={filter}
                  getTicketGroup={getTicketGroupAction}
                  handleSubmitMiniForm={handleSubmitMiniForm}
                />
              </Card>
            </Space>
          </Col>
          <Col lg={24} xl={24} xxl={6}>
            <Row gutter={[0, 16]}>
              <Can type="ticket_group_worktimes_summary">
                <Col xs={24} lg={24} xl={24} xxl={24}>
                  <Card
                    bordered={false}
                    title="Podsumowanie"
                    extra={
                      <Space>
                        <Can type="ticket_group_worktimes_summary">
                          <Link to={`/group/${groupId}/worktimes`}>
                            <Button icon={<AreaChartOutlined />} />
                          </Link>
                        </Can>
                        <Can type="ticket_group_edit" entity={ticketGroup}>
                          <Link to={`/groups/${groupId}/edit?tab=4`}>
                            <Button icon={<SettingOutlined />} />
                          </Link>
                        </Can>
                      </Space>
                    }
                  >
                    {isTypeSummaryLoading ? (
                      <Skeleton active />
                    ) : (
                      <Row gutter={[32, 32]}>
                        {typeSummary.map((type: any) => (
                          <Col xs={24} lg={8} xl={8} xxl={24}>
                            <Row
                              style={{ width: "100%" }}
                              justify="space-between"
                            >
                              <div ref={scrollRef}>
                                <div>
                                  <TicketType ticketType={type.ticketType} />
                                </div>
                                <small>{formatSeconds(type.seconds)}</small>
                              </div>
                              {ticketGroup.disposable !==
                                Disposable.Niedotyczy &&
                              (type.ticketType.limitHour > 0 ||
                                type.ticketType.costHour > 0) ? (
                                <span>
                                  {formatMoney(type.ticketType.costHour)} /{" "}
                                  {type.ticketType.limitHour || 0} h
                                </span>
                              ) : null}
                              {type.ticketType.limitHour > 0 &&
                                type.progress && (
                                  <Progress
                                    percent={Math.floor(type.progress)}
                                    strokeColor={type.ticketType.color}
                                  />
                                )}
                            </Row>
                            <Divider style={{ margin: "10 0" }} />
                          </Col>
                        ))}

                        {!types.length && (
                          <Col span={24}>
                            <Empty description="Brak podsumowania" />
                          </Col>
                        )}
                      </Row>
                    )}
                  </Card>
                </Col>
              </Can>
              <Col xs={24} lg={24} xl={24} xxl={24}>
                <Card
                  tabIndex={-1}
                  bordered={false}
                  title="Opiekunowie działu"
                  id="supervisor"
                >
                  <Row gutter={[16, 16]}>
                    {isRequest && <Skeleton active />}
                    {!isRequest &&
                      ticketGroup?.leaders?.map(
                        (leader: any, index: number) => {
                          const renderDivider = shouldRenderDivider(
                            index !== ticketGroup.leaders.length - 1
                          );
                          return (
                            <Person
                              key={leader.id}
                              person={leader.user}
                              divider={renderDivider}
                              info={leader.info}
                            />
                          );
                        }
                      )}
                    {!isRequest && !ticketGroup?.leaders?.length ? (
                      <Col span={24}>
                        <Empty description="Brak przypisanych opiekunów" />
                      </Col>
                    ) : null}
                  </Row>
                </Card>
              </Col>
              <Col xs={24} lg={24} xl={24} xxl={24}>
                <ContactPerson person={contact} isLoading={isContactLoading} />
              </Col>
              <Can type="ticket_group_display_notes">
                <Col xs={24} lg={24} xl={24} xxl={24}>
                  <Notes
                    group={ticketGroup}
                    note={notes}
                    isLoading={isNotesLoading}
                    onSubmit={(note, override: boolean) =>
                      handleSubmitNote(groupId, note, override)
                    }
                  />
                </Col>
              </Can>
            </Row>
          </Col>
        </Row>
        <Drawer
          title="Filtry"
          placement="right"
          closable={false}
          destroyOnClose
          width={300}
          visible={filterVisible}
          key="left"
          onClose={() => setFilterVisible(false)}
          className="filter-drawer--p20"
        >
          <TicketGroupUpdateFilter
            type="group"
            initFilters={filter}
            handleChange={(selected: any) => {
              const newFilter = {
                type: selected.type,
                display: selected.display,
                answerCount: selected.answerCount,
                noAnswerCount: selected.noAnswerCount,
              };
              setFilter((prevState) => {
                return { ...prevState, ...newFilter };
              });
              setFilterVisible(false);
              onMetaSave({ groupFilters: { [groupId]: newFilter } }).then(
                (res: any) => {
                  getTicketGroupAction(groupId);
                }
              );
            }}
            handleClear={() => {
              setFilter((prevState) => {
                return { ...prevState, type: 5 };
              });
              setFilterVisible(false);
              onMetaSave({ groupFilters: { [groupId]: { type: 5 } } }).then(
                (res: any) => {
                  getTicketGroupAction(groupId);
                }
              );
            }}
          />
        </Drawer>
      </div>
    </Can>
  );
};

const mapDispatchToProps = {
  getTicketGroupAction: getTicketGroup,
  getTicketTypesAction: getTicketGroupTicketTypes,
  setCurrentGroupTitleAction: setCurrentGroupTitle,
  setCurrentEntityForbiddenAction: setCurrentEntityForbidden,
  clearTicketGroupStateAction: clearTicketGroupState,
  updateTicketAction: updateTicket,
};

const mapStateToProps = (state: AppState) => {
  return {
    ticketGroup: state.ticketgroups.ticketgroup,
    types: state.ticketgroups.ticketgroupTicketTypes,
    isRequest: state.ticketgroups.isFetchTicketGroupRequest,
    isTypesRequest: state.ticketgroups.isFetchTicketGroupTicketTypesRequest,
    loggedUser: state.auth.logged,
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskGroupPage);
