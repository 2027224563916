import React, {
  MutableRefObject,
  Ref,
  RefObject,
  useCallback,
  useRef,
  useState,
} from "react";
import { Form, Select } from "formik-antd";
import { Button, Empty, Spin } from "antd";
import { Formik, FormikErrors, FormikProps, FormikValues } from "formik";
import * as Yup from "yup";

import { AccountTypeWithHighlight } from "../../../../../types/user";

const { Option, OptGroup } = Select;

export interface IFormProps {
  showSubmitButton: boolean;
  isLoading: boolean;
  assignedUsers: any[];
  leaders: any[];
  members: any[];
  users: any[];
  onTouched: (value: boolean) => void;
  formikRef?: RefObject<FormikProps<IFormValues>>;
  onSave: (
    values: IFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<IFormValues>) => void
  ) => void;
}

export interface IFormValues {
  assignedUsers: string[];
}

export interface IAddAssigned {
  label: string;
  value: number;
  key: number;
}

const TicketAddAssignedForm = (props: IFormProps) => {
  const {
    users,
    assignedUsers,
    leaders,
    members,
    isLoading,
    onSave,
    formikRef,
    showSubmitButton,
    onTouched,
  } = props;
  const initialFormValues: IFormValues = {
    assignedUsers: [],
  };

  const [formData, setFormData] = useState<IFormValues>(initialFormValues);

  const [editRequest, setEditRequest] = useState<boolean>(false);

  // useEffect(() => {
  //   setFormData({
  //     leaders,
  //   });
  // }, [leaders]);

  const FormSchema = Yup.object().shape({
    assignedUsers: Yup.array().required("Pole wymagane"),
  });

  const groupedUsers: any = {};

  users.forEach((user) => {
    if (
      !assignedUsers.some((assignedUser: any) => assignedUser.key === user.id)
    ) {
      const isLeader = leaders.some(
        (leader: any) => leader.user.id === user.id
      );

      const isMember = members.some(
        (member: any) => member.user.id === user.id
      );
      let groupLabel = null;

      if (isLeader) {
        groupLabel = 3;
      } else if (isMember) {
        groupLabel = 4;
      }

      const group = groupedUsers[groupLabel || user.typeAccount] || [];
      group.push(user);
      // eslint-disable-next-line no-param-reassign
      groupedUsers[groupLabel || user.typeAccount] = group;
    }
  }, []);

  const filterOption = useCallback((search, optionOrGroup) => {
    const isGroup = Array.isArray(optionOrGroup.options);
    if (isGroup) {
      return false;
    }
    return optionOrGroup.children.toLowerCase().includes(search.toLowerCase());
  }, []);

  return (
    <Spin spinning={isLoading}>
      <Formik
        innerRef={formikRef}
        initialValues={formData}
        enableReinitialize
        validationSchema={FormSchema}
        onSubmit={(values: IFormValues, { resetForm, setErrors }) => {
          setEditRequest(true);
          return onSave(
            values,
            () => {
              setEditRequest(false);
              resetForm();
              onTouched(false);
            },
            setErrors
          );
        }}
        validateOnChange
        render={(formProps) => (
          <Form style={{ marginTop: 16, marginBottom: 16 }}>
            <Form.Item
              name="assignedUsers"
              label="Przydziel osoby"
              labelCol={{ span: 24 }}
            >
              <Select
                name="assignedUsers"
                style={{ width: "100%" }}
                showSearch
                getPopupContainer={(trigger) =>
                  trigger.parentNode as HTMLElement
                }
                placeholder="Wybierz lub wyszukaj"
                optionFilterProp="children"
                notFoundContent={
                  <Empty description="Brak więcej użytkowników dla których posiadasz uprawnienie przydzielenia." />
                }
                mode="multiple"
                filterOption={filterOption}
                onSelect={() => {
                  onTouched(true);
                }}
              >
                {Object.keys(groupedUsers)
                  // eslint-disable-next-line no-nested-ternary
                  .sort((a, b) => (a > b ? -1 : 1))
                  .map((key: any, index: number) => {
                    return (
                      <OptGroup label={AccountTypeWithHighlight[key]}>
                        {groupedUsers[key].map((user: any) => {
                          const optionLabel = `${user.name} ${user.surname} (${user.email})`;
                          return (
                            <Option
                              value={JSON.stringify({
                                key: user.id,
                                label: `${user.name} ${user.surname}`,
                                typeAccount: user.typeAccount,
                                tag: user.tag,
                              })}
                              key={user.id}
                            >
                              {optionLabel}
                            </Option>
                          );
                        })}
                      </OptGroup>
                    );
                  })}
              </Select>
            </Form.Item>
            {showSubmitButton && (
              <Button
                disabled={!formProps.values.assignedUsers?.length}
                type="primary"
                loading={editRequest}
                onClick={(event) => {
                  formProps.submitForm().then((result) => {
                    formProps.resetForm();
                    setEditRequest(false);
                  });
                }}
              >
                Zatwierdź
              </Button>
            )}
          </Form>
        )}
      />
    </Spin>
  );
};

export default TicketAddAssignedForm;
