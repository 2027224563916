import React, { PropsWithChildren } from "react";
import { Button, Space, Table, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { FieldTimeOutlined } from "@ant-design/icons";

import Text from "antd/es/typography/Text";
import { formatSeconds } from "../../../utils/common";

const TicketList = (props: PropsWithChildren<any>): JSX.Element => {
  const { data, isRequest, isMobile } = props;
  let columns = [
    {
      title: "Dział",
      dataIndex: "name",
      key: "mobileRow",
      hidden: !isMobile,
      width: "80%",
      render: (name: string, group: any) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Link
            to={`/group/${group.id}`}
            className="jb-link jb-link--secendary"
          >
            {name}
          </Link>

          <Tooltip title={formatSeconds(parseInt(group.seconds, 10), true)}>
            <Text style={{ marginTop: 16 }}>
              <small>
                Wypracowane: {formatSeconds(parseInt(group.seconds, 10))}
              </small>
            </Text>
          </Tooltip>

          <Text>
            <small>Limit: {group.limit}h</small>
          </Text>
        </div>
      ),
    },
    {
      title: "Dział",
      dataIndex: "name",
      key: "name",
      hidden: isMobile,
      width: "50%",
      render: (name: string, group: any) => (
        <Link to={`/group/${group.id}`} className="jb-link jb-link--secendary">
          {name}
        </Link>
      ),
    },
    {
      title: "Wypracowane",
      dataIndex: "seconds",
      hidden: isMobile,
      key: "seconds",
      render: (seconds: string) => {
        return (
          <Tooltip title={formatSeconds(parseInt(seconds, 10), true)}>
            <Text>{formatSeconds(parseInt(seconds, 10))}</Text>
          </Tooltip>
        );
      },
    },
    {
      title: "Limit",
      dataIndex: "limit",
      ellipsis: true,
      hidden: isMobile,
      key: "limit",
      render: (limit: string) => {
        return <Text>{limit}h</Text>;
      },
    },
    {
      title: "Szczegóły",
      dataIndex: "action",
      ellipsis: false,
      key: "action",
      render: (progress: string, worktime: any) => {
        const groupId = worktime.id;
        return (
          <Link to={`/group/${groupId}/worktimes`}>
            <Button icon={<FieldTimeOutlined />} />
          </Link>
        );
      },
    },
  ];

  columns = columns.filter((row) => !row.hidden);
  return (
    <Table
      style={{ width: "100%" }}
      locale={{ emptyText: "Brak danych" }}
      size="middle"
      dataSource={data}
      columns={columns}
      pagination={{ position: ["bottomRight"], pageSize: 20 }}
      loading={isRequest}
      rowKey="id"
    />
  );
};

export default TicketList;
