import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Form, Input, SubmitButton } from "formik-antd";
import { Col, Divider, Row } from "antd";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import {
  ITicketGroupLeaderFormProps,
  ITicketGroupLeaderFormValues,
} from "../../../../../types/ticket-group";
import RemoveRecord from "../../../../Shared/RemoveRecord";

const TicketGroupLeaderForm = (props: ITicketGroupLeaderFormProps) => {
  const { info, userId, name, surname, isLoading, onSave, onRemove } = props;

  const initialFormValues: ITicketGroupLeaderFormValues = {
    info: "",
    userId: "",
  };
  const [formData, setFormData] = useState<ITicketGroupLeaderFormValues>(
    initialFormValues
  );

  const [editRequest, setEditRequest] = useState<boolean>(false);

  useEffect(() => {
    setFormData({
      info,
      userId,
    });
  }, [info, userId]);

  const formikRef = useRef<FormikValues>();

  const FormSchema = Yup.object().shape({
    info: Yup.string()
      .max(255, "Informacje powinny się składać z maksymalnie 255 znaków")
      .notRequired(),
  });

  const title = `${name} ${surname}`;
  return (
    <>
      <Formik
        innerRef={formikRef as MutableRefObject<any>}
        initialValues={formData}
        enableReinitialize
        validationSchema={FormSchema}
        onSubmit={(
          values: ITicketGroupLeaderFormValues,
          { resetForm, setErrors }
        ) => {
          setEditRequest(true);
          onSave(values, () => setEditRequest(false), setErrors);
        }}
        validateOnChange
        render={() => (
          <Form>
            <Row gutter={16} align="middle" justify="space-around">
              <Col xs={24} md={6} lg={6} xl={4} xxl={4}>
                <p className="leaders__name">{title}</p>
              </Col>
              <Col xs={24} md={10} lg={12} xl={12} xxl={13}>
                <Form.Item
                  name="info"
                  label="Info"
                  labelCol={{ span: 24 }}
                  rules={[{ required: false, max: 255 }]}
                >
                  <Input.TextArea name="info" autoSize={{ maxRows: 2 }} />
                </Form.Item>
              </Col>
              <Col xs={24} md={8} lg={6} xl={8} xxl={7}>
                <SubmitButton
                  loading={editRequest}
                  style={{ marginBottom: 14, marginRight: 16 }}
                >
                  Zapisz
                </SubmitButton>
                <RemoveRecord
                  title="Czy napewno chcesz usunąć opiekuna?"
                  onConfirm={(callbackHidePopover: () => void) =>
                    onRemove(userId, callbackHidePopover)
                  }
                  okText="Usuń"
                  cancelText="Anuluj"
                  tooltipText="Usuń"
                />
              </Col>
            </Row>
          </Form>
        )}
      />
      <Divider style={{ marginBottom: 8, marginTop: 8 }} />
    </>
  );
};

export default TicketGroupLeaderForm;
