import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Spin,
  Tabs,
  Typography,
} from "antd";
import { RcFile } from "antd/es/upload";
import { AxiosError, AxiosResponse } from "axios";
import { FormikErrors } from "formik";
import { AppState } from "../../../reducers";
import {
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";

import {
  bulkAddTicketGroupLeaders,
  bulkAddTicketGroupMembers,
  createTicketGroupTicketType,
  editTicketGroup,
  getTicketGroup,
  getTicketGroupLeaders,
  getTicketGroupMembers,
  getTicketGroupTicketTypes,
  removeAvatar,
  removeTicketGroupLeader,
  removeTicketGroupMember,
  removeTicketGroupTicketType,
  updateTicketGroupLeader,
  updateTicketGroupTicketType,
  uploadLogo,
} from "../../../actions/ticketgroups";
import TicketGroupBasicForm from "./EditTicketGroupForm/BasicForm";
import {
  Disposable,
  IChatFormValues,
  ISplitterBasicFormValues,
  ITicketGroupAddLeader,
  ITicketGroupAddLeadersFormValues,
  ITicketGroupBasicFormValues,
  ITicketGroupLeaderFormValues,
} from "../../../types/ticket-group";
import SplitterBasicForm from "./EditTicketGroupForm/SplitterBasicForm";
import { getUsers } from "../../../actions/users";
import TicketGroupAddLeadersForm from "./EditTicketGroupForm/AddLeadersForm";
import TicketGroupLeaderForm from "./EditTicketGroupForm/LeaderForm";
import TicketTypeForm, {
  ITicketTypeFormValues,
} from "./EditTicketGroupForm/TicketTypeForm";
import AddTicketTypeForm, {
  IAddTicketTypeFormValues,
} from "./EditTicketGroupForm/AddTicketTypeForm";
import TicketGroupMemberForm from "./EditTicketGroupForm/MemberForm";
import TicketGroupAddMembersForm, {
  ITicketGroupAddMember,
  ITicketGroupAddMembersFormValues,
} from "./EditTicketGroupForm/AddMemberForm";
import Can from "../../Shared/Can";
import {
  setCurrentGroupTitle,
  setCurrentTicketTitle,
} from "../../../actions/global";
import useRefs from "../../../hooks/useRefs";
import useIsMobile from "../../../hooks/useIsMobile";
import TicketGroupChatForm from "./EditTicketGroupForm/ChatForm";
import ChatService from "../../../services/chat-service";
import { removeChatMessages } from "../../../actions/chat";

const { TabPane } = Tabs;

interface IRouteParams {
  id: string;
}
interface IEditTicketGroupPageProps {
  ticketGroup?: any;
  members: any[];
  leaders: any[];
  ticketTypes: any[];
  users: any[];
  isFetchTicketGroupRequest: boolean;
  isEditTicketGroupRequest: boolean;
  updateTicketTypeRequest: boolean;
  getTicketGroupAction: (id: string | number) => void;
  getMembersAction: (id: string | number) => void;
  getLeadersAction: (id: string | number) => void;
  getTicketTypesAction: (id: string | number) => void;
  getUsersAction: () => void;
  uploadTicketGroupLogoAction: (
    id: string | number,
    file: string | RcFile | Blob
  ) => Promise<any>;
  editTicketGroupAction: (
    id: string | number,
    ticketGroup: any
  ) => Promise<any>;
  removeAvatarAction: (id: string | number) => Promise<any>;
  bulkAddTicketGroupLeadersAction: (
    id: string | number,
    leaders: number[]
  ) => Promise<any>;
  editTicketGroupLeaderAction: (
    id: string | number,
    userId: string | number,
    data: any
  ) => Promise<any>;
  removeTicketGroupLeaderAction: (
    id: string | number,
    userId: string | number
  ) => Promise<any>;
  createTicketGroupTicketTypeAction: (
    id: string | number,
    data: any
  ) => Promise<any>;
  removeTicketGroupTicketTypeAction: (
    id: string | number,
    typeId: string | number
  ) => Promise<any>;
  updateTicketGroupTicketTypeAction: (
    id: string | number,
    typeId: string | number,
    data: any
  ) => Promise<any>;
  bulkAddTicketGroupMembersAction: (
    id: string | number,
    leaders: number[]
  ) => Promise<any>;
  removeTicketGroupMemberAction: (
    id: string | number,
    userId: string | number
  ) => Promise<any>;

  removeChatMessagesAction: (id: string | number) => Promise<any>;
  setCurrentGroupTitleAction: (title: string | null) => void;
}

const TicketGroupEdit = (
  props: PropsWithChildren<IEditTicketGroupPageProps>
): JSX.Element => {
  const { id } = useParams<IRouteParams>();
  const location = useLocation();
  const [defaultTypeId, setDefaultTypeId] = useState<number | null>(0);
  const activeTab = new URLSearchParams(location.search).get("tab") || null;
  const [updatingTypes, setUpdatingTypes] = useState<any>([]);

  const [typeRefs, register] = useRefs();
  const isMobile = useIsMobile();
  const {
    ticketGroup,
    members,
    leaders,
    ticketTypes,
    users,
    uploadTicketGroupLogoAction,
    editTicketGroupAction,
    removeAvatarAction,
    getTicketGroupAction,
    getLeadersAction,
    getMembersAction,
    getUsersAction,
    isFetchTicketGroupRequest,
    bulkAddTicketGroupLeadersAction,
    editTicketGroupLeaderAction,
    removeTicketGroupLeaderAction,
    getTicketTypesAction,
    createTicketGroupTicketTypeAction,
    removeTicketGroupTicketTypeAction,
    updateTicketGroupTicketTypeAction,
    updateTicketTypeRequest,
    bulkAddTicketGroupMembersAction,
    removeTicketGroupMemberAction,
    setCurrentGroupTitleAction,
    removeChatMessagesAction,
  } = props;

  const onSave = (
    values:
      | ITicketGroupBasicFormValues
      | ISplitterBasicFormValues
      | IChatFormValues,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    editTicketGroupAction(id, values)
      .then((response: AxiosResponse) => {
        callbackRequestCompleted();
        openNotificationWithIcon("success", "Dane działu zapisane");
      })
      .catch((err: AxiosError) => {
        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
      });
  };

  const onRemoveMessages = (callbackHidePopover: () => void) => {
    removeChatMessagesAction(ticketGroup.id)
      .then((response: AxiosResponse) => {
        openNotificationWithIcon(
          "success",
          "Wiadomości czatu zostały usunięte"
        );
      })
      .catch((err: AxiosError) => {
        openNotificationWithIcon(
          "error",
          "Brak przypisanego kanału do tego działu"
        );
      })
      .finally(() => {
        callbackHidePopover();
      });
  };

  const onLeadersAdd = (
    values: ITicketGroupAddLeadersFormValues,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    const newLeaders: number[] = values.leaders.map(
      (leader: ITicketGroupAddLeader) => leader.value
    );

    bulkAddTicketGroupLeadersAction(id, newLeaders)
      .then((response: AxiosResponse) => {
        callbackRequestCompleted();
        openNotificationWithIcon("success", "Opiekunowie zaktualizowani");
      })
      .catch((err: AxiosError) => {
        console.error(err);
        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
        if (err.response?.status === 409) {
          openNotificationWithIcon(
            "error",
            "Wystąpił bład. Wybrana osoba znajduję się już na liście opiekunów"
          );
        }
      });
  };

  const onLeaderSave = (
    values: ITicketGroupLeaderFormValues,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    const { userId, ...data } = values;
    editTicketGroupLeaderAction(id, userId, data)
      .then((response: AxiosResponse) => {
        callbackRequestCompleted();
        openNotificationWithIcon("success", "Opiekun zapisany");
      })
      .catch((err: AxiosError) => {
        console.error(err);
        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
      });
  };

  const onLeaderRemove = (
    userId: string | number,
    callbackRequestCompleted: () => void
  ) => {
    removeTicketGroupLeaderAction(id, userId)
      .then((response: AxiosResponse) => {
        callbackRequestCompleted();
        openNotificationWithIcon("success", "Opiekun usunięty");
      })
      .catch((err: AxiosError) => {
        callbackRequestCompleted();
      });
  };

  const onMembersAdd = (
    values: ITicketGroupAddMembersFormValues,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    const newMembers: number[] = values.members.map(
      (member: ITicketGroupAddMember) => member.value
    );

    bulkAddTicketGroupMembersAction(id, newMembers)
      .then((response: AxiosResponse) => {
        callbackRequestCompleted();
        openNotificationWithIcon(
          "success",
          "Przydzielone osoby zaktualizowane."
        );
      })
      .catch((err: AxiosError) => {
        console.error(err);
        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
        if (err.response?.status === 409) {
          openNotificationWithIcon(
            "error",
            "Wystąpił bład. Wybrana osoba znajduję się już na liście przydzielonych osób"
          );
        }
      });
  };

  const onMemberRemove = (
    userId: string | number,
    callbackRequestCompleted: () => void
  ) => {
    removeTicketGroupMemberAction(id, userId)
      .then((response: AxiosResponse) => {
        callbackRequestCompleted();
        openNotificationWithIcon("success", "Przydzielona osoba usunięta");
      })
      .catch((err: AxiosError) => {
        callbackRequestCompleted();
      });
  };

  const onTicketTypeAdd = (
    values: IAddTicketTypeFormValues,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    createTicketGroupTicketTypeAction(id, values)
      .then((response: AxiosResponse) => {
        callbackRequestCompleted();
        openNotificationWithIcon("success", "Typ wątku dodany");
      })
      .catch((err: AxiosError) => {
        console.error(err);
        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
      });
  };

  const onTicketTypeSave = (
    values: ITicketTypeFormValues,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    const { typeId, ...data } = values;
    setUpdatingTypes((state: any[]) => [...state, typeId]);

    updateTicketGroupTicketTypeAction(id, typeId, data)
      .then((response: AxiosResponse) => {
        callbackRequestCompleted();
        setUpdatingTypes((prevState: any) => {
          const idx = prevState.findIndex((item: any) => item === typeId);
          if (idx > -1) {
            return [...prevState.slice(0, idx), ...prevState.slice(idx + 1)];
          }
          return null;
        });
        if (updatingTypes.length === 0)
          openNotificationWithIcon("success", "Typ wątku zapisany");
      })
      .catch((err: AxiosError) => {
        console.error(err);
        setUpdatingTypes((prevState: any) => {
          const idx = prevState.findIndex((item: any) => item === typeId);
          if (idx > -1) {
            return [...prevState.slice(0, idx), ...prevState.slice(idx + 1)];
          }
          return null;
        });

        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
      });
  };

  const onDefaultTypeChange = (ticketTypeId: number, change: boolean) => {
    setDefaultTypeId(change ? ticketTypeId : null);

    updateTicketGroupTicketTypeAction(id, ticketTypeId, { defaultType: change })
      .then((response: AxiosResponse) => {
        openNotificationWithIcon("success", "Domyślny typ zmieniony");
      })
      .catch((err: AxiosError) => {
        console.error(err);
      });
  };

  const onTicketTypeRemove = (
    typeId: string | number,
    callbackRequestCompleted: () => void
  ) => {
    removeTicketGroupTicketTypeAction(id, typeId)
      .then((response: AxiosResponse) => {
        callbackRequestCompleted();
        openNotificationWithIcon("success", "Typ wątku usunięty");
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 409) {
          openNotificationWithIcon(
            "error",
            `Nie można usunąć typu wątku ponieważ wykorzystywany jest on w wątkach.`
          );
        }
        callbackRequestCompleted();
      });
  };

  const onUpload = (
    file: RcFile | Blob | string,
    onRequestComplete: (url?: string) => void
  ) => {
    uploadTicketGroupLogoAction(id, file)
      .then((response: AxiosResponse) => {
        onRequestComplete(response.data.img);
        openNotificationWithIcon("success", "Logo zostało zapisane");
      })
      .catch((error: AxiosError) => {
        onRequestComplete();
        if (error.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił problem w trakcie zapisu logo"
          );
        }
      });
  };

  const onAvatarRemove = (setRequestCompleted: () => void) => {
    removeAvatarAction(id)
      .then((response: AxiosResponse) => {
        openNotificationWithIcon("success", "Avatar został usunięty");
        setRequestCompleted();
      })
      .catch((error: AxiosError) => {
        setRequestCompleted();
        if (error.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił problem w trakcie usuwania avataru"
          );
        }
      });
  };

  useEffect(() => {}, [activeTab]);

  useEffect(() => {
    if (ticketTypes) {
      const defaultType = ticketTypes.filter((type: any) => type.defaultType);
      if (defaultType.length) setDefaultTypeId(defaultType[0].id);
    }
  }, [ticketTypes]);

  useEffect(() => {
    if (id) {
      getTicketGroupAction(id);
      getMembersAction(id);
      getLeadersAction(id);
      getTicketTypesAction(id);
    }
    getUsersAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ticketGroup) {
      setCurrentGroupTitleAction(ticketGroup.name);
    }
    return () => {
      setCurrentGroupTitleAction(null);
    };
  }, [setCurrentGroupTitleAction, ticketGroup]);

  return (
    <Can renderError type="admin_view">
      <div className="ticket_group-edit-page">
        <Row gutter={16}>
          <Col span={24}>
            <Card
              bordered={false}
              style={{ width: "100%" }}
              size={isMobile ? "small" : "default"}
            >
              <Tabs defaultActiveKey={activeTab || "1"}>
                <TabPane tab="Dane podstawowe" key="1">
                  <Row gutter={16} align="middle" justify="center">
                    <Col sm={24} lg={24} xl={20} xxl={12}>
                      {ticketGroup.break ? (
                        <SplitterBasicForm
                          img={ticketGroup.img}
                          name={ticketGroup.name}
                          description={ticketGroup.description}
                          isLoading={isFetchTicketGroupRequest}
                          onUpload={onUpload}
                          onSave={onSave}
                          onAvatarRemove={onAvatarRemove}
                        />
                      ) : (
                        <TicketGroupBasicForm
                          img={ticketGroup.img}
                          name={ticketGroup.name}
                          description={ticketGroup.description}
                          disposable={ticketGroup.disposable}
                          managerId={ticketGroup.managerId}
                          users={users}
                          isLoading={isFetchTicketGroupRequest}
                          onUpload={onUpload}
                          onSave={onSave}
                          onAvatarRemove={onAvatarRemove}
                        />
                      )}
                    </Col>
                  </Row>
                </TabPane>
                {!ticketGroup.break && (
                  <>
                    <TabPane
                      tab="Opiekunowie działu"
                      key="2"
                      className="leaders"
                    >
                      <Row gutter={16} align="middle" justify="center">
                        <Col sm={24} lg={24} xl={20} xxl={12}>
                          <Card title="Opiekunowie działu">
                            <>
                              <Alert
                                style={{
                                  marginBottom: 16,
                                  fontWeight: "normal",
                                }}
                                message="Opiekunowie działu - osoby, które widzą wszystkie wątki w dziale w którym pełnią tą funkcję. Opiekunowie zarządzają osobami przydzielonymi do poszczególnych wątków i są automatycznie przydzielani do każdego wątku, który założą użytkownicy w danym dziale."
                                type="warning"
                              />
                              <Divider />

                              {leaders.length > 0 && (
                                <>
                                  <Spin spinning={isFetchTicketGroupRequest}>
                                    <Row
                                      gutter={16}
                                      align="middle"
                                      justify="space-around"
                                    >
                                      <Col md={6} lg={6} xl={4} xxl={4}>
                                        <p
                                          className="leaders__label"
                                          title="Opiekun"
                                        >
                                          Opiekun
                                        </p>
                                      </Col>
                                      <Col md={10} lg={12} xl={12} xxl={13}>
                                        <p
                                          className="leaders__label"
                                          title="Info"
                                        >
                                          Info
                                        </p>
                                      </Col>
                                      <Col md={8} lg={6} xl={8} xxl={7} />
                                    </Row>
                                    {leaders.map((leader: any) => (
                                      <TicketGroupLeaderForm
                                        userId={leader.user.id}
                                        name={leader.user.name}
                                        surname={leader.user.surname}
                                        info={leader.info}
                                        isLoading={isFetchTicketGroupRequest}
                                        onSave={onLeaderSave}
                                        onRemove={onLeaderRemove}
                                      />
                                    ))}
                                  </Spin>
                                </>
                              )}
                              <TicketGroupAddLeadersForm
                                users={users}
                                leaders={leaders}
                                isLoading={isFetchTicketGroupRequest}
                                onSave={onLeadersAdd}
                              />
                              <Divider />
                            </>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Tagi" key="3" disabled>
                      <div>Tagi</div>
                    </TabPane>
                    <TabPane tab="Typy wątków" key="4" className="ticket-types">
                      <Row gutter={16} align="middle" justify="center">
                        <Col sm={24} lg={24} xl={20} xxl={16}>
                          <Card title="Typy wątków">
                            {ticketTypes.length > 0 && (
                              <>
                                <Spin spinning={isFetchTicketGroupRequest}>
                                  <Row
                                    gutter={0}
                                    align="middle"
                                    justify="space-around"
                                  >
                                    <Col sm={24} md={2} lg={2} xl={2} xxl={2}>
                                      <p className="ticket-types__label">
                                        <Typography.Text
                                          ellipsis
                                          style={{ width: "100%" }}
                                        >
                                          Domyślny
                                        </Typography.Text>
                                      </p>
                                    </Col>
                                    <Col md={8} lg={8} xl={8} xxl={8}>
                                      <p className="ticket-types__label">
                                        Nazwa
                                      </p>
                                    </Col>
                                    <Col md={2} lg={2} xl={2} xxl={2}>
                                      <p className="ticket-types__label">
                                        Kolor
                                      </p>
                                    </Col>
                                    {ticketGroup.disposable !==
                                      Disposable.Niedotyczy && (
                                      <>
                                        <Col md={3} lg={3} xl={2} xxl={3}>
                                          <p className="ticket-types__label">
                                            Koszt(h)
                                          </p>
                                        </Col>
                                        <Col md={3} lg={3} xl={2} xxl={3}>
                                          <p className="ticket-types__label">
                                            Limit(h)
                                          </p>
                                        </Col>
                                      </>
                                    )}
                                    <Col md={3} lg={3} xl={2} xxl={3}>
                                      <p className="ticket-types__label">
                                        Zamykaj nieaktywne wątki (dni)
                                      </p>
                                    </Col>
                                    <Col md={1} lg={1} xl={1} xxl={1} />
                                  </Row>

                                  {ticketTypes.map((type: any) => (
                                    <TicketTypeForm
                                      ref={register(type.id)}
                                      disposable={ticketGroup.disposable}
                                      typeId={type.id}
                                      group={type.group.id}
                                      name={type.name}
                                      color={type.color}
                                      costHour={type.costHour}
                                      autoclosetickets={type.autoclosetickets}
                                      defaultType={defaultTypeId}
                                      limitHour={type.limitHour}
                                      isLoading={isFetchTicketGroupRequest}
                                      onSave={onTicketTypeSave}
                                      onRemove={onTicketTypeRemove}
                                      onDefaultTypeChange={onDefaultTypeChange}
                                      isUpdating={updateTicketTypeRequest}
                                    />
                                  ))}
                                  <Row gutter={0} align="middle" justify="end">
                                    <Col sm={24} md={3} lg={2} xl={2} xxl={2}>
                                      <Button
                                        type="primary"
                                        loading={updatingTypes.length}
                                        onClick={() => {
                                          Object.values(
                                            typeRefs.current
                                          ).forEach((child: any) => {
                                            if (child) child.bulkSubmit();
                                          });
                                        }}
                                      >
                                        Zapisz
                                      </Button>
                                    </Col>
                                  </Row>
                                </Spin>
                              </>
                            )}
                            <AddTicketTypeForm
                              isLoading={isFetchTicketGroupRequest}
                              onSave={onTicketTypeAdd}
                            />
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Przydzieleni" key="5">
                      <Row gutter={16} align="middle" justify="center">
                        <Col sm={24} lg={24} xl={14} xxl={12}>
                          <Card title="Przydzieleni">
                            <Alert
                              style={{
                                marginBottom: 16,
                                fontWeight: "normal",
                              }}
                              message="Przydzieleni - osoby przydzielone zazwyczaj odpowiadają za realizację zadań w określonym dziale. Przydzielonych można w prosty i szybki sposób dodawać do zadań w dziale, bez potrzeby ich filtracji. Ponadto, w zależności od posiadanych uprawnień, mogą widzieć wszystkie wątki dodane w dziale."
                              type="warning"
                            />
                            <Divider />
                            {members.length > 0 && (
                              <>
                                <Spin spinning={isFetchTicketGroupRequest}>
                                  <Row
                                    gutter={16}
                                    align="middle"
                                    justify="space-around"
                                  >
                                    <Col md={16} lg={18} xl={22} xxl={22}>
                                      <p
                                        className="leaders__label"
                                        title="Przydzielony"
                                      >
                                        Przydzielony
                                      </p>
                                    </Col>
                                    <Col md={8} lg={6} xl={2} xxl={2} />
                                  </Row>
                                  {members.map((member: any) => (
                                    <TicketGroupMemberForm
                                      userId={member.user.id}
                                      name={member.user.name}
                                      surname={member.user.surname}
                                      isLoading={isFetchTicketGroupRequest}
                                      onRemove={onMemberRemove}
                                    />
                                  ))}
                                </Spin>
                              </>
                            )}
                            <TicketGroupAddMembersForm
                              users={users}
                              members={members}
                              isLoading={isFetchTicketGroupRequest}
                              onSave={onMembersAdd}
                            />
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  </>
                )}
                <TabPane tab="Czat" key="6">
                  <Row gutter={16} align="middle" justify="center">
                    <Col sm={24} lg={24} xl={14} xxl={12}>
                      <TicketGroupChatForm
                        isLoading={isFetchTicketGroupRequest}
                        onRemoveMessages={onRemoveMessages}
                        chatEnabled={ticketGroup.chatEnabled}
                        onSave={onSave}
                      />
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

const mapDispatchToProps = {
  setCurrentGroupTitleAction: setCurrentGroupTitle,
  getTicketGroupAction: getTicketGroup,
  getMembersAction: getTicketGroupMembers,
  getLeadersAction: getTicketGroupLeaders,
  getTicketTypesAction: getTicketGroupTicketTypes,
  getUsersAction: getUsers,
  uploadTicketGroupLogoAction: uploadLogo,
  removeAvatarAction: removeAvatar,
  editTicketGroupAction: editTicketGroup,
  bulkAddTicketGroupLeadersAction: bulkAddTicketGroupLeaders,
  bulkAddTicketGroupMembersAction: bulkAddTicketGroupMembers,
  editTicketGroupLeaderAction: updateTicketGroupLeader,
  removeTicketGroupLeaderAction: removeTicketGroupLeader,
  removeTicketGroupMemberAction: removeTicketGroupMember,
  createTicketGroupTicketTypeAction: createTicketGroupTicketType,
  updateTicketGroupTicketTypeAction: updateTicketGroupTicketType,
  removeTicketGroupTicketTypeAction: removeTicketGroupTicketType,
  removeChatMessagesAction: removeChatMessages,
};

const mapStateToProps = (state: AppState) => {
  return {
    ticketGroup: state.ticketgroups.ticketgroup,
    members: state.ticketgroups.ticketgroupMembers,
    leaders: state.ticketgroups.ticketgroupLeaders,
    ticketTypes: state.ticketgroups.ticketgroupTicketTypes,
    users: state.users.users,
    isFetchTicketGroupRequest: state.ticketgroups.isFetchTicketGroupRequest,
    isEditTicketGroupRequest: state.ticketgroups.isEditTicketGroupRequest,
    updateTicketTypeRequest:
      state.ticketgroups.isUpdateTicketGroupTicketTypeRequest,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketGroupEdit);
