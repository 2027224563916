import React, { MutableRefObject, PropsWithChildren, useRef } from "react";
import { Form, SubmitButton } from "formik-antd";
import { Row } from "antd";
import { Formik, FormikProps, FormikValues } from "formik";
import ReactQuill from "react-quill-2";
import { AxiosError } from "axios";
import _ from "lodash";
import { openNotificationWithIcon } from "../../../../utils/common";
import { IQuickNoteFormValues } from "../../../../types/quick-forms";
import "moment/locale/pl";
import QuillEditor from "../../../Shared/Editor";

const NoteForm: React.FC<any> = (
  props: PropsWithChildren<any>
): JSX.Element => {
  const formikRef = useRef<FormikValues>();

  const { note, handleSubmit, handleAutoSave } = props;

  const initialFormValues: IQuickNoteFormValues = {
    note,
  };

  const onAutoSave = _.debounce(
    () =>
      handleAutoSave(
        formikRef?.current?.values.note,
        () => {
          // openNotificationWithIcon("success", "Zaktualizowano");
        },
        (err: AxiosError) => {
          // openNotificationWithIcon("error", err.response?.data?.message);
        }
      ),
    1000
  );

  return (
    <Formik
      innerRef={formikRef as MutableRefObject<any>}
      initialValues={initialFormValues}
      onSubmit={(
        values: IQuickNoteFormValues,
        { resetForm, setErrors, setSubmitting }
      ) => {
        handleSubmit(
          values.note,
          () => {
            openNotificationWithIcon("success", "Zaktualizowano");
            setSubmitting(false);
          },
          (err: AxiosError<any>) => {
            openNotificationWithIcon("error", err.response?.data?.message);
            setSubmitting(false);
          }
        );
      }}
      validateOnChange
      render={(formProps: FormikProps<IQuickNoteFormValues>) => (
        <Form>
          <Form.Item
            required
            name="note"
            labelCol={{ span: 24 }}
            style={{ width: "100%" }}
            rules={[{ required: true, message: "Wprowadź estymację!" }]}
          >
            <QuillEditor
              initValue={initialFormValues.note}
              value={formProps.values.note}
              reinitialize={false}
              onChange={(change: string) => {
                formProps.setFieldValue(
                  "note",
                  change === "<p><br></p>" ? null : change
                );
                formProps.setFieldTouched("note", true);
                onAutoSave();
              }}
            />
          </Form.Item>
          <Row justify="center">
            <SubmitButton>Zapisz</SubmitButton>
          </Row>
        </Form>
      )}
    />
  );
};

export default NoteForm;
