import React from "react";
import { Avatar, Space, Spin } from "antd";
import classnames from "classnames";
import {
  makeAvatarName,
  stringToColour,
  timeAgoFromTimestamp,
} from "../../../utils/common";
import useIsMobile from "../../../hooks/useIsMobile";
import ChatUser from "./ChatUser";

interface IProps {
  users: any[];
  isLoading: boolean;
  isMobile: boolean;
}

export const ChatUsers = (props: IProps): JSX.Element => {
  const { users, isLoading, isMobile } = props;
  return (
    <div className="chat__users">
      {isLoading && (
        <div className="chat__preload">
          <Spin />
        </div>
      )}

      {users
        .filter((user) => user.isOnline)
        .sort((a, b) => {
          const compareA = a.surname || a.name;
          const compareB = b.surname || b.name;
          return compareA.localeCompare(compareB);
        })
        .map((user) => (
          <ChatUser user={user} isMobile={isMobile} />
        ))}

      {users
        .filter((user) => !user.isOnline)
        .map((user) => (
          <ChatUser user={user} isMobile={isMobile} />
        ))}
    </div>
  );
};
