import React, {
  cloneElement,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { Dropdown, Empty, Menu, Space, Spin, Tooltip } from "antd";
import "moment/locale/pl";
import { FormikErrors } from "formik";
import {
  CheckOutlined,
  LoadingOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import LoadingIcon from "antd/es/button/LoadingIcon";
import { openNotificationWithIcon } from "../../../../utils/common";
import Can from "../../../Shared/Can";
import TicketGroupService from "../../../../services/taskgroups-service";

type PropsType = {
  groupId: number | string;
  ticket: any;
  ticketType: any;
  onSubmit: (
    values: any,
    onRequestComplete: () => void,
    setError?: (formikResponse: FormikErrors<any>) => void
  ) => void;
  children: ReactElement;
};

const TicketTypeForm = (props: PropsWithChildren<PropsType>): JSX.Element => {
  const { ticket, children, groupId, onSubmit, ticketType } = props;

  const [types, setTypes] = useState([]);
  const [typesLoading, setTypesLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pickedType, setPicketType] = useState(ticketType?.id);

  const canEdit = Can({
    type: "ticket_edit_type",
    entity: ticket,
    children: <></>,
  });

  useEffect(() => {
    setPicketType(ticketType?.id);
  }, [ticketType]);

  const onVisibleChange = (visible: boolean) => {
    if (!visible) return;

    setTypesLoading(true);
    TicketGroupService.getGroupTicketTypes(groupId)
      .then((response) => {
        setTypes(response.data);
      })
      .finally(() => {
        setTypesLoading(false);
      });
  };

  const changeType = (id: number) => {
    setSubmitting(true);
    setPicketType(id);
    onSubmit({ ticketType: id, entityId: ticket?.id }, () => {
      setSubmitting(false);
      openNotificationWithIcon("success", "Typ zmieniony");
    });
  };

  const menu = (
    <Menu>
      {types.map((type: any, index) => (
        <Menu.Item key={type.id} onClick={() => changeType(type.id)}>
          <Space>
            <div
              style={{
                backgroundColor: type.color,
                width: 20,
                height: 20,
              }}
            />
            {type.name}
            {type.id === pickedType && <CheckOutlined />}
          </Space>
        </Menu.Item>
      ))}
      {!types.length && typesLoading && (
        <Space
          align="center"
          style={{ padding: 8, justifyContent: "center", width: "100%" }}
        >
          <LoadingOutlined spin={typesLoading} />
        </Space>
      )}

      {!types.length && !typesLoading && (
        <Empty
          description="Brak zdefiniowanych typów wątków"
          style={{ padding: 10 }}
        />
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={canEdit ? ["click"] : []}
      onVisibleChange={onVisibleChange}
    >
      <div
        className={canEdit ? "hoverable" : ""}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {cloneElement(children, { submitting })}
      </div>
    </Dropdown>
  );
};

export default TicketTypeForm;
