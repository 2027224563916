import { Avatar, Space, Tooltip } from "antd";
import classnames from "classnames";
import React from "react";
import {
  makeAvatarName,
  stringToColour,
  timeAgoFromTimestamp,
} from "../../../utils/common";

interface IProps {
  user: any;
  isMobile: boolean;
}

const ChatUser = (props: IProps): JSX.Element => {
  const { user, isMobile } = props;

  return (
    <Tooltip
      title={
        isMobile
          ? `${user.name} ${user.surname} - ${
              user.isOnline
                ? "aktywny"
                : `${timeAgoFromTimestamp(user.lastOnline * 1000)}`
            }`
          : null
      }
    >
      <Space
        wrap={false}
        align="center"
        className="chat__user"
        style={{
          width: "100%",
        }}
      >
        <Avatar
          size={true ? 26 : 40}
          src={user.img || null}
          className={classnames({
            "user-app__avatar": true,
            user__avatar: true,
            "user__avatar--online": user.isOnline,
            "user__avatar--idle": user.isIdle,
          })}
          style={{
            fontSize: 14,
            boxSizing: "content-box",
            color: "#fefefe",
            backgroundColor: stringToColour(user.email),
          }}
        >
          {makeAvatarName(user.delated, user.name, user.surname)}
        </Avatar>
        <div>
          <p className="chat__name">
            {user.name} {user.surname}
          </p>
          <p className="chat__status">
            <small>
              {user.isOnline
                ? "aktywny"
                : ` ${user.isIdle ? "bezczynny, " : ""}${timeAgoFromTimestamp(
                    user.lastOnline * 1000
                  )}`}
            </small>
          </p>
        </div>
      </Space>
    </Tooltip>
  );
};

export default ChatUser;
