import React, { PropsWithChildren } from "react";
import { Space, Table, Tooltip } from "antd";
import { UserOutlined } from "@ant-design/icons";

import Text from "antd/es/typography/Text";
import moment from "moment";
import { formatMoney, formatSeconds } from "../../../utils/common";
import { Disposable } from "../../../types/ticket-group";
import { UserFilledIcon } from "../../Shared/UserFillled";
import { UserOutlinedIcon } from "../../Shared/UserOutlined";
import RemoveRecord from "../../Shared/RemoveRecord";
import Can from "../../Shared/Can";
import { UserIcon } from "../../Shared/UserIcon";

const SingleMonth = (props: PropsWithChildren<any>): JSX.Element => {
  const { data, isRequest, totals, month, summary, handleRemove } = props;

  const canAdminView = Can({
    type: "admin_view",
    children: <></>,
  });

  const showCost = data.length
    ? data[0].ticket?.group?.disposable !== Disposable.Niedotyczy
    : false;
  const columns = [
    {
      title: "Użytkownik",
      className: "single-month__column--users",
      dataIndex: "user",
      key: "user",
      render: (user: any, worktime: any) => {
        const { typeAccount, name, surname, id } = user;
        return (
          <Space direction="vertical">
            <Space direction="horizontal">
              <UserIcon leaders={worktime.ticket?.group?.leaders} user={user} />
              <Space direction="horizontal">
                <span>
                  {name} {surname}
                </span>
              </Space>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Czas rozpoczęcia",
      dataIndex: "dateStart",
      key: "dateStart",
      render: (dateStart: number) => (
        <Text>{moment(dateStart * 1000).format("DD.MM.Y HH:mm")}</Text>
      ),
    },
    {
      title: "Czas zakończenia",
      dataIndex: "dateEnd",
      key: "dateEnd",
      render: (dateEnd: number) => (
        <Text>{moment(dateEnd * 1000).format("DD.MM.Y HH:mm")}</Text>
      ),
    },
    {
      title: "Typ wątku",
      dataIndex: "ticketType",
      key: "ticketType",
      render: (ticketType: any) => {
        // eslint-disable-next-line react/destructuring-assignment
        return <Text>{ticketType?.name || "Brak"}</Text>;
      },
    },
    {
      title: "Godz/Min/Sek",

      dataIndex: "seconds",
      key: "seconds",
      render: (seconds: string) => {
        return (
          <Tooltip title={formatSeconds(parseInt(seconds, 10), true)}>
            <Text>{formatSeconds(parseInt(seconds, 10))}</Text>
          </Tooltip>
        );
      },
    },
    {
      hidden: !showCost,
      title: "Koszt",
      className: "single-month__column--cost",
      dataIndex: "cost",
      key: "cost",
      render: (cost: number) => {
        return <Text>{formatMoney(cost)}</Text>;
      },
    },
    {
      hidden: !showCost,
      title: "Stawka godz.",
      className: "single-month__column--hour-cost",
      dataIndex: "costHour",
      key: "costHour",
      render: (costHour: number) => {
        return <Text>{formatMoney(costHour)}</Text>;
      },
    },
    {
      title: "Komentarz",
      dataIndex: "comment",
      key: "comment",
      render: (comment: number) => <Text>{comment}</Text>,
    },
    {
      hidden: !canAdminView,
      title: "Akcje",
      dataIndex: "id",
      key: "action",
      render: (id: number, worktime: any) => (
        <RemoveRecord
          title="Czy napewno chcesz usunąć czas pracy?"
          onConfirm={(callbackHidePopover: () => void) => {
            handleRemove(id);
            callbackHidePopover();
          }}
          okText="Usuń"
          cancelText="Anuluj"
          tooltipText="Usuń"
        />
      ),
    },
  ];
  return (
    <>
      <Table
        title={() => (
          <div className="single-month__title">
            {moment(month, "MM-YYYY").format("MMMM YYYY")}
          </div>
        )}
        className="single-month"
        style={{ width: "100%" }}
        scroll={{ x: true }}
        locale={{ emptyText: "Brak danych" }}
        size="small"
        dataSource={data}
        columns={columns.filter((item) => !item.hidden)}
        pagination={false}
        loading={isRequest}
        summary={() => (
          <>
            <Table.Summary.Row className="single-month__summary--month">
              <Table.Summary.Cell index={0} />
              <Table.Summary.Cell index={1} />
              <Table.Summary.Cell index={2}>
                <Text strong>Podsumowanie</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <Text strong style={{ textTransform: "capitalize" }}>
                  {moment(month, "MM-YYYY").format("MMMM YYYY")}
                </Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4}>
                <Tooltip title={formatSeconds(summary.seconds, true)}>
                  <Text strong>{formatSeconds(summary.seconds)}</Text>
                </Tooltip>
              </Table.Summary.Cell>
              {showCost && (
                <Table.Summary.Cell index={5}>
                  <Text strong>{formatMoney(summary.cost)}</Text>
                </Table.Summary.Cell>
              )}
              <Table.Summary.Cell index={6} />
              <Table.Summary.Cell index={7} />
            </Table.Summary.Row>
            {totals && (
              <>
                <Table.Summary.Row className="single-month__summary--total">
                  <Table.Summary.Cell index={0} />
                  <Table.Summary.Cell index={1} />
                  <Table.Summary.Cell index={2}>
                    <Text strong>Suma</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} />
                  <Table.Summary.Cell index={4}>
                    <Tooltip title={formatSeconds(totals.total.seconds, true)}>
                      <Text strong>{formatSeconds(totals.total.seconds)}</Text>
                    </Tooltip>
                  </Table.Summary.Cell>
                  {showCost && (
                    <Table.Summary.Cell index={5}>
                      <Text strong>{formatMoney(totals.total.cost)}</Text>
                    </Table.Summary.Cell>
                  )}
                  <Table.Summary.Cell index={6} />
                  <Table.Summary.Cell index={7} />
                </Table.Summary.Row>
                {Object.values(totals?.users).map((userObj: any) => (
                  <Table.Summary.Row className="single-month__summary--day">
                    <Table.Summary.Cell index={0} />
                    <Table.Summary.Cell index={1} />
                    <Table.Summary.Cell index={2} />
                    <Table.Summary.Cell index={3}>
                      <Text strong style={{ textTransform: "capitalize" }}>
                        {userObj.user.name} {userObj.user.surname}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      <Tooltip title={formatSeconds(userObj.seconds, true)}>
                        <Text strong>{formatSeconds(userObj.seconds)}</Text>
                      </Tooltip>
                    </Table.Summary.Cell>
                    {showCost && (
                      <Table.Summary.Cell index={5}>
                        <Text strong>{formatMoney(userObj.cost)}</Text>
                      </Table.Summary.Cell>
                    )}
                    <Table.Summary.Cell index={6} />
                    <Table.Summary.Cell index={7} />
                  </Table.Summary.Row>
                ))}
              </>
            )}
          </>
        )}
        rowKey="id"
      />
    </>
  );
};

export default SingleMonth;
