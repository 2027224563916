import { Button, Empty, Progress, Space, Table, Tooltip } from "antd";
import React, { PropsWithChildren } from "react";
import { DeleteOutlined, WarningOutlined } from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import { formatBytes } from "../../../utils/common";
import FileImage from "../FileImage";

const UploadAttachmentsList = (
  props: PropsWithChildren<any>
): JSX.Element | null => {
  const { attachments, handleRemove, displayHeader } = props;

  const columns = [
    {
      title: "Nazwa",
      dataIndex: "fileOriginalName",
      width: "45%",
      render: (name: string, col: any) => (
        <Space>
          <FileImage type={col.fileMimeType} />
          <Text>{name}</Text>
        </Space>
      ),
    },
    {
      title: "Rozmiar",
      dataIndex: "fileSize",
      render: (size: number) => formatBytes(size, 2),
    },
    {
      dataIndex: "status",
      width: 50,
      render: (status: string, file: any) => {
        if (status === "uploading") {
          return (
            <Progress
              percent={file.progress}
              style={{ width: "100%" }}
              status={file.progress === 100 ? "success" : "active"}
              showInfo={false}
            />
          );
        }
        if (status === "error") {
          return (
            <Tooltip title="Wystąpił problem w trakcie przesyłania załącznika">
              <Button
                icon={<WarningOutlined style={{ color: "red" }} />}
                type="ghost"
                disabled
              />
            </Tooltip>
          );
        }

        return (
          <Space>
            <Tooltip title="Usuń załącznik">
              <Button
                icon={<DeleteOutlined />}
                onClick={() => handleRemove(file)}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  if (!attachments.length) return null;
  return (
    <Table
      showHeader={attachments.length > 0 && displayHeader}
      columns={columns}
      dataSource={attachments}
      size="small"
      pagination={false}
      scroll={{ x: 300, scrollToFirstRowOnChange: true }}
      locale={{
        emptyText: (
          <Empty
            description="Brak załączników"
            style={{ overflow: "hidden" }}
          />
        ),
      }}
    />
  );
};

export default UploadAttachmentsList;
