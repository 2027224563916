import { Button, Card, Col, Empty, Row, Skeleton, Space, Tooltip } from "antd";
import { EditOutlined, LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import HTMLReactParser from "html-react-parser";
import React, { useState } from "react";
import { AxiosError } from "axios";
import Text from "antd/es/typography/Text";
import NoteForm from "../Ticket/QuickForms/NoteForm";
import Can from "../../Shared/Can";

type PropsType = {
  group: any;
  note: string | null;
  isLoading: boolean;
  onSubmit: (note: string, override: boolean) => Promise<any>;
};

const Notes = (props: PropsType) => {
  const { note, isLoading, onSubmit, group } = props;

  const [isAutoSaving, setAutoSaving] = useState(false);
  const [visible, setVisible] = React.useState(false);

  const EditNotes = Can({
    type: "ticket_group_edit_notes",
    entity: group,
    children: (
      <Can type="ticket_group_edit_notes" entity={group}>
        <Tooltip title="Edytuj notatkę">
          <Button icon={<EditOutlined />} onClick={() => setVisible(true)} />
        </Tooltip>
      </Can>
    ),
  });

  const handleCancel = (
    value: string,
    onComplete: () => void,
    onError: (err: AxiosError) => void
  ) => {
    onSubmit(value, true)
      .then(() => {
        setVisible(false);
        onComplete();
      })
      .catch((err: AxiosError) => {
        onError(err);
      });
  };

  const handleAutoSave = (
    value: string,
    onComplete: () => void,
    onError: (err: AxiosError) => void
  ) => {
    setAutoSaving(true);
    onSubmit(value, false)
      .then(() => {
        onComplete();
      })
      .catch((err: AxiosError) => {
        onError(err);
      })
      .finally(() => {
        setAutoSaving(false);
      });
  };

  return (
    <Card
      className="notes"
      bordered={false}
      title={
        <Space>
          <Text>Notatki</Text>
          {isAutoSaving && (
            <>
              <LoadingOutlined className="autosave--icon" spin />
              <small className="autosave--text">Autozapis</small>
            </>
          )}
        </Space>
      }
      extra={EditNotes}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            {visible ? (
              <NoteForm
                note={note}
                handleAutoSave={(
                  val: string,
                  onComplete: () => void,
                  onError: () => void
                ) => handleAutoSave(val, onComplete, onError)}
                handleSubmit={(
                  val: string,
                  onComplete: () => void,
                  onError: () => void
                ) => handleCancel(val, onComplete, onError)}
              />
            ) : null}

            {!visible && !note && (
              <Col
                onClick={() => (EditNotes ? setVisible(true) : null)}
                style={{ cursor: EditNotes ? "pointer" : "default" }}
              >
                <Empty description="Brak notatek" />
              </Col>
            )}
            {!visible && note && HTMLReactParser(note || "")}
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default Notes;
