import { Dispatch } from "redux";
import { AxiosError } from "axios";
import WorktimeService from "../../services/worktime-service";

import {
  ADD_WORKTIME,
  ADD_WORKTIME_FAILED,
  ADD_WORKTIME_SUCCESS,
  END_WORKTIME,
  END_WORKTIME_FAILED,
  END_WORKTIME_SUCCESS,
  FETCH_TICKET_GROUP_WORKTIMES,
  FETCH_TICKET_GROUP_WORKTIMES_FAILED,
  FETCH_TICKET_GROUP_WORKTIMES_SUCCESS,
  FETCH_TICKET_GROUP_WORKTIMES_SUMMARY,
  FETCH_TICKET_GROUP_WORKTIMES_SUMMARY_FAILED,
  FETCH_TICKET_GROUP_WORKTIMES_SUMMARY_SUCCESS,
  FETCH_TICKET_WORKTIMES,
  FETCH_TICKET_WORKTIMES_FAILED,
  FETCH_TICKET_WORKTIMES_SHORT,
  FETCH_TICKET_WORKTIMES_SHORT_FAILED,
  FETCH_TICKET_WORKTIMES_SHORT_SUCCESS,
  FETCH_TICKET_WORKTIMES_SUCCESS,
  FETCH_USER_ACTIVE_WORKTIME,
  FETCH_USER_ACTIVE_WORKTIME_FAILED,
  FETCH_USER_ACTIVE_WORKTIME_SUCCESS,
  FETCH_USER_LAST_WORKTIME,
  FETCH_USER_LAST_WORKTIME_FAILED,
  FETCH_USER_LAST_WORKTIME_SUCCESS,
  FETCH_USERS_WORKTIMES,
  FETCH_USERS_WORKTIMES_FAILED,
  FETCH_USERS_WORKTIMES_SUCCESS,
  SET_CURRENT_ENTITY_FORBIDDEN,
  START_WORKTIME,
  START_WORKTIME_FAILED,
  START_WORKTIME_SUCCESS,
} from "../action-types";

export const getTicketWorktimesShort = (id: string | number) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_TICKET_WORKTIMES_SHORT,
    payload: true,
  });
  return WorktimeService.getTicketWorktimesShort(id).then(
    (response) => {
      dispatch({
        type: FETCH_TICKET_WORKTIMES_SHORT_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_TICKET_WORKTIMES_SHORT_FAILED,
      });
      return Promise.reject(error);
    }
  );
};

export const getTicketWorktimes = (id: string | number) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_TICKET_WORKTIMES,
    payload: true,
  });
  return WorktimeService.getTicketWorktimes(id).then(
    (response) => {
      dispatch({
        type: FETCH_TICKET_WORKTIMES_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error: AxiosError) => {
      dispatch({
        type: FETCH_TICKET_WORKTIMES_FAILED,
      });

      if (error.response?.status === 403) {
        dispatch({
          type: SET_CURRENT_ENTITY_FORBIDDEN,
          payload: true,
        });
      }
      return Promise.reject(error);
    }
  );
};

export const getTicketGroupWorktimes = (
  id: string | number,
  begin: number,
  end: number
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_TICKET_GROUP_WORKTIMES,
    payload: true,
  });
  return WorktimeService.getTicketGroupWorktimes(id, begin, end).then(
    (response) => {
      dispatch({
        type: FETCH_TICKET_GROUP_WORKTIMES_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error: AxiosError) => {
      dispatch({
        type: FETCH_TICKET_GROUP_WORKTIMES_FAILED,
      });

      if (error.response?.status === 403) {
        dispatch({
          type: SET_CURRENT_ENTITY_FORBIDDEN,
          payload: true,
        });
      }
      return Promise.reject(error);
    }
  );
};

export const getTicketGroupWorktimesSummary = (
  begin: number,
  end: number,
  group?: number[]
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_TICKET_GROUP_WORKTIMES_SUMMARY,
    payload: true,
  });
  return WorktimeService.getTicketGroupWorktimesSummary(begin, end, group).then(
    (response) => {
      dispatch({
        type: FETCH_TICKET_GROUP_WORKTIMES_SUMMARY_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_TICKET_GROUP_WORKTIMES_SUMMARY_FAILED,
      });
      return Promise.reject(error);
    }
  );
};

export const getUsersWorktime = (
  begin: number,
  end: number,
  status: string,
  group?: number[],
  users?: number[]
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_USERS_WORKTIMES,
    payload: true,
  });
  return WorktimeService.getUsersWorktimes(
    begin,
    end,
    status,
    group,
    users
  ).then(
    (response) => {
      dispatch({
        type: FETCH_USERS_WORKTIMES_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_USERS_WORKTIMES_FAILED,
      });
      return Promise.reject(error);
    }
  );
};

export const getUserActiveWorktime = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_USER_ACTIVE_WORKTIME,
    payload: true,
  });
  return WorktimeService.getUserActiveWorktime().then(
    (response) => {
      dispatch({
        type: FETCH_USER_ACTIVE_WORKTIME_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error: AxiosError) => {
      dispatch({
        type: FETCH_USER_ACTIVE_WORKTIME_FAILED,
      });

      return Promise.reject(error);
    }
  );
};

export const getUserLastWorktimes = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_USER_LAST_WORKTIME,
    payload: true,
  });
  return WorktimeService.getUserLastWorktime().then(
    (response) => {
      dispatch({
        type: FETCH_USER_LAST_WORKTIME_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error: AxiosError) => {
      dispatch({
        type: FETCH_USER_LAST_WORKTIME_FAILED,
      });

      return Promise.reject(error);
    }
  );
};

export const startWorktime = (id: string | number) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: START_WORKTIME,
    payload: true,
  });
  return WorktimeService.startWorktime(id).then(
    (response) => {
      dispatch({
        type: START_WORKTIME_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error: AxiosError) => {
      dispatch({
        type: START_WORKTIME_FAILED,
      });

      return Promise.reject(error);
    }
  );
};

export const endWorktime = (id: string | number, comment?: string) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: END_WORKTIME,
    payload: true,
  });
  return WorktimeService.endWorktime(id, comment).then(
    (response) => {
      dispatch({
        type: END_WORKTIME_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error: AxiosError) => {
      dispatch({
        type: END_WORKTIME_FAILED,
      });

      return Promise.reject(error);
    }
  );
};

export const addWorktime = (
  id: string | number,
  begin: number,
  seconds: number,
  comment?: string
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: ADD_WORKTIME,
    payload: true,
  });
  return WorktimeService.addWorktime(id, begin, seconds, comment).then(
    (response) => {
      dispatch({
        type: ADD_WORKTIME_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error: AxiosError) => {
      dispatch({
        type: ADD_WORKTIME_FAILED,
      });

      return Promise.reject(error);
    }
  );
};
