import React, {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { Checkbox, Form, Input, SubmitButton } from "formik-antd";
import { Button, Row } from "antd";
import { Formik, FormikProps, FormikValues } from "formik";
import * as Yup from "yup";

import { log } from "util";
import { TypeOf } from "yup";
import { IQuickAssignUserFormValues } from "../../../../types/quick-forms";
import "moment/locale/pl";
import TicketAddAssignedForm, {
  IFormValues,
} from "../NewTicketForm/AddAssignedForm";
import TicketAssignedForm from "../NewTicketForm/AssignedForm";
import Can from "../../../Shared/Can";
import { AccountType } from "../../../../types/user";
import {
  mergeByProperty,
  openNotificationWithIcon,
} from "../../../../utils/common";

const AssignUsers: React.FC<any> = (
  props: PropsWithChildren<any>
): JSX.Element => {
  const {
    assignedUsers,
    users,
    isUsersLoading,
    ticket,
    onSubmit,
    setPopoverVisible,
    loggedUser,
    canTicketLeave,
    leaders,
    members,
  } = props;

  const canAssignUsers = Can({
    entity: ticket,
    type: "ticket_can_assign_users",
    children: <></>,
  });

  const canAssignLeaders = !!Can({
    entity: ticket?.group,
    type: "ticket_can_assign_leaders",
    children: <></>,
  });

  const formikRef = useRef<FormikValues>();
  const assignedFormRef = useRef<FormikProps<IFormValues>>(null);
  const [availableUsers, setAvailableUsers] = React.useState<any[]>([]);
  const [touched, setTouched] = React.useState<boolean>(false);

  const initialFormValues: IQuickAssignUserFormValues = {
    users: [],
    comment: "",
    hiddenComment: "",
    showComment: true,
    sendSMS: false,
  };

  const [formData, setFormData] = useState<IQuickAssignUserFormValues>(
    initialFormValues
  );

  useEffect(() => {
    setFormData((prev) => {
      return {
        comment: "",
        hiddenComment: "",
        showComment: true,
        sendSMS: false,
        users: assignedUsers,
      };
    });
  }, [assignedUsers]);

  useEffect(() => {
    setTouched(
      assignedFormRef?.current?.getFieldMeta("assignedUsers").touched || false
    );
  }, [assignedFormRef]);

  const Schema = Yup.object().shape({
    users: Yup.array().nullable(),
  });

  useEffect(() => {
    let availableUsersInit = [];
    if (canAssignLeaders) {
      availableUsersInit =
        leaders?.map((leader: any) => {
          return leader.user;
        }) || [];
    }
    if (
      !availableUsersInit.find(
        (available: any) => available.id === loggedUser.id
      )
    ) {
      availableUsersInit.unshift(loggedUser);
    }

    mergeByProperty(availableUsersInit, users, "id");

    setAvailableUsers(availableUsersInit);
  }, [canAssignLeaders, leaders, loggedUser, users]);

  const handleSubmit = () => {
    assignedFormRef.current?.submitForm().then(() => {
      assignedFormRef.current?.resetForm();
      formikRef.current?.submitForm();
    });
  };
  return (
    <Formik
      innerRef={formikRef as MutableRefObject<any>}
      initialValues={formData}
      enableReinitialize
      validationSchema={Schema}
      onSubmit={(
        values: IQuickAssignUserFormValues,
        { resetForm, setErrors, setSubmitting }
      ) => {
        onSubmit(
          {
            assignedUsers: values.users.map((user) => user.key),
            sendSms: values.sendSMS,
            comment: values.showComment ? values.comment : null,
            hiddenComment: values.hiddenComment,
          },
          () => {
            setPopoverVisible(false);
            setSubmitting(false);
            setTouched(false);
            openNotificationWithIcon("success", "Przydzielenie zaktualizowane");
          },
          () => setSubmitting(false)
        );
      }}
      validateOnChange
      render={(formProps: FormikProps<IQuickAssignUserFormValues>) => {
        let lastDisplayedType: any = null;

        return (
          <Form>
            <Form.Item name="users">
              {formProps.values.users.length > 0 && <p>Przydzieleni</p>}
              {formProps.values.users.map((user: any) => {
                let DisplayHeader = (): any => null;

                if (lastDisplayedType !== user.typeAccount) {
                  lastDisplayedType = user.typeAccount;
                  DisplayHeader = () => <p>{AccountType[user.typeAccount]}</p>;
                }

                const isLeader = leaders.some(
                  (leader: any) => leader.user.id === user.key
                );

                const allowedTagAssing = user.tag.some((tag: any) =>
                  loggedUser.allowedAssignionTag.find(
                    (allowed: any) => allowed.id === tag.id
                  )
                );
                const canEdit =
                  isLeader ||
                  allowedTagAssing ||
                  loggedUser.typeAccount === AccountType.Administrator;

                return (
                  <>
                    {/* <DisplayHeader /> */}
                    <TicketAssignedForm
                      userId={user.key}
                      title={user.label}
                      canEdit={
                        user.key === loggedUser.id
                          ? canTicketLeave && canEdit
                          : canEdit
                      }
                      canEditLeaders={!!canAssignLeaders}
                      isLeader={isLeader}
                      tags={user.tag}
                      onRemove={(userId) => {
                        const removedAssigned = Array.from(
                          formProps.values.users
                        );

                        removedAssigned.splice(
                          removedAssigned.findIndex(
                            (findUser: any) => findUser.key === userId
                          ),
                          1
                        );

                        formProps.setFieldValue("users", removedAssigned);
                        formProps.validateField("users");
                        formProps.setFieldTouched("users");
                      }}
                    />
                  </>
                );
              })}

              <TicketAddAssignedForm
                formikRef={assignedFormRef}
                showSubmitButton={false}
                isLoading={isUsersLoading}
                assignedUsers={formProps.values.users}
                leaders={leaders}
                members={members}
                users={availableUsers}
                onTouched={setTouched}
                onSave={(values, onRequestComplete, setError) => {
                  formProps.setFieldTouched("users");
                  formProps.setFieldValue("users", [
                    ...formProps.values.users,
                    ...values.assignedUsers.map((assinged) =>
                      JSON.parse(assinged)
                    ),
                  ]);
                }}
              />
            </Form.Item>

            <Form.Item label="Komentarz" name="comment" labelCol={{ span: 24 }}>
              <Input.TextArea name="comment" style={{ width: "100%" }} />
            </Form.Item>

            <Can type="ticket_hidden_comments" entity={ticket}>
              <Form.Item
                label="Ukryty komentarz"
                name="hiddenComment"
                labelCol={{ span: 24 }}
                style={{ marginBottom: "4px", marginTop: "4px" }}
              >
                <Input.TextArea
                  name="hiddenComment"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Can>

            {/* <Form.Item */}
            {/*  name="showComment" */}
            {/*  style={{ marginBottom: "4px" }} */}
            {/*  labelCol={{ span: 24 }} */}
            {/* > */}
            {/*  <Checkbox value={1} name="showComment"> */}
            {/*    Pokaż komentarz */}
            {/*  </Checkbox> */}
            {/* </Form.Item> */}

            {/* <Can type="ticket_send_sms" entity={ticket}> */}
            {/*  <Form.Item name="sendSMS" style={{ width: "250px" }}> */}
            {/*    <Checkbox value={1} name="sendSMS"> */}
            {/*      Powiadom SMS */}
            {/*    </Checkbox> */}
            {/*  </Form.Item> */}
            {/* </Can> */}

            <Row justify="center">
              <Button
                loading={formikRef?.current?.isSubmitting}
                onClick={handleSubmit}
                type="primary"
                disabled={!formProps.getFieldMeta("users").touched && !touched}
                style={{ marginTop: "8px" }}
              >
                Zapisz
              </Button>
            </Row>
          </Form>
        );
      }}
    />
  );
};

export default AssignUsers;
