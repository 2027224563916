import { Popover } from "antd";
import React, { useState } from "react";
import Can from "../../../Shared/Can";
import ProgressForm from "./ProgressForm";

const ProgressPopover = (props: any): JSX.Element => {
  const { ticket, onSubmit, children, progress } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const editable = Can({
    entity: ticket,
    type: "ticket_edit_progress",
    children: <></>,
  });

  return (
    <Popover
      visible={visible}
      onVisibleChange={(change) => setVisible(change)}
      style={{ width: "400px" }}
      placement="top"
      content={
        <ProgressForm
          progress={progress}
          onSubmit={onSubmit}
          setPopoverVisible={setVisible}
        />
      }
      title="Ustaw postęp"
      trigger={editable ? "click" : "disabled"}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
    >
      {children}
    </Popover>
  );
};

export default ProgressPopover;
