import React from "react";
import { Skeleton, Tooltip } from "antd";
import { TrophyOutlined, TrophyTwoTone } from "@ant-design/icons";

interface IStatisticBoxProps {
  title: string;
  value: string;
  isLoading: boolean;
  valueStyle?: any;
  iconStyle?: any;
  icon: JSX.Element;
  suffix?: string;
  prefix?: string;
  tooltip?: string;
  easterEgg?: { color: string; count: number; url: string } | null;
}

const StatisticsBox = (props: IStatisticBoxProps): JSX.Element => {
  const {
    title,
    value,
    valueStyle,
    icon,
    suffix,
    tooltip,
    easterEgg,
    prefix,
    iconStyle,
    isLoading,
  } = props;
  return (
    <div className="statistics-box">
      {!isLoading ? (
        <>
          <div className="statistics-box__icon" style={iconStyle}>
            {icon}
          </div>
          <div className="statistics-box__content">
            <p className="statistics-box__value" style={valueStyle}>
              {easterEgg ? (
                <TrophyTwoTone
                  twoToneColor={easterEgg.color}
                  style={{ marginRight: 4 }}
                  onClick={() => window.open(easterEgg.url, "_blank")}
                />
              ) : null}
              {tooltip ? (
                <Tooltip title={tooltip}>
                  {prefix}
                  {value}
                  {suffix}
                </Tooltip>
              ) : (
                <>
                  {prefix}
                  {value}
                  {suffix}
                </>
              )}
            </p>
            <p className="statistics-box__title">{title}</p>
          </div>
        </>
      ) : (
        <Skeleton active paragraph={{ rows: 2 }} />
      )}
    </div>
  );
};

StatisticsBox.defaultProps = {
  valueStyle: {},
  iconStyle: {},
  suffix: "",
  prefix: "",
  tooltip: null,
  easterEgg: null,
};

export default StatisticsBox;
