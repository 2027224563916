import { AxiosResponse } from "axios";
import api from "../axiosInstance";

class SearchService {
  getLastVisited = async (): Promise<AxiosResponse> => {
    const response = await api.get("search/last-visited");
    return response;
  };

  search = async (
    query: string,
    abortController: AbortController
  ): Promise<AxiosResponse<{ query: string }>> => {
    const response = await api.post(
      "search",
      { query },
      { signal: abortController.signal }
    );
    return response;
  };
}
export default new SearchService();
