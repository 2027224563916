import React from "react";
import Icon from "@ant-design/icons";

const UserFilled = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="18"
    viewBox="0 0 20 28"
  >
    <title>user</title>
    <path d="M20 21.859c0 2.281-1.5 4.141-3.328 4.141h-13.344c-1.828 0-3.328-1.859-3.328-4.141 0-4.109 1.016-8.859 5.109-8.859 1.266 1.234 2.984 2 4.891 2s3.625-0.766 4.891-2c4.094 0 5.109 4.75 5.109 8.859zM16 8c0 3.313-2.688 6-6 6s-6-2.688-6-6 2.688-6 6-6 6 2.688 6 6z" />
  </svg>
);
export const UserFilledIcon = (iconProps: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Icon component={UserFilled} {...iconProps} />
);

export default UserFilled;
