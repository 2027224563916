import React from "react";
import { SketchPicker } from "react-color";
import { Button, Popover } from "antd";

interface Props {
  color: string;
  onChange: (change: string) => void;
}
const ColorPicker = (props: Props) => {
  const { color, onChange } = props;
  return (
    <Popover
      placement="bottom"
      title="Title"
      trigger="click"
      content={
        <SketchPicker
          color={color}
          onChange={(change) => onChange(change.hex)}
          onChangeComplete={(change) => onChange(change.hex)}
        />
      }
    >
      <Button
        style={{
          backgroundColor: color,
          minHeight: 25,
          minWidth: 25,
          color: "transparent",
        }}
      >
        t
      </Button>
    </Popover>
  );
};

export default ColorPicker;
