import React, { MutableRefObject, useCallback, useRef, useState } from "react";
import { Form, Select, SubmitButton } from "formik-antd";
import { Spin } from "antd";
import { Formik, FormikErrors, FormikValues } from "formik";
import * as Yup from "yup";
import { AccountType } from "../../../../../types/user";

const { Option, OptGroup } = Select;
export interface IUserAddMembershipFormProps {
  isLoading: boolean;
  groupsMembership: any[];
  groups: any[];
  onSave: (
    values: IUserAddMembershipFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<IUserAddMembershipFormValues>) => void
  ) => void;
}

export interface IUserAddMembership {
  label: string;
  value: number;
  key: number;
}
export interface IUserAddMembershipFormValues {
  memberships: IUserAddMembership[];
}

const UserAddMembershipForm = (props: IUserAddMembershipFormProps) => {
  const { groups, groupsMembership, isLoading, onSave } = props;

  const initialFormValues: IUserAddMembershipFormValues = {
    memberships: [],
  };
  const [formData, setFormData] = useState<IUserAddMembershipFormValues>(
    initialFormValues
  );

  const [editRequest, setEditRequest] = useState<boolean>(false);

  const formikRef = useRef<FormikValues>();

  const FormSchema = Yup.object().shape({
    memberships: Yup.array()
      .min(1, "Wybierz przynajmniej jeden dział.")
      .required("Pole wymagane"),
  });
  const filteredGroups = groups.filter(
    (group) =>
      !groupsMembership.some((membership) => membership.id === group.id)
  );
  const filterOption = useCallback((search, optionOrGroup) => {
    const isGroup = Array.isArray(optionOrGroup.options);
    if (isGroup) {
      return false;
    }
    return optionOrGroup.children.toLowerCase().includes(search.toLowerCase());
  }, []);

  return (
    <Spin spinning={isLoading}>
      <Formik
        innerRef={formikRef as MutableRefObject<any>}
        initialValues={formData}
        enableReinitialize
        validationSchema={FormSchema}
        onSubmit={(
          values: IUserAddMembershipFormValues,
          { resetForm, setErrors }
        ) => {
          setEditRequest(true);
          onSave(
            values,
            () => {
              setEditRequest(false);
              resetForm();
            },
            setErrors
          );
        }}
        validateOnChange
        render={() => (
          <Form style={{ marginTop: 16 }}>
            <Form.Item
              name="memberships"
              className="show-label"
              label="Wybierz działy do przydzielenia"
              labelCol={{ span: 24 }}
            >
              <Select
                name="memberships"
                labelInValue
                showSearch
                placeholder="Wybierz lub wyszukaj"
                optionFilterProp="children"
                mode="multiple"
                filterOption={filterOption}
              >
                {filteredGroups.map((group: any) => {
                  const optionLabel = `${group.name}`;
                  return <Option value={group.id}>{optionLabel}</Option>;
                })}
              </Select>
            </Form.Item>
            <SubmitButton loading={editRequest}>Dodaj</SubmitButton>
          </Form>
        )}
      />
    </Spin>
  );
};

export default UserAddMembershipForm;
