import React, { useState } from "react";
import { Button, Popconfirm, Tooltip } from "antd";
import { DeleteOutlined, StopOutlined } from "@ant-design/icons";

interface IRemoveMessagesButton {
  title: string;
  onConfirm: (callback: () => void) => void;
  okText: string;
  cancelText: string;
}

const RemoveMessagesButton = (props: IRemoveMessagesButton): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { title, onConfirm, okText, cancelText } = props;

  const handleConfirm = () => {
    setLoading(true);
    onConfirm(() => {
      setVisible(false);
      setLoading(false);
    });
  };

  return (
    <Popconfirm
      visible={visible}
      placement="topLeft"
      title={title}
      style={{ maxWidth: "300px" }}
      okButtonProps={{ loading: isLoading }}
      onConfirm={handleConfirm}
      okText={okText}
      cancelText={cancelText}
      onCancel={() => setVisible(false)}
    >
      <Button danger type="primary" onClick={() => setVisible(true)}>
        Wyczyść wiadomości
      </Button>
    </Popconfirm>
  );
};

export default RemoveMessagesButton;
