import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import "moment/locale/pl";

import {
  Button,
  Card,
  Col,
  Drawer,
  Grid,
  Input,
  Row,
  Space,
  Tabs,
  Tooltip,
} from "antd";

import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { AxiosResponse } from "axios";
import moment, { Moment } from "moment";
import { FormikErrors } from "formik";
import TicketService from "../../../services/tickets-service";
import { AppState } from "../../../reducers";
import AssignedList from "./list";

import CalendarPicker, {
  CalendarFilterOptions,
} from "../../Shared/CustomFilters/CalendarPicker";
import Can from "../../Shared/Can";
import UsersService from "../../../services/users-service";
import TicketGroupUpdateFilter from "../../Shared/CustomFilters/TicketGroupUpdate";
import { MetaTitle } from "../../Shared/MetaTitle";
import { getTicketTypes, updateTicket } from "../../../actions/tickets";
import {
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";

const AssignedPage = (props: PropsWithChildren<any>): JSX.Element => {
  const [assignedTickets, setAssignedTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    getTicketTypesAction,
    updateTicketAction,
    loggedUser,
    displayNotification,
    types,
  } = props;
  const { useBreakpoint } = Grid;
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [filterVisible, setFilterVisible] = React.useState(false);
  const screens = useBreakpoint();

  const getAssignedTickets = () => {
    setIsLoading(true);
    TicketService.getUserAssignedTickets()
      .then((response: AxiosResponse) => {
        setAssignedTickets(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // eslint-disable-next-line eqeqeq

    const isMdBreakpoint = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .filter((screen) => screen[0] === "md").length;

    setIsMobile(!isMdBreakpoint);
  }, [screens, setIsMobile]);

  useEffect(() => {
    getAssignedTickets();
  }, []);

  const onMetaSave = (meta: any) => {
    return UsersService.updateMeta(meta);
  };

  const handleSubmitMiniForm = (
    values: any,
    onRequestComplete: () => void,
    setError?: (formikResponse: FormikErrors<any>) => void
  ) => {
    const { entityId, ...vals } = values;
    updateTicketAction(entityId, vals)
      .then((res: any) => {
        getAssignedTickets();
        onRequestComplete();
      })
      .catch((err: any) => {
        const formikResponse = transformToFormikError(err);
        if (err.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił błąd w trakcie wykonywania akcji"
          );
          if (setError) setError(formikResponse);
        }

        if (err.response?.status === 403) {
          openNotificationWithIcon(
            "error",
            "Nie posiadasz wystarczających uprawnień do wykonania tej akcji."
          );
          if (setError) setError(formikResponse);
        }
      });
  };

  const userMeta = loggedUser?.meta;
  const assignedPageFilters = userMeta?.assignedPageFilters || {};
  const [filter, setFilter] = React.useState<{
    begin: number;
    end: number;
    selectedType: CalendarFilterOptions;
    type: number;
    answerCount: number;
    noAnswerCount: number;
  }>({
    begin: assignedPageFilters?.begin || moment("2022-01-01").unix(),
    end: assignedPageFilters?.end || moment().endOf("month").unix(),
    selectedType:
      assignedPageFilters?.selectedType || CalendarFilterOptions.ALL,
    type: assignedPageFilters?.type || 5,
    noAnswerCount: assignedPageFilters?.noAnswerCount || 7,
    answerCount: assignedPageFilters?.answerCount || 5,
  });

  useEffect(() => {
    const meta = loggedUser?.meta;
    const assignedFilters = meta?.assignedPageFilters || {};
    setFilter({
      begin: assignedFilters?.begin || moment("2022-01-01").unix(),
      end: assignedFilters?.end || moment().endOf("month").unix(),
      selectedType: assignedFilters?.selectedType || CalendarFilterOptions.ALL,
      type: assignedFilters?.type || 5,
      noAnswerCount: assignedFilters?.noAnswerCount || 7,
      answerCount: assignedFilters?.answerCount || 5,
    });
  }, [loggedUser]);

  useEffect(() => {
    getTicketTypesAction();
  }, [getTicketTypesAction]);

  const filterRef = useRef();

  return (
    <Can renderError type="ticket_group_view">
      <div className="calendar-page">
        <MetaTitle
          title="Przydzielone do mnie"
          displayBadge={displayNotification}
        />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
            <Space
              direction="vertical"
              style={{ marginLeft: "auto", width: "100%" }}
            >
              <Card bordered={false}>
                <Row justify="space-between" gutter={[16, 16]}>
                  <Space>
                    <Input
                      prefix={<SearchOutlined />}
                      placeholder="Szukaj w przydzielonych"
                      onChange={(text) => console.log(text)}
                      // style={{ width: 200 }}
                    />
                    <CalendarPicker
                      ref={filterRef}
                      begin={filter.begin}
                      end={filter.end}
                      selectedType={filter.selectedType}
                      onChange={(
                        begin: Moment,
                        end: Moment,
                        selectedType: CalendarFilterOptions
                      ) => {
                        setFilter((prevState) => {
                          return { ...prevState, ...filter };
                        });
                        onMetaSave({
                          assignedPageFilters: {
                            begin: begin.unix(),
                            end: end.unix(),
                            selectedType,
                          },
                        }).then((res: any) => {
                          getAssignedTickets();
                        });
                      }}
                    />
                  </Space>
                  {isMobile && (
                    <Tooltip title="Filtry">
                      <Button
                        type={filter.type !== 5 ? "primary" : "default"}
                        icon={<FilterOutlined />}
                        onClick={() => setFilterVisible(true)}
                      />
                    </Tooltip>
                  )}
                </Row>
              </Card>
              <Card bordered={false} size="small">
                <AssignedList
                  tasks={assignedTickets}
                  isRequest={isLoading}
                  isMobile={isMobile}
                  types={types}
                  onMetaSave={onMetaSave}
                  userMeta={loggedUser?.meta}
                  filters={filter}
                  getAssignedTickets={getAssignedTickets}
                  handleSubmitMiniForm={handleSubmitMiniForm}
                />
              </Card>
            </Space>
          </Col>
        </Row>
        <Drawer
          title="Filtry"
          placement="right"
          closable={false}
          destroyOnClose
          width={300}
          visible={filterVisible}
          key="left"
          onClose={() => setFilterVisible(false)}
          className="filter-drawer--p20"
        >
          <TicketGroupUpdateFilter
            type="assigned"
            initFilters={filter}
            handleChange={(selected: any) => {
              const newFilter = {
                type: selected.type,
                display: selected.display,
                answerCount: selected.answerCount,
                noAnswerCount: selected.noAnswerCount,
              };
              setFilter((prevState) => {
                return { ...prevState, ...newFilter };
              });
              setFilterVisible(false);
              onMetaSave({
                assignedPageFilters: newFilter,
              }).then((res: any) => {
                getAssignedTickets();
              });
            }}
            handleClear={() => {
              setFilter((prevState) => {
                return { ...prevState, type: 5 };
              });
              onMetaSave({
                assignedPageFilters: { ...filter, type: 5 },
              }).then((res: any) => {
                getAssignedTickets();
              });
            }}
          />
        </Drawer>
      </div>
    </Can>
  );
};

const mapDispatchToProps = {
  getTicketTypesAction: getTicketTypes,
  updateTicketAction: updateTicket,
};

const mapStateToProps = (state: AppState) => {
  return {
    loggedUser: state.auth.logged,
    displayNotification: state.notifications.newNotificationIndicator,
    types: state.ticket.types,
    isRequest: state.ticket.isTicketsRequest,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignedPage);
