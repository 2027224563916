import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Form } from "formik-antd";
import { Col, Divider, Row } from "antd";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import RemoveRecord from "../../../../Shared/RemoveRecord";

export interface IUserMembershipFormProps {
  groupId: string | number;
  name: string;
  isLoading: boolean;
  onRemove: (userId: string | number, onRequestComplete: () => void) => void;
}

export interface IUserMembershipFormValues {
  groupId: string | number;
}

const UserGroupMembershipForm = (props: IUserMembershipFormProps) => {
  const { groupId, name, isLoading, onRemove } = props;

  const initialFormValues: IUserMembershipFormValues = {
    groupId: "",
  };
  const [formData, setFormData] = useState<IUserMembershipFormValues>(
    initialFormValues
  );

  const [editRequest, setEditRequest] = useState<boolean>(false);

  useEffect(() => {
    setFormData({
      groupId,
    });
  }, [groupId]);

  const formikRef = useRef<FormikValues>();

  const FormSchema = Yup.object().shape({});

  const title = `${name}`;
  return (
    <>
      <Formik
        innerRef={formikRef as MutableRefObject<any>}
        initialValues={formData}
        enableReinitialize
        validationSchema={FormSchema}
        onSubmit={(
          values: IUserMembershipFormValues,
          { resetForm, setErrors }
        ) => {
          setEditRequest(true);
        }}
        validateOnChange
        render={() => (
          <Form>
            <Row gutter={16} align="middle" justify="space-around">
              <Col xs={20} md={20} lg={22} xl={22} xxl={22}>
                <Link
                  to={`/group/${groupId}`}
                  className="memberships__name jb-link jb-link--secendary"
                >
                  {title}
                </Link>
              </Col>
              <Col xs={4} md={4} lg={2} xl={2} xxl={2}>
                <RemoveRecord
                  title="Czy napewno chcesz usunąć przydzielenie do działu?"
                  onConfirm={(callbackHidePopover: () => void) =>
                    onRemove(groupId, callbackHidePopover)
                  }
                  okText="Usuń"
                  cancelText="Anuluj"
                  tooltipText="Usuń"
                />
              </Col>
            </Row>
          </Form>
        )}
      />
      <Divider style={{ marginBottom: 8, marginTop: 8 }} />
    </>
  );
};

export default UserGroupMembershipForm;
