import React from "react";
import { Button, message, Upload } from "antd";
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { RcFile, UploadChangeParam } from "antd/lib/upload/interface";
// eslint-disable-next-line import/no-extraneous-dependencies
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";
import ImgCrop from "antd-img-crop";

interface IUploadAvatarState {
  imageUrl: string | null;
  loading: boolean;
  removeLoading: boolean;
}
interface IUploadAvatarProps {
  imageUrl: string | null;
  onUpload: (
    file: RcFile | Blob | string,
    onRequestComplete: (url?: string) => void
  ) => void;
  onRemove: (callback: () => void) => void;
}

function beforeUpload(file: RcFile) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Obrazek musi być mniejszy niż 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

class UploadAvatar extends React.Component<
  IUploadAvatarProps,
  IUploadAvatarState
> {
  constructor(props: IUploadAvatarProps) {
    super(props);
    this.state = {
      imageUrl: props.imageUrl,
      loading: false,
      removeLoading: false,
    };
  }

  static getDerivedStateFromProps(
    props: IUploadAvatarProps,
    state: IUploadAvatarState
  ) {
    if (props.imageUrl !== state.imageUrl) {
      return {
        imageUrl: props.imageUrl,
      };
    }
    return null;
  }

  handleChange = (info: UploadChangeParam) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
    }
  };

  onRequestComplete = (url?: string) => {
    const { imageUrl } = this.state;
    this.setState({
      loading: false,
      imageUrl: url || imageUrl,
    });
  };

  onRemoveRequestComplete = () => {
    this.setState({
      removeLoading: false,
    });
  };

  handleUpload = (options: RcCustomRequestOptions) => {
    const { onUpload } = this.props;
    onUpload(options.file, this.onRequestComplete);
  };

  handleRemove = () => {
    const { onRemove } = this.props;
    this.setState({
      removeLoading: true,
    });
    onRemove(this.onRemoveRequestComplete);
  };

  render() {
    const { loading, imageUrl, removeLoading } = this.state;
    const { onRemove } = this.props;
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        {!loading ? <div style={{ marginTop: 8 }}>Dodaj zdjęcie</div> : null}
      </div>
    );
    return (
      <>
        <ImgCrop rotate modalTitle="Edytuj avatar">
          <Upload
            name="avatar"
            listType="picture-card"
            className="upload-avatar"
            customRequest={this.handleUpload}
            showUploadList={false}
            onRemove={() => {}}
            beforeUpload={beforeUpload}
            onChange={this.handleChange}
          >
            {imageUrl && !loading ? (
              <div className="upload-avatar">
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              </div>
            ) : (
              uploadButton
            )}
          </Upload>
        </ImgCrop>
        {imageUrl ? (
          <Button
            icon={<DeleteOutlined />}
            onClick={this.handleRemove}
            loading={removeLoading}
          >
            Usuń avatar
          </Button>
        ) : null}
      </>
    );
  }
}

export default UploadAvatar;
