import React, { useEffect, useState } from "react";
import { Card, Col, Grid, Row } from "antd";
import {
  FieldTimeOutlined,
  HddOutlined,
  HourglassOutlined,
  IssuesCloseOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { connect } from "react-redux";
import StatisticsBox from "../../Shared/StatisticBox";
import ProjectsWorkTime from "../../Shared/ProjectsWorkTime";
import UsersOnline from "../../Shared/UsersOnline";
import ActiveTasks from "../../Shared/ActiveTasks";

import DashboardService from "../../../services/dashboard-service";
import {
  formatSeconds,
  formatSecondsToFootballMatch,
} from "../../../utils/common";
import TicketGroupsWorktimesPage from "../TicketGroupsWorktimes";
import { MetaTitle } from "../../Shared/MetaTitle";
import { createTicket } from "../../../actions/tickets";
import { AppState } from "../../../reducers";
import UsersWorktimes from "../../Shared/UsersWorktimes";
import Can from "../../Shared/Can";

const DashboardPage = (props: any): JSX.Element => {
  const [stats, setStats] = useState<any>(null);
  const [isStatsLoading, setStatsLoading] = useState(false);

  const [worktimes, setWorktimes] = useState(null);
  const [isWorktimesLoading, setWorktimesLoading] = useState(false);

  const [users, setUsers] = useState(null);
  const [isUsersLoading, setUsersLoading] = useState(false);

  const [activeTasks, setActiveTasks] = useState(null);
  const [isActiveTasksLoading, setActiveTasksLoading] = useState(false);

  const { displayNotification } = props;
  const { useBreakpoint } = Grid;
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const screens = useBreakpoint();
  useEffect(() => {
    // eslint-disable-next-line eqeqeq

    const isMdBreakpoint = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .filter((screen) => screen[0] === "md").length;

    setIsMobile(!isMdBreakpoint);
  }, [screens, setIsMobile]);

  useEffect(() => {
    setStatsLoading(true);
    setWorktimesLoading(true);
    setUsersLoading(true);
    setActiveTasksLoading(true);

    DashboardService.getStats()
      .then((response) => {
        setStats(response.data);
      })
      .finally(() => {
        setStatsLoading(false);
      });

    DashboardService.getWorktimes()
      .then((response) => {
        setWorktimes(response.data);
      })
      .finally(() => {
        setWorktimesLoading(false);
      });

    DashboardService.getUserActivity()
      .then((response) => {
        setUsers(response.data);
      })
      .finally(() => {
        setUsersLoading(false);
      });

    DashboardService.getActiveWorktimes()
      .then((response) => {
        setActiveTasks(response.data);
      })
      .finally(() => {
        setActiveTasksLoading(false);
      });
  }, []);

  const currentMonth = moment().format("MMMM YYYY");
  const matches = formatSecondsToFootballMatch(
    stats?.worktimeCurrentMonth || 0
  );

  const countEasterEggsArray = [
    {
      count: 200,
      color: "brown",
      url: "https://www.youtube.com/watch?v=DjDSUqTcrv4",
    },
    {
      count: 400,
      color: "silver",
      url: "https://www.youtube.com/watch?v=0ov53YrKl8c",
    },
    {
      count: 600,
      color: "gold",
      url: "https://www.youtube.com/watch?v=QdSC5NDaE48",
    },
    {
      count: 800,
      color: "#00c3ff",
      url: "https://www.youtube.com/watch?v=rUMozsSpVVA",
    },
    {
      count: 1000,
      color: "red",
      url: "https://www.youtube.com/watch?v=fDtZgnXCm1Q",
    },
  ];

  const hoursEasterEggsArray = [
    {
      count: 1000,
      color: "gold",
      url: "https://www.youtube.com/watch?v=5ZCgbGgA-_8",
    },
  ];

  const ticketCountEasterEgg = countEasterEggsArray
    .reverse()
    .filter((item) => stats?.ticketCount >= item.count);

  const hoursEasterEgg = hoursEasterEggsArray
    .reverse()
    .filter(
      (item) =>
        Math.floor((stats?.worktimeCurrentMonth || 0) / 3600) >= item.count
    );

  return (
    <Can renderError type="admin_view">
      <div className="dashboard-page">
        <MetaTitle title="Kokpit" displayBadge={displayNotification} />
        {!isMobile ? (
          <Row gutter={[16, 16]} className="dashboard-page__statistics">
            <Col xs={24} sm={24} md={12} lg={6}>
              <Card>
                <StatisticsBox
                  isLoading={isStatsLoading}
                  title="wszystkich wątków"
                  value={stats?.ticketCount}
                  icon={<HddOutlined />}
                  iconStyle={{ color: "#E21C3C" }}
                  easterEgg={
                    ticketCountEasterEgg.length ? ticketCountEasterEgg[0] : null
                  }
                />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <Card>
                <StatisticsBox
                  isLoading={isStatsLoading}
                  title="otwartych wątków"
                  value={stats?.openTickets}
                  icon={<HourglassOutlined />}
                  iconStyle={{ color: "#026CD6" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <Card>
                <StatisticsBox
                  isLoading={isStatsLoading}
                  title={`zamkniętych wątków - ${currentMonth}`}
                  value={stats?.closedCurrentMonth}
                  icon={<IssuesCloseOutlined />}
                  iconStyle={{ color: "#169C85" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <Card>
                <StatisticsBox
                  isLoading={isStatsLoading}
                  title={`wypracowanego czasu - ${currentMonth}`}
                  value={formatSeconds(stats?.worktimeCurrentMonth || 0)}
                  tooltip={
                    matches
                      ? `Razem rozegraliście ${matches} meczów piłkarskich`
                      : ""
                  }
                  icon={<FieldTimeOutlined />}
                  iconStyle={{ color: "#E68320" }}
                  easterEgg={hoursEasterEgg.length ? hoursEasterEgg[0] : null}
                />
              </Card>
            </Col>
          </Row>
        ) : null}

        <Row gutter={[16, 16]} className="dashboard-page__worktime">
          <Col xs={24} sm={24} md={24} lg={12}>
            <UsersOnline
              data={users}
              isLoading={isUsersLoading}
              isMobile={isMobile}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <ProjectsWorkTime
              data={worktimes}
              isLoading={isWorktimesLoading}
              currentMonth={currentMonth}
              isMobile={isMobile}
            />
          </Col>
        </Row>
        <Row gutter={16} className="dashboard-page__worktime">
          <Col xs={24} sm={24} md={24}>
            <ActiveTasks
              data={activeTasks}
              isLoading={isActiveTasksLoading}
              isMobile={isMobile}
            />
          </Col>
        </Row>
        <Row gutter={16} className="dashboard-page__worktime">
          <Col xs={24} sm={24} md={24} lg={24}>
            <TicketGroupsWorktimesPage isMobile={isMobile} />
          </Col>
        </Row>

        <Row gutter={16} className="dashboard-page__worktime">
          <Col xs={24} sm={24} md={24} lg={24}>
            <UsersWorktimes isMobile={isMobile} />
          </Col>
        </Row>
        {isMobile ? (
          <Row gutter={[16, 16]} className="dashboard-page__statistics">
            <Col xs={24} sm={24} md={12} lg={6}>
              <Card>
                <StatisticsBox
                  isLoading={isStatsLoading}
                  title="wszystkich wątków"
                  value={stats?.ticketCount}
                  icon={<HddOutlined />}
                  iconStyle={{ color: "#E21C3C" }}
                  easterEgg={
                    ticketCountEasterEgg.length ? ticketCountEasterEgg[0] : null
                  }
                />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <Card>
                <StatisticsBox
                  isLoading={isStatsLoading}
                  title="otwartych wątków"
                  value={stats?.openTickets}
                  icon={<HourglassOutlined />}
                  iconStyle={{ color: "#026CD6" }}
                />
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={6}>
              <Card>
                <StatisticsBox
                  title={`zamkniętych wątków - ${currentMonth}`}
                  isLoading={isStatsLoading}
                  value={stats?.closedCurrentMonth}
                  icon={<IssuesCloseOutlined />}
                  iconStyle={{ color: "#169C85" }}
                />
              </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={6}>
              <Card>
                <StatisticsBox
                  isLoading={isStatsLoading}
                  title={`wypracowanego czasu - ${currentMonth}`}
                  value={formatSeconds(stats?.worktimeCurrentMonth || 0)}
                  tooltip={
                    matches
                      ? `Razem rozegraliście ${matches} meczów piłkarskich`
                      : ""
                  }
                  icon={<FieldTimeOutlined />}
                  iconStyle={{ color: "#E68320" }}
                  easterEgg={hoursEasterEgg.length ? hoursEasterEgg[0] : null}
                />
              </Card>
            </Col>
          </Row>
        ) : null}
      </div>
    </Can>
  );
};

const mapDispatchToProps = {
  createTicketAction: createTicket,
};

const mapStateToProps = (state: AppState) => {
  return {
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
