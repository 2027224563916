import { Dispatch } from "redux";
import { AxiosError } from "axios";
import TicketService from "../../services/tickets-service";

import {
  CREATE_COMMENT,
  CREATE_COMMENT_FAILED,
  CREATE_COMMENT_SUCCESS,
  CREATE_TICKET_REQUEST,
  CREATE_TICKET_REQUEST_FAILED,
  CREATE_TICKET_REQUEST_SUCCESS,
  FETCH_COMMENTS,
  FETCH_COMMENTS_FAILED,
  FETCH_COMMENTS_SUCCESS,
  FETCH_TICKET_FAILED,
  FETCH_TICKET_REQUEST,
  FETCH_TICKET_SUCCESS,
  FETCH_TICKET_TYPES,
  FETCH_TICKET_TYPES_FAILED,
  FETCH_TICKET_TYPES_SUCCESS,
  FETCH_TICKETS_FAILED,
  FETCH_TICKETS_REQUEST,
  FETCH_TICKETS_SUCCESS,
  SET_COMMENTS,
  SET_CURRENT_ENTITY_FORBIDDEN,
  SET_TICKET,
  UPDATE_TICKET_DEADLINE_CALENDAR,
  UPDATE_TICKET_DEADLINE_CALENDAR_FAILED,
  UPDATE_TICKET_DEADLINE_CALENDAR_SUCCESS,
  UPDATE_TICKET_FAILED,
  UPDATE_TICKET_REQUEST,
  UPDATE_TICKET_SUCCESS,
} from "../action-types";

export const getTicket = (id: string | number, noLoader = false) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_TICKET_REQUEST,
    payload: !noLoader,
  });
  return TicketService.getTicket(id).then(
    (response) => {
      dispatch({
        type: FETCH_TICKET_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error: AxiosError) => {
      dispatch({
        type: FETCH_TICKET_FAILED,
      });

      if (error.response?.status === 403) {
        dispatch({
          type: SET_CURRENT_ENTITY_FORBIDDEN,
          payload: true,
        });
      }
      return Promise.reject();
    }
  );
};

export const getTicketsCalendar = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_TICKETS_REQUEST,
    payload: true,
  });
  return TicketService.getCalendarTickets().then(
    (response) => {
      dispatch({
        type: FETCH_TICKETS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_TICKETS_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const getTicketTypes = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_TICKET_TYPES,
    payload: true,
  });
  return TicketService.getTicketTypes().then(
    (response) => {
      dispatch({
        type: FETCH_TICKET_TYPES_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_TICKET_TYPES_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const clearTicketState = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: SET_TICKET,
    payload: null,
  });

  return Promise.resolve();
};

export const clearCommentsState = (id: string | number) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: SET_COMMENTS,
    payload: { id, data: [] },
  });

  return Promise.resolve();
};

export const createTicket = (ticket: any) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: CREATE_TICKET_REQUEST,
    payload: true,
  });
  return TicketService.create(ticket).then(
    (response) => {
      dispatch({
        type: CREATE_TICKET_REQUEST_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: CREATE_TICKET_REQUEST_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};

export const createQuickTicket = (ticket: any) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: CREATE_TICKET_REQUEST,
    payload: true,
  });
  return TicketService.createQuick(ticket).then(
    (response) => {
      dispatch({
        type: CREATE_TICKET_REQUEST_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: CREATE_TICKET_REQUEST_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};

export const updateTicket = (id: string | number, ticket: any) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: UPDATE_TICKET_REQUEST,
    payload: true,
  });
  return TicketService.update(id, ticket).then(
    (response) => {
      dispatch({
        type: UPDATE_TICKET_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: UPDATE_TICKET_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};

export const updateTicketDueDateCalendar = (
  id: string | number,
  ticket: any
) => (dispatch: Dispatch<any>) => {
  dispatch({
    type: UPDATE_TICKET_DEADLINE_CALENDAR,
    payload: { id, dueDate: ticket.dueDate },
  });
  return TicketService.update(id, ticket).then(
    (response) => {
      dispatch({
        type: UPDATE_TICKET_DEADLINE_CALENDAR_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: UPDATE_TICKET_DEADLINE_CALENDAR_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};

export const createComment = (id: number | string, comment: any) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: CREATE_COMMENT,
    payload: true,
  });
  return TicketService.createComment(id, comment).then(
    (response) => {
      dispatch({
        type: CREATE_COMMENT_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: CREATE_COMMENT_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};

export const fetchComments = (id: number | string, noLoader = false) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_COMMENTS,
    payload: !noLoader,
  });
  return TicketService.getComments(id).then(
    (response) => {
      dispatch({
        type: FETCH_COMMENTS_SUCCESS,
        payload: { id, data: response.data },
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FETCH_COMMENTS_FAILED,
        payload: error,
      });

      return Promise.reject(error);
    }
  );
};
