import React, { createRef, PropsWithChildren, useState } from "react";
import { Button, DatePicker, Divider, Popover } from "antd";
import "moment/locale/pl";
import { CalendarOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";

import Text from "antd/es/typography/Text";

export enum CalendarFilterOptions {
  ALL = 1,
  TODAY,
  THIS_WEEK,
  THIS_MONTH,
  LAST_WEEK,
  LAST_MONTH,
  CUSTOM,
}

export const getCalendarName = (type: CalendarFilterOptions) => {
  switch (type) {
    case CalendarFilterOptions.ALL:
      return "Cały okres";
      break;
    case CalendarFilterOptions.TODAY:
      return "Dzisiaj";
      break;
    case CalendarFilterOptions.THIS_WEEK:
      return "Ten tydzień";
      break;
    case CalendarFilterOptions.THIS_MONTH:
      return "Ten miesiąc";
      break;
    case CalendarFilterOptions.LAST_WEEK:
      return "Poprzedni tydzień";
      break;
    case CalendarFilterOptions.LAST_MONTH:
      return "Poprzedni miesiąc";
      break;
    case CalendarFilterOptions.CUSTOM:
      return "Inna data";
      break;
    default:
      return "Cały okres";
  }
};

const TicketGroupUpdateFilter: React.FC<any> = (
  props: PropsWithChildren<any>
): JSX.Element => {
  const { begin, end, onChange, selectedType } = props;
  const [selected, setSelected] = useState(selectedType);
  const [fromDate, setFromDate] = useState(moment.unix(begin));
  const [toDate, setToDate] = useState(moment.unix(end));
  const [visible, setVisible] = useState(false);

  const onChangeDateRange = (
    type: CalendarFilterOptions,
    dates: [Moment, Moment]
  ) => {
    setSelected(type);
    setFromDate(dates ? dates[0] : moment());
    setToDate(dates ? dates[1] : moment());
  };
  const onResetClick = () => {
    setFromDate(moment());
    setToDate(moment());
  };
  const calendarRef = createRef<any>();

  return (
    <Popover
      visible={visible}
      onVisibleChange={(change) => {
        if (!change) {
          onChange(fromDate, toDate, selected);
        }
        setVisible(change);
      }}
      style={{ width: "400px", maxWidth: "400px", minWidth: "400px" }}
      placement="bottom"
      content={
        <div>
          <Button
            type={selected === CalendarFilterOptions.ALL ? "primary" : "text"}
            style={{ width: "100%", textAlign: "left" }}
            onClick={() =>
              onChangeDateRange(CalendarFilterOptions.ALL, [
                moment("2022-01-01"),
                moment(),
              ])
            }
          >
            Cały okres
          </Button>
          <Button
            type={selected === CalendarFilterOptions.TODAY ? "primary" : "text"}
            style={{ width: "100%", textAlign: "left" }}
            onClick={() =>
              onChangeDateRange(CalendarFilterOptions.TODAY, [
                moment().startOf("day"),
                moment().endOf("day"),
              ])
            }
          >
            Dzisiaj
          </Button>
          <Button
            type={
              selected === CalendarFilterOptions.THIS_WEEK ? "primary" : "text"
            }
            style={{ width: "100%", textAlign: "left" }}
            onClick={() =>
              onChangeDateRange(CalendarFilterOptions.THIS_WEEK, [
                moment().startOf("weeks"),
                moment().endOf("week"),
              ])
            }
          >
            Ten tydzień
          </Button>
          <Button
            type={
              selected === CalendarFilterOptions.THIS_MONTH ? "primary" : "text"
            }
            style={{ width: "100%", textAlign: "left" }}
            onClick={() =>
              onChangeDateRange(CalendarFilterOptions.THIS_MONTH, [
                moment().startOf("month"),
                moment().endOf("month"),
              ])
            }
          >
            Ten miesiąc
          </Button>
          <Divider style={{ margin: "4px" }} />
          <Button
            type={
              selected === CalendarFilterOptions.LAST_WEEK ? "primary" : "text"
            }
            style={{ width: "100%", textAlign: "left" }}
            onClick={() =>
              onChangeDateRange(CalendarFilterOptions.LAST_WEEK, [
                moment().subtract(1, "week").startOf("week"),
                moment().subtract(1, "week").endOf("week"),
              ])
            }
          >
            Poprzedni tydzień
          </Button>
          <Button
            type={
              selected === CalendarFilterOptions.LAST_MONTH ? "primary" : "text"
            }
            style={{ width: "100%", textAlign: "left" }}
            onClick={() =>
              onChangeDateRange(CalendarFilterOptions.LAST_MONTH, [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ])
            }
          >
            Poprzedni miesiąc
          </Button>
          <Divider style={{ margin: "4px" }} />
          <DatePicker.RangePicker
            ref={calendarRef}
            allowClear={false}
            onChange={(dates: any) => {
              if (dates) onChangeDateRange(CalendarFilterOptions.CUSTOM, dates);
              else {
                onChangeDateRange(CalendarFilterOptions.THIS_MONTH, [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ]);
              }
            }}
            value={[
              moment(fromDate, "YYYY-MM-DD"),
              moment(toDate, "YYYY-MM-DD"),
            ]}
            suffixIcon={
              <CalendarOutlined
                style={{
                  color:
                    selected === CalendarFilterOptions.CUSTOM
                      ? "blue"
                      : undefined,
                }}
              />
            }
          />
        </div>
      }
      title="Wybierz datę"
      trigger="click"
    >
      <Button
        icon={<CalendarOutlined />}
        style={{ textAlign: "left", width: "200px" }}
      >
        <Text ellipsis>
          {" "}
          {selected === CalendarFilterOptions.CUSTOM
            ? `${fromDate.format("DD.MM.YY")} - ${toDate.format("DD.MM.YY")}`
            : getCalendarName(selected)}
        </Text>
      </Button>
    </Popover>
  );
};

export default TicketGroupUpdateFilter;
