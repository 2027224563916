import { Dispatch } from "redux";
import NotificationService from "../../services/notification-service";

import {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILED,
  SET_NEW_NOTIFICATION,
  READ_TICKET_NOTIFICATIONS,
} from "../action-types";

export const getNotifications = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_NOTIFICATIONS,
    payload: true,
  });
  return NotificationService.getNotifications().then(
    (response) => {
      dispatch({
        type: FETCH_NOTIFICATIONS_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: FETCH_NOTIFICATIONS_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const readTicketNotifications = (id: string) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: READ_TICKET_NOTIFICATIONS,
    payload: parseInt(id, 10),
  });
};

export const setNewNotification = (isNew: boolean) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: SET_NEW_NOTIFICATION,
    payload: isNew,
  });
};
