import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { connect } from "react-redux";
import "moment/locale/pl";

import {
  Select,
  Card,
  Col,
  Empty,
  Row,
  Space,
  Tooltip,
  Form,
  Button,
} from "antd";
import { FilterOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import { Helmet } from "react-helmet";
import FormItemLabel from "antd/es/form/FormItemLabel";
import { AppState } from "../../../reducers";
import List from "./list";
import CalendarPicker, {
  CalendarFilterOptions,
} from "../CustomFilters/CalendarPicker";
import { getTicketGroupWorktimesSummary } from "../../../actions/worktimes";
import { getTicketGroups } from "../../../actions/ticketgroups";
import WorktimeGroups from "../CustomFilters/WorktimeGroups";
import WorktimeService from "../../../services/worktime-service";
import Can from "../Can";
import { getUsers } from "../../../actions/users";
import { AccountTypeWithHighlight } from "../../../types/user";

const { Option, OptGroup } = Select;

const UsersWorktimes = (props: PropsWithChildren<any>): JSX.Element => {
  const [filterVisible, setFilterVisible] = React.useState(false);
  const [deadlinesVisible, setDeadlinesVisible] = React.useState(false);
  const [worktimesList, setWorktimesList] = React.useState<any>(null);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const {
    worktimes,
    groups,
    groupsRequest,
    worktimesRequest,
    usersRequest,
    users,
    getUsersAction,
    getTicketGroupsAction,
    isMobile,
  } = props;

  const [filter, setFilter] = React.useState<{
    begin: number;
    end: number;
    groups: number[];
    users: number[];
    status: string;
    selectedType: CalendarFilterOptions;
  }>({
    begin: moment().startOf("month").unix(),
    end: moment().endOf("month").unix(),
    groups: [],
    users: [],
    status: "all",
    selectedType: CalendarFilterOptions.THIS_MONTH,
  });

  const filterRef = useRef();

  useEffect(() => {
    getTicketGroupsAction(false);
    getUsersAction();
  }, [getTicketGroupsAction, getUsersAction]);

  const handleSubmit = () => {
    setLoading(true);
    WorktimeService.getUsersWorktimes(
      filter.begin,
      filter.end,
      filter.status,
      filter.groups,
      filter.users
    )
      .then((res: any) => {
        setWorktimesList(res?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const groupedUsers: any = {};

  users.forEach((user: any) => {
    if (
      true
      //! assignedUsers.some((assignedUser: any) => assignedUser.key === user.id)
    ) {
      const groupLabel = null;

      const group = groupedUsers[groupLabel || user.typeAccount] || [];
      group.push(user);
      // eslint-disable-next-line no-param-reassign
      groupedUsers[groupLabel || user.typeAccount] = group;
    }
  }, []);

  const filterOption = useCallback((search, optionOrGroup) => {
    const isGroup = Array.isArray(optionOrGroup.options);
    if (isGroup) {
      return false;
    }
    return optionOrGroup.children.toLowerCase().includes(search.toLowerCase());
  }, []);

  const filterUsers = useCallback((search, optionOrGroup) => {
    const isGroup = Array.isArray(optionOrGroup.options);
    if (isGroup) {
      return false;
    }
    const userObj = JSON.parse(optionOrGroup.value);

    const haveTag = userObj.tag?.find((tag: any) =>
      tag.name.toLowerCase().includes(search.toLowerCase())
    );

    return (
      optionOrGroup.children[0]?.toLowerCase().includes(search.toLowerCase()) ||
      haveTag
    );
  }, []);

  return (
    <Can renderError type="admin_view">
      <>
        <div className="ticket-group-page">
          <Row gutter={[0, 0]}>
            <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
              <Space
                direction="vertical"
                style={{ marginLeft: "auto", width: "100%" }}
              >
                <Card bordered={false} title="Czas pracy użytkowników">
                  <div className="users-worktimes__filters">
                    <Space
                      direction="vertical"
                      className="users-worktimes__calendar"
                    >
                      <small>Data</small>
                      <CalendarPicker
                        ref={filterRef}
                        begin={filter.begin}
                        end={filter.end}
                        selectedType={filter.selectedType}
                        onChange={(
                          begin: Moment,
                          end: Moment,
                          selectedType: CalendarFilterOptions
                        ) => {
                          setFilter({
                            begin: begin.unix(),
                            end: end.unix(),
                            groups: [...filter.groups],
                            users: [...filter.users],
                            status: filter.status,
                            selectedType,
                          });
                        }}
                      />
                    </Space>
                    <Space
                      direction="vertical"
                      className="users-worktimes__status"
                    >
                      <small>Status wątku</small>
                      <Select
                        defaultValue="all"
                        onChange={(selected: any) => {
                          setFilter((prevState) => {
                            return {
                              ...prevState,
                              status: selected,
                            };
                          });
                        }}
                      >
                        <Option value="all">Wszystkie</Option>
                        <Option value="open">Otwarte</Option>
                        <Option value="closed">Zamknięte</Option>
                      </Select>
                    </Space>
                    <Space
                      direction="vertical"
                      className="users-worktimes__users"
                    >
                      <small>Użytkownik</small>
                      <Select
                        id="filterUsers"
                        style={{
                          width: "100%",
                        }}
                        dropdownStyle={{ minHeight: 300 }}
                        maxTagCount="responsive"
                        maxTagTextLength={15}
                        showSearch
                        getPopupContainer={(trigger) =>
                          trigger.parentNode as HTMLElement
                        }
                        placeholder="Wybierz lub pozostaw puste"
                        optionFilterProp="children"
                        optionLabelProp="label"
                        notFoundContent={
                          <Empty description="Brak więcej użytkowników dla których posiadasz uprawnienie przydzielenia." />
                        }
                        mode="multiple"
                        filterOption={filterUsers}
                        onChange={(selected: any[]) => {
                          const selectedUsersIds = selected.map((select) => {
                            const value = JSON.parse(select);
                            return value.key;
                          });
                          setFilter((prevState) => {
                            return {
                              ...prevState,
                              users: selectedUsersIds,
                            };
                          });
                        }}
                      >
                        {Object.keys(groupedUsers)
                          // eslint-disable-next-line no-nested-ternary
                          .sort((a, b) => (a > b ? -1 : 1))
                          .map((key: any, index: number) => {
                            return (
                              <OptGroup label={AccountTypeWithHighlight[key]}>
                                {groupedUsers[key].map((user: any) => {
                                  const optionLabel = `${user.name} ${user.surname} (${user.email})`;
                                  return (
                                    <Option
                                      value={JSON.stringify({
                                        key: user.id,
                                        label: `${user.name} ${user.surname}`,
                                        typeAccount: user.typeAccount,
                                        tag: user.tag,
                                      })}
                                      key={user.id}
                                      title="asd"
                                      label={`${user.name} ${user.surname}`}
                                    >
                                      {optionLabel}
                                      <div
                                        style={{
                                          marginTop: -2,
                                          color: "#999",
                                        }}
                                      >
                                        <small>
                                          {user?.tag.map(
                                            (tag: any, idx: number) =>
                                              `${tag.name} ${
                                                idx !== user?.tag.length - 1
                                                  ? ", "
                                                  : ""
                                              }`
                                          )}
                                        </small>
                                      </div>
                                    </Option>
                                  );
                                })}
                              </OptGroup>
                            );
                          })}
                      </Select>
                    </Space>

                    <Space
                      direction="vertical"
                      className="users-worktimes__groups"
                    >
                      <small>Dział</small>
                      <Select
                        id="group"
                        style={{
                          width: "100%",
                        }}
                        dropdownStyle={{ minHeight: 300 }}
                        maxTagCount="responsive"
                        maxTagTextLength={15}
                        showSearch
                        getPopupContainer={(trigger) =>
                          trigger.parentNode as HTMLElement
                        }
                        placeholder="Wybierz lub pozostaw puste"
                        optionFilterProp="children"
                        notFoundContent={<Empty description="Brak działów." />}
                        mode="multiple"
                        filterOption={filterOption}
                        onChange={(selected: any[]) => {
                          setFilter((prevState) => {
                            return {
                              ...prevState,
                              groups: selected,
                            };
                          });
                        }}
                      >
                        {groups
                          .sort((a: any, b: any) =>
                            a.parent?.position > b.parent?.position ? -1 : 1
                          )
                          .map((group: any, index: number) => {
                            return (
                              <Option value={group.id} key={group.id}>
                                {group.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Space>

                    <Button
                      loading={worktimesRequest}
                      type="primary"
                      className="users-worktimes__submit"
                      onClick={handleSubmit}
                    >
                      Wyszukaj
                    </Button>
                  </div>
                </Card>
                <Card bordered={false}>
                  <List
                    data={worktimesList?.data || []}
                    users={worktimesList?.summary?.users || []}
                    total={worktimesList?.summary?.total}
                    isRequest={isLoading}
                    isMobile={isMobile}
                  />
                </Card>
              </Space>
            </Col>
          </Row>
        </div>
      </>
    </Can>
  );
};

const mapDispatchToProps = {
  getTicketGroupsAction: getTicketGroups,
  getUsersAction: getUsers,
};

const mapStateToProps = (state: AppState) => {
  return {
    worktimes: state.worktime.users,
    groups: state.ticketgroups.ticketgroups,
    users: state.users.users,
    usersRequest: state.users.isRequest,
    groupsRequest: state.ticketgroups.isFetchTicketGroupsRequest,
    worktimesRequest: state.worktime.usersRequest,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersWorktimes);
