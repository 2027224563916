import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Checkbox, Form, InputNumber, SubmitButton } from "formik-antd";
import { Card, Spin } from "antd";
import { Formik, FormikErrors, FormikValues } from "formik";
import * as Yup from "yup";
import {
  IUserSettingsForm,
  IUserSettingsFormValues,
} from "../../../../../types/user";

interface IUserSettingsFormProps {
  minTime: number;
  multTime: number;
  autostartAfterTime: number;
  disableEmail: boolean;
  isLoading: boolean;
  onSave: (
    values: IUserSettingsFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<IUserSettingsFormValues>) => void
  ) => void;
}

const UserSettingsForm = (props: IUserSettingsFormProps) => {
  const {
    isLoading,
    onSave,
    autostartAfterTime,
    multTime,
    disableEmail,
    minTime,
  } = props;
  const initialFormValues: IUserSettingsFormValues = {
    minTime: 0,
    autostartAfterTime: 0,
    multTime: 0,
    disableEmail: false,
  };
  const [formData, setFormData] = useState<IUserSettingsForm>(
    initialFormValues
  );

  const [editRequest, setEditRequest] = useState<boolean>(false);
  useEffect(() => {
    setFormData({
      minTime,
      autostartAfterTime,
      multTime,
      disableEmail,
    });
  }, [minTime, autostartAfterTime, multTime, disableEmail]);

  const formikRef = useRef<FormikValues>();
  const UserSettingSchema = Yup.object().shape({
    minTime: Yup.number()
      .min(0, "Minimalna wartość to 0 minut")
      .integer("Podany czas musi być liczbą całkowitą")
      .typeError(
        "Pole nie może być puste. Jeśli chcesz dezaktywować tę opcję wpisz 0"
      ),
    autostartAfterTime: Yup.number()
      .min(0, "Minimalna wartość to 0 minut")
      .integer("Podany czas musi być liczbą całkowitą")
      .typeError(
        "Pole nie może być puste. Jeśli chcesz dezaktywować tę opcję wpisz 0"
      ),
    multTime: Yup.number()
      .min(0, "Minimalna wartość to 0 minut")
      .typeError(
        "Pole nie może być puste. Jeśli chcesz dezaktywować tę opcję wpisz 0"
      ),
  });
  return (
    <Spin spinning={isLoading}>
      <Card title="Ustawienia czasu pracy">
        <Formik
          innerRef={formikRef as MutableRefObject<any>}
          initialValues={formData}
          enableReinitialize
          validationSchema={UserSettingSchema}
          onSubmit={(
            values: IUserSettingsFormValues,
            { resetForm, setErrors }
          ) => {
            setEditRequest(true);
            onSave(values, () => setEditRequest(false), setErrors);
          }}
          validateOnChange
          render={() => (
            <Form>
              <Form.Item
                label="Minimalny czas pracy (minuty)"
                name="minTime"
                labelCol={{ span: 24 }}
              >
                <InputNumber
                  name="minTime"
                  type="number"
                  placeholder="Wprowadź czas w minutach"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                label="Autostart po upływie X sekund"
                name="autostartAfterTime"
                labelCol={{ span: 24 }}
              >
                <InputNumber
                  name="autostartAfterTime"
                  type="number"
                  placeholder="Wprowadź czas w sekundach"
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                label="Mnożnik czasu pracy"
                name="multTime"
                labelCol={{ span: 24 }}
              >
                <InputNumber
                  step="0.0001"
                  type="number"
                  name="multTime"
                  placeholder="Mnożnik"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              {/* <Form.Item name="disableEmail" valuePropName="checked"> */}
              {/*  <Checkbox name="disableEmail">Wyłącz wysyłanie maili</Checkbox> */}
              {/* </Form.Item> */}
              <SubmitButton loading={editRequest}>Zapisz</SubmitButton>
            </Form>
          )}
        />
      </Card>
    </Spin>
  );
};

export default UserSettingsForm;
