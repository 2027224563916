import { AxiosResponse } from "axios";
import api from "../axiosInstance";

class RolesService {
  getRoles = async (): Promise<AxiosResponse> => {
    const response = await api.get("roles");
    return response;
  };

  getRolesGroups = async (): Promise<AxiosResponse> => {
    const response = await api.get("roles/groups");
    return response;
  };
}
export default new RolesService();
