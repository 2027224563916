/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from "react";
import { Col, Divider, Empty, Input, Row, Space, Typography } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import classnames from "classnames";
import { Link } from "react-router-dom";
import SearchInput from "./SearchInput";
import SearchTicket from "./SearchTicket";
import TrimmedTitle from "../TrimmedTitle";
import SearchGroup from "./SearchGroup";

interface ISearchResultsProps {
  visible: boolean;
  onRowClick: () => void;
  results: {
    tickets: any[];
    groups: any[];
  };
}
const SearchResults = (props: ISearchResultsProps) => {
  const { visible, results, onRowClick } = props;
  const foundTickets = !!results.tickets.length;
  const foundGroups = !!results.groups.length;

  if (!visible) return null;

  if (!foundGroups && !foundTickets) {
    return <Empty description="Brak pasujących wyników wyszukiwania" />;
  }

  return (
    <Row style={{ width: "100%" }} gutter={[16, 16]}>
      {foundTickets && (
        <Col xs={24} sm={24} md={foundGroups ? 16 : 20}>
          <p style={{ marginBottom: 16 }}>
            <strong>Wyszukane wątki</strong>
          </p>
          {results.tickets.map((ticket) => {
            return <SearchTicket ticket={ticket} onClick={onRowClick} />;
          })}
        </Col>
      )}
      {foundGroups && (
        <Col xs={24} sm={24} md={foundTickets ? 8 : 24}>
          <p style={{ marginBottom: 16 }}>
            <strong>Wyszukane działy</strong>
          </p>
          {results.groups.map((group) => {
            return <SearchGroup group={group} onClick={onRowClick} />;
          })}
        </Col>
      )}
    </Row>
  );
};

export default SearchResults;
