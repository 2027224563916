import { AxiosResponse } from "axios";
import api from "../axiosInstance";

class WorktimeService {
  getTicketWorktimes = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`tickets/${id}/worktimes`);
    return response;
  };

  getTicketWorktimesShort = async (
    id: string | number
  ): Promise<AxiosResponse> => {
    const response = await api.get(`tickets/${id}/worktimes/short`);
    return response;
  };

  getTicketGroupWorktimes = async (
    id: string | number,
    begin: number,
    end: number
  ): Promise<AxiosResponse> => {
    const response = await api.post(`ticketgroups/${id}/worktimes`, {
      begin,
      end,
    });
    return response;
  };

  getTicketGroupWorktimesSummary = async (
    begin: number,
    end: number,
    group?: number[]
  ): Promise<AxiosResponse> => {
    const response = await api.post(`ticketgroups/worktimes`, {
      begin,
      end,
      group,
    });
    return response;
  };

  getUsersWorktimes = async (
    begin: number,
    end: number,
    status: string,
    groups?: number[],
    users?: number[]
  ): Promise<AxiosResponse> => {
    const response = await api.post("dashboard/users-worktime", {
      begin,
      end,
      groups,
      users,
      status,
    });
    return response;
  };

  getUserActiveWorktime = async (): Promise<AxiosResponse> => {
    const response = await api.get(`worktimes/my-active`);
    return response;
  };

  getUserLastWorktime = async (): Promise<AxiosResponse> => {
    const response = await api.get(`worktimes/my-last`);
    return response;
  };

  getAllActive = async (): Promise<AxiosResponse> => {
    const response = await api.get(`worktimes/active`);
    return response;
  };

  startWorktime = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.get(`worktimes/start/${id}`);
    return response;
  };

  endWorktime = async (
    id: string | number,
    comment?: string
  ): Promise<AxiosResponse> => {
    const response = await api.post(`worktimes/end/${id}`, {
      comment,
    });
    return response;
  };

  addWorktime = async (
    id: string | number,
    start: number,
    seconds: number,
    comment?: string
  ): Promise<AxiosResponse> => {
    const response = await api.post(`worktimes/add/${id}`, {
      comment,
      start,
      seconds,
    });
    return response;
  };

  removeWorktime = async (id: string | number): Promise<AxiosResponse> => {
    const response = await api.delete(`worktimes/${id}`);
    return response;
  };
}
export default new WorktimeService();
