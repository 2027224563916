import React from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Avatar, Card, Space, Table, Tooltip } from "antd";

import { Skeleton } from "antd/es";
import Text from "antd/es/typography/Text";
import {
  formatSeconds,
  makeAvatarName,
  stringToColour,
} from "../../../utils/common";

const ProjectsWorkTime = (props: any): JSX.Element => {
  const { data, isLoading, currentMonth, isMobile } = props;
  interface GroupAvatar {
    image: string | null;
    avatarColor: string;
    avatarBackground: string;
    name: string;
  }

  const dataSource = data?.map((splitter: any) => {
    const row = {
      key: splitter?.id,
      name: splitter?.name ? splitter?.name : "Nieprzydzielone",
      tasks: Object.values(splitter.rows).length,
      timeWorked: splitter.seconds,
      avatar: {
        image: splitter?.img,
        avatarColor: "white",
        avatarBackground: stringToColour(
          splitter?.name ? splitter?.name : "Nieprzydzielone"
        ),
        name: splitter?.name ? splitter?.name : "Nieprzydzielone",
      },
      expandable: Object.values(splitter.rows).map((ticketGroup: any) => {
        return {
          key: ticketGroup.id,
          name: ticketGroup.name,
          tasks: ticketGroup.ticketCount,
          timeWorked: ticketGroup.seconds,
        };
      }),
    };

    return row;
  });

  const CompanyAvatar = (group: GroupAvatar) => {
    const { image, avatarColor, avatarBackground, name } = group;
    return (
      <Avatar
        size={40}
        src={image || null}
        className="user-app__avatar"
        style={{
          color: avatarColor,
          backgroundColor: avatarBackground,
          borderColor: "#fefefe",
          borderWidth: 1,
          borderStyle: "solid",
        }}
      >
        {makeAvatarName(false, name)}
      </Avatar>
    );
  };

  let columns = [
    {
      title: "Dział",
      dataIndex: "avatar",
      key: "mobileRow",
      width: "100%",
      hidden: !isMobile,
      render: (company: GroupAvatar, row: any) => {
        const { image, avatarColor, avatarBackground, name } = company;
        return (
          <>
            <Space>
              <CompanyAvatar
                image={image}
                avatarColor={avatarColor}
                avatarBackground={avatarBackground}
                name={name}
              />
              <Space direction="vertical">
                <Text>{row.name}</Text>
                <Space direction="horizontal">
                  <small>
                    Działy: <strong>{row.tasks}</strong>
                  </small>
                  <small>
                    Czas:{" "}
                    <Tooltip title={formatSeconds(row.timeWorked, true)}>
                      <Text strong>{formatSeconds(row.timeWorked)}</Text>
                    </Tooltip>
                  </small>
                </Space>
              </Space>
            </Space>
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "avatar",
      key: "avatar",
      hidden: isMobile,
      render: (company: GroupAvatar) => {
        const { image, avatarColor, avatarBackground, name } = company;
        return (
          <CompanyAvatar
            image={image}
            avatarColor={avatarColor}
            avatarBackground={avatarBackground}
            name={name}
          />
        );
      },
      width: "60px",
    },
    {
      title: "Dział",
      dataIndex: "name",
      hidden: isMobile,
      key: "name",
      ellipsis: true,
    },
    {
      title: "Działy",
      dataIndex: "tasks",
      hidden: isMobile,
      key: "tasks",
      ellipsis: true,
      width: "20%",
    },
    {
      title: "Wypracowane",
      dataIndex: "timeWorked",
      hidden: isMobile,
      key: "timeWorked",
      width: "20%",
      ellipsis: true,
      render: (seconds: number) => (
        <Tooltip title={formatSeconds(seconds, true)}>
          <Text strong>{formatSeconds(seconds)}</Text>
        </Tooltip>
      ),
    },
  ];

  columns = columns.filter((row) => !row.hidden);
  const expandedRow = ({ expandable }: any) => {
    let expandableColumns = [
      {
        title: "Dział",
        dataIndex: "avatar",
        key: "mobileRow",
        width: "100%",
        hidden: !isMobile,
        render: (company: GroupAvatar, row: any) => {
          return (
            <>
              <Space>
                <Space direction="vertical">
                  <Text>{row.name}</Text>
                  <Space direction="horizontal">
                    <small>
                      Ilość wątków: <strong>{row.tasks}</strong>
                    </small>
                    <small>
                      Czas:{" "}
                      <Tooltip title={formatSeconds(row.timeWorked, true)}>
                        <Text strong>{formatSeconds(row.timeWorked)}</Text>
                      </Tooltip>
                    </small>
                  </Space>
                </Space>
              </Space>
            </>
          );
        },
      },
      {
        title: "",
        dataIndex: "avatar",
        hidden: isMobile,

        key: "avatar",
        render: () => "",
        width: "60px",
      },
      {
        title: "Dział",
        dataIndex: "name",
        hidden: isMobile,

        key: "name",
      },
      {
        title: "Ilość wątków",
        width: "20%",
        dataIndex: "tasks",
        hidden: isMobile,

        key: "tasks",
        ellipsis: true,
      },
      {
        title: "Wypracowane",
        width: "25%",
        dataIndex: "timeWorked",
        hidden: isMobile,

        key: "timeWorked",
        ellipsis: true,
        render: (seconds: number) => (
          <Tooltip title={formatSeconds(seconds, true)}>
            <Text strong>{formatSeconds(seconds)}</Text>
          </Tooltip>
        ),
      },
    ];

    expandableColumns = expandableColumns.filter((row: any) => !row.hidden);
    return (
      <Table
        columns={expandableColumns}
        dataSource={expandable}
        showHeader={!isMobile}
        locale={{ emptyText: "Brak danych" }}
        pagination={false}
      />
    );
  };

  return (
    <Card
      title={`Czas pracy - ${currentMonth}`}
      className="worktime"
      bodyStyle={{ padding: isLoading ? 20 : 0 }}
    >
      {!isLoading ? (
        <Table
          dataSource={dataSource}
          className="worktime__table"
          columns={columns}
          pagination={false}
          locale={{ emptyText: "Brak danych" }}
          expandedRowRender={expandedRow}
          expandIconColumnIndex={columns.length}
          rowClassName={(record, index) => {
            if (record.timeBalanceNumber < 0) {
              return "worktime__row--fulfilled";
            }
            return "worktime__row--unfulfilled";
          }}
          indentSize={0}
          expandedRowClassName={() => "worktime__row--expandable"}
          expandIcon={({ expanded, onExpand, record }) =>
            expanded ? (
              <CaretUpOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
            )
          }
          scroll={{ y: 365 }}
        />
      ) : (
        <Skeleton active paragraph />
      )}
    </Card>
  );
};

export default ProjectsWorkTime;
