import React, { MutableRefObject, useRef, useState } from "react";
import { Form, Input, Select, SubmitButton } from "formik-antd";
import { Spin } from "antd";
import { Formik, FormikErrors, FormikValues } from "formik";
import * as Yup from "yup";

const { Option, OptGroup } = Select;

export interface IAddTicketTypeFormProps {
  isLoading: boolean;
  onSave: (
    values: IAddTicketTypeFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<IAddTicketTypeFormValues>) => void
  ) => void;
}

export interface IAddTicketTypeFormValues {
  name: string;
}

const AddTicketTypeForm = (props: IAddTicketTypeFormProps) => {
  const { isLoading, onSave } = props;

  const initialFormValues: IAddTicketTypeFormValues = {
    name: "",
  };
  const [formData, setFormData] = useState<IAddTicketTypeFormValues>(
    initialFormValues
  );

  const [editRequest, setEditRequest] = useState<boolean>(false);

  const formikRef = useRef<FormikValues>();

  const FormSchema = Yup.object().shape({
    name: Yup.string()
      .max(255, "Nazwa powinna się składać z maksymalnie 255 znaków")
      .required("Pole wymagane"),
  });

  return (
    <Spin spinning={isLoading}>
      <Formik
        innerRef={formikRef as MutableRefObject<any>}
        initialValues={formData}
        enableReinitialize
        validationSchema={FormSchema}
        onSubmit={(
          values: IAddTicketTypeFormValues,
          { resetForm, setErrors }
        ) => {
          setEditRequest(true);
          onSave(
            values,
            () => {
              setEditRequest(false);
              resetForm();
            },
            setErrors
          );
        }}
        validateOnChange
        render={() => (
          <Form style={{ marginTop: 16 }}>
            <Form.Item
              name="name"
              className="show-label"
              label="Nazwa nowego typu"
              labelCol={{ span: 24 }}
              rules={[{ required: true, max: 255 }]}
            >
              <Input name="name" />
            </Form.Item>
            <SubmitButton loading={editRequest}>Dodaj</SubmitButton>
          </Form>
        )}
      />
    </Spin>
  );
};

export default AddTicketTypeForm;
