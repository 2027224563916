import React, { PropsWithChildren, useEffect, useState } from "react";
import { connect } from "react-redux";

import "moment/locale/pl";

import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Grid,
  Input,
  Progress,
  Row,
  Skeleton,
  Space,
  Tabs,
  Tooltip,
} from "antd";

import {
  CalendarOutlined,
  FieldTimeOutlined,
  FilterOutlined,
  NotificationOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { AxiosResponse } from "axios";
import TicketService from "../../../services/tickets-service";
import TaskGroupsService from "../../../services/taskgroups-service";
import { AppState } from "../../../reducers";
import ArchiveList from "./list";
import { formatMoney } from "../../../utils/common";

import {
  clearTicketGroupState,
  getTicketGroup,
  getTicketGroupTicketTypes,
} from "../../../actions/ticketgroups";
import {
  setArchivedCount,
  setCurrentEntityForbidden,
  setCurrentGroupTitle,
} from "../../../actions/global";
import CalendarPicker from "../../Shared/CustomFilters/CalendarPicker";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";

const { TabPane } = Tabs;

interface IRouteParams {
  groupId: string;
}

const ArchivePage = (props: PropsWithChildren<any>): JSX.Element => {
  const [archive, setArchive] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setArchivedCountAction, displayNotification } = props;
  const { useBreakpoint } = Grid;
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const screens = useBreakpoint();
  useEffect(() => {
    // eslint-disable-next-line eqeqeq

    const isMdBreakpoint = Object.entries(screens)
      .filter((screen) => !!screen[1])
      .filter((screen) => screen[0] === "md").length;

    setIsMobile(!isMdBreakpoint);
  }, [screens, setIsMobile]);

  useEffect(() => {
    setIsLoading(true);
    setArchivedCountAction(0);
    TicketService.getArchiveTickets()
      .then((response: AxiosResponse) => {
        setArchive(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [setArchivedCountAction]);
  return (
    <Can renderError type="ticket_group_view">
      <div className="calendar-page">
        <MetaTitle title="Archiwum" displayBadge={displayNotification} />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
            <Space
              direction="vertical"
              style={{ marginLeft: "auto", width: "100%" }}
            >
              <Card bordered={false}>
                <Row justify="space-between" gutter={[16, 16]}>
                  <Space>
                    <Input
                      prefix={<SearchOutlined />}
                      placeholder="Szukaj w dziale"
                      onChange={(text) => console.log(text)}
                      // style={{ width: 200 }}
                    />
                    <CalendarPicker />
                  </Space>
                </Row>
              </Card>
              <Card bordered={false} size="small">
                <ArchiveList
                  tasks={archive}
                  isRequest={isLoading}
                  isMobile={isMobile}
                />
              </Card>
            </Space>
          </Col>
        </Row>
      </div>
    </Can>
  );
};

const mapDispatchToProps = {
  setArchivedCountAction: setArchivedCount,
};

const mapStateToProps = (state: AppState) => {
  return {
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchivePage);
