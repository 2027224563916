import React, { MutableRefObject, PropsWithChildren, useRef } from "react";
import { Form, InputNumber, Radio, SubmitButton } from "formik-antd";
import { Button, Divider, Row, Space } from "antd";
import { Formik, FormikProps, FormikValues } from "formik";
import "moment/locale/pl";

const TicketGroupUpdateFilter: React.FC<any> = (
  props: PropsWithChildren<any>
): JSX.Element => {
  const formikRef = useRef<FormikValues>();
  const { handleChange, handleClear, type, initFilters } = props;

  const initialFormValues: any = {
    type: initFilters?.type || 5,
    noAnswerCount: initFilters?.noAnswerCount || 7,
    display: initFilters?.display || 6,
    answerCount: initFilters?.answerCount || 5,
  };

  return (
    <Formik
      innerRef={formikRef as MutableRefObject<any>}
      initialValues={initialFormValues}
      onSubmit={(values: any, { resetForm, setErrors }) => {
        handleChange(values);
      }}
      enableReinitialize
      validateOnChange
      render={(formProps: FormikProps<any>) => (
        <Form>
          <Form.Item
            required
            name="type"
            labelCol={{ span: 24 }}
            style={{ width: "300px" }}
            rules={[{ required: true, message: "Wprowadź estymację!" }]}
          >
            <Radio.Group name="type">
              <Space direction="vertical">
                {type === "home" && (
                  <Radio value={5} name="type">
                    Ostatnio zaktualizowane
                  </Radio>
                )}
                <Radio value={1} name="type">
                  Tylko nowości
                </Radio>
                <Radio value={2} name="type">
                  Tylko bez odpowiedzi
                </Radio>
                <Radio value={3} name="type">
                  <Space>
                    <span>Tylko po</span>
                    <InputNumber
                      min={1}
                      bordered
                      size="small"
                      max={20}
                      style={{ width: "50px" }}
                      name="answerCount"
                    />
                    <span>odpowiedziach</span>
                  </Space>
                </Radio>
                <Radio value={4} name="type">
                  <Space>
                    <span>Bez odpowiedzi od</span>
                    <InputNumber
                      min={1}
                      bordered
                      size="small"
                      max={20}
                      style={{ width: "50px" }}
                      name="noAnswerCount"
                    />
                    <span>dni</span>
                  </Space>
                </Radio>
                {type !== "assigned" && (
                  <Radio value={6} name="type">
                    Przydzielone do mnie
                  </Radio>
                )}
              </Space>
            </Radio.Group>
          </Form.Item>
          {type === "home" && (
            <Row justify="center">
              <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
              <Space>
                <span>Pokaż</span>
                <InputNumber
                  min={1}
                  bordered
                  size="small"
                  max={10}
                  style={{ width: "50px" }}
                  name="display"
                />
                <span>wątków</span>
              </Space>
            </Row>
          )}
          <Row justify="space-between">
            <Divider style={{ marginTop: "16px", marginBottom: "8px" }} />
            <Button
              type="text"
              size="small"
              disabled={!formProps.values.type}
              onClick={() => {
                formProps.resetForm();
                handleClear(initialFormValues);
              }}
            >
              Wyczyść
            </Button>
            <SubmitButton size="small" loading={false}>
              Zapisz
            </SubmitButton>
          </Row>
        </Form>
      )}
    />
  );
};

export default TicketGroupUpdateFilter;
