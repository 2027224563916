// TODO: DODAĆ PRZEJŚCIE Z ZESTAWIENIA DO CZASU W GRUPIE.
// TODO: !!!!!

import React, { PropsWithChildren } from "react";
import { Empty, Spin, Tooltip } from "antd";
import { Link } from "react-router-dom";

import Text from "antd/es/typography/Text";
import { formatSeconds } from "../../../utils/common";
import SingleMonth from "./SingleMonth";
import { Disposable } from "../../../types/ticket-group";

const WorktimesTable = (props: PropsWithChildren<any>): JSX.Element => {
  const { data, summary, isRequest, ticketGroup } = props;
  return (
    <Spin spinning={isRequest}>
      {Object.keys(data).map((key: any, index) => {
        const month = data[key];
        return (
          <SingleMonth
            showCost={ticketGroup?.disposable !== Disposable.Niedotyczy}
            month={key}
            data={month.rows}
            summary={month.summary}
            users={month.users}
            totals={Object.keys(data).length - 1 === index ? summary : null}
          />
        );
      })}
      {!isRequest && !Object.values(data).length && (
        <Empty description="Brak raportów w podanym okresie czasu" />
      )}
    </Spin>
  );
};

export default WorktimesTable;
