import React from "react";
import { Avatar, Space, Spin } from "antd";
import classnames from "classnames";
import {
  makeAvatarName,
  stringToColour,
  timeAgoFromTimestamp,
} from "../../../utils/common";
import useIsMobile from "../../../hooks/useIsMobile";
import ChatUser from "./ChatUser";
import ChatRoom from "./ChatRoom";

interface IProps {
  rooms: any[];
  isLoading: boolean;
  isMobile: boolean;
  count: any;
  selectedRoom: number | null;
  selectRoom: (id: number | string) => void;
}

export const ChatRooms = (props: IProps): JSX.Element => {
  const { rooms, isLoading, isMobile, selectedRoom, selectRoom, count } = props;

  return (
    <div className="chat__rooms">
      {isLoading && (
        <div className="chat__preload">
          <Spin />
        </div>
      )}

      {rooms.map((room) => {
        const isNewMessagesCount = count.filter(
          (obj: any) => obj.room === room.id
        );

        return (
          <ChatRoom
            count={
              isNewMessagesCount.length > 0 ? isNewMessagesCount[0].count : 0
            }
            room={room}
            isMobile={isMobile}
            selectedRoom={selectedRoom}
            selectRoom={selectRoom}
          />
        );
      })}
    </div>
  );
};
