import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import {
  DatePicker,
  Form,
  Input,
  Select,
  SubmitButton,
  Switch,
} from "formik-antd";
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Spin,
  Upload,
} from "antd";
import { Formik, FormikErrors, FormikProps, FormikValues } from "formik";
import * as Yup from "yup";

import moment, { Moment } from "moment";
import Text from "antd/es/typography/Text";
import { PaperClipOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/lib/upload/interface";

import TicketAddAssignedForm, { IFormValues } from "../AddAssignedForm";
import TicketAssignedForm from "../AssignedForm";
import { Priority, PriorityColor } from "../../../../../types/ticket-group";
import TicketService from "../../../../../services/tickets-service";
import UploadAttachmentsList from "../../../../Shared/UplaodAttachmentsList";
import Can from "../../../../Shared/Can";
import QuillEditor from "../../../../Shared/Editor";

export interface ITicketBasicFormValues {
  title: string;
  ticketType: number | null;
  description: string;
  hiddenComment: string | undefined;
  assignedUsers: any[];
  attachments: any[];
  dueDate: number | null;
  paid: boolean;
  cost: number | null;
  priorityId: number;
  status: number;
  priority: number;
  group: string | null;
}

export interface ITicketProps {
  title?: string;
  ticketType?: any;
  ticketTypes: any;
  description?: string;
  hiddenComment?: string;
  assignedUsers: any[];
  leaders: any[];
  members: any[];
  dueDate?: number;
  paid?: boolean;
  cost?: number | null;
  priority?: number;
  canEdit: boolean;
  canEditLeaders: boolean;
  users: any[];
  group: string;
  isLoading: boolean;
  onSave: (
    values: ITicketBasicFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<ITicketBasicFormValues>) => void
  ) => void;
}

const disabledDate = (current: Moment) => {
  return current && current < moment().startOf("day");
};

const { Option, OptGroup } = Select;
const TicketBasicForm = (props: ITicketProps) => {
  const {
    title,
    users,
    description,
    assignedUsers,
    paid,
    isLoading,
    canEdit,
    canEditLeaders,
    onSave,
    leaders,
    members,
    ticketTypes,
    group,
    cost,
    dueDate,
    priority,
    hiddenComment,
  } = props;
  // TODO: managerID should be relation instead of integer
  const initialFormValues: ITicketBasicFormValues = {
    title: "",
    description: "",
    cost: null,
    hiddenComment: "",
    paid: false,
    assignedUsers: [],
    dueDate: null,
    priority: 1,
    priorityId: 1,
    ticketType: null,
    group: null,
    status: 10,
    attachments: [],
  };
  const [formData, setFormData] = useState<ITicketBasicFormValues>(
    initialFormValues
  );

  const [editRequest, setEditRequest] = useState<boolean>(false);

  const [uploadList, setUploadList] = useState<any>([]);

  const dueDateRequired = !!Can({
    type: "due_date_required",
    children: <></>,
  });

  const assignedFormRef = useRef<FormikProps<IFormValues>>(null);
  useEffect(() => {
    let defaultType = ticketTypes.filter((type: any) => type.defaultType);
    defaultType = defaultType.length ? defaultType : ticketTypes;

    setFormData({
      title: title || "",
      description: description || "",
      paid: paid || false,
      assignedUsers,
      cost: cost || null,
      dueDate: dueDate || null,
      hiddenComment,
      priority: priority || 1,
      ticketType: defaultType.length ? defaultType[0].id : null,
      group,
      status: 10,
      priorityId: priority || 1,
      attachments: [],
    });
  }, [
    title,
    description,
    paid,
    leaders,
    assignedUsers,
    ticketTypes,
    group,
    cost,
    dueDate,
    hiddenComment,
    priority,
  ]);

  const handleAttachmentProgressChange = (file: any, progress: number) => {
    setUploadList((prevState: any) => {
      const idx = prevState.findIndex((item: any) => item.id === file.uid);
      if (idx > -1) {
        const copyItem = { ...prevState[idx] };
        copyItem.progress = progress;
        return [
          ...prevState.slice(0, idx),
          copyItem,
          ...prevState.slice(idx + 1),
        ];
      }
      return null;
    });
  };

  const handleAttachmentsRemove = (file: any) => {
    setUploadList((prevState: any) => {
      const idx = prevState.findIndex((item: any) => item.id === file.id);
      if (idx > -1) {
        return [...prevState.slice(0, idx), ...prevState.slice(idx + 1)];
      }
      return null;
    });
  };

  const handleAttachmentsChange = (info: UploadChangeParam) => {
    const { file, fileList } = info;
    const item = {
      date: moment().unix(),
      fileMimeType: file.type,
      fileName: file.name,
      fileOriginalName: file.name,
      fileSize: file.size,
      progress: 0,
      hidden: false,
      id: file.uid,
      ticket: {},
      user: {},
      status: file.status,
    };

    setUploadList((prevState: any) => {
      return [item, ...prevState];
    });
  };

  const handleAttachmentsUpload = (options: any) => {
    // setIsUploading(true);
    return TicketService.uploadAttachment(options.file, (change) =>
      handleAttachmentProgressChange(options.file, change)
    )
      .then((response) => {
        setUploadList((prevState: any) => {
          const idx = prevState.findIndex(
            (item: any) => item.id === options.file.uid
          );

          if (idx > -1) {
            return [
              ...prevState.slice(0, idx),
              response.data,
              ...prevState.slice(idx + 1),
            ];
          }

          return null;
        });
      })
      .catch((err) => {
        setUploadList((prevState: any) => {
          const idx = prevState.findIndex(
            (item: any) => item.id === options.file.uid
          );

          const copyItem = { ...prevState[idx] };
          copyItem.progress = 0;
          copyItem.status = "error";

          if (idx > -1) {
            return [
              ...prevState.slice(0, idx),
              copyItem,
              ...prevState.slice(idx + 1),
            ];
          }

          return null;
        });
      });
  };

  const formikRef = useRef<FormikValues>();

  const handleSubmit = () => {
    if (canEdit)
      assignedFormRef.current?.submitForm().then(() => {
        assignedFormRef.current?.resetForm();
        formikRef.current?.submitForm();
      });
    else formikRef.current?.submitForm();
  };

  const FormSchema = Yup.object().shape({
    title: Yup.string().required("Pole wymagane"),
    description: Yup.string().required("Pole wymagane"),
    // assignedUsers: Yup.array().min(
    //   1,
    //   "Zadanie powinno być przypisane do min. jednej osoby."
    // ),
    paid: Yup.boolean(),
    dueDate: dueDateRequired
      ? Yup.mixed().required("Termin jest wymagany")
      : Yup.mixed().nullable(),
    cost: Yup.number().when("paid", {
      is: true,
      then: Yup.number()
        .typeError("Pole musi być wartością liczbową")
        .required("Pole jest wymagane")
        .min(0.01, "Podaj wartość większą niż 0."),
      otherwise: Yup.number().nullable(),
    }),
  });

  return (
    <Spin spinning={isLoading}>
      <Card title="Podstawowe informacje">
        <Formik
          innerRef={formikRef as MutableRefObject<any>}
          initialValues={formData}
          enableReinitialize
          validationSchema={FormSchema}
          onSubmit={(
            values: ITicketBasicFormValues,
            { resetForm, setErrors }
          ) => {
            setEditRequest(true);
            // console.log(assignedFormRef.current, "??");
            // assignedFormRef.current?.submitForm();
            onSave(
              {
                ...values,
                assignedUsers: values.assignedUsers.map((user) => user.key),
                dueDate: values.dueDate ? moment(values.dueDate).unix() : null,
                attachments: uploadList
                  .filter(
                    (file: any) =>
                      file.status !== "error" || file.status === "uploading"
                  )
                  .map((file: any) => file.id),
              },
              () => setEditRequest(false),
              setErrors
            );
          }}
          validateOnChange
          render={(formProps) => (
            <Form>
              <Row gutter={16}>
                <Can type="ticket_edit_type">
                  <Col xs={24} md={24} lg={12} xl={10}>
                    <Form.Item
                      name="ticketType"
                      label="Typ wątku"
                      labelCol={{ span: 24 }}
                    >
                      <Select name="ticketType" placeholder="Wybierz typ wątku">
                        {ticketTypes.map((type: any) => (
                          <Option value={type.id}>
                            <Space>
                              <div
                                style={{
                                  backgroundColor: type.color,
                                  width: 20,
                                  height: 20,
                                }}
                              />
                              {type.name}
                            </Space>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Can>
                <Col span={24}>
                  <Form.Item
                    label="Tytuł"
                    required
                    name="title"
                    labelCol={{ span: 24 }}
                    rules={[{ required: true }]}
                  >
                    <Input name="title" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Opis"
                    required
                    name="description"
                    labelCol={{ span: 24 }}
                    rules={[{ required: true }]}
                  >
                    <QuillEditor
                      initValue={initialFormValues.description}
                      value={formProps.values.description}
                      withMedia
                      reinitialize={false}
                      onChange={(change: string) => {
                        const val = change === "<p><br></p>" ? "" : change;
                        formProps.setFieldValue("description", val);
                        formProps.setFieldTouched("description", true);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Can type="ticket_hidden_comments">
                  <Col span={24}>
                    <Form.Item
                      label="Ukryty komentarz"
                      name="hiddenComment"
                      labelCol={{ span: 24 }}
                      rules={[{ required: false }]}
                    >
                      <QuillEditor
                        initValue={initialFormValues.hiddenComment}
                        value={formProps.values.hiddenComment}
                        withMedia
                        reinitialize={false}
                        className="hidden"
                        onChange={(change: string) => {
                          const val = change === "<p><br></p>" ? "" : change;
                          formProps.setFieldValue("hiddenComment", val);
                          formProps.setFieldTouched("hiddenComment", true);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Can>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <h3 style={{ marginTop: 16 }}>Przydzielone osoby</h3>

                      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                      {formProps.errors.assignedUsers ? (
                        <div className="ant-form-item-explain ant-form-item-explain-error">
                          {formProps.errors.assignedUsers}
                        </div>
                      ) : null}
                      {formProps.values.assignedUsers.map((user) => {
                        return (
                          <TicketAssignedForm
                            canEdit={canEdit}
                            canEditLeaders={canEditLeaders}
                            userId={user.key}
                            title={user.label}
                            isLeader={leaders.some(
                              (leader: any) => leader.user.id === user.key
                            )}
                            tags={user.tag}
                            onRemove={(userId) => {
                              const removedAssigned = Array.from(
                                formProps.values.assignedUsers
                              );

                              removedAssigned.splice(
                                removedAssigned.findIndex(
                                  (findUser: any) => findUser.key === userId
                                ),
                                1
                              );

                              formProps.setFieldValue(
                                "assignedUsers",
                                removedAssigned
                              );
                              // formProps.validateField("assignedUsers");
                            }}
                          />
                        );
                      })}
                    </Col>
                  </Row>
                  {canEdit && (
                    <TicketAddAssignedForm
                      showSubmitButton={false}
                      formikRef={assignedFormRef}
                      isLoading={false}
                      assignedUsers={formProps.values.assignedUsers}
                      leaders={leaders}
                      onTouched={() => {}}
                      members={members}
                      users={users}
                      onSave={(values, onRequestComplete, setError) => {
                        formProps.setFieldValue("assignedUsers", [
                          ...formProps.values.assignedUsers,
                          ...values.assignedUsers.map((assinged) =>
                            JSON.parse(assinged)
                          ),
                        ]);
                      }}
                    />
                  )}
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <h3 style={{ marginTop: 16 }}>Załączniki</h3>

                      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                      <Row align="middle" justify="space-between">
                        <Col sm={24} md={24} lg={24}>
                          <UploadAttachmentsList
                            displayHeader
                            attachments={uploadList}
                            handleRemove={handleAttachmentsRemove}
                          />
                        </Col>
                      </Row>
                      <Row
                        align="middle"
                        justify="space-between"
                        gutter={[0, 16]}
                      >
                        <Col sm={24} md={24} lg={24}>
                          <Upload
                            multiple
                            listType="text"
                            showUploadList={false}
                            customRequest={handleAttachmentsUpload}
                            onChange={handleAttachmentsChange}
                          >
                            <Button
                              icon={<PaperClipOutlined />}
                              style={{ marginTop: 16, marginBottom: 24 }}
                            >
                              Dodaj załącznik
                            </Button>
                          </Upload>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Can type="ticket_edit_duo_date">
                  <Col xs={24} md={24} lg={12} xl={10}>
                    <Form.Item
                      name="dueDate"
                      label="Termin"
                      required={dueDateRequired}
                      labelCol={{ span: 24 }}
                    >
                      <DatePicker
                        format="DD.MM.Y HH:mm"
                        showTime={{ showSecond: false }}
                        name="dueDate"
                        allowClear
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  </Col>
                </Can>
              </Row>
              <Can type="ticket_edit_priority">
                <Row gutter={16}>
                  <Col xs={24} md={24} lg={12} xl={10}>
                    <Form.Item
                      name="priorityId"
                      label="Priorytet"
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        name="priorityId"
                        placeholder="Wybierz priorytet wątku"
                      >
                        {[5, 4, 3, 2, 1].map((value: number) => (
                          <Option value={value}>
                            <Space>
                              <Badge
                                status="processing"
                                text={
                                  <Space size="small">
                                    <Text>{Priority[value]}</Text>
                                  </Space>
                                }
                                color={PriorityColor[value]}
                              />
                            </Space>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Can>
              <Can type="ticket_edit_cost">
                <>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={12} xl={10}>
                      <Form.Item
                        name="paid"
                        label="Płatne"
                        labelCol={{ span: 24 }}
                      >
                        <Switch
                          name="paid"
                          onChange={(change) => {
                            if (!change) formProps.setFieldValue("cost", null);
                          }}
                          checkedChildren="Tak"
                          unCheckedChildren="nie"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={12} xl={10}>
                      <Form.Item
                        label="Koszt"
                        name="cost"
                        labelCol={{ span: 24 }}
                        hidden={!formProps.values.paid}
                      >
                        <Input
                          type="number"
                          name="cost"
                          style={{ width: "100%" }}
                          suffix="zł."
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              </Can>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    loading={editRequest}
                    disabled={
                      !formProps.isValid ||
                      (uploadList &&
                        uploadList.findIndex(
                          (attachment: any) => attachment.status === "uploading"
                        ) !== -1)
                    }
                  >
                    Zapisz
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        />
      </Card>
    </Spin>
  );
};

export default React.memo(TicketBasicForm);
