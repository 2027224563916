import { AnyAction } from "redux";

import {
  CREATE_COMMENT,
  CREATE_COMMENT_FAILED,
  CREATE_COMMENT_SUCCESS,
  FETCH_COMMENTS,
  FETCH_COMMENTS_FAILED,
  FETCH_COMMENTS_SUCCESS,
  SET_COMMENTS,
  FETCH_TICKET_FAILED,
  FETCH_TICKET_REQUEST,
  FETCH_TICKET_SUCCESS,
  FETCH_TICKET_TYPES,
  FETCH_TICKET_TYPES_FAILED,
  FETCH_TICKET_TYPES_SUCCESS,
  FETCH_TICKETS_FAILED,
  FETCH_TICKETS_REQUEST,
  FETCH_TICKETS_SUCCESS,
  SET_TICKET,
  UPDATE_TICKET_DEADLINE_CALENDAR,
  UPDATE_TICKET_DEADLINE_CALENDAR_FAILED,
  UPDATE_TICKET_DEADLINE_CALENDAR_SUCCESS,
  UPDATE_TICKET_FAILED,
  UPDATE_TICKET_REQUEST,
  UPDATE_TICKET_SUCCESS,
} from "../../actions/action-types";

export interface TicketsState {
  ticket: any;
  tickets: any;
  types: any;
  comments: Record<string, any>;
  isFetchCommentsRequest: boolean;
  isCreateCommentRequest: boolean;
  isRequest: boolean;
  isCalendarUpdateRequest: boolean;
  isTicketsRequest: boolean;
  isUpdateRequest: boolean;
  isTypesRequest: boolean;
}
const initialState: TicketsState = {
  ticket: null,
  tickets: [],
  types: [],
  comments: {},
  isTypesRequest: false,
  isTicketsRequest: false,
  isRequest: false,
  isUpdateRequest: false,
  isCalendarUpdateRequest: false,
  isCreateCommentRequest: false,
  isFetchCommentsRequest: false,
};

export default (state = initialState, action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TICKET_REQUEST: {
      return {
        ...state,
        isRequest: payload,
      };
    }
    case SET_TICKET: {
      return {
        ...state,
        ticket: payload,
        isRequest: false,
      };
    }
    case FETCH_TICKET_FAILED: {
      return {
        ...state,
        isRequest: false,
      };
    }
    case FETCH_TICKET_SUCCESS: {
      return {
        ...state,
        ticket: payload,
        isRequest: false,
      };
    }

    case UPDATE_TICKET_REQUEST: {
      return {
        ...state,
        isUpdateRequest: true,
      };
    }
    case UPDATE_TICKET_SUCCESS: {
      return {
        ...state,
        ticket: payload,
        isUpdateRequest: false,
      };
    }
    case UPDATE_TICKET_FAILED: {
      return {
        ...state,
        isUpdateRequest: false,
      };
    }
    case FETCH_TICKETS_REQUEST: {
      return {
        ...state,
        isTicketsRequest: true,
      };
    }
    case FETCH_TICKETS_FAILED: {
      return {
        ...state,
        isTicketsRequest: false,
      };
    }
    case FETCH_TICKETS_SUCCESS: {
      return {
        ...state,
        tickets: payload,
        isTicketsRequest: false,
      };
    }
    case FETCH_TICKET_TYPES: {
      return {
        ...state,
        isTypesRequest: true,
      };
    }
    case FETCH_TICKET_TYPES_FAILED: {
      return {
        ...state,
        isTypesRequest: false,
      };
    }
    case FETCH_TICKET_TYPES_SUCCESS: {
      return {
        ...state,
        types: payload,
        isTypesRequest: false,
      };
    }
    case UPDATE_TICKET_DEADLINE_CALENDAR: {
      const index = state.tickets.findIndex(
        (ticket: any) => ticket.id === payload.id
      );
      return {
        ...state,
        tickets: [
          ...state.tickets.map((item: any, idx: number) => {
            if (idx !== index) {
              return item;
            }
            return { ...item, dueDate: payload.dueDate };
          }),
        ],
        isCalendarUpdateRequest: true,
      };
    }
    case UPDATE_TICKET_DEADLINE_CALENDAR_SUCCESS: {
      return {
        ...state,
        isCalendarUpdateRequest: false,
      };
    }
    case UPDATE_TICKET_DEADLINE_CALENDAR_FAILED: {
      return {
        ...state,
        isCalendarUpdateRequest: false,
      };
    }
    case FETCH_COMMENTS: {
      return {
        ...state,
        isFetchCommentsRequest: payload,
      };
    }
    case FETCH_COMMENTS_FAILED: {
      return {
        ...state,
        isFetchCommentsRequest: false,
      };
    }
    case FETCH_COMMENTS_SUCCESS: {
      return {
        ...state,
        comments: { ...state.comments, [payload.id]: payload.data },
        isFetchCommentsRequest: false,
      };
    }
    case SET_COMMENTS: {
      return {
        ...state,
        comments: { ...state.comments, [payload.id]: payload.data },
      };
    }

    case CREATE_COMMENT: {
      return {
        ...state,
        isCreateCommentRequest: true,
      };
    }

    case CREATE_COMMENT_FAILED: {
      return {
        ...state,
        isCreateCommentRequest: false,
      };
    }

    case CREATE_COMMENT_SUCCESS: {
      return {
        ...state,
        isCreateCommentRequest: false,
      };
    }

    default:
      return state;
  }
};
