import React, { useState } from "react";
import { Button, Popconfirm, Tooltip } from "antd";
import { DeleteOutlined, StopOutlined } from "@ant-design/icons";

interface ILeaveTicketButton {
  title: string;
  onConfirm: (callback: () => void) => void;
  okText: string;
  cancelText: string;
  tooltipText: string;
  additionalClass?: string;
}

const defaultProps = {
  additionalClass: "",
};

const LeaveTicketButton = (props: ILeaveTicketButton): JSX.Element => {
  const [visible, setVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const {
    title,
    onConfirm,
    okText,
    cancelText,
    tooltipText,
    additionalClass,
  } = props;

  const handleConfirm = () => {
    setLoading(true);
    onConfirm(() => {
      setVisible(false);
      setLoading(false);
    });
  };

  return (
    <Popconfirm
      visible={visible}
      placement="topLeft"
      title={title}
      style={{ maxWidth: "300px" }}
      okButtonProps={{ loading: isLoading }}
      onConfirm={handleConfirm}
      okText={okText}
      cancelText={cancelText}
      onCancel={() => setVisible(false)}
    >
      <Tooltip title={tooltipText}>
        <Button
          icon={<StopOutlined />}
          className={additionalClass}
          onClick={() => setVisible(true)}
        />
      </Tooltip>
    </Popconfirm>
  );
};

LeaveTicketButton.defaultProps = defaultProps;
export default LeaveTicketButton;
