import React, {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { Form, Input, Slider, SubmitButton } from "formik-antd";
import { Row } from "antd";
import { Formik, FormikErrors, FormikValues } from "formik";
import * as Yup from "yup";
import { openNotificationWithIcon } from "../../../../utils/common";
import { IQuickProgressFormValues } from "../../../../types/quick-forms";
import "moment/locale/pl";

const ProgressForm: React.FC<any> = (
  props: PropsWithChildren<any>
): JSX.Element => {
  const { progress, onSubmit, setPopoverVisible } = props;
  const formikRef = useRef<FormikValues>();

  const initialFormValues: IQuickProgressFormValues = {
    progress,
    comment: null,
  };

  const [formData, setFormData] = useState<IQuickProgressFormValues>(
    initialFormValues
  );

  useEffect(() => {
    setFormData({
      progress,
      comment: null,
    });
    formikRef.current?.resetForm();
  }, [progress]);

  const Schema = Yup.object().shape({});

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef as MutableRefObject<any>}
      initialValues={formData}
      validationSchema={Schema}
      onSubmit={(
        values: IQuickProgressFormValues,
        { resetForm, setErrors, setSubmitting }
      ) => {
        onSubmit(
          values,
          () => {
            setPopoverVisible(false);
            setSubmitting(false);
            openNotificationWithIcon("success", "Postęp zaktualizowany");
          },
          (err: FormikErrors<any>) => {
            setErrors(err);
            setSubmitting(false);
          }
        );
      }}
      validateOnChange
      render={() => (
        <Form>
          <Form.Item
            label="Postęp"
            name="progress"
            labelCol={{ span: 24 }}
            style={{ width: "200px" }}
          >
            <Slider defaultValue={30} name="progress" fast />
          </Form.Item>
          <Form.Item
            label="Komentarz"
            name="comment"
            labelCol={{ span: 24 }}
            style={{ width: "200px" }}
          >
            <Input.TextArea name="comment" style={{ width: "100%" }} />
          </Form.Item>
          <Row justify="center">
            <SubmitButton type="primary">Zapisz</SubmitButton>
          </Row>
        </Form>
      )}
    />
  );
};

export default ProgressForm;
