import { useCallback, useRef } from "react";

const useRefs = () => {
  const refs = useRef<any>({});

  const register = useCallback(
    (refName: any) => (ref: any) => {
      refs.current[refName] = ref;
    },
    []
  );

  return [refs, register] as const;
};

export default useRefs;
