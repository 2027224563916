import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { Form } from "formik-antd";
import { Col, Divider, Row } from "antd";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import RemoveRecord from "../../../../Shared/RemoveRecord";

export interface ITicketGroupMemberFormProps {
  userId: string | number;
  name: string;
  surname: string;
  isLoading: boolean;
  onRemove: (userId: string | number, onRequestComplete: () => void) => void;
}

export interface ITicketGroupMemberFormValues {
  userId: string | number;
}

const TicketGroupMemberForm = (props: ITicketGroupMemberFormProps) => {
  const { userId, name, surname, isLoading, onRemove } = props;

  const initialFormValues: ITicketGroupMemberFormValues = {
    userId: "",
  };
  const [formData, setFormData] = useState<ITicketGroupMemberFormValues>(
    initialFormValues
  );

  const [editRequest, setEditRequest] = useState<boolean>(false);

  useEffect(() => {
    setFormData({
      userId,
    });
  }, [userId]);

  const formikRef = useRef<FormikValues>();

  const FormSchema = Yup.object().shape({});

  const title = `${name} ${surname}`;
  return (
    <>
      <Formik
        innerRef={formikRef as MutableRefObject<any>}
        initialValues={formData}
        enableReinitialize
        validationSchema={FormSchema}
        onSubmit={(
          values: ITicketGroupMemberFormValues,
          { resetForm, setErrors }
        ) => {
          setEditRequest(true);
        }}
        validateOnChange
        render={() => (
          <Form>
            <Row gutter={16} align="middle" justify="space-around">
              <Col xs={20} md={20} lg={22} xl={22} xxl={22}>
                <p className="leaders__name">{title}</p>
              </Col>
              <Col xs={4} md={4} lg={2} xl={2} xxl={2}>
                <RemoveRecord
                  title="Czy napewno chcesz usunąć przydzieloną osobę?"
                  onConfirm={(callbackHidePopover: () => void) =>
                    onRemove(userId, callbackHidePopover)
                  }
                  okText="Usuń"
                  cancelText="Anuluj"
                  tooltipText="Usuń"
                />
              </Col>
            </Row>
          </Form>
        )}
      />
      <Divider style={{ marginBottom: 8, marginTop: 8 }} />
    </>
  );
};

export default TicketGroupMemberForm;
