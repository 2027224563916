import React, { PropsWithChildren, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "moment/locale/pl";

import { Badge, Button, Card, Col, Drawer, Row, Space, Tooltip } from "antd";

import { FilterOutlined } from "@ant-design/icons";
import moment, { Moment } from "moment";
import { Helmet } from "react-helmet";
import { AppState } from "../../../reducers";
import List from "./list";
import CalendarPicker, {
  CalendarFilterOptions,
} from "../../Shared/CustomFilters/CalendarPicker";
import { getTicketGroupWorktimesSummary } from "../../../actions/worktimes";
import { getTicketGroups } from "../../../actions/ticketgroups";
import WorktimeGroups from "../../Shared/CustomFilters/WorktimeGroups";
import Can from "../../Shared/Can";

const TicketGroupsWorktimesPage = (
  props: PropsWithChildren<any>
): JSX.Element => {
  const [filterVisible, setFilterVisible] = React.useState(false);
  const [deadlinesVisible, setDeadlinesVisible] = React.useState(false);
  const {
    summary,
    groups,
    groupsRequest,
    summaryRequest,
    getTicketGroupsWorktimesSummaryAction,
    getTicketGroupsAction,
    isMobile,
  } = props;

  const [filter, setFilter] = React.useState<{
    begin: number;
    end: number;
    group: number[];
    selectedType: CalendarFilterOptions;
  }>({
    begin: moment().startOf("month").unix(),
    end: moment().endOf("month").unix(),
    group: [],
    selectedType: CalendarFilterOptions.THIS_MONTH,
  });

  const filterRef = useRef();

  useEffect(() => {
    getTicketGroupsAction(false);
  }, [getTicketGroupsAction]);

  useEffect(() => {
    getTicketGroupsWorktimesSummaryAction(
      filter.begin,
      filter.end,
      filter.group
    );
  }, [
    filter.begin,
    filter.end,
    filter.group,
    getTicketGroupsWorktimesSummaryAction,
  ]);

  return (
    <Can renderError type="admin_view">
      <>
        <div className="ticket-group-page">
          <Row gutter={[0, 0]}>
            <Col xs={24} sm={24} lg={24} xl={24} xxl={24}>
              <Space
                direction="vertical"
                style={{ marginLeft: "auto", width: "100%" }}
              >
                <Card bordered={false} title="Czas pracy w działach">
                  <Row justify="space-between" gutter={[16, 16]}>
                    <Space>
                      <CalendarPicker
                        ref={filterRef}
                        begin={filter.begin}
                        end={filter.end}
                        selectedType={filter.selectedType}
                        onChange={(
                          begin: Moment,
                          end: Moment,
                          selectedType: CalendarFilterOptions
                        ) => {
                          setFilter({
                            begin: begin.unix(),
                            end: end.unix(),
                            group: [...filter.group],
                            selectedType,
                          });
                        }}
                      />
                    </Space>
                    <Space align="end">
                      <Tooltip title="Filtry">
                        <Badge
                          count={filter.group?.length}
                          size="small"
                          className="ant-badge-round"
                        >
                          <Button
                            type={filter.group.length ? "primary" : "default"}
                            icon={<FilterOutlined />}
                            onClick={() => setFilterVisible(true)}
                          />
                        </Badge>
                      </Tooltip>
                    </Space>
                  </Row>
                </Card>
                <Card bordered={false}>
                  <List
                    data={summary}
                    isRequest={summaryRequest}
                    isMobile={isMobile}
                  />
                </Card>
              </Space>
            </Col>
          </Row>
        </div>
        <Drawer
          title="Filtry"
          placement="right"
          closable={false}
          destroyOnClose
          visible={filterVisible}
          key="left"
          onClose={() => setFilterVisible(false)}
          className="filter-drawer"
        >
          <WorktimeGroups
            groups={groups.filter((group: any) => !group.break)}
            isLoading={groupsRequest}
            checked={filter.group}
            handleSubmit={(value: number[]) => {
              setFilter({
                begin: filter.begin,
                end: filter.end,
                group: value,
                selectedType: filter.selectedType,
              });
              setFilterVisible(false);
            }}
            handleClear={(value: number[]) => {
              setFilter({
                begin: filter.begin,
                end: filter.end,
                group: value,
                selectedType: filter.selectedType,
              });
              setFilterVisible(false);
            }}
          />
        </Drawer>
      </>
    </Can>
  );
};

const mapDispatchToProps = {
  getTicketGroupsWorktimesSummaryAction: getTicketGroupWorktimesSummary,
  getTicketGroupsAction: getTicketGroups,
};

const mapStateToProps = (state: AppState) => {
  return {
    summary: state.worktime.ticketGroupSummary,
    groups: state.ticketgroups.ticketgroups,
    groupsRequest: state.ticketgroups.isFetchTicketGroupsRequest,
    summaryRequest: state.worktime.ticketGroupSummaryRequest,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketGroupsWorktimesPage);
