import React, { MutableRefObject, PropsWithChildren, useRef } from "react";
import { Checkbox, Form, SubmitButton } from "formik-antd";
import { Button, Col, Row, Spin } from "antd";
import { Formik, FormikProps, FormikValues } from "formik";
import "moment/locale/pl";
import { CheckboxChangeEvent } from "antd/es/checkbox";

const WorktimeGroups: React.FC<any> = (
  props: PropsWithChildren<any>
): JSX.Element => {
  const formikRef = useRef<FormikValues>();
  const { handleSubmit, handleClear, checked, groups, isLoading } = props;

  const [checkedList, setCheckedList] = React.useState(checked);
  const [checkAll, setCheckAll] = React.useState(
    groups.length && checked.length === groups.length
  );

  const onChange = (list: any[]) => {
    setCheckAll(list.length === groups.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    formikRef.current?.setFieldValue(
      "group",
      e.target.checked ? groups.map((group: any) => group.id) : []
    );
    setCheckAll(e.target.checked);
  };

  const initialFormValues: any = {
    group: checked,
  };

  return (
    <Formik
      innerRef={formikRef as MutableRefObject<any>}
      initialValues={initialFormValues}
      onSubmit={(values: any, { resetForm, setErrors }) => {
        handleSubmit(values.group);
      }}
      validateOnChange
      render={(formProps: FormikProps<any>) => (
        <Spin spinning={isLoading} className="worktime-filter__spinner">
          <Form>
            <Form.Item
              required
              name="group"
              labelCol={{ span: 24 }}
              className="worktime-filter"
            >
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Checkbox
                    onChange={onCheckAllChange}
                    checked={checkAll}
                    name="all"
                    className="worktime-filter__select-all"
                  >
                    Zaznacz wszystkie
                  </Checkbox>
                </Col>
              </Row>

              <Checkbox.Group
                name="group"
                onChange={onChange}
                className="worktime-filter__scrollable"
              >
                <Row gutter={[8, 8]}>
                  {groups.map((group: any) => (
                    <Col span={24}>
                      <Checkbox name="group" value={group.id}>
                        {group.name}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>

            <Row justify="space-between" className="worktime-filter__options">
              <Button
                type="text"
                disabled={!formProps.values.group.length}
                onClick={() => {
                  formProps.resetForm();
                  handleClear([]);
                }}
              >
                Wyczyść
              </Button>
              <SubmitButton loading={false}>Zapisz</SubmitButton>
            </Row>
          </Form>
        </Spin>
      )}
    />
  );
};

export default WorktimeGroups;
