// TODO: display errors while name is not unique (backend/front - create/edit)
import React, { PropsWithChildren, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, Col, Divider, Empty, Row, Spin, Tabs } from "antd";
import { RcFile } from "antd/es/upload";
import { AxiosError, AxiosResponse } from "axios";
import { FormikErrors } from "formik";
import { Helmet } from "react-helmet";
import { AppState } from "../../../reducers";
import {
  bulkAddTicketGroupMembership,
  editOwnUser,
  editUser,
  getTags,
  getTicketGroupMembership,
  getUser,
  removeAvatar,
  removeOwnAvatar,
  setUser,
  uploadAvatar,
  uploadOwnAvatar,
} from "../../../actions/users";
import { getRolesGroups } from "../../../actions/roles";
import UserBasicForm from "./EditUserForm/BasicForm";
import {
  openNotificationWithIcon,
  transformToFormikError,
} from "../../../utils/common";
import UserPasswordForm from "./EditUserForm/PasswordForm";

import { RoleGroup, RoleModel } from "../../../reducers/roles";
import Can from "../../Shared/Can";
import { MetaTitle } from "../../Shared/MetaTitle";
import useIsMobile from "../../../hooks/useIsMobile";
import TicketGroupMemberForm from "../TicketGroups/EditTicketGroupForm/MemberForm";
import TicketGroupAddMembersForm, {
  ITicketGroupAddMember,
  ITicketGroupAddMembersFormValues,
} from "../TicketGroups/EditTicketGroupForm/AddMemberForm";
import {
  bulkAddTicketGroupMembers,
  getTicketGroups,
  removeTicketGroupMember,
} from "../../../actions/ticketgroups";

import { setCurrentEditedUser } from "../../../actions/global";
import { setLoggedUser } from "../../../actions/auth";

const { TabPane } = Tabs;

interface IRouteParams {
  id: string;
}
interface IUsersPageProps {
  user?: any;
  tags: any[];
  groupsMembership: any[];
  groups: any[];
  roles: RoleGroup[];
  displayNotification: boolean;
  isEditRequest?: boolean;
  isGroupsMembershipRequest: boolean;
  getUser: (id: string | number) => void;
  getGroupsMembershipAction: (id: string | number) => void;
  removeTicketGroupMemberAction: (
    id: string | number,
    groupId: string | number
  ) => Promise<any>;
  getTagsAction: () => void;
  getRolesGroupsAction: () => void;
  setUserAction: (user: any) => void;
  setCurrentEditedUserAction: (name: string | null) => void;
  getTicketGroupsAction: (noLoader: boolean) => Promise<void>;
  uploadAvatarAction: (file: string | RcFile | Blob) => Promise<any>;
  setLoggedUserAction: (options: { archive: boolean }) => void;
  editUserAction: (user: any) => Promise<any>;
  removeAvatarAction: () => Promise<any>;
  bulkAddTicketGroupMembershipAction: (
    id: string | number,
    groups: number[]
  ) => Promise<any>;
}

const UserProfilePage = (
  props: PropsWithChildren<IUsersPageProps>
): JSX.Element => {
  const { id } = useParams<IRouteParams>();
  const isMobile = useIsMobile();
  const {
    user,
    tags,
    setCurrentEditedUserAction,
    setUserAction,
    uploadAvatarAction,
    editUserAction,
    removeAvatarAction,
    setLoggedUserAction,
    displayNotification,
  } = props;

  const onSave = (
    values: any,
    callbackRequestCompleted: () => void,
    setFormErrors: (errors: FormikErrors<any>) => void
  ) => {
    editUserAction({
      ...values,
    })
      .then((response: AxiosResponse) => {
        callbackRequestCompleted();
        setLoggedUserAction({ archive: true });
        openNotificationWithIcon("success", "Dane użytkownika zapisane");
      })
      .catch((err: AxiosError) => {
        callbackRequestCompleted();
        if (err.response?.status === 400) {
          const formikResponse = transformToFormikError(err);
          setFormErrors(formikResponse);
        }
      });
  };

  const onUpload = (
    file: RcFile | Blob | string,
    onRequestComplete: (url?: string) => void
  ) => {
    uploadAvatarAction(file)
      .then((response: AxiosResponse) => {
        onRequestComplete(response.data.img);
        setLoggedUserAction({ archive: true });
        openNotificationWithIcon("success", "Avatar został zapisany");
      })
      .catch((error: AxiosError) => {
        onRequestComplete();
        if (error.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił problem w trakcie zapisu avatara"
          );
        }
      });
  };

  const onAvatarRemove = (setRequestCompleted: () => void) => {
    removeAvatarAction()
      .then((response: AxiosResponse) => {
        setLoggedUserAction({ archive: true });
        openNotificationWithIcon("success", "Avatar został usunięty");
        setRequestCompleted();
      })
      .catch((error: AxiosError) => {
        setRequestCompleted();
        if (error.response?.status === 400) {
          openNotificationWithIcon(
            "error",
            "Wystąpił problem w trakcie usuwania avataru"
          );
        }
      });
  };

  useEffect(() => {
    return () => {
      setCurrentEditedUserAction(null);
      setUserAction(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.name) {
      setCurrentEditedUserAction(`${user.name} ${user.surname}`);
    }
  }, [setCurrentEditedUserAction, user]);

  return (
    <div className="user-edit-page">
      <MetaTitle
        title={
          user.name
            ? `${user.name} ${user.surname} - Edycja użytkownika`
            : "Edycja użytkownika"
        }
        displayBadge={displayNotification}
      />
      <Row gutter={16}>
        <Col span={24}>
          <Card
            bordered={false}
            style={{ width: "100%" }}
            size={isMobile ? "small" : "default"}
          >
            <Tabs defaultActiveKey="1">
              <TabPane tab="Dane podstawowe" key="1">
                <Row gutter={16} align="middle" justify="center">
                  <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                    <UserBasicForm
                      img={user.img}
                      email={user.email}
                      name={user.name}
                      surname={user.surname}
                      tag={user.tag}
                      tags={tags}
                      phone={user.phone}
                      typeAccount={user.typeAccount}
                      isLoading={false}
                      onUpload={onUpload}
                      onSave={onSave}
                      onAvatarRemove={onAvatarRemove}
                    />
                    <Divider />
                    <UserPasswordForm isLoading={false} onSave={onSave} />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapDispatchToProps = {
  getUser,
  setLoggedUserAction: setLoggedUser,
  uploadAvatarAction: uploadOwnAvatar,
  removeAvatarAction: removeOwnAvatar,
  editUserAction: editOwnUser,
  getTagsAction: getTags,
  getRolesGroupsAction: getRolesGroups,
  getTicketGroupsAction: getTicketGroups,
  getGroupsMembershipAction: getTicketGroupMembership,
  removeTicketGroupMemberAction: removeTicketGroupMember,
  bulkAddTicketGroupMembershipAction: bulkAddTicketGroupMembership,
  setCurrentEditedUserAction: setCurrentEditedUser,
  setUserAction: setUser,
};

const mapStateToProps = (state: AppState) => {
  return {
    user: state.auth.logged,
    tags: state.users.tags,
    groups: state.ticketgroups.ticketgroups,
    isFetchTicketGroupsRequest: state.ticketgroups.isFetchTicketGroupsRequest,
    groupsMembership: state.users.groupsMembership,
    isGroupsMembershipRequest: state.users.isGroupsMembershipRequest,
    isEditRequest: state.users.isEditRequest,
    roles: state.roles.roles,
    displayNotification: state.notifications.newNotificationIndicator,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage);
