import { AnyAction } from "redux";

import {
  LOGOUT,
  SET_LOGGED_USER,
  SIGN_UP_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
} from "../../actions/action-types";

export interface AuthState {
  token: string | null;
  isLogged: boolean;
  isRequest: boolean;
  logged: any;
}

const initialState: AuthState = {
  token: null,
  isLogged: !!localStorage.getItem("token"),
  isRequest: false,
  logged: null,
};

export default (state = initialState, action: AnyAction) => {
  const { type, payload } = action;
  // console.log(type, payload, "reducer - TYPE, PAYLOAD");
  switch (type) {
    case SIGN_UP_REQUEST: {
      return {
        ...state,
        isRequest: true,
      };
    }
    case SIGN_UP_SUCCESS: {
      return {
        ...state,
        isRequest: false,
        isLogged: true,
        token: payload.token,
      };
    }
    case SIGN_UP_FAILURE: {
      return {
        ...state,
        isRequest: false,
        isLogged: false,
        token: null,
        logged: null,
      };
    }
    case SET_LOGGED_USER: {
      return {
        ...state,
        logged: payload,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isLogged: false,
        token: null,
        logged: null,
      };
    }
    default:
      return state;
  }
};
