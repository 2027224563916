import { Button, Modal, Popover } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import DueDateForm from "./DueDateForm";
import Can from "../../../Shared/Can";
import AddWorkTimeForm from "./AddWorkTimeForm";

const AddWorktimePopover = (props: any): JSX.Element => {
  const { ticket, onSubmit } = props;
  const [visible, setVisible] = useState<boolean>(false);

  const AddWorkTime = Can({
    entity: ticket,
    type: "worktime_add_time",
    children: (
      <>
        <Button icon={<PlusOutlined />} onClick={() => setVisible(true)} />
        <Modal
          onCancel={() => setVisible(false)}
          visible={visible}
          title="Dodaj czas pracy"
          footer={null}
        >
          <AddWorkTimeForm onSubmit={onSubmit} setPopoverVisible={setVisible} />
        </Modal>
      </>
    ),
  });
  return <>{AddWorkTime}</>;
};

export default AddWorktimePopover;
