import { AxiosResponse } from "axios";
import api from "../axiosInstance";

class DashboardService {
  getStats = async (): Promise<AxiosResponse> => {
    const response = await api.get("dashboard/stats");
    return response;
  };

  getWorktimes = async (): Promise<AxiosResponse> => {
    const response = await api.get("dashboard/worktimes");
    return response;
  };

  getUserActivity = async (): Promise<AxiosResponse> => {
    const response = await api.get("dashboard/users");
    return response;
  };

  getActiveWorktimes = async (): Promise<AxiosResponse> => {
    const response = await api.get("dashboard/active-worktimes");
    return response;
  };
}
export default new DashboardService();
