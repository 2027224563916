import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Card, Col, Row, Space, Table, Tag } from "antd";
import Text from "antd/es/typography/Text";
import moment from "moment";
import { Skeleton } from "antd/es";
import {
  makeAvatarName,
  renderLastActivity,
  stringToColour,
} from "../../../utils/common";

const UsersOnline = (props: any): JSX.Element => {
  const { isLoading, data, isMobile } = props;
  interface IUserAvatar {
    image: string | null;
    avatarColor: string;
    avatarBackground: string;
    name: string;
    surname: string;
  }

  const dataSource = data?.map((user: any) => {
    const row = {
      key: user.id,
      name: user.name,
      surname: user.surname,
      isOnline: user.isOnline,
      isIdle: user.isIdle,
      // eslint-disable-next-line no-nested-ternary
      firstActive: user.firstActivity?.date
        ? moment(user.firstActivity.date * 1000)
        : 0,
      // eslint-disable-next-line no-nested-ternary
      lastActive: user.lastActivity?.date
        ? moment(user.lastActivity.date * 1000)
        : 0,
      lastActivity: user.lastActivity,
      avatar: {
        image: user.img,
        avatarColor: "white",
        avatarBackground: stringToColour(`${user.email}`),

        name: user.name,
        surname: user.surname,
      },
    };

    return row;
  });

  const UserAvatar = (user: IUserAvatar) => {
    const { image, avatarColor, avatarBackground, name, surname } = user;
    return (
      <Avatar
        size={40}
        src={image || null}
        className="user-app__avatar"
        style={{
          color: avatarColor,
          backgroundColor: avatarBackground,
          borderColor: "#fefefe",
          borderWidth: 1,
          borderStyle: "solid",
        }}
      >
        {makeAvatarName(false, name, surname)}
      </Avatar>
    );
  };

  let columns = [
    {
      title: "",
      dataIndex: "avatar",
      hidden: !isMobile,
      key: "mobileRow",
      render: (user: IUserAvatar, row: any) => {
        const { image, avatarColor, avatarBackground, name, surname } = user;
        return (
          <>
            <Row
              style={{ width: "100%" }}
              justify="space-between"
              align="middle"
            >
              <Space>
                <UserAvatar
                  image={image}
                  avatarColor={avatarColor}
                  avatarBackground={avatarBackground}
                  name={name}
                  surname={surname}
                />
              </Space>
              <Text
                style={{ marginLeft: 16, marginRight: "auto" }}
              >{`${row.name} ${row.surname}`}</Text>
              <div>
                {row.isOnline ? (
                  <Tag color="green">
                    {row.firstActive
                      ? moment(row.firstActive).format("HH:mm")
                      : "Brak"}
                  </Tag>
                ) : (
                  <Tag color={row.isIdle ? "orange" : "red"}>
                    {row.firstActive
                      ? moment(row.firstActive).format("HH:mm")
                      : "Brak"}
                  </Tag>
                )}
              </div>
            </Row>

            <Row
              style={{ width: "100%" }}
              justify="space-between"
              align="middle"
            >
              <Text style={{ paddingLeft: 56 }}>
                <Row>
                  <Col span={24}>
                    <small>
                      {row.lastActivity
                        ? renderLastActivity(row.lastActivity)
                        : null}
                    </small>
                  </Col>
                  <Col span={24}>
                    <small>
                      {row.lastActive
                        ? moment(row.lastActive).format("DD.MM.YYYY HH:mm")
                        : "Brak"}
                    </small>
                  </Col>
                </Row>
              </Text>
            </Row>
          </>
        );
      },
      width: "100%",
    },
    {
      title: "",
      dataIndex: "avatar",
      hidden: isMobile,
      key: "avatar",
      render: (user: IUserAvatar) => {
        const { image, avatarColor, avatarBackground, name, surname } = user;
        return (
          <UserAvatar
            image={image}
            avatarColor={avatarColor}
            avatarBackground={avatarBackground}
            name={name}
            surname={surname}
          />
        );
      },
      width: 60,
    },
    {
      title: "Imię Nazwisko",
      hidden: isMobile,
      dataIndex: "name",
      key: "name",
      minWidth: 130,
      ellipsis: true,
      render: (name: string, user: any) => {
        return `${name} ${user.surname}`;
      },
    },
    {
      title: (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
          }}
        >
          Pierwsza aktywność
        </div>
      ),
      hidden: isMobile,
      dataIndex: "firstActive",
      key: "firstActive",
      ellipsis: false,
      render: (firstActive: number, record: any) => {
        if (record.isOnline)
          return (
            <Tag color="green">
              {firstActive
                ? moment(firstActive).format("DD.MM.YYYY HH:mm")
                : "Brak"}
            </Tag>
          );
        return (
          <Tag color={record.isIdle ? "orange" : "red"}>
            {firstActive
              ? moment(firstActive).format("DD.MM.YYYY HH:mm")
              : "Brak"}
          </Tag>
        );
      },
    },
    {
      title: (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
          }}
        >
          Ost. aktywność
        </div>
      ),
      dataIndex: "lastActive",
      key: "lastActive",
      hidden: isMobile,
      ellipsis: false,
      render: (lastActive: number, record: any) => {
        return (
          <Text>
            <Row>
              <Col span={24}>
                <small>
                  {record.lastActivity
                    ? renderLastActivity(record.lastActivity)
                    : null}
                </small>
              </Col>
              <Col span={24}>
                <small>
                  {lastActive
                    ? moment(lastActive).format("DD.MM.YYYY HH:mm")
                    : "Brak"}
                </small>
              </Col>
            </Row>
          </Text>
        );
      },
    },
  ];

  columns = columns.filter((row: any) => !row.hidden);

  return (
    <Card
      title="Ostatnio online"
      className="users-online"
      bodyStyle={{ padding: isLoading ? 20 : 0 }}
      extra={<Link to="/users">Pokaż więcej</Link>}
    >
      {!isLoading ? (
        <Table
          locale={{ emptyText: "Brak danych" }}
          dataSource={dataSource}
          className="users-online__table"
          columns={columns}
          pagination={false}
          scroll={{ y: 365 }}
        />
      ) : (
        <Skeleton active paragraph />
      )}
    </Card>
  );
};

export default UsersOnline;
