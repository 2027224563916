import { Dispatch } from "redux";
import { AxiosError } from "axios";
import TaskGroupsService from "../../services/taskgroups-service";
import {
  FETCH_TICKET_GROUP_FAILED,
  FETCH_TICKET_GROUP_HOME_FAILED,
  FETCH_TICKET_GROUP_HOME_REQUEST,
  FETCH_TICKET_GROUP_HOME_SUCCESS,
  FETCH_TICKET_GROUP_REQUEST,
  FETCH_TICKET_GROUP_SUCCESS,
  FETCH_TICKET_GROUPS_HOME_FAILED,
  FETCH_TICKET_GROUPS_HOME_REQUEST,
  FETCH_TICKET_GROUPS_HOME_SUCCESS,
  SET_CURRENT_ENTITY_FORBIDDEN,
} from "../action-types";

export const getTicketGroups = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_TICKET_GROUPS_HOME_REQUEST,
    payload: true,
  });
  return TaskGroupsService.getGroupsHome().then(
    (response) => {
      dispatch({
        type: FETCH_TICKET_GROUPS_HOME_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve(response.data);
    },
    (error) => {
      dispatch({
        type: FETCH_TICKET_GROUPS_HOME_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const getTicketGroup = (id: string | number) => (
  dispatch: Dispatch<any>
) => {
  dispatch({
    type: FETCH_TICKET_GROUP_HOME_REQUEST,
    payload: true,
  });
  return TaskGroupsService.getGroupHome(id).then(
    (response) => {
      dispatch({
        type: FETCH_TICKET_GROUP_HOME_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error: AxiosError) => {
      dispatch({
        type: FETCH_TICKET_GROUP_HOME_FAILED,
      });

      return Promise.reject();
    }
  );
};
