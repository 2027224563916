import { Dispatch } from "redux";
import RolesService from "../../services/roles-service";

import {
  FETCH_ROLES_FAILED,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
} from "../action-types";

export const getRoles = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_ROLES_REQUEST,
    payload: true,
  });
  return RolesService.getRoles().then(
    (response) => {
      dispatch({
        type: FETCH_ROLES_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_ROLES_FAILED,
      });
      return Promise.reject();
    }
  );
};

export const getRolesGroups = () => (dispatch: Dispatch<any>) => {
  dispatch({
    type: FETCH_ROLES_REQUEST,
    payload: true,
  });
  return RolesService.getRolesGroups().then(
    (response) => {
      dispatch({
        type: FETCH_ROLES_SUCCESS,
        payload: response.data,
      });
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: FETCH_ROLES_FAILED,
      });
      return Promise.reject();
    }
  );
};

export default getRoles;
