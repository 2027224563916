/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Spin } from "antd";
import {
  ArrowDownOutlined,
  Loading3QuartersOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import classnames from "classnames";
import ReactDOM from "react-dom";
import MessageBubble from "./MessageBubble";

import {
  getMobileOperatingSystem,
  isInstalledIOS,
} from "../../../utils/common";

import ChatReactions from "./ChatReactions";

interface IProps {
  linkMessageId: string;
  messages: any;
  messagesLength: number;
  loggedUser: any;
  isFetching: boolean;
  users: any;
  selectedRoom: number | null;
  isArchiveFetching: boolean;
  getArchiveMessages: (timestamp: number) => void;
  openImagePreview: (fileId: number) => void;
  postMessageReaction: (messageId: string | number, reaction: string) => void;
}

const SCROLL_TO_BOTTOM_OFFSET = -200;

export const MessagesList = (props: IProps) => {
  const {
    linkMessageId,
    messages,
    users,
    selectedRoom,
    loggedUser,
    isFetching,
    messagesLength,
    getArchiveMessages,
    isArchiveFetching,
    openImagePreview,
    postMessageReaction,
  } = props;
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const messagesListRef = useRef<null | HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrolledToLink, setScrolledToLink] = useState<string | null>(null);

  const checkIsScrolled = (
    scrollTop?: number,
    scrollHeight?: number,
    offsetHeight?: number
  ) => {
    if (
      typeof scrollTop !== "undefined" &&
      typeof scrollHeight !== "undefined" &&
      typeof offsetHeight !== "undefined"
    ) {
      setIsScrolled(scrollTop <= SCROLL_TO_BOTTOM_OFFSET);
    }
  };

  const debounceGetArchiveMessages = useMemo(
    () => _.debounce(getArchiveMessages, 100),
    [getArchiveMessages]
  );

  const fetchArchiveMessages = () => {
    let oldestMessageTimestamp: number = new Date().getTime();
    if (messages.length) {
      oldestMessageTimestamp = messages[messages.length - 1][0].dateAdd;
    }

    if (!isArchiveFetching) {
      debounceGetArchiveMessages(oldestMessageTimestamp);
    }
  };

  const onMessagesListScroll = (event: any) => {
    checkIsScrolled(
      messagesListRef.current?.scrollTop,
      messagesListRef.current?.scrollHeight,
      messagesListRef.current?.offsetHeight
    );
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      requestAnimationFrame(() => {
        const platform = getMobileOperatingSystem();
        if (platform === "iOS") messagesListRef.current?.scrollTo({ top: 0 });
        else messagesListRef.current?.scrollTo({ top: 0 });
      });
    }, 50);
  };

  const scrollToLinkedMessage = () => {
    const linkedMessageElement = document.getElementById(
      `bubble-${linkMessageId}`
    );
    const messageElement = linkedMessageElement?.closest(".chat__message");

    if (!linkedMessageElement) return;

    setTimeout(() => {
      requestAnimationFrame(() => {
        const platform = getMobileOperatingSystem();
        if (platform === "iOS") linkedMessageElement.scrollIntoView(true);
        else
          linkedMessageElement.scrollIntoView({
            block: "center",
          });

        setScrolledToLink(linkMessageId);
      });
    }, 50);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      if (linkMessageId && scrolledToLink !== linkMessageId) {
        scrollToLinkedMessage();
        return;
      }

      if (!isScrolled) {
        scrollToBottom();
      }
    });
  }, [messagesLength, linkMessageId]);

  useEffect(() => {
    requestAnimationFrame(() => {
      if (!isScrolled && !linkMessageId) {
        scrollToBottom();
      }
    });
  }, [selectedRoom]);

  return (
    <div
      id="scrollableChatList"
      style={{
        display: "flex",
        flexDirection: "column-reverse",
        overflow: "auto",
      }}
      className={classnames({
        chat__list: true,
        "chat__list--ios":
          !isInstalledIOS() && getMobileOperatingSystem() === "iOS",
        "chat__list--ios-installed": isInstalledIOS(),
        "chat__list--android": getMobileOperatingSystem() === "Android",
      })}
      ref={messagesListRef}
    >
      {isFetching && !messages.length && (
        <div className="chat__preload">
          <Spin />
        </div>
      )}
      <InfiniteScroll
        onScroll={onMessagesListScroll}
        dataLength={messages.length}
        next={fetchArchiveMessages}
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          overflowY: "visible",
          overflowX: "hidden",
        }}
        scrollThreshold="200px"
        inverse
        hasMore
        endMessage="TO KONIEC"
        loader={
          isFetching && messages.length === 0 ? null : (
            <div className="chat__archive-spinner-container">
              <LoadingOutlined className="chat__archive-spinner" spin />
            </div>
          )
        }
        scrollableTarget="scrollableChatList"
      >
        {isScrolled && (
          <Button
            onClick={scrollToBottom}
            className="chat__scroll-to-bottom"
            type="primary"
            shape="circle"
            size="middle"
            icon={<ArrowDownOutlined />}
          />
        )}

        {messages.map((message: any) => {
          const msg = message[message.length - 1];
          return (
            <MessageBubble
              key={msg.id}
              messages={message}
              author={msg.user}
              isOwn={loggedUser.id === msg.user.id}
              isOnline={
                users.findIndex(
                  (usr: any) => usr.id === msg.user.id && usr.isOnline
                ) >= 0
              }
              isIdle={
                users.findIndex(
                  (usr: any) => usr.id === msg.user.id && usr.isIdle
                ) >= 0
              }
              date={msg.dateAdd}
              linkMessageId={linkMessageId}
              openImagePreview={openImagePreview}
              postMessageReaction={postMessageReaction}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
};
