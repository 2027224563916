import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import { IssuesCloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { TicketStatus } from "../../../../types/ticket";
import { openNotificationWithIcon } from "../../../../utils/common";

const CloseTicket = (props: any) => {
  const { onSubmit } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Tooltip title="Zamknij wątek">
      <Button
        icon={
          isLoading ? (
            <LoadingOutlined spin />
          ) : (
            <IssuesCloseOutlined style={{ color: "red" }} />
          )
        }
        onClick={() => {
          setIsLoading(true);
          onSubmit(
            {
              statusId: TicketStatus.CLOSED,
            },
            () => {
              openNotificationWithIcon("success", "Wątek zamknięty");
              setIsLoading(false);
            },
            () => setIsLoading(false)
          );
        }}
      />
    </Tooltip>
  );
};

export default CloseTicket;
