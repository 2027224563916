/* eslint-disable prefer-promise-reject-errors,no-plusplus */
import {
  AudioContext as AudioCtx,
  IAudioContext,
} from "standardized-audio-context";
import notificationSound from "../../assets/audio/notification.mp3";
import messageSound from "../../assets/audio/message.mp3";

class AudioService {
  private static instance: AudioService;

  private audioContext: IAudioContext | undefined;

  private sounds = [notificationSound, messageSound];

  constructor() {
    if (AudioService.instance) {
      throw new Error("Error - use Singleton.getInstance()");
    }

    this.audioContext = new AudioCtx();
    this.unlock();
  }

  static getInstance(): AudioService {
    AudioService.instance = AudioService.instance || new AudioService();
    return AudioService.instance;
  }

  private getBuffer(audio: string): Promise<AudioBuffer> {
    return new Promise((resolve, reject) => {
      fetch(audio)
        .then((response) => {
          if (!response) reject("Bad data");

          return response.arrayBuffer();
        })
        .then((arrayBuffer) => {
          if (!this.audioContext) {
            return;
          }
          this.audioContext.decodeAudioData(
            arrayBuffer.slice(0),
            (decodedBuffer) => {
              resolve(decodedBuffer);
            },
            (err) => reject(err)
          );
        });
    });
  }

  unlock() {
    const unlock = () => {
      if (!this.audioContext) return;
      const buffer = this.audioContext.createBuffer(1, 1, 22050);
      const source = this.audioContext.createBufferSource();
      source.buffer = buffer;
      source.connect(this.audioContext.destination);
      if (source.start) {
        source.start(0);
      }
      document.body.removeEventListener("click", unlock);
      document.body.removeEventListener("touchstart", unlock);
      document.body.removeEventListener("touchend", unlock);
    };
    document.body.addEventListener("click", unlock);
    document.body.addEventListener("touchstart", unlock);
    document.body.addEventListener("touchend", unlock);
  }

  play(type: "notification" | "message") {
    this.getBuffer(
      type === "notification" ? notificationSound : messageSound
    ).then((buffer) => {
      if (!this.audioContext) return;
      this.audioContext.resume().then(() => {
        if (!this.audioContext) return;
        const source = this.audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(this.audioContext.destination);
        source.start();
      });
    });
  }
}

export default AudioService;
