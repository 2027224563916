import { Button, Modal, Space } from "antd";
import {
  CaretRightOutlined,
  LoadingOutlined,
  PauseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { timeElapsedFromTimestamp } from "../../../utils/common";
import DueDatePopover from "../../Pages/Ticket/QuickForms/DueDatePopover";
import AddWorkTimePopover from "../../Pages/Ticket/QuickForms/AddWorkTimePopover";
import EndWorkTimeModal from "../../Pages/Ticket/QuickForms/EndWorkTimeModal";
import Can from "../Can";
import AddWorkTimeForm from "../../Pages/Ticket/QuickForms/AddWorkTimeForm";

const ToggleTimeButton = (props: any) => {
  const [taskTimer, setTaskTimer] = useState(0);
  const {
    activeWorktime,
    ticketId,
    handleEndWorktime,
    handleStartWorktime,
    handleAddWorktime,
    loading,
  } = props;

  useEffect(() => {
    let interval: any;
    if (activeWorktime) {
      interval = setInterval(() => setTaskTimer(Date.now()), 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [activeWorktime]);

  if (!activeWorktime) {
    return (
      <Space style={{ marginTop: 16 }}>
        <Button
          icon={loading ? <LoadingOutlined spin /> : <CaretRightOutlined />}
          type="primary"
          onClick={() => handleStartWorktime(ticketId)}
        >
          Start czasu pracy
        </Button>
        <AddWorkTimePopover onSubmit={handleAddWorktime} />
      </Space>
    );
  }

  if (activeWorktime && activeWorktime?.ticket?.id === ticketId) {
    return (
      <>
        <EndWorkTimeModal
          onSubmit={handleEndWorktime}
          activeWorktime={activeWorktime}
          loading={loading}
        />
      </>
    );
  }
  return null;
};

export default ToggleTimeButton;
