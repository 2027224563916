import React from "react";
import { Space } from "antd";
import LastVisited from "./LastVisited";
import SearchResults from "./SearchResults";

interface ISearchBox {
  open: boolean;
  lastVisited: any;
  lastVisitedLoading: boolean;
  results: any;
  resultsVisible: boolean;
  onRowClick: () => void;
}

const SearchBox = (props: ISearchBox) => {
  const {
    open,
    lastVisited,
    results,
    resultsVisible,
    onRowClick,
    lastVisitedLoading,
  } = props;

  if (!open) return null;

  return (
    <Space className="search__box" align="start">
      <LastVisited
        visible={!resultsVisible}
        lastVisited={lastVisited}
        isLoading={lastVisitedLoading}
        onRowClick={onRowClick}
      />
      <SearchResults
        visible={resultsVisible}
        results={results}
        onRowClick={onRowClick}
      />
    </Space>
  );
};

export default SearchBox;
