import React from "react";
import { Button, Col, Row, Space, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

interface IAssignedProps {
  userId: number;
  isLeader: boolean;
  canEditLeaders: boolean;
  title: string;
  tags: any[];
  onRemove: (userId: number) => void;
  canEdit: boolean;
}

const renderPosition = (isLeader: boolean, tags: any[]) => {
  let positions = "";
  if (isLeader) {
    positions = "(Opiekun działu";
  } else {
    positions = "(";
  }
  // eslint-disable-next-line prettier/prettier,prefer-template

  if (tags.length) {
    if (isLeader) {
      positions += ", ";
    }
    tags.forEach((tag: any, index) => {
      positions += `${tag.name}${index !== tags.length - 1 ? `, ` : ""}`;
    });
    positions += ")";
  } else if (isLeader) {
    positions += ")";
  } else {
    positions = "";
  }

  return positions;
};

const TicketAssignedForm = (props: IAssignedProps) => {
  const {
    userId,
    title,
    onRemove,
    isLeader,
    canEdit,
    canEditLeaders,
    tags,
  } = props;

  return (
    <Row gutter={16} align="middle" justify="space-around">
      <Col span={24}>
        <Space size="middle" style={{ marginBottom: 4 }}>
          <Tooltip title="Usuń przydzielonego" mouseEnterDelay={1}>
            <Button
              disabled={!canEdit || (isLeader && !canEditLeaders)}
              onClick={() => onRemove(userId)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
          <span style={{ marginBottom: 14 }}>
            {title} {renderPosition(isLeader, tags)}
          </span>
        </Space>
      </Col>
    </Row>
  );
};

export default TicketAssignedForm;
