export enum TicketStatus {
  "NEW" = 1,
  "ASSIGNED",
  "VALUATED",
  "ACCEPTED",
  "WORKING",
  "COMPLETED",
  "REJECTED",
  "CLOSED",
  "DELETED",
}

export enum TicketValuationStatus {
  "NEW" = 1,
  "ACCEPTED",
  "REJECTED",
}
