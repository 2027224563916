import { Button, Modal, Popover } from "antd";
import React, { useState } from "react";
import moment from "moment";
import {
  LoadingOutlined,
  PauseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import DueDateForm from "./DueDateForm";
import Can from "../../../Shared/Can";
import AddWorkTimeForm from "./AddWorkTimeForm";
import { timeElapsedFromTimestamp } from "../../../../utils/common";
import EndWorkTimeForm from "./EndWorkTimeForm";

const EndWorkTimeModal = (props: any): JSX.Element => {
  const { ticket, onSubmit, loading, activeWorktime, children } = props;
  const [visible, setVisible] = useState<boolean>(false);

  const commentRequired = Can({
    type: "worktime_comment_required",
    children: <></>,
  });
  return (
    <>
      <Button
        icon={loading ? <LoadingOutlined spin /> : <PauseOutlined />}
        type="primary"
        className="danger"
        danger
        style={{ marginTop: 16 }}
        onClick={() =>
          !commentRequired
            ? onSubmit({ id: activeWorktime?.id })
            : setVisible(true)
        }
      >
        {timeElapsedFromTimestamp(activeWorktime?.dateStart * 1000)}
      </Button>
      <Modal
        cancelText="Kurwa"
        onCancel={() => setVisible(false)}
        visible={visible}
        title="Dodaj komentarz do pracy"
        footer={null}
      >
        <EndWorkTimeForm
          onSubmit={onSubmit}
          setPopoverVisible={setVisible}
          worktimeId={activeWorktime?.id}
        />
      </Modal>
    </>
  );
};

export default EndWorkTimeModal;
