import { Space, Table } from "antd";
import Text from "antd/es/typography/Text";
import React, { PropsWithChildren, useEffect, useState } from "react";
import moment from "moment";
import { Simulate } from "react-dom/test-utils";
import TicketService from "../../../services/tickets-service";

const renderActivityKey = (action: any) => {
  switch (action) {
    case "title":
      return (
        <Space>
          <span>Zmiana tytułu</span>
        </Space>
      );
    case "view":
      return (
        <Space>
          <span>Wyświetlenie</span>
        </Space>
      );
    case "attachment":
      return (
        <Space>
          <span>Dodanie załącznika</span>
        </Space>
      );
    case "hidden_attachment":
      return (
        <Space>
          <span>Dodanie ukrytego załącznika</span>
        </Space>
      );
    case "status":
      return <span>Zmiana statusu</span>;
    case "valuation_status":
      return <span>Zmiana status wyceny</span>;
    case "progress":
      return <span>Zmiana postępu</span>;
    case "sms":
      return <span>Wiadomość SMS</span>;
      break;
    case "estimation":
      return <span>Zmiana estymacji</span>;
      break;
    case "favorite":
      return <span>Zmiana wyróżnienia</span>;
      break;
    case "highlight":
      return <span>Przypięcie do góry</span>;
      break;
    case "callclient":
      return <span>Zaznaczenie zadzwoń</span>;
      break;
    case "priority":
      return <span>Zmiana priorytetu</span>;
      break;
    case "due_date":
      return <span>Zmiana terminu</span>;
      break;
    case "due_date_show":
      return <span>Ustawienie terminu na stronie głównej</span>;
      break;
    case "paid":
      return <span>Ustawienie płatne</span>;
      break;
    case "cost":
      return <span>Ustawienie wyceny</span>;
      break;
    case "forward":
      return <span>Przekazanie</span>;
      break;
    case "assigned":
      return <span>Zmiana przydzielenia</span>;
      break;
    default:
      return action;
  }
};
const columns = [
  {
    title: "Data",
    dataIndex: "date",
    render: (date: number) => {
      return moment(date * 1000).format("DD.MM.YYYY HH:mm");
    },
  },
  {
    title: "Osoba",
    dataIndex: "user",
    render: (name: string, col: any) => (
      <Space>
        <Text>{name}</Text>
      </Space>
    ),
  },
  {
    title: "Akcja",
    dataIndex: "action",
    render: (action: string, col: any) => (
      <Space>
        <Text>{renderActivityKey(action)}</Text>
      </Space>
    ),
  },
];

const LastActivity = (props: PropsWithChildren<any>): JSX.Element => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = props;
  useEffect(() => {
    setLoading(true);
    TicketService.getActivity(id)
      .then((res) => {
        setData(res.data);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={data}
      style={{ width: 300 }}
      pagination={{
        defaultPageSize: 5,
        showSizeChanger: false,
      }}
      size="small"
    />
  );
};

export default LastActivity;
