import { AnyAction } from "redux";

import {
  FETCH_TICKET_GROUP_HOME_FAILED,
  FETCH_TICKET_GROUP_HOME_REQUEST,
  FETCH_TICKET_GROUP_HOME_SUCCESS,
  FETCH_TICKET_GROUPS_HOME_FAILED,
  FETCH_TICKET_GROUPS_HOME_REQUEST,
  FETCH_TICKET_GROUPS_HOME_SUCCESS,
} from "../../actions/action-types";

export interface HomeState {
  ticketgroups: any[];
  ticketgroupsFetching: boolean;
}

const initialState: HomeState = {
  ticketgroups: [],
  ticketgroupsFetching: false,
};

export default (state = initialState, action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TICKET_GROUPS_HOME_REQUEST: {
      return {
        ...state,
        ticketgroupsFetching: payload,
      };
    }
    case FETCH_TICKET_GROUPS_HOME_SUCCESS: {
      return {
        ...state,
        ticketgroups: payload,
        ticketgroupsFetching: false,
      };
    }
    case FETCH_TICKET_GROUPS_HOME_FAILED: {
      return {
        ...state,
      };
    }
    case FETCH_TICKET_GROUP_HOME_REQUEST: {
      return {
        ...state,
      };
    }
    case FETCH_TICKET_GROUP_HOME_SUCCESS: {
      const indexOf = state.ticketgroups.findIndex(
        (ticketsGroup: any) => ticketsGroup.id === payload.id
      );
      return {
        ...state,
        ticketgroups: [
          ...state.ticketgroups.map((item: any, idx: number) => {
            if (idx !== indexOf) {
              return item;
            }
            return { ...item, tasks: payload.tasks };
          }),
        ],
      };
    }
    case FETCH_TICKET_GROUP_HOME_FAILED: {
      return {
        ...state,
        ticketgroupsFetching: false,
      };
    }
    default:
      return state;
  }
};
