import React, { MutableRefObject, PropsWithChildren, useRef } from "react";
import { Form, Input } from "formik-antd";
import { Modal } from "antd";
import { Formik, FormikValues } from "formik";

import * as Yup from "yup";

import {
  INewSplitterFormProps,
  INewSplitterFormValues,
} from "../../../types/ticket-group";

const NewSplitterForm: React.FC<INewSplitterFormProps> = (
  props: PropsWithChildren<INewSplitterFormProps>
): JSX.Element => {
  const { visible, onCancel, onCreate, isRequest } = props;

  const formikRef = useRef<FormikValues>();

  const initialFormValues: INewSplitterFormValues = {
    name: "",
    description: "",
  };

  const NewSplitterSchema = Yup.object().shape({
    name: Yup.string().required("Pole wymagane"),
    description: Yup.string().max(
      255,
      "Opis powinien składać się z maksymalnie 255 znaków"
    ),
  });

  return (
    <Modal
      visible={visible}
      title="Nowa podziałka"
      okText="Zapisz podziałkę"
      cancelText="Anuluj"
      onCancel={() => {
        if (formikRef.current) {
          formikRef.current.resetForm();
        }
        onCancel();
      }}
      confirmLoading={isRequest}
      onOk={() => {
        if (formikRef.current) {
          formikRef.current.handleSubmit();
        }
      }}
    >
      <Formik
        innerRef={formikRef as MutableRefObject<any>}
        initialValues={initialFormValues}
        validationSchema={NewSplitterSchema}
        onSubmit={(
          values: INewSplitterFormValues,
          { resetForm, setErrors }
        ) => {
          onCreate(values, resetForm, setErrors);
        }}
        validateOnChange
        render={() => (
          <Form>
            <Form.Item
              label="Tytuł"
              name="name"
              labelCol={{ span: 24 }}
              required
              rules={[{ required: true }]}
            >
              <Input name="name" />
            </Form.Item>
            <Form.Item
              label="Opis"
              name="description"
              labelCol={{ span: 24 }}
              rules={[{ max: 255 }]}
            >
              <Input.TextArea name="description" />
            </Form.Item>
          </Form>
        )}
      />
    </Modal>
  );
};

export default NewSplitterForm;
