import React, {
  MutableRefObject,
  PropsWithChildren,
  useRef,
  useState,
} from "react";
import { Form, SubmitButton } from "formik-antd";
import { Modal, Row } from "antd";
import { Formik, FormikProps, FormikValues } from "formik";
import ReactQuill from "react-quill-2";
import { AxiosError } from "axios";
import { IQuickAnnouncementValues } from "../../types/quick-forms";
import "moment/locale/pl";
import QuillEditor from "./Editor";

const AnnouncementForm: React.FC<any> = (
  props: PropsWithChildren<any>
): JSX.Element => {
  const formikRef = useRef<FormikValues>();

  const { announcement, handleSubmit, onCancel, visible } = props;

  const [clear, setClear] = useState(0);
  const initialFormValues: IQuickAnnouncementValues = {
    announcement,
  };
  const [submitting, setSubmitting] = useState(false);
  return (
    <Modal
      visible={visible}
      title="Ogłoszenie"
      okText="Zapisz"
      cancelText="Anuluj"
      onCancel={() => {
        if (formikRef.current) {
          formikRef.current.resetForm();
          setClear(new Date().getTime());
        }
        onCancel();
      }}
      confirmLoading={submitting}
      onOk={() => {
        formikRef?.current?.handleSubmit();
      }}
    >
      <Formik
        innerRef={formikRef as MutableRefObject<any>}
        initialValues={initialFormValues}
        enableReinitialize
        onSubmit={(
          values: IQuickAnnouncementValues,
          { resetForm, setErrors }
        ) => {
          setSubmitting(true);
          handleSubmit(
            values,
            () => {
              setSubmitting(false);
              setClear(new Date().getTime());
            },
            (err: AxiosError) => {
              setSubmitting(false);
            }
          );
        }}
        validateOnChange
        render={(formProps: FormikProps<IQuickAnnouncementValues>) => (
          <Form>
            <Form.Item
              required
              name="announcement"
              labelCol={{ span: 24 }}
              style={{ width: "100%" }}
            >
              <QuillEditor
                initValue={initialFormValues.announcement}
                value={formProps.values.announcement}
                reinitialize={visible}
                onChange={(change: string) => {
                  formProps.setFieldValue(
                    "announcement",
                    change === "<p><br></p>" ? null : change
                  );
                  formProps.setFieldTouched("announcement", true);
                }}
              />
            </Form.Item>
          </Form>
        )}
      />
    </Modal>
  );
};

export default AnnouncementForm;
