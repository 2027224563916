import React, { MutableRefObject, useCallback, useRef, useState } from "react";
import { Form, Select, SubmitButton } from "formik-antd";
import { Spin } from "antd";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";
import {
  ITicketGroupAddLeadersFormProps,
  ITicketGroupAddLeadersFormValues,
} from "../../../../../types/ticket-group";

import { AccountType } from "../../../../../types/user";

const { Option, OptGroup } = Select;
const TicketGroupAddLeadersForm = (props: ITicketGroupAddLeadersFormProps) => {
  const { users, leaders, isLoading, onSave } = props;

  const initialFormValues: ITicketGroupAddLeadersFormValues = {
    leaders: [],
  };
  const [formData, setFormData] = useState<ITicketGroupAddLeadersFormValues>(
    initialFormValues
  );

  const [editRequest, setEditRequest] = useState<boolean>(false);

  // useEffect(() => {
  //   setFormData({
  //     leaders,
  //   });
  // }, [leaders]);

  const formikRef = useRef<FormikValues>();

  const FormSchema = Yup.object().shape({
    leaders: Yup.array()
      .min(1, "Wybierz przynajmniej jednego opiekuna.")
      .required("Pole wymagane"),
  });

  const groupedUsers = users.reduce((groups, user) => {
    if (!leaders.some((leader: any) => leader.user.id === user.id)) {
      const group = groups[user.typeAccount] || [];
      group.push(user);
      // eslint-disable-next-line no-param-reassign
      groups[user.typeAccount] = group;
    }
    return groups;
  }, []);

  const filterOption = useCallback((search, optionOrGroup) => {
    const isGroup = Array.isArray(optionOrGroup.options);
    if (isGroup) {
      return false;
    }
    return optionOrGroup.children.toLowerCase().includes(search.toLowerCase());
  }, []);

  return (
    <Spin spinning={isLoading}>
      <Formik
        innerRef={formikRef as MutableRefObject<any>}
        initialValues={formData}
        enableReinitialize
        validationSchema={FormSchema}
        onSubmit={(
          values: ITicketGroupAddLeadersFormValues,
          { resetForm, setErrors }
        ) => {
          setEditRequest(true);
          onSave(
            values,
            () => {
              setEditRequest(false);
              resetForm();
            },
            setErrors
          );
        }}
        validateOnChange
        render={() => (
          <Form style={{ marginTop: 16 }}>
            <Form.Item
              name="leaders"
              className="show-label"
              label="Wybierz nowych opiekunów"
              labelCol={{ span: 24 }}
            >
              <Select
                name="leaders"
                labelInValue
                showSearch
                placeholder="Wybierz lub wyszukaj"
                optionFilterProp="children"
                mode="multiple"
                filterOption={filterOption}
              >
                {groupedUsers.map((group: any, index: number) => (
                  <OptGroup label={AccountType[index]}>
                    {group.map((user: any) => {
                      const optionLabel = `${user.name} ${user.surname} (${user.email})`;
                      return <Option value={user.id}>{optionLabel}</Option>;
                    })}
                  </OptGroup>
                ))}
              </Select>
            </Form.Item>
            <SubmitButton loading={editRequest}>Dodaj</SubmitButton>
          </Form>
        )}
      />
    </Spin>
  );
};

export default TicketGroupAddLeadersForm;
