import { AnyAction } from "redux";

import {
  BULK_ASSIGN_TICKET_GROUPS_LEADERS_FAILED,
  BULK_ASSIGN_TICKET_GROUPS_LEADERS_REQUEST,
  BULK_ASSIGN_TICKET_GROUPS_LEADERS_SUCCESS,
  BULK_ASSIGN_TICKET_GROUPS_MEMBERS,
  BULK_ASSIGN_TICKET_GROUPS_MEMBERS_FAILED,
  BULK_ASSIGN_TICKET_GROUPS_MEMBERS_SUCCESS,
  CLEAR_TICKET_GROUP_STATE,
  CLEAR_TICKET_GROUPS_STATE,
  CREATE_TICKET_GROUP_REQUEST,
  CREATE_TICKET_GROUP_REQUEST_FAILED,
  CREATE_TICKET_GROUP_REQUEST_SUCCESS,
  CREATE_TICKET_GROUPS_TICKET_TYPE,
  CREATE_TICKET_GROUPS_TICKET_TYPE_FAILED,
  CREATE_TICKET_GROUPS_TICKET_TYPE_SUCCESS,
  DELETE_TICKET_GROUPS_LEADER_FAILED,
  DELETE_TICKET_GROUPS_LEADER_REQUEST,
  DELETE_TICKET_GROUPS_LEADER_SUCCESS,
  DELETE_TICKET_GROUPS_MEMBER,
  DELETE_TICKET_GROUPS_MEMBER_FAILED,
  DELETE_TICKET_GROUPS_MEMBER_SUCCESS,
  DELETE_TICKET_GROUPS_TICKET_TYPE,
  DELETE_TICKET_GROUPS_TICKET_TYPE_FAILED,
  DELETE_TICKET_GROUPS_TICKET_TYPE_SUCCESS,
  EDIT_TICKET_GROUP_FAILED,
  EDIT_TICKET_GROUP_REQUEST,
  EDIT_TICKET_GROUP_SUCCESS,
  FETCH_TICKET_GROUP_FAILED,
  FETCH_TICKET_GROUP_REQUEST,
  FETCH_TICKET_GROUP_SUCCESS,
  FETCH_TICKET_GROUPS_FAILED,
  FETCH_TICKET_GROUPS_LEADERS_FAILED,
  FETCH_TICKET_GROUPS_LEADERS_REQUEST,
  FETCH_TICKET_GROUPS_LEADERS_SUCCESS,
  FETCH_TICKET_GROUPS_MEMBERS_FAILED,
  FETCH_TICKET_GROUPS_MEMBERS_REQUEST,
  FETCH_TICKET_GROUPS_MEMBERS_SUCCESS,
  FETCH_TICKET_GROUPS_REQUEST,
  FETCH_TICKET_GROUPS_SUCCESS,
  FETCH_TICKET_GROUPS_TICKET_TYPES_FAILED,
  FETCH_TICKET_GROUPS_TICKET_TYPES_REQUEST,
  FETCH_TICKET_GROUPS_TICKET_TYPES_SUCCESS,
  SET_TICKET_GROUP,
  SOFTDELETE_TICKET_GROUPS_FAILED,
  SOFTDELETE_TICKET_GROUPS_REQUEST,
  SOFTDELETE_TICKET_GROUPS_SUCCESS,
  UPDATE_TICKET_GROUPS_POSITIONS_FAILED,
  UPDATE_TICKET_GROUPS_POSITIONS_REQUEST,
  UPDATE_TICKET_GROUPS_POSITIONS_SUCCESS,
  UPDATE_TICKET_GROUPS_TICKET_TYPE,
  UPDATE_TICKET_GROUPS_TICKET_TYPE_FAILED,
  UPDATE_TICKET_GROUPS_TICKET_TYPE_SUCCESS,
} from "../../actions/action-types";

export interface TicketGroupsState {
  ticketgroups: any[];
  ticketgroup: any; // todo: dodać typ
  ticketgroupMembers: any[];
  ticketgroupLeaders: any[];
  ticketgroupTicketTypes: any[];
  isFetchTicketGroupsRequest: boolean;
  isFetchTicketGroupTicketTypesRequest: boolean;
  isFetchTicketGroupMembersRequest: boolean;
  isFetchTicketGroupLeadersRequest: boolean;
  isFetchTicketGroupRequest: boolean;
  isUpdateTaskGroupPositionsRequest: boolean;
  isCreateTicketGroupRequest: boolean;
  isCreateTicketGroupTicketTypeRequest: boolean;
  isUpdateTicketGroupTicketTypeRequest: boolean;
  isDeleteTicketGroupTicketTypeRequest: boolean;
  isBulkAssignTicketGroupLeaders: boolean;
  isDeleteTicketGroupLeadersRequest: boolean;
  isBulkAssignTicketGroupMembers: boolean;
  isDeleteTicketGroupMembersRequest: boolean;
  createTicketGroupErrors: any;
  isSoftDeleteRequest: boolean;
  isEditTicketGroupRequest: boolean;
}

const initialState: TicketGroupsState = {
  ticketgroups: [],
  ticketgroup: {},
  ticketgroupMembers: [],
  ticketgroupLeaders: [],
  ticketgroupTicketTypes: [],
  isFetchTicketGroupsRequest: false,
  isFetchTicketGroupTicketTypesRequest: false,
  isFetchTicketGroupLeadersRequest: false,
  isFetchTicketGroupMembersRequest: false,
  isFetchTicketGroupRequest: false,
  isUpdateTaskGroupPositionsRequest: false,
  isCreateTicketGroupRequest: false,
  isBulkAssignTicketGroupLeaders: false,
  createTicketGroupErrors: null,
  isSoftDeleteRequest: false,
  isEditTicketGroupRequest: false,
  isDeleteTicketGroupLeadersRequest: false,
  isCreateTicketGroupTicketTypeRequest: false,
  isDeleteTicketGroupTicketTypeRequest: false,
  isUpdateTicketGroupTicketTypeRequest: false,
  isBulkAssignTicketGroupMembers: false,
  isDeleteTicketGroupMembersRequest: false,
};

export default (state = initialState, action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TICKET_GROUPS_REQUEST: {
      return {
        ...state,
        isFetchTicketGroupsRequest: payload,
      };
    }
    case FETCH_TICKET_GROUPS_SUCCESS: {
      return {
        ...state,
        ticketgroups: payload,
        isFetchTicketGroupsRequest: false,
      };
    }
    case FETCH_TICKET_GROUPS_FAILED: {
      return {
        ...state,
        isFetchTicketGroupsRequest: false,
      };
    }
    case FETCH_TICKET_GROUP_REQUEST: {
      return {
        ...state,
        isFetchTicketGroupRequest: true,
      };
    }
    case FETCH_TICKET_GROUP_SUCCESS: {
      return {
        ...state,
        ticketgroup: payload,
        isFetchTicketGroupRequest: false,
      };
    }
    case FETCH_TICKET_GROUP_FAILED: {
      return {
        ...state,
        isFetchTicketGroupRequest: false,
      };
    }

    case SET_TICKET_GROUP: {
      return {
        ...state,
        ticketgroup: payload,
      };
    }

    case UPDATE_TICKET_GROUPS_POSITIONS_REQUEST: {
      return {
        ...state,
        isUpdateTaskGroupPositionsRequest: true,
      };
    }
    case UPDATE_TICKET_GROUPS_POSITIONS_SUCCESS: {
      return {
        ...state,
        isUpdateTaskGroupPositionsRequest: false,
      };
    }
    case UPDATE_TICKET_GROUPS_POSITIONS_FAILED: {
      return {
        ...state,
        isUpdateTaskGroupPositionsRequest: false,
      };
    }
    case CREATE_TICKET_GROUP_REQUEST: {
      return {
        ...state,
        isCreateTicketGroupRequest: true,
      };
    }
    case CREATE_TICKET_GROUP_REQUEST_SUCCESS: {
      const ticketGroups = [...state.ticketgroups, payload];
      ticketGroups.sort((a, b) => {
        if (a.position === b.position) {
          return b.id - a.id;
        }
        return parseInt(a.position, 10) - parseInt(b.position, 10);
      });

      return {
        ...state,
        ticketgroups: ticketGroups,
        isCreateTicketGroupRequest: false,
      };
    }
    case CREATE_TICKET_GROUP_REQUEST_FAILED: {
      return {
        ...state,
        isCreateTicketGroupRequest: false,
        createTicketGroupErrors: payload,
      };
    }
    case SOFTDELETE_TICKET_GROUPS_REQUEST: {
      return {
        ...state,
        isSoftDeleteRequest: true,
      };
    }
    case SOFTDELETE_TICKET_GROUPS_SUCCESS: {
      const indexOf = state.ticketgroups.findIndex(
        (ticketGroup: any) => ticketGroup.id === parseInt(payload, 10)
      );

      return {
        ...state,
        ticketgroups:
          indexOf >= 0
            ? [
                ...state.ticketgroups.slice(0, indexOf),
                ...state.ticketgroups.slice(indexOf + 1),
              ]
            : [...state.ticketgroups],
        isSoftDeleteRequest: false,
      };
    }
    case SOFTDELETE_TICKET_GROUPS_FAILED: {
      return {
        ...state,
        isSoftDeleteRequest: false,
      };
    }
    case EDIT_TICKET_GROUP_REQUEST: {
      return {
        ...state,
        isEditTicketGroupRequest: true,
      };
    }
    case EDIT_TICKET_GROUP_SUCCESS: {
      return {
        ...state,
        isEditTicketGroupRequest: false,
        ticketgroup: payload,
      };
    }
    case EDIT_TICKET_GROUP_FAILED: {
      return {
        ...state,
        isEditTicketGroupRequest: false,
      };
    }
    case CLEAR_TICKET_GROUPS_STATE: {
      return {
        ...state,
        ticketgroups: [],
        ticketgroup: {},
      };
    }
    case CLEAR_TICKET_GROUP_STATE: {
      return {
        ...state,
        ticketgroup: {},
      };
    }
    case FETCH_TICKET_GROUPS_MEMBERS_REQUEST: {
      return {
        ...state,
        isFetchTicketGroupMembersRequest: true,
      };
    }
    case FETCH_TICKET_GROUPS_MEMBERS_SUCCESS: {
      return {
        ...state,
        ticketgroupMembers: payload,
        isFetchTicketGroupMembersRequest: false,
      };
    }
    case FETCH_TICKET_GROUPS_MEMBERS_FAILED: {
      return {
        ...state,
        isFetchTicketGroupMembersRequest: false,
      };
    }

    case BULK_ASSIGN_TICKET_GROUPS_MEMBERS: {
      return {
        ...state,
        isBulkAssignTicketGroupMembers: true,
      };
    }
    case BULK_ASSIGN_TICKET_GROUPS_MEMBERS_SUCCESS: {
      return {
        ...state,
        ticketgroupMembers: [...state.ticketgroupMembers, ...payload],
        isBulkAssignTicketGroupMembers: false,
      };
    }
    case BULK_ASSIGN_TICKET_GROUPS_MEMBERS_FAILED: {
      return {
        ...state,
        isBulkAssignTicketGroupMembers: false,
      };
    }

    case DELETE_TICKET_GROUPS_MEMBER: {
      return {
        ...state,
        isDeleteTicketGroupMembersRequest: true,
      };
    }
    case DELETE_TICKET_GROUPS_MEMBER_SUCCESS: {
      const indexOf = state.ticketgroupMembers.findIndex(
        (member: any) => member.user.id === payload
      );
      return {
        ...state,
        ticketgroup: {
          ...state.ticketgroup,
          managerId:
            state.ticketgroup.managerId === payload
              ? null
              : state.ticketgroup.managerId,
        },
        ticketgroupMembers:
          indexOf >= 0
            ? [
                ...state.ticketgroupMembers.slice(0, indexOf),
                ...state.ticketgroupMembers.slice(indexOf + 1),
              ]
            : [...state.ticketgroupMembers],
        isDeleteTicketGroupMembersRequest: false,
      };
    }
    case DELETE_TICKET_GROUPS_MEMBER_FAILED: {
      return {
        ...state,
        isDeleteTicketGroupMembersRequest: false,
      };
    }

    case FETCH_TICKET_GROUPS_LEADERS_REQUEST: {
      return {
        ...state,
        isFetchTicketGroupLeadersRequest: true,
      };
    }
    case FETCH_TICKET_GROUPS_LEADERS_SUCCESS: {
      return {
        ...state,
        ticketgroupLeaders: payload,
        isFetchTicketGroupLeadersRequest: false,
      };
    }
    case FETCH_TICKET_GROUPS_LEADERS_FAILED: {
      return {
        ...state,
        isFetchTicketGroupLeadersRequest: false,
      };
    }

    case BULK_ASSIGN_TICKET_GROUPS_LEADERS_REQUEST: {
      return {
        ...state,
        isBulkAssignTicketGroupLeaders: true,
      };
    }
    case BULK_ASSIGN_TICKET_GROUPS_LEADERS_SUCCESS: {
      return {
        ...state,
        ticketgroupLeaders: [...state.ticketgroupLeaders, ...payload],
        isBulkAssignTicketGroupLeaders: false,
      };
    }
    case BULK_ASSIGN_TICKET_GROUPS_LEADERS_FAILED: {
      return {
        ...state,
        isBulkAssignTicketGroupLeaders: false,
      };
    }

    case DELETE_TICKET_GROUPS_LEADER_REQUEST: {
      return {
        ...state,
        isDeleteTicketGroupLeadersRequest: true,
      };
    }
    case DELETE_TICKET_GROUPS_LEADER_SUCCESS: {
      const indexOf = state.ticketgroupLeaders.findIndex(
        (leader: any) => leader.user.id === payload
      );

      return {
        ...state,
        ticketgroupLeaders:
          indexOf >= 0
            ? [
                ...state.ticketgroupLeaders.slice(0, indexOf),
                ...state.ticketgroupLeaders.slice(indexOf + 1),
              ]
            : [...state.ticketgroupLeaders],
        isDeleteTicketGroupLeadersRequest: false,
      };
    }
    case DELETE_TICKET_GROUPS_LEADER_FAILED: {
      return {
        ...state,
        isDeleteTicketGroupLeadersRequest: false,
      };
    }

    case FETCH_TICKET_GROUPS_TICKET_TYPES_REQUEST: {
      return {
        ...state,
        isFetchTicketGroupTicketTypesRequest: true,
      };
    }
    case FETCH_TICKET_GROUPS_TICKET_TYPES_SUCCESS: {
      return {
        ...state,
        ticketgroupTicketTypes: payload,
        isFetchTicketGroupTicketTypesRequest: false,
      };
    }
    case FETCH_TICKET_GROUPS_TICKET_TYPES_FAILED: {
      return {
        ...state,
        isFetchTicketGroupTicketTypesRequest: false,
      };
    }

    case CREATE_TICKET_GROUPS_TICKET_TYPE: {
      return {
        ...state,
        isCreateTicketGroupTicketTypeRequest: true,
      };
    }
    case CREATE_TICKET_GROUPS_TICKET_TYPE_SUCCESS: {
      return {
        ...state,
        ticketgroupTicketTypes: [...state.ticketgroupTicketTypes, payload],
        isCreateTicketGroupTicketTypeRequest: false,
      };
    }
    case CREATE_TICKET_GROUPS_TICKET_TYPE_FAILED: {
      return {
        ...state,
        isCreateTicketGroupTicketTypeRequest: false,
      };
    }

    case UPDATE_TICKET_GROUPS_TICKET_TYPE: {
      return {
        ...state,
        isUpdateTicketGroupTicketTypeRequest: true,
      };
    }
    case UPDATE_TICKET_GROUPS_TICKET_TYPE_SUCCESS: {
      return {
        ...state,
        isUpdateTicketGroupTicketTypeRequest: false,
      };
    }
    case UPDATE_TICKET_GROUPS_TICKET_TYPE_FAILED: {
      return {
        ...state,
        isUpdateTicketGroupTicketTypeRequest: false,
      };
    }

    case DELETE_TICKET_GROUPS_TICKET_TYPE: {
      return {
        ...state,
        isDeleteTicketGroupTicketTypeRequest: true,
      };
    }
    case DELETE_TICKET_GROUPS_TICKET_TYPE_SUCCESS: {
      const indexOf = state.ticketgroupTicketTypes.findIndex(
        (ticketType: any) => ticketType.id === payload
      );

      return {
        ...state,
        ticketgroupTicketTypes:
          indexOf >= 0
            ? [
                ...state.ticketgroupTicketTypes.slice(0, indexOf),
                ...state.ticketgroupTicketTypes.slice(indexOf + 1),
              ]
            : [...state.ticketgroupTicketTypes],
        isDeleteTicketGroupTicketTypeRequest: false,
      };
    }
    case DELETE_TICKET_GROUPS_TICKET_TYPE_FAILED: {
      return {
        ...state,
        isDeleteTicketGroupLeadersRequest: false,
      };
    }

    default:
      return state;
  }
};
