import { Button, Popover } from "antd";
import React, { useState } from "react";
import Estimation from "./Estimation";

const EstimationPopover = (props: any): JSX.Element => {
  const { value, onSubmit, editable } = props;
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <Popover
      visible={visible}
      onVisibleChange={(change) => setVisible(change)}
      style={{ width: "400px" }}
      placement="bottom"
      content={
        <Estimation
          onSubmit={onSubmit}
          value={value}
          setPopoverVisible={setVisible}
        />
      }
      title="Ustaw estymację"
      trigger={editable ? "click" : "disabled"}
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
    >
      <Button
        type="text"
        style={{ padding: 0, pointerEvents: editable ? "initial" : "none" }}
      >
        {value ? `${value} h` : "Brak"}
      </Button>
    </Popover>
  );
};

export default EstimationPopover;
