import { FormikErrors } from "formik";
import { RcFile } from "antd/es/upload";

export enum Disposable {
  "Miesięczna",
  "Jednorazowa",
  "Niedotyczy",
}

export interface INewSplitterFormProps {
  visible: boolean;
  isRequest: boolean;
  onCreate: (
    values: INewSplitterFormValues,
    resetForm: () => void,
    setError: (errors: FormikErrors<INewSplitterFormValues>) => void
  ) => void;
  onCancel: () => void;
}
export interface INewSplitterFormValues {
  name: string;
  description: string;
}

export interface IChatFormValues {
  chatEnabled: boolean;
}

export interface IChatFormProps {
  chatEnabled: boolean;
  isLoading: boolean;
  onRemoveMessages: (callback: () => void) => void;
  onSave: (
    values: IChatFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<IChatFormValues>) => void
  ) => void;
}

export interface ISplitterBasicFormValues {
  name: string | null;
  description: string | null;
}

export interface ISplitterBasicFormProps {
  img: string | null;
  name: string | null;
  description: string | null;
  isLoading: boolean;
  onUpload: (
    file: RcFile | Blob | string,
    onRequestComplete: (url?: string) => void
  ) => void;
  onAvatarRemove: (callback: () => void) => void;
  onSave: (
    values: ISplitterBasicFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<ISplitterBasicFormValues>) => void
  ) => void;
}

export interface ITicketGroupBasicFormValues {
  name: string | null;
  description: string | null;
  managerId: number | null;
  disposable: string | number;
}

export interface ITicketGroupFormProps {
  img: string | null;
  name: string | null;
  description: string | null;
  disposable: string | number;
  users: any[];
  managerId: number | null;
  isLoading: boolean;
  onUpload: (
    file: RcFile | Blob | string,
    onRequestComplete: (url?: string) => void
  ) => void;
  onAvatarRemove: (callback: () => void) => void;
  onSave: (
    values: ITicketGroupBasicFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<ITicketGroupBasicFormValues>) => void
  ) => void;
}

export interface ITicketGroupAddLeadersFormProps {
  isLoading: boolean;
  leaders: any[];
  users: any[];
  onSave: (
    values: ITicketGroupAddLeadersFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<ITicketGroupAddLeadersFormValues>) => void
  ) => void;
}

export interface ITicketGroupAddLeader {
  label: string;
  value: number;
  key: number;
}
export interface ITicketGroupAddLeadersFormValues {
  leaders: ITicketGroupAddLeader[];
}

export interface ITicketGroupLeaderFormProps {
  userId: string | number;
  name: string;
  surname: string;
  info: string;
  isLoading: boolean;
  onSave: (
    values: ITicketGroupLeaderFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<ITicketGroupLeaderFormValues>) => void
  ) => void;
  onRemove: (userId: string | number, onRequestComplete: () => void) => void;
}

export interface ITicketGroupLeaderFormValues {
  userId: string | number;
  info: string;
}

export enum Priority {
  "Natychmiastowy" = 5,
  "Wysoki" = 4,
  "Normalny" = 3,
  "Niski" = 2,
  "Brak" = 1,
}

export enum PriorityColor {
  "red" = 5,
  "orange" = 4,
  "blue" = 3,
  "green" = 2,
  "gray" = 1,
}
