import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import {
  DatePicker,
  Form,
  Input,
  Select,
  SubmitButton,
  Switch,
} from "formik-antd";
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Space,
  Spin,
  Upload,
} from "antd";
import { Formik, FormikErrors, FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill-2";
import { Linkify, Options } from "quill-linkify";

import { UploadChangeParam } from "antd/lib/upload/interface";
import moment, { Moment } from "moment/moment";
import { at } from "lodash";
import { PaperClipOutlined } from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import {
  defaultEditorFormats,
  defaultEditorModules,
} from "../../../../utils/common";
import Can from "../../../Shared/Can";
import QuillEditor from "../../../Shared/Editor";
import { AccountTypeWithHighlight } from "../../../../types/user";
import TicketAssignedForm from "../NewTicketForm/AssignedForm";
import TicketAddAssignedForm, {
  IFormValues,
} from "../NewTicketForm/AddAssignedForm";
import TicketService from "../../../../services/tickets-service";
import UploadAttachmentsList from "../../../Shared/UplaodAttachmentsList";
import { Priority, PriorityColor } from "../../../../types/ticket-group";

export interface IDuplicateTicketFormValues {
  title: string;
  ticketType: number | null;
  description: string;
  group: string | number | null;
  assignedUsers: any[];
  attachments: any[];
  dueDate: any;
  paid: boolean;
  cost: number | null;
  priorityId: number;
  status: number;
  priority: number;
}

export interface IDuplicateEditProps {
  title?: string;
  ticketType?: any;
  ticketTypes: any;
  description?: string;
  group: string | number | null;
  groups: any[];
  assignedUsers: any[];
  leaders: any[];
  members: any[];
  dueDate?: number;
  paid?: boolean;
  cost?: number | null;
  priority?: number;
  priorityId?: number;
  canEdit: boolean;
  canEditLeaders: boolean;
  users: any[];
  isLoading: boolean;

  status?: number;
  statusId?: number;

  attachments: any[];
  onSave: (
    values: IDuplicateTicketFormValues,
    onRequestComplete: () => void,
    setError: (errors: FormikErrors<IDuplicateTicketFormValues>) => void
  ) => void;
  onGroupChange: (id: number) => void;
}

const { Option, OptGroup } = Select;
const DuplicateTicketForm = (props: IDuplicateEditProps) => {
  const {
    title,
    description,
    isLoading,
    assignedUsers,
    onSave,
    onGroupChange,
    ticketType,
    ticketTypes,
    group,
    groups,
    canEdit,
    canEditLeaders,
    leaders,
    members,
    users,
    dueDate,
    priority,
    priorityId,
    status,
    statusId,
    paid,
    cost,
    attachments,
  } = props;
  const initialFormValues: IDuplicateTicketFormValues = {
    title: "",
    description: description || "",
    ticketType: null,
    group: null,
    assignedUsers: assignedUsers || [],
    dueDate: dueDate || null,
    paid: paid || false,
    attachments: attachments || [],
    priority: priorityId || 1,
    priorityId: priorityId || 1,
    status: 10,
    cost: cost || null,
  };
  const [formData, setFormData] = useState<IDuplicateTicketFormValues>(
    initialFormValues
  );
  const assignedFormRef = useRef<FormikProps<IFormValues>>(null);

  const dueDateRequired = !!Can({
    type: "due_date_required",
    children: <></>,
  });

  const disabledDate = (current: Moment) => {
    return current && current < moment().startOf("day");
  };

  const [editRequest, setEditRequest] = useState<boolean>(false);
  const [splitters, setSplitters] = useState<any[]>([]);
  const [splittersOrder, setSplittersOrder] = useState<any[]>([]);
  const [uploadList, setUploadList] = useState<any>([]);

  const NOT_ASSIGNED = "not_assigned";

  useEffect(() => {
    const splittersTemp: any = {};
    const splittersOrderTemp: string[] = [];
    if (groups.length) {
      groups.forEach((ticketGroup: any) => {
        if (ticketGroup.break) {
          splittersTemp[ticketGroup.id.toString()] = {
            id: ticketGroup.id.toString(),
            title: ticketGroup.name,
            img: ticketGroup.img,
            groups: [],
          };
          splittersOrderTemp.push(ticketGroup.id.toString());
        }
      });
      splittersTemp[NOT_ASSIGNED] = {
        id: NOT_ASSIGNED,
        title: "Nieprzydzielone",
        groups: [],
      };
      splittersOrderTemp.push(NOT_ASSIGNED);

      groups.forEach((ticketGroup: any) => {
        if (!ticketGroup.break) {
          if (
            ticketGroup.parent &&
            splittersTemp[ticketGroup.parent.id.toString()]
          ) {
            splittersTemp[ticketGroup.parent.id.toString()].groups.push(
              ticketGroup
            );
          } else {
            splittersTemp[NOT_ASSIGNED].groups.push(ticketGroup);
          }
        }
      });
      setSplitters(splittersTemp);
      setSplittersOrder(splittersOrderTemp);
    }
  }, [groups]);

  useEffect(() => {
    console.log(assignedUsers, "??");
    setFormData({
      title: title || "",
      description: description || "",
      ticketType: ticketType?.id,
      group,
      assignedUsers,
      cost: cost || null,
      dueDate: dueDate ? moment(dueDate * 1000) : null,
      priority: priority || 1,
      priorityId: priority || 1,
      status: status || 10,
      attachments,
      paid: paid || false,
    });
  }, [
    title,
    description,
    ticketType,
    group,
    assignedUsers,
    cost,
    dueDate,
    priority,
    priorityId,
    attachments,
    paid,
    status,
  ]);

  useEffect(() => {
    setUploadList(attachments);
  }, [attachments]);
  useEffect(() => {
    let defaultType = ticketTypes.filter((type: any) => type.defaultType);
    defaultType = defaultType.length ? defaultType[0] : null;

    if (!defaultType) defaultType = ticketTypes.length ? ticketTypes[0] : null;

    setFormData((prevState) => {
      return { ...prevState, ticketType: defaultType?.id || null };
    });
  }, [ticketTypes]);

  const formikRef = useRef<FormikValues>();

  const FormSchema = Yup.object().shape({
    title: Yup.string().required("Pole wymagane"),
    description: Yup.string().required("Pole wymagane"),
    paid: Yup.boolean(),
    dueDate: dueDateRequired
      ? Yup.mixed().required("Termin jest wymagany")
      : Yup.mixed().nullable(),
    cost: Yup.number().when("paid", {
      is: true,
      then: Yup.number()
        .typeError("Pole musi być wartością liczbową")
        .required("Pole jest wymagane")
        .min(0.01, "Podaj wartość większą niż 0."),
      otherwise: Yup.number().nullable(),
    }),
  });

  const handleSubmit = () => {
    if (canEdit)
      assignedFormRef.current?.submitForm().then(() => {
        assignedFormRef.current?.resetForm();
        formikRef.current?.submitForm();
      });
    else formikRef.current?.submitForm();
  };

  const handleAttachmentProgressChange = (file: any, progress: number) => {
    setUploadList((prevState: any) => {
      const idx = prevState.findIndex((item: any) => item.id === file.uid);
      if (idx > -1) {
        const copyItem = { ...prevState[idx] };
        copyItem.progress = progress;
        return [
          ...prevState.slice(0, idx),
          copyItem,
          ...prevState.slice(idx + 1),
        ];
      }
      return null;
    });
  };

  const handleAttachmentsRemove = (file: any) => {
    setUploadList((prevState: any) => {
      const idx = prevState.findIndex((item: any) => item.id === file.id);
      if (idx > -1) {
        return [...prevState.slice(0, idx), ...prevState.slice(idx + 1)];
      }
      return null;
    });
  };

  const handleAttachmentsChange = (info: UploadChangeParam) => {
    const { file, fileList } = info;
    const item = {
      date: moment().unix(),
      fileMimeType: file.type,
      fileName: file.name,
      fileOriginalName: file.name,
      fileSize: file.size,
      progress: 0,
      hidden: false,
      id: file.uid,
      ticket: {},
      user: {},
      status: file.status,
    };

    setUploadList((prevState: any) => {
      return [item, ...prevState];
    });
  };

  const handleAttachmentsUpload = (options: any) => {
    // setIsUploading(true);
    return TicketService.uploadAttachment(options.file, (change) =>
      handleAttachmentProgressChange(options.file, change)
    )
      .then((response) => {
        setUploadList((prevState: any) => {
          const idx = prevState.findIndex(
            (item: any) => item.id === options.file.uid
          );

          if (idx > -1) {
            return [
              ...prevState.slice(0, idx),
              response.data,
              ...prevState.slice(idx + 1),
            ];
          }

          return null;
        });
      })
      .catch((err) => {
        setUploadList((prevState: any) => {
          const idx = prevState.findIndex(
            (item: any) => item.id === options.file.uid
          );

          const copyItem = { ...prevState[idx] };
          copyItem.progress = 0;
          copyItem.status = "error";

          if (idx > -1) {
            return [
              ...prevState.slice(0, idx),
              copyItem,
              ...prevState.slice(idx + 1),
            ];
          }

          return null;
        });
      });
  };

  return (
    <Spin spinning={isLoading}>
      <Card title="Podstawowe informacje">
        <Formik
          innerRef={formikRef as MutableRefObject<any>}
          initialValues={formData}
          enableReinitialize
          validationSchema={FormSchema}
          onSubmit={(
            values: IDuplicateTicketFormValues,
            { resetForm, setErrors }
          ) => {
            setEditRequest(true);
            onSave(
              {
                ...values,
                assignedUsers: values.assignedUsers.map((user) => user.key),
                dueDate: values.dueDate ? moment(values.dueDate).unix() : null,
                attachments: uploadList
                  .filter(
                    (file: any) =>
                      file.status !== "error" || file.status === "uploading"
                  )
                  .map((file: any) => file.id),
              },
              () => setEditRequest(false),
              setErrors
            );
          }}
          validateOnChange
          render={(formProps) => (
            <Form>
              <Row gutter={16}>
                <Can type="ticket_edit_type">
                  <Col xs={24} md={24} lg={12} xl={10}>
                    <Form.Item
                      name="ticketType"
                      label="Typ wątku"
                      labelCol={{ span: 24 }}
                    >
                      <Select name="ticketType" placeholder="Wybierz typ wątku">
                        {ticketTypes.map((type: any) => (
                          <Option value={type.id}>
                            <Space>
                              <div
                                style={{
                                  backgroundColor: type.color,
                                  width: 20,
                                  height: 20,
                                }}
                              />
                              {type.name}
                            </Space>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Can>
                <Col span={24}>
                  <Form.Item
                    label="Tytuł"
                    name="title"
                    labelCol={{ span: 24 }}
                    rules={[{ required: true }]}
                  >
                    <Input name="title" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Opis"
                    name="description"
                    labelCol={{ span: 24 }}
                  >
                    <QuillEditor
                      initValue={initialFormValues.description}
                      value={formProps.values.description}
                      withMedia
                      reinitialize={false}
                      onChange={(change: string) => {
                        const val = change === "<p><br></p>" ? "" : change;
                        formProps.setFieldValue("description", val);
                        formProps.setFieldTouched("description", true);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <h3 style={{ marginTop: 16 }}>Przydzielone osoby</h3>

                      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                      {formProps.errors.assignedUsers ? (
                        <div className="ant-form-item-explain ant-form-item-explain-error">
                          {formProps.errors.assignedUsers}
                        </div>
                      ) : null}
                      {formProps.values.assignedUsers.map((user) => {
                        return (
                          <TicketAssignedForm
                            canEdit={canEdit}
                            canEditLeaders={canEditLeaders}
                            userId={user.key}
                            title={user.label}
                            isLeader={leaders.some(
                              (leader: any) => leader.user.id === user.key
                            )}
                            tags={user.tag}
                            onRemove={(userId) => {
                              const removedAssigned = Array.from(
                                formProps.values.assignedUsers
                              );

                              removedAssigned.splice(
                                removedAssigned.findIndex(
                                  (findUser: any) => findUser.key === userId
                                ),
                                1
                              );

                              formProps.setFieldValue(
                                "assignedUsers",
                                removedAssigned
                              );
                              // formProps.validateField("assignedUsers");
                            }}
                          />
                        );
                      })}
                    </Col>
                  </Row>
                  {canEdit && (
                    <TicketAddAssignedForm
                      showSubmitButton={false}
                      formikRef={assignedFormRef}
                      isLoading={false}
                      assignedUsers={formProps.values.assignedUsers}
                      leaders={leaders}
                      onTouched={() => {}}
                      members={members}
                      users={users}
                      onSave={(values, onRequestComplete, setError) => {
                        console.log("sejw");
                        formProps.setFieldValue("assignedUsers", [
                          ...formProps.values.assignedUsers,
                          ...values.assignedUsers.map((assinged) =>
                            JSON.parse(assinged)
                          ),
                        ]);
                      }}
                    />
                  )}
                </Col>
                <Col span={24}>
                  <Row>
                    <Col span={24}>
                      <h3 style={{ marginTop: 16 }}>Załączniki</h3>

                      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                      <Row align="middle" justify="space-between">
                        <Col sm={24} md={24} lg={24}>
                          <UploadAttachmentsList
                            displayHeader
                            attachments={uploadList}
                            handleRemove={handleAttachmentsRemove}
                          />
                        </Col>
                      </Row>
                      <Row
                        align="middle"
                        justify="space-between"
                        gutter={[0, 16]}
                      >
                        <Col sm={24} md={24} lg={24}>
                          <Upload
                            multiple
                            listType="text"
                            showUploadList={false}
                            customRequest={handleAttachmentsUpload}
                            onChange={handleAttachmentsChange}
                          >
                            <Button
                              icon={<PaperClipOutlined />}
                              style={{ marginTop: 16, marginBottom: 24 }}
                            >
                              Dodaj załącznik
                            </Button>
                          </Upload>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Can type="ticket_edit_duo_date">
                  <Col xs={24} md={24} lg={12} xl={10}>
                    <Form.Item
                      name="dueDate"
                      label="Termin"
                      required={dueDateRequired}
                      labelCol={{ span: 24 }}
                    >
                      <DatePicker
                        format="DD.MM.Y HH:mm"
                        showTime={{ showSecond: false }}
                        name="dueDate"
                        allowClear
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  </Col>
                </Can>
              </Row>

              <Can type="ticket_edit_priority">
                <Row gutter={16}>
                  <Col xs={24} md={24} lg={12} xl={10}>
                    <Form.Item
                      name="priorityId"
                      label="Priorytet"
                      labelCol={{ span: 24 }}
                    >
                      <Select
                        name="priorityId"
                        placeholder="Wybierz priorytet wątku"
                      >
                        {[5, 4, 3, 2, 1].map((value: number) => (
                          <Option value={value}>
                            <Space>
                              <Badge
                                status="processing"
                                text={
                                  <Space size="small">
                                    <Text>{Priority[value]}</Text>
                                  </Space>
                                }
                                color={PriorityColor[value]}
                              />
                            </Space>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Can>
              <Can type="ticket_edit_cost">
                <>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={12} xl={10}>
                      <Form.Item
                        name="paid"
                        label="Płatne"
                        labelCol={{ span: 24 }}
                      >
                        <Switch
                          name="paid"
                          onChange={(change) => {
                            if (!change) formProps.setFieldValue("cost", null);
                          }}
                          checkedChildren="Tak"
                          unCheckedChildren="nie"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={24} lg={12} xl={10}>
                      <Form.Item
                        label="Koszt"
                        name="cost"
                        labelCol={{ span: 24 }}
                        hidden={!formProps.values.paid}
                      >
                        <Input
                          type="number"
                          name="cost"
                          style={{ width: "100%" }}
                          suffix="zł."
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              </Can>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    loading={editRequest}
                    disabled={
                      uploadList &&
                      uploadList.findIndex(
                        (attachment: any) => attachment.status === "uploading"
                      ) !== -1
                    }
                  >
                    Zapisz
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        />
      </Card>
    </Spin>
  );
};

export default React.memo(DuplicateTicketForm);
