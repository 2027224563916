import { AnyAction } from "redux";

import {
  FETCH_MESSAGES_FAILED,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES,
  SET_NEW_MESSAGES,
  FETCH_NEW_MESSAGES_COUNT,
  FETCH_NEW_MESSAGES_COUNT_SUCCESS,
  FETCH_NEW_MESSAGES_COUNT_FAILED,
  FETCH_CHAT_USERS,
  FETCH_CHAT_USERS_SUCCESS,
  FETCH_CHAT_USERS_FAILED,
  POST_CHAT_MESSAGE,
  POST_CHAT_MESSAGE_SUCCESS,
  POST_CHAT_MESSAGE_FAILED,
  FETCH_NEW_MESSAGES,
  FETCH_NEW_MESSAGES_SUCCESS,
  FETCH_NEW_MESSAGES_FAILED,
  FETCH_ARCHIVE_MESSAGES,
  FETCH_ARCHIVE_MESSAGES_SUCCESS,
  FETCH_ARCHIVE_MESSAGES_FAILED,
  SET_CHAT_ATTACHMENTS,
  FETCH_CHAT_ATTACHMENTS,
  FETCH_CHAT_ATTACHMENTS_SUCCESS,
  FETCH_CHAT_ATTACHMENTS_FAILED,
  FETCH_CHAT_TICKETS,
  FETCH_CHAT_TICKETS_FAILED,
  FETCH_CHAT_TICKETS_SUCCESS,
  FETCH_CHAT_USER_TAGS,
  FETCH_CHAT_USER_TAGS_SUCCESS,
  FETCH_CHAT_USER_TAGS_FAILED,
  FETCH_LINK_MESSAGES,
  FETCH_LINK_MESSAGES_SUCCESS,
  FETCH_LINK_MESSAGES_FAILED,
  SET_SELECTED_CHAT_ROOM,
  FETCH_CHAT_ROOMS,
  FETCH_CHAT_ROOMS_SUCCESS,
  FETCH_CHAT_ROOMS_FAILED,
  POST_CHAT_REACTION_FAILED,
  POST_CHAT_REACTION_SUCCESS,
  CLEAR_CHAT_MESSAGES,
} from "../../actions/action-types";
import { isImageFileType, mergeByProperty } from "../../utils/common";

export interface ChatState {
  rooms: [];
  selectedRoom: number | null;
  messages: any;
  attachments: any;
  users: any;
  tags: any[];
  tickets: any[];
  newMessagesCount: number;
  newMessagesRoomsCount: any;
  isFetching: boolean;
  isUsersFetching: boolean;
  isRoomsFetching: boolean;
  isNewMessagesCountFetching: boolean;
  isSending: boolean;
  isArchiveFetching: boolean;
  isTagsFetching: boolean;
  isTicketsFetching: boolean;
  isAttachmentsFetching: boolean;
  allAttachmentsFetched: boolean;
}

const initialState: ChatState = {
  rooms: [],
  selectedRoom: null,
  messages: {},
  attachments: {},
  users: {},
  tickets: [],
  tags: [],
  newMessagesCount: 0,
  newMessagesRoomsCount: [],
  isFetching: false,
  isUsersFetching: false,
  isRoomsFetching: false,
  isNewMessagesCountFetching: false,
  isSending: false,
  isArchiveFetching: false,
  isTagsFetching: false,
  isTicketsFetching: false,
  isAttachmentsFetching: false,
  allAttachmentsFetched: false,
};

export default (state = initialState, action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_MESSAGES: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_MESSAGES_SUCCESS: {
      let newState = [];
      if (state.messages[payload.room]) {
        newState = [...state.messages[payload.room]];
        mergeByProperty(newState, payload.data, "id");
      } else {
        newState = payload.data;
      }

      // { ...state.messages, [payload.id]: payload.data }
      // const newState = [...state.messages[payload.room]];
      // mergeByProperty(newState, payload.data, "id");
      return {
        ...state,
        messages: { ...state.messages, [payload.room]: newState },
        isFetching: false,
      };
    }
    case FETCH_MESSAGES_FAILED: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case FETCH_LINK_MESSAGES: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_LINK_MESSAGES_SUCCESS: {
      // const newState = [...state.messages];
      // mergeByProperty(newState, payload, "id");

      let newState = [];
      if (state.messages[payload.room]) {
        newState = [...state.messages[payload.room]];
        mergeByProperty(newState, payload.data, "id");
      } else {
        newState = payload.data;
      }
      return {
        ...state,
        messages: { ...state.messages, [payload.room]: newState },
        isFetching: false,
      };
    }
    case FETCH_LINK_MESSAGES_FAILED: {
      return {
        ...state,
        isFetching: false,
      };
    }

    case FETCH_ARCHIVE_MESSAGES: {
      return {
        ...state,
        isArchiveFetching: true,
      };
    }
    case FETCH_ARCHIVE_MESSAGES_SUCCESS: {
      let newState = [];
      if (state.messages[payload.room]) {
        newState = [...state.messages[payload.room]];
        mergeByProperty(newState, payload.data, "id");
      } else {
        newState = payload.data;
      }
      return {
        ...state,
        messages: { ...state.messages, [payload.room]: newState },
        isArchiveFetching: false,
      };
    }
    case FETCH_ARCHIVE_MESSAGES_FAILED: {
      return {
        ...state,
        isArchiveFetching: false,
      };
    }

    case FETCH_CHAT_ATTACHMENTS: {
      return {
        ...state,
        isAttachmentsFetching: true,
      };
    }

    case SET_CHAT_ATTACHMENTS: {
      let newState = [];
      if (state.attachments[payload.room]) {
        newState = [...state.attachments[payload.room]];
        mergeByProperty(newState, payload.data, "id");
      } else {
        newState = payload.data;
      }

      newState.sort((a: any, b: any) => b.id - a.id);
      return {
        ...state,
        attachments: { ...state.attachments, [payload.room]: newState },
      };
    }
    case FETCH_CHAT_ATTACHMENTS_SUCCESS: {
      let newState = [];
      if (state.attachments[payload.room]) {
        newState = [
          ...state.attachments[payload.room].filter((file: any) =>
            isImageFileType(file.fileMimeType)
          ),
        ];
        mergeByProperty(newState, payload.data, "id");
      } else {
        newState = payload.data;
      }

      newState.sort((a: any, b: any) => b.id - a.id);
      return {
        ...state,
        attachments: { ...state.attachments, [payload.room]: newState },
        allAttachmentsFetched: payload.length === 0,
        isAttachmentsFetching: false,
      };
    }
    case FETCH_CHAT_ATTACHMENTS_FAILED: {
      return {
        ...state,
        isArchiveFetching: false,
      };
    }

    case FETCH_NEW_MESSAGES_SUCCESS: {
      let newState = [];
      if (state.messages[payload.room]) {
        newState = [...state.messages[payload.room]];
        mergeByProperty(newState, payload.data, "id");
      } else {
        newState = payload.data;
      }
      return {
        ...state,
        messages: { ...state.messages, [payload.room]: newState },
      };
    }

    case FETCH_NEW_MESSAGES_COUNT: {
      return {
        ...state,
        isNewMessagesCountFetching: true,
      };
    }
    case FETCH_NEW_MESSAGES_COUNT_SUCCESS: {
      return {
        ...state,
        newMessagesCount: payload.count,
        newMessagesRoomsCount: payload.rooms,
        isNewMessagesCountFetching: false,
      };
    }

    case FETCH_NEW_MESSAGES_COUNT_FAILED: {
      return {
        ...state,
        isNewMessagesCountFetching: false,
      };
    }

    case FETCH_CHAT_USERS: {
      return {
        ...state,
        isUsersFetching: true,
      };
    }
    case FETCH_CHAT_USERS_SUCCESS: {
      return {
        ...state,
        users: { ...state.users, [payload.room]: payload.data },
        isUsersFetching: false,
      };
    }
    case FETCH_CHAT_USERS_FAILED: {
      return {
        ...state,
        isUsersFetching: false,
      };
    }

    case FETCH_CHAT_ROOMS: {
      return {
        ...state,
        isRoomsFetching: true,
      };
    }
    case FETCH_CHAT_ROOMS_SUCCESS: {
      return {
        ...state,
        rooms: payload,
        isRoomsFetching: false,
      };
    }
    case FETCH_CHAT_ROOMS_FAILED: {
      return {
        ...state,
        isRoomsFetching: false,
      };
    }

    case FETCH_CHAT_TICKETS: {
      return {
        ...state,
        isTicketsFetching: true,
      };
    }
    case FETCH_CHAT_TICKETS_SUCCESS: {
      return {
        ...state,
        tickets: payload,
        isTicketsFetching: false,
      };
    }
    case FETCH_CHAT_TICKETS_FAILED: {
      return {
        ...state,
        isTicketsFetching: false,
      };
    }

    case FETCH_CHAT_USER_TAGS: {
      return {
        ...state,
        isTagsFetching: true,
      };
    }
    case FETCH_CHAT_USER_TAGS_SUCCESS: {
      return {
        ...state,
        tags: payload,
        isTagsFetching: false,
      };
    }
    case FETCH_CHAT_USER_TAGS_FAILED: {
      return {
        ...state,
        isTagsFetching: false,
      };
    }

    case POST_CHAT_MESSAGE: {
      return {
        ...state,
        isSending: true,
      };
    }
    case POST_CHAT_MESSAGE_SUCCESS: {
      return {
        ...state,
        isSending: false,
      };
    }
    case POST_CHAT_MESSAGE_FAILED: {
      return {
        ...state,
        isSending: false,
      };
    }

    case SET_NEW_MESSAGES: {
      return {
        ...state,
        newMessagesCount: payload,
      };
    }

    case SET_SELECTED_CHAT_ROOM: {
      return {
        ...state,
        selectedRoom: payload,
      };
    }

    case POST_CHAT_REACTION_SUCCESS: {
      let newState = [];
      if (state.messages[payload.room]) {
        newState = [...state.messages[payload.room]];
        mergeByProperty(newState, payload.data, "id");
      } else {
        newState = payload.data;
      }

      return {
        ...state,
        messages: { ...state.messages, [payload.room]: newState },
        isFetching: false,
      };
    }
    case CLEAR_CHAT_MESSAGES: {
      return { ...state, messages: [] };
    }

    default:
      return state;
  }
};
